import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/TableStyles";
import ProfileCard from "../../User/Family/ProfileCard";
import HealthCard from "../../User/Health/HealthCard";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import { Auth } from "aws-amplify";
import config from "../../config";

class InformationSummary extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.props.profiles.map( (profile,index) => {
          return (
            <ProfileCard
              Summary={true}
              key={"profileCard_" + profile.id}
              profile={profile}
              isBrokerRendered={true}
            />
          );
        })}
        {this.props.healthRecords.map( (healthRecord, index) => {
          return (
              <HealthCard
                Summary={true}
                isBrokerRendered={true}
                profiles={this.props.profiles}
                key={index}
                healthID={healthRecord.id}
                Condition={healthRecord.condition}
                ICD={healthRecord.icd}
                HasPrognosis={healthRecord.has_progosis}
                HasHospitalization={healthRecord.has_hospitalization}
                HasSurgery={healthRecord.has_surgery}
                HasMedication={healthRecord.has_medication}
                Profile={healthRecord.profile}
                StartDate={healthRecord.start_date}
                EndDate={healthRecord.end_date}
                DateLabel={healthRecord.date_label}
                Prognosis={healthRecord.prognosis}
                CustomDetails={healthRecord.details}
                PastHospitalization={healthRecord.past_hospitalization}
                FutureHospitalization={healthRecord.future_hospitalization}
                PastSurgery={healthRecord.past_surgery}
                FutureSurgery={healthRecord.future_surgery}
                Medications={healthRecord.medications}
                Detail={healthRecord.icd_description}
              />
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InformationSummary);
