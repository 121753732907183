import { 
    LOAD_USER,
    UPDATE_CUSTOMER_CODE,
} from "../Constants/ActionTypes";

const intitialState = {
    isLoaded: false,
    username: "",
    user_id: "",
    profile_id: "",
    employer_id: "",
    customer_id: "",
    preferredMFA: "",
    federatedUser: false,
    given_name: "",
    family_name: "",
    email: "",
    email_verified: false,
    phone_number: "",
    phone_number_verified: false,
    picture: ""
};

const AuthReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_USER:
            return {
                ...state,
                //user: action.payload,
                username: action.payload.username,
                user_id: action.payload.user_id,
                profile_id: action.payload.profile_id,
                employer_id: action.payload.employer_id,
                employer_code: action.payload.employer_code ? action.payload.employer_code : null, 
                employer_name: action.payload.employer_name ? action.payload.employer_name : null, 
                quoting_end_date: action.payload.quoting_end_date ? action.payload.quoting_end_date : null,
                customer_id: action.payload.customer_id,
                customer_code: action.payload.customer_code ? action.payload.customer_code : null,
                preferredMFA: action.payload.preferredMFA,
                federatedUser:
                    action.payload.username.includes("Google_") ||
                    action.payload.username.includes("Facebook_"),
                given_name: action.payload.attributes.given_name,
                family_name: action.payload.attributes.family_name,
                email: action.payload.attributes.email,
                email_verified: action.payload.attributes.email_verified,
                phone_number: action.payload.attributes.phone_number,
                phone_number_verified:
                    action.payload.attributes.phone_number_verified,
                picture: action.payload.attributes.picture,
                isLoaded: true
            };
        case UPDATE_CUSTOMER_CODE:
            return {
                ...state,
                customer_code: action.payload
            };
        default:
            return state;
    }
};

export default AuthReducer;
