export const LOAD_USER = "LOAD_USER";

export const LOAD_CARRIERS = "LOAD_CARRIERS";

export const LOAD_CONDITION_CATEGORIES = "LOAD_CONDITION_CATEGORIES";

export const LOAD_STATES = "LOAD_STATES";

export const LOAD_FORMS = "LOAD_FORMS";

export const UPDATE_CUSTOMER_CODE = "UPDATE_CUSTOMER_CODE";