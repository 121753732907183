import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { Auth } from "aws-amplify";
import config from "../config";

import UserRouter from "../Auth/UserRouter";

//import SecuritySettings from '../UserSettings/SecuritySettings'

import SubmissionsContainer from "./Submissions/SubmissionsContainer";
import FormReviewContainer from "./FormReview/FormReviewContainer";

import NavList from "./Nav/NavList";
import SettingsMenu from "./Nav/SettingsMenu";

import BaseContainer from "../Shared/Layouts/BaseContainer";

import { connect } from "react-redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/Store/index";
import api from "../Shared/Functions/APIHelpers";

import { loadUser } from "../Shared/Redux/Actions/index";
import { loadMessageSummaries, setNewMessageCount } from "./Redux/Actions/index";
import { getUser } from "../Shared/Functions/StaticFunctions";


const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
    loadMessageSummaries: messageSummaries => dispatch(loadMessageSummaries(messageSummaries)),
    setNewMessageCount: newMessageCount => dispatch(setNewMessageCount(newMessageCount)),
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    isUserLoaded: state.AuthReducer.isLoaded,
    isMessageSummariesLoaded : state.MessageReducer.isMessageSummariesLoaded,
    messageSummaries: state.MessageReducer.messageSummaries,
    newMessageCount: state.MessageReducer.newMessageCount,
  };
};

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {}
  };

  componentDidMount() {
    if (!this.props.isUserLoaded) {
      getUser(Auth).then(user => {
        this.props.loadUser(user);
        this.loadEverythingElse(user.user_id);
      });
    }
  }

  loadEverythingElse = userID => {

    if(!this.props.isMessageSummariesLoaded) {
      api
        .getMessageSummaries(userID)
        .then(response => {
          this.props.loadMessageSummaries(response)
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Message Summaries" });
          console.log(err);
        });

      api
        .getNewMessageCount(userID)
        .then(response => {
          this.props.setNewMessageCount(response)
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting New Message Count" });
          console.log(err);
        });
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    //const { loaded, isAuthenticated } = this.state;
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <BaseContainer
              {...props}
              user={this.props.user}
              reformUser={this.props.auth}
              hasMessageBar={true}
              hasMessageMenu={true}
              newMessageCount={this.props.newMessageCount}
              setNewMessageCount={this.props.setNewMessageCount}
              messageSummaries={this.props.messageSummaries}
              fixed={this.props.fixed}
              component={Component}
              navList={NavList}
              home={"/carrier"}
              settingsMenu={SettingsMenu}
              isLoaded={this.props.isUserLoaded}
            />
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute);
PrivateRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivateRoute)
);

const Routes = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/carrier"
            user={props.user}
            fixed={true}
            component={SubmissionsContainer}
          />
          <PrivateRoute
            exact
            path="/carrier/user-router"
            user={props.user}
            component={UserRouter}
          />
          <PrivateRoute
            exact
            path="/carrier/home"
            user={props.user}
            fixed={true}
            component={SubmissionsContainer}
          />
          <PrivateRoute
            path="/carrier/submissions"
            user={props.user}
            fixed={true}
            component={SubmissionsContainer}
          />
          <PrivateRoute
            path="/carrier/form-review"
            user={props.user}
            fixed={true}
            component={FormReviewContainer}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
/*export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);*/
