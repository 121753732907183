import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { Route, withRouter } from "react-router-dom";
import api from "../../Shared/Functions/APIHelpers";

import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Pin from "../Shared/Pin";
import MedicareInfo from "./MedicareInfo";
import SignatureContainer from "./SignatureContainer";
import Form from "./Form";

class MedicareContainer extends React.Component {
  state = {
    pin: "",
    id: "",
    preloaded: "",
    presigned: "",
    step: "notverified",
    states: []
  };

  componentDidMount() {
    this.getStates();
  }

  isVerified = (result, pin, id) => {
    this.setState({
      pin: pin,
      id: id,
      preloaded: result.preloaded,
      presigned: result.presigned
    }, () => { this.setStep("sign")})

  };

  notVerified = () => {
    this.props.enqueueSnackbar("Incorrect Pin ", {
      variant: "error"
    });
  };

  setStep = step => {
    this.setState({ step: step });
  };

  getStates = () => {
    return new Promise((resolve, reject) => {
      api
        .getStates()
        .then(response => {
          this.setState({ states: response });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.basecontent}>
        <Paper className={classes.baseroot} elevation={1} square={true}>
          {this.state.step === "notverified" && (
            <Pin
              type="Medicare"
              isVerified={this.isVerified}
              notVerified={this.notVerified}
            />
          )}

          {this.state.step === "info" && (
            <MedicareInfo
              preloaded={this.state.preloaded}
              setStep={this.setStep}
              states={this.state.states}
              id={this.state.id}
              pin={this.state.pin}
            />
          )}

          {this.state.step === "form" && (
            <Form />
          )}

          {this.state.step === "sign" && (
            <SignatureContainer
              presigned={this.state.presigned}
            />  
          )}

        </Paper>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(MedicareContainer));