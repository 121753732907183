import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { EditIcon, SendPlaneIcon } from "../../Shared/Components/Icons";

//import EditFormGroup from "./EditFormGroup";

import { Auth } from "aws-amplify";
import config from "../../config";

import InviteEmployer from "../Employers/InviteEmployer";
import { setEmployerInvitationTable, openInviteEmployer } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setEmployerInvitationTable: employerInvitationTable =>
      dispatch(setEmployerInvitationTable(employerInvitationTable)),
    openInviteEmployer: () => dispatch(openInviteEmployer())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    employerInvitationTable: state.TableReducer.employerInvitationTable
  };
};

class InvitationsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, invitation) => {
    var tempState = this.props.employerInvitationTable;
    tempState.selected = invitation;
    this.props.setEmployerInvitationTable(tempState);

    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  handleEditEmployerInvitation = () => {
    this.props.openInviteEmployer();
    this.handleCloseMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <InviteEmployer isEdit={true} refreshTable={this.handleRefreshTable} />
        <CustomTable
          columns={[
            {
              label: "Code",
              dataKey: "code",
              numeric: false,
              width: 300
            },
            {
              label: "Employer",
              dataKey: "employer_name",
              numeric: false,
              width: 150
            },
            {
              label: "Email",
              dataKey: "email",
              numeric: false,
              width: 150
            },
            {
              label: "Satus",
              dataKey: "status",
              numeric: false,
              width: 150
            }
          ]}
          name={'Invitations'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={
            "customers/"+ this.props.auth.customer_id +"/employer-invitations"
          }

          filters={[
            {column:"status", modelColumn:"status",options:['Sent', 'Accepted', 'Expired', 'Pending']},
          ]}
          
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleEditEmployerInvitation}>
            <EditIcon className={classes.leftIcon} /> Edit Invitation
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvitationsTable));
