import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import ExitIcon from "@material-ui/icons/ExitToApp";

import CircularProgress from "@material-ui/core/CircularProgress";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { getEmployeeName } from "../Shared/Functions/StaticFunctions";

import { connect } from "react-redux";

import sig_styles from "./Styles/sig_styles.cssm";
import SignaturePad from "./signature_pad.js";

import "./Styles/checkmark.css";

//import S3FileUpload from 'react-s3';

import api from "../../Shared/Functions/APIHelpers";

import { Storage } from "aws-amplify";
import { Auth } from "aws-amplify";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    profiles: state.ProfilesReducer.profiles
  };
};

const styles = theme => ({
  title: {
    "@media only screen and (max-height: 400px)": {
      display: "none"
    }
  },
  content: {
    height: 400
  },
  saving: {
    marginTop: 90,
    width: 700,
    textAlign: "center",
    "@media only screen and (max-width: 400px)": {
      width: "100%"
    }
  },
  sigDiv: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-width: 800px)": {
      justifyContent: "left"
    }
  },
  textField: {
    "@media only screen and (max-width: 600px)": {
      display: "none"
    },
    "@media only screen and (max-height: 400px)": {
      display: "none"
    }
  },
  instructions: {
    //marginTop:10,
    "@media only screen and (max-height: 400px)": {
      display: "none"
    }
  },
  notification: {
    fontSize: 12
    //color: 'blue',
  },
  sigFooterDiv: {
    textAlign: "center",
    "@media only screen and (max-width: 910px)": {
      textAlign: "center"
    }
  },
  checkDone: {
    height: 300,
    minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%"
    },
    "@media only screen and (max-height: 400px)": {
      marginTop: 0,
      height: 280
    }
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

export class Signature extends React.Component {
  state = {
    trimmedDataURL: null,
    name: "",
    ip: "",
    alert: false,
    sigWidth: 700,
    canvasProps: { height: 250, width: 700, clearonresize: "false" },
    sigDone: false,
    sigSuccess: false
  };

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName("body")[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height =
        w.innerHeight || documentElement.clientHeight || body.clientHeight;

    this.setState({ width, height });

    if (width > 900) {
      this.setState({
        canvasProps: { clearonresize: "false", height: 250, width: 700 }
      });
    } else if (width > 400) {
      this.setState({
        canvasProps: { clearonresize: "false", height: 250, width: width - 150 }
      });
    } else {
      this.setState({
        canvasProps: { clearonresize: "false", height: 250, width: width - 50 }
      });
    }
  };

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    //get external IP for stamp
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => this.setState({ ip: data.ip }));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getCurrentForms = () => {
    return new Promise((resolve, reject) => {
      api
        .getCurrentForms(this.props.auth.user_id, true)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getFooterStampPrivate = time => {
    return (
      getEmployeeName(this.props.profiles, this.props.auth.profile_id) +
      " - " +
      time +
      " from " +
      this.state.ip +
      " - Reform Health"
    );
  };

  getFooterStampPublic = time => {
    return "JOE" + " - " + time + " from " + this.state.ip + " - Reform Health";
  };

  storeImageFilePrivate = buf => {
    Storage.configure({ level: "private" });

    Storage.put("sig_versioned.png", buf, {
      level: "private",
      contentType: "img/png"
    })
      .then(result => {
        this.getCurrentForms().then(r => {
          this.setState({ sigSuccess: true });
        });
      })
      .catch(err => console.log(err));
  };

  storeImageFilePublic = buf => {
    /*var body = JSON.stringify({
      file: buf,
      key : this.props.Presigned.fields.key,
      policy: this.props.Presigned.fields.policy,
      'x-amz-algorithm': this.props.Presigned.fields['x-amz-algorithm'],
      'x-amz-credential' : this.props.Presigned.fields['x-amz-credential'],
      'x-amz-date' : this.props.Presigned.fields['x-amz-date'],
      'x-amz-signature' : this.props.Presigned.fields['x-amz-signature'],
    });*/
    var file = new File([buf], "pic",{type: "image/png"});
    console.log(this.props.Presigned.fields);
    const formData = new FormData();
    formData.append("key",this.props.Presigned.fields.key);
    formData.append("file", file);
    formData.append("AWSAccessKeyId", this.props.Presigned.fields.AWSAccessKeyId);
    formData.append("policy", this.props.Presigned.fields.policy);
    formData.append("signature", this.props.Presigned.fields.signature);
    /*
    formData.append("x-amz-algorithm", this.props.Presigned.fields['x-amz-algorithm']);
    formData.append("x-amz-credential", this.props.Presigned.fields['x-amz-credential']);  
    formData.append("x-amz-date", this.props.Presigned.fields["x-amz-date"]);  
    formData.append("x-amz-signature", this.props.Presigned.fields["x-amz-signature"]);    
    */
    console.log(formData);
    api
      .storePublicSignature(this.props.Presigned.url, formData)
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        this.setState({ errorMessage: "Error Getting Forms" });
        console.log(err);
      });

    this.setState({ sigSuccess: true });
  };

  addStamp = () => {
    var canvas = this.refs.canvas_stamped;
    var ctx = canvas.getContext("2d");

    var time = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }).format(Date.now());
    if (this.props.Public) var footerStamp = this.getFooterStampPublic(time);
    else var footerStamp = this.getFooterStampPrivate(time);

    var img_temp = document.createElement("img");
    img_temp.setAttribute("src", this.state.trimmedDataURL);

    img_temp.onload = () => {
      //ctx.scale(1/dpr, 1/dpr)
      ctx.drawImage(img_temp, 0, 25);

      var baseFontSize = 16;
      var fontSize = baseFontSize;
      ctx.font = String(fontSize) + "px sans-serif";
      ctx.textBaseline = "top";
      ctx.fillStyle = "#fff";

      var width = ctx.measureText(footerStamp).width;
      var height = parseInt(ctx.font, 8);

      ctx.fillRect(5, canvas.height - 20, width, height);

      //set stamp font color
      ctx.fillStyle = "#808080";
      ctx.fillText(footerStamp, 5, canvas.height - 20);

      var img = this.refs.canvas_stamped.toDataURL("image/png");

      var buf = new Buffer(
        img.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      if (this.props.Public) this.storeImageFilePublic(buf);
      else this.storeImageFilePrivate(buf);
    };
  };

  //should we ever wish to display the signature...
  /*
  load = () => {

    Storage.get('sig_versioned.png', {level: 'private'})
      .then(result => {
        console.log(result)
        this.setState({trimmedDataURL: result})
        var canvas = this.refs.canvas_stamped
        var ctx = canvas.getContext('2d')

        var img_temp = document.createElement("img")
        img_temp.setAttribute("src", result)
        img_temp.onload = () => {
          ctx.drawImage(img_temp, 0, 0)
        }
      })
      .catch(err => console.log(err));
  }
  */

  handleNameChange = event => {
    this.setState({ name: event.target.value });

    var canvas = this.sigPad.getCanvas();
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //set font type
    ctx.font = "70px 'La Belle Aurore', cursive";
    ctx.textBaseline = "top";

    //set font color
    ctx.fillStyle = "#000";
    ctx.fillText(event.target.value, 0, 90);
  };

  handleSave = () => {
    var img = this.sigPad.getCanvas().toDataURL("image/png");
    var trimmed = this.sigPad.getTrimmedCanvas().toDataURL("image/png");

    var img_temp = document.createElement("img");

    img_temp.setAttribute("src", trimmed);

    setTimeout(
      function() {
        var height = img_temp.height;
        var width = img_temp.width;

        if (!(height > 70 || width > 70)) {
          this.setState({ alert: true });
        } else {
          this.setState(
            {
              trimmedDataURL: img
            },
            () => {
              this.addStamp();
              this.setState({ sigDone: true });
            }
          );
        }
      }.bind(this),
      0
    );
  };

  handleClearSignature = () => {
    this.setState({
      name: "",
      alert: false
    });

    if (this.state.trimmedDataURL) {
      this.setState({ trimmedDataURL: null });
    } else {
      this.sigPad.clear();
    }
  };

  handleClose = () => {
    this.props.Close();
  };

  handleSignOut = () => {
    Auth.signOut().then(() => {
      localStorage.clear();
    });
  };

  render() {
    let { trimmedDataURL } = this.state;
    const { classes } = this.props;

    const { fullScreen } = this.props;

    return (
      <Dialog
        open={this.props.isOpen}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        fullScreen={fullScreen}
        scroll="paper"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Signature
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.sigDiv}>
            <div>
              <div style={{ display: this.state.sigDone ? "none" : "block" }}>
                <div className={classes.textField}>
                  <Typography
                    variant="subtitle2"
                    className={classes.instructions}
                  >
                    Draw or type your signature to complete your application.
                  </Typography>
                  <TextField
                    id="standard-dense"
                    label="Full Name"
                    margin="dense"
                    onChange={this.handleNameChange}
                    value={this.state.name}
                    autoComplete="off"
                    fullWidth
                  />
                </div>
              </div>

              {!this.state.sigDone && this.state.name ? (
                <Typography
                  color="primary"
                  className={classes.notification}
                  gutterBottom
                >
                  You may still draw over your signature!
                </Typography>
              ) : null}

              <div
                className={sig_styles.sigContainer}
                style={{
                  height: 250,
                  marginTop: 10,
                  borderTop: "0px solid #d9d9d9",
                  borderBottom: "1px solid #d9d9d9",
                  marginBottom: 25,
                  display: this.state.sigDone ? "none" : "block"
                }}
              >
                <SignaturePad
                  canvasProps={this.state.canvasProps}
                  ref={ref => {
                    this.sigPad = ref;
                  }}
                />
                <div className={classes.sigFooterDiv}>
                  {this.state.alert ? (
                    <Typography variant="caption" color="error" gutterBottom>
                      Please make your signature a little larger!
                    </Typography>
                  ) : null}
                </div>
              </div>

              <div style={{ display: "none" }}>
                <canvas ref="canvas_stamped" width={700} height={300} />
              </div>

              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  display: this.state.sigDone ? "none" : "inline-block"
                }}
              >
                <Button
                  variant="contained"
                  color="default"
                  style={{ width: 150, marginLeft: 0 }}
                  onClick={this.handleClearSignature}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 150, marginLeft: 20 }}
                  onClick={this.handleSave}
                >
                  Sign
                </Button>
              </div>
            </div>
          </div>

          {trimmedDataURL ? (
            <img
              ref="sig_image"
              alt="sig_image"
              className={sig_styles.sigImage}
              src={trimmedDataURL}
              style={{ display: "none" }}
            />
          ) : null}

          {this.state.sigDone && !this.state.sigSuccess && (
            <React.Fragment>
              <div className={classes.saving}>
                <CircularProgress />
                <Typography variant="body1">Saving...</Typography>
              </div>
            </React.Fragment>
          )}

          {this.state.sigSuccess && (
            <React.Fragment>
              <div className={classes.checkDone}>
                <div class="checkmark">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 161.2 161.2"
                    enableBackground="new 0 0 161.2 161.2"
                  >
                    <path
                      class="path"
                      fill="none"
                      stroke="#7DB0D5"
                      strokeMiterlimit="10"
                      d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                      c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                      c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                    />
                    <circle
                      class="path"
                      fill="none"
                      stroke="#7DB0D5"
                      strokeWidth="4"
                      strokeMiterlimit="10"
                      cx="80.6"
                      cy="80.6"
                      r="62.1"
                    />
                    <polyline
                      class="path"
                      fill="none"
                      stroke="#7DB0D5"
                      strokeWidth="6"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="113,52.8 
                      74.1,108.4 48.2,86.4 "
                    />
                    <circle
                      class="spin"
                      fill="none"
                      stroke="#7DB0D5"
                      strokeWidth="4"
                      strokeMiterlimit="10"
                      strokeDasharray="12.2175,12.2175"
                      cx="80.6"
                      cy="80.6"
                      r="73.9"
                    />
                  </svg>
                  <Typography
                    variant="h4"
                    style={{ textAlign: "center", marginTop: 10 }}
                  >
                    All done!
                  </Typography>
                </div>
              </div>

              <div style={{ marginTop: 0, textAlign: "center" }}>
                <Button
                  variant="contained"
                  style={{ width: 250 }}
                  color="primary"
                  onClick={this.handleSignOut}
                >
                  <ExitIcon className={classes.leftIcon} />
                  Sign out
                </Button>
              </div>
            </React.Fragment>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            style={{ marginLeft: -17 }}
            onClick={this.handleClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(Signature)));

//export { Signature as SignatureNoRedux};