import { SET_SUBMISSION_TABLE } from "../Constants/ActionTypes";

const initialState = {
    submissionTable: {
        selected: {},
        filters: {},
        sort: {}
    }
};

const TableReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBMISSION_TABLE:
            return { ...state, submissionTable: action.payload };
        default:
            return state;
    }
};

export default TableReducer;
