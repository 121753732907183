import {
    LOAD_HEALTH,
    ADD_HEALTH,
    UPDATE_HEALTH,
    DELETE_HEALTH,
    SET_ACTIVE_HEALTH,
    CLEAR_ACTIVE_HEALTH,
    SET_ACTIVE_CONDITION,
    CLEAR_ACTIVE_CONDITION,
    HEALTH_LOADED
} from "../Constants/ActionTypes";

const intitialState = {
    health: [],
    isLoaded: false,
    isLoading: false,
    activeHealth: {},
    activeCondition: ""
};

const healthReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_HEALTH:
            return {
                ...state,
                health: state.health.concat(action.payload),
                isLoaded: true
            };
        case ADD_HEALTH:
            return { ...state, health: state.health.concat(action.payload) };
        case UPDATE_HEALTH:
            return {
                ...state,
                health: state.health.map(health =>
                    health.id === action.payload.id ? action.payload : health
                )
            };
        case DELETE_HEALTH:
            return {
                ...state,
                health: state.health.filter(
                    health => health.id !== action.payload
                )
            };
        case SET_ACTIVE_HEALTH:
            return {
                ...state,
                activeHealth: state.health.find(health =>
                    health.id === action.payload ? health : null
                )
            };
        case CLEAR_ACTIVE_HEALTH:
            return { ...state, activeHealth: {} };
        case SET_ACTIVE_CONDITION:
            return { ...state, activeCondition: action.payload };
        case CLEAR_ACTIVE_CONDITION:
            return { ...state, activeCondition: "" };
        case HEALTH_LOADED:
            return { ...state, isLoaded: true };
        default:
            return state;
    }
};

export default healthReducer;
