export const LOAD_FIELDS = "LOAD_FIELDS";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const ADD_FIELD = "ADD_FIELD";
export const DELETE_FIELD = "DELETE_FIELD";

export const LOAD_CONDITION_RULES = "LOAD_CONDITION_RULES";
export const DELETE_CONDITION_RULE = "DELETE_CONDITION_RULE";
export const ADD_CONDITION_RULE = "ADD_CONDITION_RULE";

export const LOAD_CONDITION_GROUP_RULES = "LOAD_CONDITION_GROUP_RULES";
export const DELETE_CONDITION_GROUP_RULE = "DELETE_CONDITION_GROUP_RULE";
export const ADD_CONDITION_GROUP_RULE = "ADD_CONDITION_GROUP_RULE";

export const LOAD_MODELS = "LOAD_MODELS";
export const LOAD_MODEL_FIELDS = "LOAD_MODEL_FIELDS";

export const CLOSE_EDIT_FORM_REVISION = "CLOSE_EDIT_FORM_REVISION";
export const OPEN_EDIT_FORM_REVISION = "OPEN_EDIT_FORM_REVISION";

export const CLOSE_EDIT_FORM = "CLOSE_EDIT_FORM";
export const OPEN_EDIT_FORM = "OPEN_EDIT_FORM";

export const SET_FORM_TABLE = "SET_FORM_TABLE";
export const SET_FORM_REVISION_TABLE = "SET_FORM_REVISION_TABLE";






