import {
    LOAD_EMPLOYMENT,
    ADD_EMPLOYMENT,
    UPDATE_EMPLOYMENT,
    DELETE_EMPLOYMENT,
    SET_ACTIVE_EMPLOYMENT,
    CLEAR_ACTIVE_EMPLOYMENT,
    EMPLOYMENT_LOADED
} from "../Constants/ActionTypes";

const intitialState = {
    employment: [],
    isLoaded: false,
    isLoading: false,
    activeEmployment: {}
};

const employmentReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_EMPLOYMENT:
            return {
                ...state,
                employment: state.employment.concat(action.payload)
            };
        case ADD_EMPLOYMENT:
            return {
                ...state,
                employment: state.employment.concat(action.payload)
            };
        case UPDATE_EMPLOYMENT:
            return {
                ...state,
                employment: state.employment.map(employment =>
                    employment.id === action.payload.id
                        ? action.payload
                        : employment
                )
            };
        case DELETE_EMPLOYMENT:
            return {
                ...state,
                employment: state.employment.filter(
                    employment => employment.id !== action.payload
                )
            };
        case SET_ACTIVE_EMPLOYMENT:
            return {
                ...state,
                activeEmployment: state.employment.find(employment =>
                    employment.id === action.payload ? employment : null
                )
            };
        case CLEAR_ACTIVE_EMPLOYMENT:
            return { ...state, activeEmployment: {} };
        case EMPLOYMENT_LOADED:
            return { ...state, isLoaded: true };
        default:
            return state;
    }
};

export default employmentReducer;
