import { createStore } from "redux";
import rootReducer from "../Reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "broker",
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/*
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
*/

export const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({
            name: "Reform Health - Broker"
        })
);
export const persistor = persistStore(store);
