import { combineReducers } from "redux";
import UIReducer from "./UIReducer";
import TableReducer from "./TableReducer";
import MessageReducer from "./MessageReducer";
import AuthReducer from "../../../Shared/Redux/Reducers/AuthReducer";
import CarrierReducer from "../../../Shared/Redux/Reducers/CarrierReducer";
import StateReducer from "../../../Shared/Redux/Reducers/StateReducer";
import FormReducer from "../../../Shared/Redux/Reducers/FormReducer";


const rootReducer = combineReducers({
    UIReducer,
    TableReducer,
    AuthReducer,
    CarrierReducer,
    StateReducer,
    FormReducer,
    MessageReducer,
});

export default rootReducer;
