import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";

import IconButton from "@material-ui/core/IconButton";

import CollapseLeft from "@material-ui/icons/ChevronLeft";

import blue from "@material-ui/core/colors/blue";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/HighlightOff";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AsyncSelect from "react-select/lib/Async";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../Shared/Functions/APIHelpers";

import {
  formatDate,
  getDateFromMonthYear,
  getMonth,
  getMonthFromDate,
  getYearFromDate
} from "../../Shared/Functions/Format";

import { Auth } from "aws-amplify";

import config from "../../config";

import { connect } from "react-redux";

import {
  addHealth,
  updateHealth,
  deleteHealth,
  closeEditHealth,
  openAddHealth,
  clearActiveHealth,
  clearActiveCondition
} from "../Redux/Actions/index";

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const styles = theme => ({
  title: {
    backgroundColor: "#2996cc",
    color: "white"
  },
  header: {
    fontSize: 18,
    color: "#4d4d4d"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 2
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 0
  },
  content: {
    minWidth: 500,
    maxWidth: 500,
    height: 920,
    "@media only screen and (min-height: 1200px)": {
      //height: 920,
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: 0
      //minHeight: 0,
    }
  }
});

const mapDispatchToProps = dispatch => {
  return {
    addHealth: health => dispatch(addHealth(health)),
    updateHealth: health => dispatch(updateHealth(health)),
    deleteHealth: healthID => dispatch(deleteHealth(healthID)),
    closeEditHealth: () => dispatch(closeEditHealth()),
    openAddHealth: () => dispatch(openAddHealth()),
    clearActiveHealth: () => dispatch(clearActiveHealth()),
    clearActiveCondition: () => dispatch(clearActiveCondition())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    activeHealth: state.HealthReducer.activeHealth,
    activeCondition: state.HealthReducer.activeCondition,
    healthModalOpen: state.UIReducer.healthModalOpen,
    isEdit: state.UIReducer.isEdit,
    isAdd: state.UIReducer.isAdd,
    conditionCategories: state.ConditionCategoryReducer.conditionCategories,
    profiles: state.ProfilesReducer.profiles
  };
};

const initialState = {
  errorMessage: "",
  directAdd: false,

  navHeader: "Select a category to add a health record:",

  conditionID: "",
  condition: {},

  step: "condition",
  conditionStep: "category",
  conditionDone: false,
  familyDone: false,

  category: "",
  categoryName: "",

  conditions: [],
  details: [],

  detail1List: [],
  detail1: null,
  detail1Name: "",

  detail2List: [],
  detail2: null,
  detail2Name: "",

  detail3List: [],
  detail3: null,
  detail3Name: "",

  profileID: "",
  profile: "",

  conditionStartMonth: "",
  conditionStartYear: "",
  conditionEndMonth: "",
  conditionEndYear: "",

  specificDate: "",

  prognosis: "",
  customDetails: [],
  customDetailsTextValues: [null, "A", "B", null, null, "C"],
  customDetailsYesNoValues: [null, null, null, "Yes"],

  checkedCurrentTreatment: true,
  checkedPastHospitalization: false,
  checkedFutureHospitalization: false,
  checkedPastSurgery: false,
  checkedFutureSurgery: false,
  checkedMedication: false,
  checkedSameDates: true,
  checkedCurrentMedication: true,

  medicationSearchValue: "",
  medication: "",
  medicationName: "",
  medications: [],
  dosages: [],

  dosage: "",
  frequency: "",

  medicationStartMonth: "",
  medicationStartYear: "",
  medicationEndMonth: "",
  medicationEndYear: "",

  selectedMeds: [],

  icdID: "-",
  icdDescription: "",

  confirmAlertOpen: false
};

class EditHealth extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  asyncRef: ElementRef<*>;

  componentDidUpdate(prevProps) {
    if (this.props.healthModalOpen && !prevProps.healthModalOpen) {
      this.reset();
    }
    if (this.props.activeCondition !== "" && this.state.conditionID === "") {
      this.setState(
        {
          directAdd: true,
          conditionStep: "",
          conditionID: this.props.activeCondition
        },
        () => {
          console.log('setting condition?')
          this.setCondition(this.props.activeCondition);
        }
      );
    }

    if (this.props.activeHealth.id !== prevProps.activeHealth.id) {
      if (this.props.activeHealth.id !== undefined) {
        if (this.props.activeHealth.end_date != "") {
          this.setState({
            checkedCurrentTreatment: false,
            conditionEndMonth: getMonthFromDate(
              this.props.activeHealth.end_date
            ),
            conditionEndYear: getYearFromDate(this.props.activeHealth.end_date)
          });
        } else {
          this.setState({
            checkedCurrentTreatment: true,
            conditionEndMonth: "",
            conditionEndYear: ""
          });
        }

        if (!this.props.activeHealth.has_date_range) {
          this.setState({
            specificDate: this.props.activeHealth.end_date
          });
        }

        if (this.props.activeHealth.medications.length > 0) {
          this.setState({
            checkedMedication: true
          });
        } else {
          this.setState({
            checkedMedication: false
          });
        }

        if (
          !this.props.activeHealth.end_date ||
          this.props.activeHealth.end_date === ""
        ) {
          this.setState({ checkedCurrentTreatment: true });
        }

        this.setState(
          {
            navHeader: this.props.activeHealth.condition.name,

            step: "family",
            conditionDone: true,

            condition: this.props.activeHealth.condition,
            icdID: this.props.activeHealth.icd.id,
            healthRecordID: this.props.activeHealth.id,
            profileID: this.props.activeHealth.profile.id,

            conditionStartMonth: getMonthFromDate(
              this.props.activeHealth.start_date
            ),
            conditionStartYear: getYearFromDate(
              this.props.activeHealth.start_date
            ),

            prognosis: this.props.activeHealth.prognosis,

            checkedPastHospitalization: this.props.activeHealth
              .past_hospitalization,
            checkedFutureHospitalization: this.props.activeHealth
              .future_hospitalization,
            checkedPastSurgery: this.props.activeHealth.past_surgery,
            checkedFutureSurgery: this.props.activeHealth.future_surgery,

            medications: this.props.activeHealth.medications,
            customDetails: this.props.activeHealth.condition.custom_details,
            details: this.props.activeHealth.details
          },
          () => {
            this.getConditionAndICD(this.props.activeHealth.condition.id);
          }
        );
      } else {
      }
    }
  }

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  scrollToMyRef2 = () => {
    var element = this.refs.myRef2;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  scrollToMyRef3 = () => {
    var element = this.refs.myRef3;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
    //element.scrollIntoView({ behavior: "smooth", block: "start", alignToTop: true });
    //element.scrollIntoView({ alignToTop: true });
  };

  deleteHealthRecord = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteHealthRecord(this.props.activeHealth.id)
        .then(r => {
          this.props.deleteHealth(this.props.activeHealth.id);
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Health Record" });
          console.log(err);
        });
    });
  };

  getCondition = conditionID => {
    return new Promise((resolve, reject) => {
      api
        .getCondition(conditionID)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Condition" });
          console.log(err);
        });
    });
  };

  getICD = (conditionID, detail1, detail2, detail3) => {
    return new Promise((resolve, reject) => {
      api
        .getICD(conditionID, detail1, detail2, detail3)
        .then(response => {
          this.setState({
            icdID: response[0].icd.id,
            icdDescription: response[0].icd.description,
            step: "family",
            conditionDone: true
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Condition" });
          console.log(err);
        });
    });
  };

  loadMedicationOptions = () => {
    return new Promise((resolve, reject) => {
      api
        .getMedicationOptions(this.state.medicationSearchValue)
        .then(response => {
          var medicationList = [];
          response.forEach(r => {
            r.value = r.id;
            r.label = r.brand_name;
            medicationList.push(r);
          });
          resolve(medicationList);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Medication Options" });
          console.log(err);
        });
    });
  };

  getDosageList = () => {
    return new Promise((resolve, reject) => {
      api
        .getDosageList(this.state.medication)
        .then(response => {
          this.setState({ dosages: response });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Medication Options" });
          console.log(err);
        });
    });
  };

  getEndDate = () => {
    if (this.state.condition.has_date_range) {
      return getDateFromMonthYear(
        this.state.conditionEndMonth,
        this.state.conditionEndYear
      );
    } else {
      return this.state.specificDate;
    }
  };

  addHealthRecord = () => {
    var body = JSON.stringify({
      profile_id: this.state.profileID,
      icd_id: this.state.icdID,
      start_date: getDateFromMonthYear(
        this.state.conditionStartMonth,
        this.state.conditionStartYear
      ),
      end_date: this.getEndDate(),
      prognosis: this.state.prognosis,
      past_hospitalization: this.state.checkedPastHospitalization,
      future_hospitalization: this.state.checkedFutureHospitalization,
      past_surgery: this.state.checkedPastSurgery,
      future_surgery: this.state.checkedFutureSurgery,
      medications: this.state.medications,
      details: this.state.details
    });
    return new Promise((resolve, reject) => {
      api
        .addHealthRecord(this.props.auth.user_id, body)
        .then(response => {
          this.props.addHealth(response);
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Health Record" });
          console.log(err);
        });
    });
  };

  updateHealthRecord = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        id: this.props.activeHealth.id,
        profile_id: this.state.profileID,
        icd_id: this.state.icdID,
        start_date: getDateFromMonthYear(
          this.state.conditionStartMonth,
          this.state.conditionStartYear
        ),
        end_date: this.getEndDate(),
        prognosis: this.state.prognosis,
        past_hospitalization: this.state.checkedPastHospitalization,
        future_hospitalization: this.state.checkedFutureHospitalization,
        past_surgery: this.state.checkedPastSurgery,
        future_surgery: this.state.checkedFutureSurgery,
        medications: this.state.medications,
        details: this.state.details
      });
      api
        .updateHealthRecord(this.props.activeHealth.id, body)
        .then(response => {
          this.props.updateHealth(response);
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Address" });
          console.log(err);
        });
    });
  };

  addMedication = () => {
    var body = JSON.stringify({
      health_record_id: this.props.activeHealth.id,
      medication_id: this.state.medication,
      dosage_id: this.state.dosage,
      frequency: this.state.frequency,
      start_date: this.getMedicationStartDate(),
      end_date: this.getMedicationEndDate()
    });
    return new Promise((resolve, reject) => {
      api
        .addMedicationRecord(this.props.auth.user_id, body)
        .then(response => {
          var medications = this.state.medications.slice();
          medications.push(response);

          var healthRecord = this.props.activeHealth;
          healthRecord.medications = medications;
          this.props.updateHealth(healthRecord);

          this.setState({
            medications: medications,
            medication: "",
            dosages: [],
            frequency: "",
            medicationStartMonth: "",
            medicationStartYear: "",
            medicationEndMonth: "",
            medicationEndYear: "",
            checkedSameDates: true,
            checkedCurrentMedication: true,
            medicationSearchValue: "ADDED"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Medication Record" });
          console.log(err);
        });
    });
  };

  deleteMedication = index => {
    return new Promise((resolve, reject) => {
      api
        .deleteMedicationRecord(this.state.medications[index].id)
        .then(r => {
          var medications = this.state.medications.slice();

          if (index !== -1) {
            medications.splice(index, 1);
            this.setState({ medications: medications });

            var healthRecord = this.props.activeHealth;
            healthRecord.medications = medications;
            this.props.updateHealth(healthRecord);
          }
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Medication Record" });
          console.log(err);
        });
    });
  };

  getConditionAndICD = conditionID => {
    this.getCondition(conditionID).then(condition => {
      if (condition.detail_1_list.length == 0) {
        this.getICD(conditionID, null, null, null);
      }
      this.setState({
        conditionStep: "detail1",
        detail1List: condition.detail_1_list,
        condition: condition
      });
      if (!this.props.activeHealth.id) {
        this.setState({
          details: condition.custom_details.map(cd => {
            return {
              custom_detail: cd,
              value: ""
            };
          })
        });
      }
      this.configureCustomDetails();
    });
  };

  configureCustomDetails = () => {
    return;
    var customDetails = this.state.condition.custom_details.slice();
    if (this.props.isEdit) {
      var details = this.props.activeHealth.details;
      var localDetails = [];

      customDetails.map(detail => {
        var detailDict = new Object();
        var dict = new Object();
        /*{
          value: '',
          custom_detai: {
            id:'',
            name: '',
            field_type:''
          }
        }*/
        dict["id"] = detail.id;
        dict["name"] = detail.name;
        dict["field_type"] = detail.field_type;
        detailDict["value"] = "";
        detailDict["custom_detail"] = dict;

        for (var i = 0; i < details.length; i++) {
          if (details[i].custom_detail.id === detail.id) {
            detailDict["value"] = details[i].value;
          }
        }
        localDetails.push(detailDict);
        //}
      });
    }
    /*else {
      var details = []
      customDetails.map((detail) => {

        var detailDict = new Object
        var dict = new Object

        dict["id"] = detail.id
        dict["name"] = detail.name
        dict["field_type"] = detail.field_type


        detailDict["custom_detail"] = dict
        detailDict["value"] = 

        details.push(detailDict)
      })
    }*/
    this.setState({ details: localDetails, customDetails: customDetails });
  };

  close = () => {
    this.props.closeEditHealth();
    this.props.clearActiveCondition();
    this.props.clearActiveHealth();

    this.reset();
  };

  handleClickOpen = () => {
    this.props.Health();
  };

  handleDelete = () => {
    this.deleteHealthRecord().then(r => {
      this.close();
    });
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  handleCancel = () => {
    this.close();
  };

  handleSave = () => {
    if (this.props.isEdit) {
      this.updateHealthRecord().then(r => {
        this.props.clearActiveHealth();
        this.close();
      });
    } else {
      this.addHealthRecord().then(r => {
        this.props.clearActiveHealth();
        this.close();
      });
    }
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleCategory = id => {
    var category = this.props.conditionCategories.find(c => c.id === id);
    this.setState({
      conditionStep: "condition",
      categoryName: category.name,
      navHeader: category.name,
      category: id
    });
  };

  setCondition = conditionID => {
    var category = "";
    var condition = "";

    for (var i = 0; i < this.props.conditionCategories.length; i++) {
      for (
        var j = 0;
        j < this.props.conditionCategories[i].conditions.length;
        j++
      ) {
        if (
          this.props.conditionCategories[i].conditions[j].id === conditionID
        ) {
          category = this.props.conditionCategories[i];
          condition = this.props.conditionCategories[i].conditions[j];
          break;
        }
      }
    }
    this.getConditionAndICD(conditionID);

    this.setState({
      categoryName: category.name,
      navHeader: category.name + " > " + condition.name,
      conditionID: conditionID
    });
  };

  handleCondition = id => {
    this.setCondition(id);
  };

  handleDetail1 = id => {
    var detail = this.state.condition.detail_1_list.find(c => c.id === id);
    this.setState({
      conditionStep: "detail2",
      detail1Name: detail.name,
      navHeader:
        this.state.categoryName +
        " > " +
        this.state.condition.name +
        " > " +
        detail.name,
      detail1: id,
      detail2List: detail.detail_2_list
    });

    if (detail.detail_2_list.length == 0) {
      this.getICD(this.state.conditionID, id, null, null);
      this.setState({
        step: "family",
        conditionDone: true
      });
    }
  };

  handleDetail2 = id => {
    var detail = this.state.detail2List.find(c => c.id === id);

    this.setState({
      conditionStep: "detail3",
      detail2Name: detail.name,
      navHeader:
        this.state.categoryName +
        " > " +
        this.state.condition.name +
        " > " +
        this.state.detail1Name +
        " > " +
        detail.name,
      detail2: id,
      detail3List: detail.detail_3_list
    });
    if (detail.detail_3_list.length == 0) {
      this.getICD(this.state.conditionID, this.state.detail1, id, null);
      this.setState({
        step: "family",
        conditionDone: true
      });
    }
  };

  handleDetail3 = id => {
    var detail = this.state.detail3List.find(c => c.id === id);

    this.setState({
      step: "family",
      conditionStep: "done",
      conditionDone: true,
      detail3Name: detail.name,
      navHeader:
        this.state.categoryName +
        " > " +
        this.state.condition.name +
        " > " +
        this.state.detail1Name +
        " > " +
        this.state.detail2Name +
        " > " +
        detail.name,
      detail3: id
    });
    this.getICD(
      this.state.conditionID,
      this.state.detail1,
      this.state.detail2,
      id
    );
    this.setState({
      step: "family",
      conditionDone: true
    });
  };

  handleConditionBack = () => {
    console.log(this.state)
    console.log(this.state.conditionStep)
    if (this.state.conditionStep == "condition") {
      this.setState({
        conditionStep: "category",
        conditionDone: false,
        navHeader: "Select a category to add a health record:",
        condition: "{}",
        conditions: []
      });
    } else if (this.state.conditionStep == "detail1") {
      this.setState({
        conditions: [],
        conditionStep: "condition",
        conditionDone: false,
        navHeader: this.state.categoryName,
        detail1: null,
        detail1List: []
      });
    } else if (this.state.conditionStep == "detail2") {
      this.setState({
        conditionStep: "detail1",
        conditionDone: false,
        navHeader: this.state.categoryName + " > " + this.state.condition.name,
        detail2: null,
        detail2List: []
      });
    } else if (this.state.conditionStep == "detail3") {
      this.setState({
        conditionStep: "detail2",
        conditionDone: false,
        navHeader:
          this.state.categoryName +
          " > " +
          this.state.condition.name +
          " > " +
          this.state.detail1Name,
        detail3: null,
        detail3List: []
      });
    } else if (this.state.conditionStep == "done") {
      this.setState({
        conditionStep: "detail3",
        conditionDone: false,
        navHeader:
          this.state.categoryName +
          " > " +
          this.state.condition.name +
          " > " +
          this.state.detail1Name +
          " > " +
          this.state.detail2Name
      });
    }
  };

  handleBack = () => {
    
    console.log(this.state)
    if (this.state.directAdd) {
      this.setState({
        step: "condition",
        conditionStep : "category",
        navHeader: ""
      }) 
      //this.close();
    } 
    else if (this.state.step == "condition") {
      this.handleConditionBack();
    } else if (this.state.step == "family") {
      this.setState({
        step: "condition",
        conditionDone: false
      });
      this.handleConditionBack();
    } else if (this.state.step == "date") {
      this.setState({
        step: "family"
      });
    }
    console.log('maqde it herer')
  };

  handleConditionStartMonthChange = event => {
    this.setState({ conditionStartMonth: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked }, () => {
      if (
        name == "checkedCurrentTreatment" &&
        this.state.checkedCurrentTreatment == true
      ) {
        this.setState({
          conditionEndMonth: "",
          conditionEndYear: ""
        });
      }

      if (
        name == "checkedCurrentTreatment" &&
        this.state.checkedCurrentTreatment == false
      ) {
        this.scrollToMyRef1();
      } else if (
        name == "checkedMedication" &&
        this.state.checkedMedication == true
      ) {
        this.scrollToMyRef3(); //3
      } else if (
        name == "checkedSameDates" &&
        this.state.checkedSameDates == false
      ) {
        this.scrollToMyRef2();
      } else if (
        name == "checkedCurrentMedication" &&
        this.state.checkedCurrentMedication == false
      ) {
        this.scrollToMyRef2();
      }
    });
  };

  handleMedicationInputChange = (newValue, change) => {
    if (change.action !== "menu-close") {
      this.setState({ medicationSearchValue: newValue });
    }
  };

  handleMedicationChange = event => {
    if (event) {
      this.setState(
        {
          medication: event.id,
          medicationName: event.label
        },
        () => {
          this.getDosageList();
        }
      );
    }
    this.scrollToMyRef3();
  };

  handleDosage = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAddMedication = () => {
    if (this.props.isEdit) {
      this.addMedication().then(r => {
        this.asyncRef.select.select.clearValue();
        this.scrollToMyRef3();
      });
    } else {
      var dosage = this.state.dosages.find(
        dosage => dosage.id === this.state.dosage
      );

      var startDate = this.getMedicationStartDate();
      var endDate = this.getMedicationEndDate();

      var medications = this.state.medications.slice();

      var medicationRecordDict = {};
      var medicationRecordDict = new Object();

      var medicationDict = new Object();
      medicationDict["id"] = this.state.medication;
      medicationDict["brand_name"] = this.state.medicationName;

      var dosageDict = new Object();
      dosageDict["id"] = this.state.dosage;
      dosageDict["form"] = dosage.form;
      dosageDict["ingredients"] = dosage.ingredients;

      medicationRecordDict["medication"] = medicationDict;
      medicationRecordDict["dosage"] = dosageDict;
      medicationRecordDict["frequency"] = this.state.frequency;
      medicationRecordDict["start_date"] = startDate;
      medicationRecordDict["end_date"] = endDate;

      medications.push(medicationRecordDict);

      this.setState(
        {
          medications: medications,
          medication: "",
          dosages: [],
          frequency: "",
          medicationStartMonth: "",
          medicationStartYear: "",
          medicationEndMonth: "",
          medicationEndYear: "",
          checkedSameDates: true,
          checkedCurrentMedication: true,
          medicationSearchValue: "ADDED"
        },
        () => {
          this.asyncRef.select.select.clearValue();
          this.scrollToMyRef3();
        }
      );
    }
  };

  getMedicationStartDate = () => {
    if (this.state.checkedSameDates) {
      return getDateFromMonthYear(
        this.state.conditionStartMonth,
        this.state.conditionStartYear
      );
    } else {
      return getDateFromMonthYear(
        this.state.medicationStartMonth,
        this.state.medicationStartYear
      );
    }
  };

  getMedicationEndDate = () => {
    if (this.state.checkedSameDates) {
      return getDateFromMonthYear(
        this.state.conditionEndMonth,
        this.state.conditionEndYear
      );
    } else {
      return getDateFromMonthYear(
        this.state.medicationEndMonth,
        this.state.medicationEndYear
      );
    }
  };

  handleDeleteMedication = e => {
    var value = e.currentTarget.value;

    if (this.props.isEdit) {
      this.deleteMedication(value);
    } else {
      var medications = this.state.medications.slice();

      var index = value;

      if (index !== -1) {
        medications.splice(index, 1);
        this.setState({ medications: medications });
      }
    }
  };

  filteredProfiles() {
    var male = this.state.condition.applies_male;
    var female = this.state.condition.applies_female;
    var sexFilter = "";

    var minAge = this.state.condition.min_age;
    var maxAge = this.state.condition.max_age;

    if (!male || !female) {
      if (!male) {
        sexFilter = "F";
      } else if (!female) {
        sexFilter = "M";
      }
    }

    if (maxAge) {
      var startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - maxAge);
    } else {
      var startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 150);
    }

    if (minAge) {
      var endDate = new Date();
      endDate.setFullYear(endDate.getFullYear() - minAge);
    } else {
      var endDate = new Date();
    }

    var profiles = this.props.profiles.filter(
      profile =>
        (sexFilter === "" || profile.sex === sexFilter) &&
        new Date(profile.date_of_birth) > startDate &&
        new Date(profile.date_of_birth) < endDate
    );

    return profiles;
  }

  handleCustomDetailTextChange(index, event) {
    var details = this.state.details.slice();
    details[index].value = event.target.value;
    this.setState({ details: details });
  }

  handleCustomDetailYesNoChange(index, event) {
    var customDetailsYesNoValues = this.state.customDetailsYesNoValues.slice();
    customDetailsYesNoValues[index] = event.target.value;
    this.setState({ customDetailsYesNoValues: customDetailsYesNoValues });
  }

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;
    const conditionCategories = this.props.conditionCategories.find(
      c => c.id === this.state.category
    );
    return (
      <div>
        <Dialog
          open={this.props.healthModalOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {(this.props.isEdit && "Update") || "Add"} Health Record
          </DialogTitle>
          <DialogContent className={classes.content}>
            <br />

            <Typography variant="subtitle2" gutterBottom>
              <div style={{ float: "left", marginTop: 7 }}>
                {this.state.navHeader}
              </div>

              {!this.props.isEdit && this.state.conditionDone && (
                <div>
                  <Button
                    onClick={this.handleBack}
                    style={{ float: "left" }}
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              )}
              <div style={{ clear: "both" }} />
            </Typography>

            {this.state.conditionStep === "" && (
              <div style={{ marginTop: 75, textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}

            {this.state.step == "condition" &&
              this.state.conditionStep == "category" && (
                <MenuList>
                  {this.props.conditionCategories.map(category => {
                    return (
                      <MenuItem
                        value={category.id}
                        name={category.name}
                        key={"conditionCategory_" + category.id}
                        onClick={() => {
                          this.handleCategory(category.id);
                        }}
                      >
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}

            {this.state.step == "condition" &&
              this.state.conditionStep == "condition" && (
                <MenuList>
                  {/*{this.state.conditions.map(condition => {*/}
                  {/*this.props.conditionCategories[this.state.category-1].conditions.map(condition => { */}
                  {conditionCategories.conditions.map(condition => {
                    return (
                      <MenuItem
                        value={condition.id}
                        key={"condition_" + condition.id}
                        onClick={() => {
                          this.handleCondition(condition.id);
                        }}
                      >
                        {condition.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}

            {this.state.step == "condition" &&
              this.state.conditionStep == "detail1" && (
                <MenuList>
                  {this.state.detail1List.map(detail => {
                    return (
                      <MenuItem
                        value={detail.id}
                        key={"detail1_" + detail.id}
                        onClick={() => {
                          this.handleDetail1(detail.id);
                        }}
                      >
                        {detail.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}

            {this.state.step == "condition" &&
              this.state.conditionStep == "detail2" && (
                <MenuList>
                  {this.state.detail2List.map(detail => {
                    return (
                      <MenuItem
                        value={detail.id}
                        key={"detail2_" + detail.id}
                        onClick={() => {
                          this.handleDetail2(detail.id);
                        }}
                      >
                        {detail.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}

            {this.state.step == "condition" &&
              this.state.conditionStep == "detail3" && (
                <MenuList>
                  {this.state.detail3List.map(detail => {
                    return (
                      <MenuItem
                        value={detail.id}
                        key={"detail3_" + detail.id}
                        onClick={() => {
                          this.handleDetail3(detail.id);
                        }}
                      >
                        {detail.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}

            {!this.state.conditionDone &&
              this.state.conditionStep != "category" && (
                <Button onClick={this.handleBack} color="primary">
                  Back
                </Button>
              )}

            {this.state.step == "family" && (
              <div>
                <FormControl margin="dense" fullWidth={true}>
                  <InputLabel htmlFor="month">Family Member</InputLabel>
                  <Select
                    value={this.state.profileID}
                    onChange={this.handleChange("profileID")}
                    inputProps={{
                      name: "profile",
                      id: "profileID"
                    }}
                  >
                    {this.filteredProfiles().map(profile => {
                      return (
                        <MenuItem
                          value={profile.id}
                          key={"profile_" + profile.id}
                        >
                          {profile.first_name + " " + profile.last_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {!this.state.condition.has_date_range && (
                  <div>
                    <TextField
                      id="date"
                      label={this.state.condition.date_label}
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      defaultValue={this.state.specificDate}
                      onChange={e =>
                        this.setState({ specificDate: e.target.value })
                      }
                      style={{ width: 150, marginRight: 10 }}
                      margin="dense"
                    />
                    <br />
                  </div>
                )}

                {this.state.condition.has_date_range && (
                  <div>
                    <FormLabel component="legend" style={{ marginTop: 20 }}>
                      Treatment or status started
                    </FormLabel>
                    <FormControl margin="dense">
                      <InputLabel htmlFor="month">Month</InputLabel>
                      <Select
                        value={this.state.conditionStartMonth}
                        onChange={this.handleSelectChange}
                        inputProps={{
                          name: "conditionStartMonth",
                          id: "start-Month"
                        }}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>January</MenuItem>
                        <MenuItem value={2}>February</MenuItem>
                        <MenuItem value={3}>March</MenuItem>
                        <MenuItem value={4}>April</MenuItem>
                        <MenuItem value={5}>May</MenuItem>
                        <MenuItem value={6}>June</MenuItem>
                        <MenuItem value={7}>July</MenuItem>
                        <MenuItem value={8}>August</MenuItem>
                        <MenuItem value={9}>September</MenuItem>
                        <MenuItem value={10}>October</MenuItem>
                        <MenuItem value={11}>November</MenuItem>
                        <MenuItem value={12}>December</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="standard-dense"
                      label="Year"
                      defaultValue={this.state.conditionStartYear}
                      type="text"
                      margin="dense"
                      style={{ width: 100 }}
                      onChange={e =>
                        this.setState({ conditionStartYear: e.target.value })
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedCurrentTreatment}
                          onChange={this.handleChangeChecked(
                            "checkedCurrentTreatment"
                          )}
                          //value={this.state.checkedCurrentTreatment}
                          color="primary"
                        />
                      }
                      label="Currently being treated"
                    />

                    {!this.state.checkedCurrentTreatment && (
                      <div style={{ display: "block" }}>
                        <FormLabel component="legend" style={{ marginTop: 20 }}>
                          Treatment or status ended
                        </FormLabel>
                        <FormControl margin="dense">
                          <InputLabel htmlFor="month">Month</InputLabel>
                          <Select
                            value={this.state.conditionEndMonth}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: "conditionEndMonth",
                              id: "end-Month"
                            }}
                            style={{ width: 200, marginRight: 10 }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          id="standard-dense"
                          label="Year"
                          defaultValue={this.state.conditionEndYear}
                          type="text"
                          margin="dense"
                          style={{ width: 100 }}
                          onChange={e =>
                            this.setState({ conditionEndYear: e.target.value })
                          }
                        />
                        <br />
                      </div>
                    )}
                  </div>
                )}
                {this.state.condition.has_prognosis && (
                  <div>
                    <TextField
                      id="standard-dense"
                      label="Prognosis or other details"
                      margin="dense"
                      onChange={e =>
                        this.setState({ prognosis: e.target.value })
                      }
                      defaultValue={this.state.prognosis}
                      fullWidth={true}
                    />
                    <br />
                  </div>
                )}

                {this.state.details.map((detail, i) => {
                  if (detail.custom_detail.field_type === 1) {
                    return (
                      <TextField
                        key={i}
                        id="standard-dense"
                        label={detail.custom_detail.name}
                        margin="dense"
                        onChange={this.handleCustomDetailTextChange.bind(
                          this,
                          i
                        )}
                        defaultValue={detail.value}
                        fullWidth={true}
                      />
                    );
                  } else {
                    return (
                      <div key={i}>
                        <FormControl margin="dense" fullWidth>
                          <InputLabel htmlFor="month">
                            {detail.custom_detail.name}
                          </InputLabel>
                          <Select
                            onChange={this.handleCustomDetailTextChange.bind(
                              this,
                              i
                            )}
                            value={detail.value || ""}
                            inputProps={{
                              name: "endMonth",
                              id: "end-Month"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    );
                  }
                })}

                {this.state.condition.has_hospitalization && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedPastHospitalization}
                          onChange={this.handleChangeChecked(
                            "checkedPastHospitalization"
                          )}
                          value="checkedPastHospitalization"
                          color="primary"
                        />
                      }
                      label="Required hospitalization"
                    />

                    <br />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedFutureHospitalization}
                          onChange={this.handleChangeChecked(
                            "checkedFutureHospitalization"
                          )}
                          value="checkedFutureHospitalization"
                          color="primary"
                        />
                      }
                      label="May require future hospitalization"
                    />
                    <br />
                  </div>
                )}
                {this.state.condition.has_surgery && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedPastSurgery}
                          onChange={this.handleChangeChecked(
                            "checkedPastSurgery"
                          )}
                          value="checkedPastSurgery"
                          color="primary"
                        />
                      }
                      label="Required surgery"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedFutureSurgery}
                          onChange={this.handleChangeChecked(
                            "checkedFutureSurgery"
                          )}
                          value="checkedFutureSurgery"
                          color="primary"
                        />
                      }
                      label="May require future surgery"
                    />
                    <br />
                  </div>
                )}
                {this.state.condition.has_medication && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedMedication}
                          onChange={this.handleChangeChecked(
                            "checkedMedication"
                          )}
                          value="checkedMedication"
                          color="primary"
                        />
                      }
                      label="Medications prescribed for this condition"
                    />

                    {this.state.checkedMedication && (
                      <div>
                        <Typography
                          variant="body1"
                          style={{ marginTop: 20, marginBottom: 10 }}
                        >
                          Please add any medications the individual has been
                          prescribed for this condition
                        </Typography>

                        <List dense={false}>
                          {this.state.medications.map((med, index) => {
                            return (
                              <ListItem key={"med_item_" + index}>
                                <ListItemText
                                  primary={
                                    med.medication.brand_name +
                                    " " +
                                    med.dosage.form +
                                    " " +
                                    med.dosage.ingredients +
                                    " " +
                                    med.frequency
                                  }
                                  secondary={
                                    formatDate(med.start_date, "mmm yyyy") +
                                    " - " +
                                    formatDate(med.end_date, "mmm yyyy")
                                  }
                                />

                                <ListItemSecondaryAction>
                                  <IconButton
                                    value={index}
                                    onClick={this.handleDeleteMedication}
                                    aria-label="Delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>

                        <AsyncSelect
                          cacheOptions
                          //defaultValue={this.state.medicationSearchValue}
                          //inputValue={this.state.medicationSearchValue}
                          loadOptions={this.loadMedicationOptions}
                          onInputChange={this.handleMedicationInputChange}
                          onChange={this.handleMedicationChange}
                          styles={customStyles}
                          isSearchable={true}
                          openMenuOnClick={false}
                          fullWidth={true}
                          placeholder={"Type medication name..."}
                          isClearable
                          ref={ref => {
                            this.asyncRef = ref;
                          }}
                        />
                        {this.state.medication == "" && (
                          <div
                            style={{ height: 0, backgroundColor: "white" }}
                          />
                        )}
                        {this.state.medication != "" && (
                          <div>
                            <FormControl margin="dense" fullWidth={true}>
                              <InputLabel htmlFor="month">Dosage</InputLabel>
                              <Select
                                value={this.state.dosage}
                                onChange={this.handleDosage}
                                inputProps={{
                                  name: "dosage",
                                  id: "dosage"
                                }}
                              >
                                {this.state.dosages.map(dosage => {
                                  return (
                                    <MenuItem
                                      value={dosage.id}
                                      key={"dosage_" + dosage.id}
                                    >
                                      {dosage.form + " " + dosage.ingredients}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>

                            <TextField
                              id="standard-dense"
                              label="Frequency"
                              margin="dense"
                              onChange={e =>
                                this.setState({ frequency: e.target.value })
                              }
                              defaultValue={this.state.frequency}
                              fullWidth={true}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.checkedSameDates}
                                  onChange={this.handleChangeChecked(
                                    "checkedSameDates"
                                  )}
                                  value="checkedSameDates"
                                  color="primary"
                                />
                              }
                              label="Dates taken are the same as condition"
                            />
                            {!this.state.checkedSameDates && (
                              <div>
                                <FormLabel
                                  component="legend"
                                  style={{ marginTop: 20 }}
                                >
                                  Started taking medication
                                </FormLabel>
                                <FormControl margin="dense">
                                  <InputLabel htmlFor="month">Month</InputLabel>
                                  <Select
                                    value={this.state.medicationStartMonth}
                                    onChange={this.handleSelectChange}
                                    inputProps={{
                                      name: "medicationStartMonth",
                                      id: "medication-start-month"
                                    }}
                                    style={{ width: 200, marginRight: 10 }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>January</MenuItem>
                                    <MenuItem value={2}>February</MenuItem>
                                    <MenuItem value={3}>March</MenuItem>
                                    <MenuItem value={4}>April</MenuItem>
                                    <MenuItem value={5}>May</MenuItem>
                                    <MenuItem value={6}>June</MenuItem>
                                    <MenuItem value={7}>July</MenuItem>
                                    <MenuItem value={8}>August</MenuItem>
                                    <MenuItem value={9}>September</MenuItem>
                                    <MenuItem value={10}>October</MenuItem>
                                    <MenuItem value={11}>November</MenuItem>
                                    <MenuItem value={12}>December</MenuItem>
                                  </Select>
                                </FormControl>
                                <TextField
                                  id="standard-dense"
                                  label="Year"
                                  defaultValue={this.state.medicationStartYear}
                                  type="text"
                                  margin="dense"
                                  style={{ width: 100 }}
                                  onChange={e =>
                                    this.setState({
                                      medicationStartYear: e.target.value
                                    })
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.checkedCurrentMedication
                                      }
                                      onChange={this.handleChangeChecked(
                                        "checkedCurrentMedication"
                                      )}
                                      value="checkedCurrentMedication"
                                      color="primary"
                                    />
                                  }
                                  label="Currently taking this medication"
                                />
                                <br />

                                {!this.state.checkedCurrentMedication && (
                                  <div>
                                    <FormLabel
                                      component="legend"
                                      style={{ marginTop: 20 }}
                                    >
                                      Stopped taking medication
                                    </FormLabel>
                                    <FormControl margin="dense">
                                      <InputLabel htmlFor="month">
                                        Month
                                      </InputLabel>
                                      <Select
                                        value={this.state.medicationEndMonth}
                                        onChange={this.handleSelectChange}
                                        inputProps={{
                                          name: "medicationEndMonth",
                                          id: "medication-end-month"
                                        }}
                                        style={{ width: 200, marginRight: 10 }}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                      </Select>
                                    </FormControl>
                                    <TextField
                                      id="standard-dense"
                                      label="Year"
                                      defaultValue={
                                        this.state.medicationEndYear
                                      }
                                      type="text"
                                      margin="dense"
                                      style={{ width: 100 }}
                                      onChange={e =>
                                        this.setState({
                                          medicationEndYear: e.target.value
                                        })
                                      }
                                    />
                                    <br />
                                  </div>
                                )}
                              </div>
                            )}
                            <br />
                            <Button
                              className={classes.button}
                              style={{ marginLeft: -17 }}
                              onClick={this.handleAddMedication}
                              color="primary"
                            >
                              <AddIcon className={classes.leftIcon} />
                              Add Medication
                            </Button>
                          </div>
                        )}
                        <div style={{ height: 0 }} />
                        <div
                          id="myRef2"
                          ref="myRef2"
                          style={{
                            height: 50,
                            marginTop: 0,
                            backgroundColor: "white",
                            display: "block"
                          }}
                        />
                        <div
                          id="myRef3"
                          ref="myRef3"
                          style={{
                            height: 175,
                            marginTop: 0,
                            backgroundColor: "white",
                            display: "block"
                          }}
                        />
                        <div style={{ height: 0, backgroundColor: "white" }} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {/*</div>*/}
            <div
              id="myRef1"
              ref="myRef1"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block"
              }}
            />

            <div className={classes.divider} />
          </DialogContent>

          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this health record? This cannot be undone."
              }
            />
            {this.props.isEdit && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" disabled={this.state.medication!==""}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditHealth.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(EditHealth)));
