import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";

//import PageHeader from "../../Shared/PageHeader";
import {Signature} from "../../User/Signature/Signature.js";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

class SignatureContainer extends React.Component {
  state = {
    signatureModalOpen: false
  };

  goBack = () => {
    this.props.action("form");
  };

  handleClose = () => {
    this.setState({ signatureModalOpen: false });
  };

  sign = () => {
    this.setState({ signatureModalOpen: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
      Test
      <Button id="test" onClick={() => {this.setState({signatureModalOpen: !this.state.signatureModalOpen})}}>
          Sign
        </Button>
        <Signature
          isOpen={this.state.signatureModalOpen}
          Public={true}
          Presigned={this.props.presigned}
          Close={this.handleClose}
          classes={''}
        />
      </React.Fragment>
      /*
      <div>
        
        <PageHeader
          pageTitle="Signature"
          backText="Back"
          nextText="Sign"
          back={this.goBack}
          next={this.sign}
        />

        <Card style={{ marginTop: 20 }}>
          <CardHeader
            subheader="Signature Instructions"
            style={{ backgroundColor: "#f2f2f2" }}
          />
          <CardContent>
            <Typography variant="body1" style={{ textAlign: "left" }}>
              Please affirm that the information on the previous pages as well
              as the forms you have reviewed are complete and correct to the
              best of your knowledge by drawing or typing your signature.
              Missing or incorrect information may lead to denial of coverage or
              claims. If you have any questions about the forms or your
              coverage, please contact your company's benefits broker or your HR
              person.
            </Typography>
            <div style={{ clear: "both", height: 12 }} />
            <div
              style={{ marginTop: 30, marginBottom: 50, textAlign: "center" }}
            >
              <Button variant="contained" color="primary" onClick={this.sign}>
                <EditIcon className={classes.leftIcon} />
                <div>Sign My Application</div>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      */
    );
  }
}

export default (withStyles(styles)(SignatureContainer));
