import React from "react";
import PropTypes from "prop-types";
import { Elements, StripeProvider } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Agreement from "../Agreement";

import { styles } from "../Styles/AuthStyles";

import PasswordInput from ".././Shared/Components/PasswordInput";
import CustomerPayment from "./CustomerPayment";

import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

const initialState = {
  open: false,
  showConfirmation: false,
  errorMessage: "",
  emailLabel: "Work Email",
  loginType: "Employer Login",
  checkedAgree: false,
  agencyName: "",
  phone: "",
  street: "",
  city: "",
  customerState: "",
  employerZip: "",
  states: [],
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  subheader: "",
  selectedPlan: "",
  planName: "",
  token: {},
  agreementModalOpen: false,
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    //variable that gets the plan type from the URL string
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let plan = params.get("plan");

    console.log("calling states");
    this.getStates();

    if (plan) {
      console.log(plan);
      this.state.selectedPlan = plan;
      this.getCustomerPlanInfo(plan).then(r => {
        console.log(r);
      });
    }
  }

  getStates = () => {
    console.log("in states");
    return new Promise((resolve, reject) => {
      api
        .getStates()
        .then(response => {
          console.log(response);
          this.setState({ states: response });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  SignUp = () => {
    const { password, email } = this.state;

    const username = email;
    Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:app": "broker"
      }
    })
      .then(() => {
        console.log("in then of signup");
        this.props.enqueueSnackbar("Account Created", {
          variant: "success"
        });
        this.setState({ showConfirmation: true });
      })
      .catch(err => {
        console.log("in error for signup");
        this.props.enqueueSnackbar(err.message, {
          variant: "error"
        });
      });
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  getCustomerPlanInfo = planID => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerPlanInfo(planID)
        .then(response => {
          this.setState({ planName: response.planName });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Plan Info" });
          console.log(err);
        });
    });
  };
  createStripeCustomer = () => {
    var body = JSON.stringify({
      card_token: this.state.token.id,
      email: this.state.email,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      street: this.state.street,
      city: this.state.city,
      state: this.state.customerState,
      zip: this.state.employerZip,
      agencyName: this.state.agencyName,
      phone: this.state.phone,
      plan: this.state.selectedPlan //"plan_F7VVk7wpfPmQiX"
    });

    api
      .createCustomerBilling(body)
      .then(response => {
        if (response.success) this.props.history.push("/broker/home");
        else {
          this.props.enqueueSnackbar("Error Creating Account", {
            variant: "error"
          });
        }
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error"
        });
      });
  };

  SignIn = () => {
    Auth.signIn(this.state.email, this.state.password)
      .then(user => {
        /*
        console.log("Preferred MFA: " + user.challengeName);
        if (
          user.challengeName === "NOMFA" ||
          user.challengeName === undefined
        ) {
          console.log("redirect home");
          //this.props.history.push('/home')
          this.props.history.push("user-router?" + this.getCode());
        } else {
          console.log("show confirmation");
          this.setState({ user, showConfirmation: true });
          this.setState({ challengeParam: user.challengeParam });
          this.setState({ errorMessage: "" });
        }
        */
        //call payment endpoint
        this.createStripeCustomer();
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  ConfirmSignUp = () => {
    console.log(
      "about to confirm signup with: " +
        this.state.email +
        " and " +
        this.state.code
    );

    Auth.confirmSignUp(this.state.email, this.state.code)
      .then(data => {
        this.SignIn();
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error"
        });
      });
  };

  emailLabel() {
    switch (this.props.app) {
      case "user":
        return "Email";
      case "employer":
        return "Work Email";
      case "admin":
        return "Work Email";
      case "carrier":
        return "Work Email";
      default:
        return "Email";
    }
  }

  signInLabel() {
    switch (this.props.app) {
      case "user":
        return "Already have a User Account?";
      case "employer":
        return "Already have an Employer Account?";
      case "admin":
        return "Already have an Admin Account?";
      case "carrier":
        return "Already have a Carrier Account?";
      default:
        return "Already have a User Account?";
    }
  }

  handleGetToken = () => {
    this.getToken().then(token => {
      console.log(token);
      console.log(JSON.stringify(token));
      console.log(token["id"]);
      this.setState({ token: token });
      this.SignUp();
    });
  };

  closeTerms = () => {
    this.setState({ agreementModalOpen: false });
  };
  openTerms = () => {
    console.log('OPEN')
    this.setState({ agreementModalOpen: true });
  };  

  render() {
    const { showConfirmation } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Agreement
          isOpen={this.state.agreementModalOpen}
          Close={this.closeTerms}
        />      
        {!showConfirmation && (
          <div style={{ zIndex: 1 }}>
            <div>
              <Typography variant="h6" color="inherit">
                Create your agency account now
              </Typography>
              <Typography variant="caption">
                Get your agency up and running in minutes!
              </Typography>
              {!this.props.customer && (
                <Typography variant="subtitle2" color="default">
                  {this.state.subheader}
                </Typography>
              )}
              <Typography
                variant="h5"
                color="default"
                style={{ marginTop: 10 }}
              >
                {this.state.planName}
              </Typography>
            </div>
            <br />
            {(this.props.customer !== null || this.props.employer !== null) && (
              <React.Fragment>
                <Typography variant="body1">
                  Create a new{" "}
                  {(this.props.app === "employer" && "employer") || "user"}{" "}
                  account linked to
                </Typography>
                <Typography variant="h6" color="primary">
                  {(this.props.app === "employer" && this.props.customer) ||
                    this.props.employer}
                </Typography>
                <Typography variant="caption" style={{ display: "none" }}>
                  Already have an account? Simply sign in to link your existing
                  account.
                </Typography>
              </React.Fragment>
            )}
            <Typography variant="body2" color="error" gutterBottom>
              {this.state.errorMessage}
            </Typography>
            <form>
              <div style={{ textAlign: "left" }}>
                <Typography variant="subtitle2">Agency Info</Typography>
                <TextField
                  id="standard-email-dense"
                  label="Agency Name"
                  className={classes.textField}
                  name="agencyName"
                  margin="dense"
                  onChange={e => this.setState({ agencyName: e.target.value })}
                  defaultValue={this.state.agencyName}
                  fullWidth
                />
                <TextField
                  id="standard-email-dense"
                  label="Phone"
                  className={classes.textField}
                  name="phone"
                  margin="dense"
                  onChange={e => this.setState({ phone: e.target.value })}
                  defaultValue={this.state.phone}
                  fullWidth
                />
                <TextField
                  id="standard-email-dense"
                  label="Street"
                  className={classes.textField}
                  name="street"
                  margin="dense"
                  onChange={e => this.setState({ street: e.target.value })}
                  defaultValue={this.state.street}
                  fullWidth
                />
                <TextField
                  id="standard-email-dense"
                  label="City"
                  className={classes.textField}
                  name="city"
                  margin="dense"
                  onChange={e => this.setState({ city: e.target.value })}
                  defaultValue={this.state.city}
                  fullWidth
                />
                <FormControl margin="dense">
                  <InputLabel htmlFor="states">State</InputLabel>
                  <Select
                    value={this.state.customerState}
                    onChange={e => {
                      this.setState({ customerState: e.target.value });
                    }}
                    inputProps={{
                      name: "customerState",
                      id: "customer-state"
                    }}
                    style={{ width: 70, marginRight: 10 }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {this.state.states.map(state => {
                      return (
                        <MenuItem value={state.id} key={"state_" + state.id}>
                          {state.abbreviation}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="standard-dense"
                  label="Zip"
                  margin="dense"
                  onChange={e => this.setState({ employerZip: e.target.value })}
                  value={this.state.employerZip}
                />
              </div>
              <div style={{ textAlign: "left" }}>
                {/*}
              <TextField
                id="standard-password-input"
                label="Password"
                className={classes.textField}
                type="Password"
                margin="dense"
                onChange={e => this.setState({ password: e.target.value })}
                style = {{width: 300}}
              />
            */}
                <br />
                <br />
                <Typography variant="subtitle2">Your Info</Typography>
                <TextField
                  id="standard-email-dense"
                  label="First Name"
                  className={classes.textField}
                  name="street"
                  margin="dense"
                  onChange={e => this.setState({ firstName: e.target.value })}
                  defaultValue={this.state.firstName}
                  fullWidth
                />
                <TextField
                  id="standard-email-dense"
                  label="Last Name"
                  className={classes.textField}
                  name="street"
                  margin="dense"
                  onChange={e => this.setState({ lastName: e.target.value })}
                  defaultValue={this.state.lastName}
                  fullWidth
                />
                <TextField
                  id="standard-email-dense"
                  label={this.emailLabel()}
                  className={classes.textField}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="dense"
                  onChange={e => this.setState({ email: e.target.value })}
                  defaultValue={this.state.email}
                  fullWidth
                />
                <PasswordInput
                  id="standard-password-input"
                  label="Password"
                  //defaultValue={this.state.password}
                  //name="password"
                  value={this.state.password}
                  className={classes.textField}
                  onChange={e => this.setState({ password: e.target.value })}
                  fullWidth
                />
                <br />
                <br />
                <br />
                <br />
                <React.Fragment>
                  <StripeProvider apiKey="pk_test_1pA4rezGFLZmKX6XTZhQrt3G00ta56xOFK">
                    <div className="example">
                      <Typography variant="subtitle2">Payment Info</Typography>
                      <Elements>
                        <CustomerPayment
                          setCreateToken={func => (this.getToken = func)}
                          enqueueSnackbar={this.props.enqueueSnackbar}
                        />
                      </Elements>
                    </div>
                  </StripeProvider>
                </React.Fragment>
              </div>
            </form>
            <br />
            <div>
              <div>
                <Typography variant="body1" />
                <Button
                  onClick={this.openTerms}
                  style={{ marginTop: 20 }}
                  color="primary"
                >
                  View Terms of Service
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedAgree}
                      onChange={e => {
                        this.setState({ checkedAgree: e.target.checked });
                      }}
                      value="checkedSameDates"
                      color="primary"
                    />
                  }
                  label="I have read and agree to the Terms of Service"
                />
                <div style={{ textAlign: "center", marginTop: 30 }}>
                  <Button
                    disabled={
                      !this.state.checkedAgree || this.state.token === {}
                    }
                    variant="contained"
                    color="primary"
                    onClick={this.handleGetToken}
                    fullWidth
                  >
                    Create Account
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                Confirm
              </Typography>
            </div>
            <br />
            <Typography variant="body2" color="error" gutterBottom>
              {this.state.errorMessage}
            </Typography>
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter the code sent to {this.state.email} to confirm your
                account.
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={e => this.setState({ code: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={this.Back}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.ConfirmSignUp}
                  style={{ width: 170 }}
                >
                  Confirm Signup
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignUp);
