import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FormViewerContainer from "../../Shared/Components/FormViewer/FormViewerContainer";
import MessageModal from "../../Shared/Components/MessageModal";
import Button from "@material-ui/core/Button";
//import PageHeader from '../Shared/PageHeader'

import { setNewMessageCount } from "../Redux/Actions/index";

import { connect } from "react-redux";

const styles = theme => ({});

const mapStateToProps = state => {
  return {
    submissionTable: state.TableReducer.submissionTable
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewMessageCount: newMessageCount => dispatch(setNewMessageCount(newMessageCount)),
  };
};

class ReviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    nextDisabled: true,
    displayInstructions: false,
    disabledTooltip: "Please wait for forms to load.",
    isLastForm: false,
    isFirstForm: true,
    messagesOpen: false
  };

  goBack = () => {
    if (this.state.isFirstForm) {
      if (this.state.waiving) {
        this.props.history.push("home");
      } else {
        this.props.action("summary");
      }
    } else {
      this.childGoBack();
    }
  };

  goNext = () => {
    if (this.state.isLastForm) {
      this.props.action("signature");
    } else {
      this.childGoNext();
    }
  };

  setFirstForm = isFirstForm => {
    this.setState({ isFirstForm: isFirstForm });
  };
  setLastForm = isLastForm => {
    this.setState({ isLastForm: isLastForm });
  };
  isDoneLoading = () => {
    this.setState({ nextDisabled: false, disabledTooltip: "" });
  };
  handleClose = () => {
    this.setState({ editModalOpen: false });
  };
  openMessages = () => {
    this.setState({messagesOpen: true})
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          
          <ExpansionPanel
            defaultExpanded
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ margin: 0, padding: 0 }} />}
              style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
              onClick={this.handlePanelClick}
            >
              <Typography variant="subtitle2">
                Form Review Instructions
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ marginTop: 20 }}>
              <Typography variant="body1">
                Please review the each of the forms below for accuracy. If you
                need to make a change to your data, you may do so on the Family,
                Health or Summary screens. If you need to manually change what
                is displayed on the form, simply click the field on the form
                directly to add an override. Note, this will only modify the
                single form you are reviewing. Your account information and
                other forms will not be changed.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        <FormViewerContainer
          displaySidebar={true}
          forms={"employer"}
          employer={this.props.submissionTable.selected.employer.id}
          form={this.props.submissionTable.selected.form.id}
          setFirstForm={this.setFirstForm}
          isDoneLoading={this.isDoneLoading}
          setLastForm={this.setLastForm}
          setGoNext={func => (this.childGoNext = func)}
          setGoBack={func => (this.childGoBack = func)}
          reformUser={this.props.reformUser}
          setNewMessageCount={this.props.setNewMessageCount}     
        />
      </React.Fragment>
    );
  }
}

ReviewContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReviewContainer));
