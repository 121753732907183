import React from "react";
import PageHeader from "../Shared/PageHeader";
import EditProfile from "./EditProfile";
import ProfileCard from "./ProfileCard";
import AddCard from "../Shared/AddCard";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { connect } from "react-redux";
import { openAddProfile } from "../Redux/Actions/index";

import { TakingCoverage } from "../Shared/Functions/StaticFunctions";

const mapDispatchToProps = dispatch => {
  return {
    openAddProfile: () => dispatch(openAddProfile())
  };
};

const mapStatetoProps = state => {
  return {
    auth: state.AuthReducer,
    profiles: state.ProfilesReducer.profiles,
    profilesLoaded: state.ProfilesReducer.profilesLoaded
  };
};

class ProfilesContainer extends React.Component {
  sortedProfiles() {
    var profiles = this.props.profiles
      .sort((a, b) => a.relationship_type - b.relationship_type)
      .sort((a, b) => {
        if (a.date_of_birth < b.date_of_birth) return -1;
        if (a.date_of_birth > b.date_of_birth) return 1;
        return 0;
      });

    return profiles;
  }

  handleAdd = () => {
    this.props.openAddProfile();
  };

  goBack = () => {
    this.props.history.push("home");
  };

  goNext = () => {
    this.props.history.push("health");
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <EditProfile {...this.props} />
        <PageHeader
          pageTitle="Family"
          back={this.goBack}
          next={this.goNext}
          summary={this.props.Summary}
          waiving={
            !TakingCoverage(this.props.profiles, this.props.auth.profile_id)
          }
        />
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 20 }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
              onClick={this.handlePanelClick}
            >
              <Typography variant="subtitle2">Family Record Instructions</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ marginTop: 20 }}>
              <Typography variant="body1">
                Please add a record for each of your immediate family members. If you are sure they will not require coverage, you waive them individually. Also, be sure to complete your own record!
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        {this.sortedProfiles().map(profile => {
          return (
            <ProfileCard
              Summary={this.props.Summary}
              key={"profileCard_" + profile.id}
              profile={profile}
            />
          );
        })}

        <AddCard ButtonTitle={"Add Family Member"} Action={this.handleAdd} />
      </div>
    );
  }
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ProfilesContainer);

export { ProfilesContainer as PureProfilesContainer};