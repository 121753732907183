import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { Route, withRouter } from "react-router-dom";

import api from "../../Shared/Functions/APIHelpers";
import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class MedicareInfo extends React.Component {
  state = {

  };

  saveInfo = () => {
    this.addMedicare().then(r => {
      this.props.setStep("form")
    })
  };

  addMedicare = () => { 

    var body = JSON.stringify({
      employer_name: this.state.employer_name ? this.state.employer_name  : this.props.preloaded.employer_state,
      employer_street : this.state.employer_street ? this.state.employer_street  : this.props.preloaded.employer_street,
      employer_city :this.state.employer_city ? this.state.employer_city  : this.props.preloaded.employer_city,
      employer_state : this.state.employer_name ? this.state.employer_name  : this.props.preloaded.employer_state,
      employer_zip : this.state.employer_zip ? this.state.employer_zip  : this.props.preloaded.employer_zip,
      employer_contact_first:this.state.employer_contact_first ? this.state.employer_contact_first : this.props.preloaded.employer_contact_first,
      employer_contact_last : this.state.employer_contact_last ? this.state.employer_contact_last  : this.props.preloaded.employer_contact_last,
      employer_contact_email : this.state.employer_contact_email ? this.state.employer_contact_email : this.props.preloaded.employer_contact_email,
      employee_email : this.state.employee_email ? this.state.employee_email  : this.props.preloaded.employee_email,        
      number_employees : this.state.number_employees ? this.state.number_employees : this.props.preloaded.number_employees,
      EIN : this.state.EIN ? this.state.EIN  : this.props.preloaded.EIN,
      TIN : this.state.TIN ? this.state.TIN  : this.props.preloaded.TIN,
      employee_first : this.state.employee_first ? this.state.employee_first  : this.props.preloaded.employee_first,
      employee_last : this.state.employee_last ? this.state.employee_last : this.props.preloaded.employee_last,
      beneficiary_first : this.state.beneficiary_first ? this.state.beneficiary_first  : this.props.preloaded.beneficiary_first,
      beneficiary_last : this.state.beneficiary_last ? this.state.beneficiary_last : this.props.preloaded.beneficiary_last,
      effective_date : this.state.effective_date ? this.state.effective_date : this.props.preloaded.effective_date,
    });

    return new Promise((resolve, reject) => {
      api
        .addMedicare(this.props.id, this.props.pin, body)
        .then(r => { 
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Medicare" });
          console.log(err);
        });
    });

  };

  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <React.Fragment>
        <TextField
          id="standard-dense"
          label="Employer Name"
          margin="dense"
          onChange={e => {
            this.setState({ employer_name: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_name}
        />

        <TextField
          id="standard-dense"
          label="Employer Street"
          margin="dense"
          onChange={e => {
            this.setState({ employer_street: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_street}
        />

        <TextField
          id="standard-dense"
          label="Employer City"
          margin="dense"
          onChange={e => {
            this.setState({ employer_city: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_city}
        />

        <FormControl margin="dense">
          <InputLabel htmlFor="states">State</InputLabel>
          <Select
            value={this.props.preloaded.employer_state}
            onChange={e => {
              this.setState({ employer_state: e.target.value });
            }}
            inputProps={{
              name: "employerState",
              id: "employer-state"
            }}
            style={{ width: 70, marginRight: 10 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {this.props.states.map(state => {
              return (
                <MenuItem value={state.id} key={"state_" + state.id}>
                  {state.abbreviation}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="standard-dense"
          label="Employer Zip"
          margin="dense"
          onChange={e => {
            this.setState({ employer_zip: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_zip}
        />


        <TextField
          id="standard-dense"
          label="Employer Contact First"
          margin="dense"
          onChange={e => {
            this.setState({ employer_contact_first: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_contact_first}
        />

        <TextField
          id="standard-dense"
          label="Employer Contact Last"
          margin="dense"
          onChange={e => {
            this.setState({ employer_contact_last: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_contact_last}
        />

        <TextField
          id="standard-dense"
          label="Employer Contact Email"
          margin="dense"
          onChange={e => {
            this.setState({ employer_contact_email: e.target.value });
          }}
          defaultValue={this.props.preloaded.employer_contact_email}
        />

        <TextField
          id="standard-dense"
          label="Employee Email"
          margin="dense"
          onChange={e => {
            this.setState({ employee_email: e.target.value });
          }}
          defaultValue={this.props.preloaded.employee_email}
        />

        <TextField
          id="standard-dense"
          label="Number Employees"
          margin="dense"
          onChange={e => {
            this.setState({ number_employees: e.target.value });
          }}
          defaultValue={this.props.preloaded.number_employees}
        />

        <TextField
          id="standard-dense"
          label="EIN"
          margin="dense"
          onChange={e => {
            this.setState({ EIN: e.target.value });
          }}
          defaultValue={this.props.preloaded.EIN}
        />

        <TextField
          id="standard-dense"
          label="TIN"
          margin="dense"
          onChange={e => {
            this.setState({ TIN: e.target.value });
          }}
          defaultValue={this.props.preloaded.TIN}
        />

        <TextField
          id="standard-dense"
          label="Employee First"
          margin="dense"
          onChange={e => {
            this.setState({ employee_first: e.target.value });
          }}
          defaultValue={this.props.preloaded.employee_first}
        />

        <TextField
          id="standard-dense"
          label="Employee Last"
          margin="dense"
          onChange={e => {
            this.setState({ employee_last: e.target.value });
          }}
          defaultValue={this.props.preloaded.employee_last}
        />

        <TextField
          id="standard-dense"
          label="Beneficiary First"
          margin="dense"
          onChange={e => {
            this.setState({ beneficiary_first: e.target.value });
          }}
          defaultValue={this.props.preloaded.beneficiary_first}
        />

        <TextField
          id="standard-dense"
          label="Beneficiary Last"
          margin="dense"
          onChange={e => {
            this.setState({ beneficiary_last: e.target.value });
          }}
          defaultValue={this.props.preloaded.beneficiary_last}
        />

        <TextField
          id="effectiveDateTextBox"
          label="Effective Date"
          name="effectiveDateTextField"
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          defaultValue={this.props.preloaded.effective_date}
          onChange={e => this.setState({ effective_date: e.target.value })}
          style={{ width: 150, marginRight: 10 }}
          margin="dense"
        />


        <Button id="saveInfoButton" onClick={this.saveInfo}>
          Save
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MedicareInfo);