import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/SettingsMenuStyles";

import {
  SettingsIcon,
  UserIcon,
  TeamIcon,
  RecipientsIcon,
  FormIcon,
  BillingIcon
} from "../../Shared/Components/Icons";
import EditEnrollment from "../Enrollment/EditEnrollment";
import EditInfo from "../Info/EditInfo";

class SettingsMenu extends React.Component {
  state = {
    anchorEl: null, 
    editEnrollmentOpen: false,
    editInfoOpen: false
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEnrollmentSettings = () => {
    this.setState({editEnrollmentOpen: true, anchorEl: null })
  };

  handleCompanyInfo = () => {
    this.setState({ editInfoOpen: true, anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <div style={{display:'none'}}>
          <EditEnrollment enqueueSnackbar={this.props.enqueueSnackbar} isOpen={this.state.editEnrollmentOpen} close={() => {this.setState({editEnrollmentOpen: false})}} />
          <EditInfo enqueueSnackbar={this.props.enqueueSnackbar} isOpen={this.state.editInfoOpen} close={() => {this.setState({editInfoOpen: false})}} />
        </div>
        {1 === 1 && (
          <React.Fragment>
            <IconButton
              aria-owns={open ? "menu-settings" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <SettingsIcon className={classes.settingsMenuIcon} />
            </IconButton>

            <Menu
              id="menu-settings"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleEnrollmentSettings}>
                <UserIcon className={classes.settingsMenuItemIcon} />
                Enrollment Settings
              </MenuItem>
              <MenuItem onClick={this.handleCompanyInfo}>
                <TeamIcon className={classes.settingsMenuItemIcon} />
                Company Info
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
        
      </React.Fragment>
    );
  }
}

SettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SettingsMenu);
