import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { HomeIcon } from "../../Shared/Components/Icons";

import Menu from "@material-ui/core/Menu";

import IconButton from "@material-ui/core/IconButton";

import CollapseLeft from "@material-ui/icons/ChevronLeft";

import blue from "@material-ui/core/colors/blue";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Clear";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/HighlightOff";

import api from "../../Shared/Functions/APIHelpers";

import { Auth } from "aws-amplify";

import config from "../../config";

import { connect } from "react-redux";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const styles = theme => ({
  title: {
    backgroundColor: "#2996cc",
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 2
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  },
  inline: {
    display: "inline"
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  }
});

const initialState = {
  messages: [],
  newMessage: "",
  status: NOT_LOADED,
};

class MessageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.Open &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      this.setState({ status: IS_LOADING});
      this.getMessages(this.props.SenderID);
    }
  }

  getMessages = (senderID) => {
    return new Promise((resolve, reject) => {
      api
        .getMessages(this.props.User.user_id, senderID)
        .then(response => {
          console.log(response)
          this.setState({
            messages: response,
            status: IS_LOADED
          }, () => {
            if(response[0])
              this.props.setNewMessageCount(response[0].current_message_count)
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Messages From Sender" });
          console.log(err);
        });
    });
  };

  addMessage = () => {
    var body = JSON.stringify(
      {
        recipient_id: this.props.SenderID,
        message: this.state.newMessage
      }
    );

    return new Promise((resolve, reject) => {
      api
        .addMessage(this.props.User.user_id, body)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Message" });
          console.log(err);
        });
    });
  };

  handleSend = () => {
    this.addMessage().then(newMessage => {
      var temp = this.state.messages;
      temp.push(newMessage);
      this.setState({ messages: temp, newMessage: "" }, () => {
        this.scrollToMyRef1("smooth");
      });
    });
  };

  handleClose = () => {
    this.props.Close()
    this.reset();
  }
  scrollToMyRef1 = type => {
    var element = this.refs.myRef1;
    element.scrollIntoView({ behavior: type, block: "end" });
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;
    console.log(this.props)
    return (
      <div>

        <Dialog
          open={this.props.Open}
          aria-labelledby="message-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle>{this.props.Name}</DialogTitle>
          <DialogContent>
            <List style={{ overflow: "auto", maxHeight: 400 }}>
              {this.state.messages.map((message, index) => {
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                var time = new Date(message.date_added);
                var iconPicture = ""
                if(this.props.SenderID !== message.sender.id)
                  iconPicture = this.props.User.picture
                return (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        src={iconPicture}
                        className={classNames(
                          { [classes.purpleAvatar]: this.props.SenderID === message.sender.id },
                          { [classes.orangeAvatar]: !(this.props.SenderID === message.sender.id) }
                        )}
                      >
                        {message.sender.first_name.charAt(0)}{message.sender.last_name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {message.sender.first_name} {message.sender.last_name}
                          <div style={{ display: "flex", alignItems:"baseline",justifyContent: 'space-between'}}>
                            <div>
                              <Typography
                                component="span"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                { (message.sender.employer) ? message.sender.employer.name : null }
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="caption"
                              >
                              {time.toLocaleString('en-US', { timeZone: timeZone })}
                              </Typography>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            className={classes.inline}
                            color="textPrimary"
                          />
                          {message.message}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })}
              <div
                id="myRef1"
                ref="myRef1"
                style={{
                  height: 0,
                  marginTop: 0,
                  backgroundColor: "red",
                  display: "block"
                }}
              />
            </List>
            <div
              style={{
                width: 500,
                marginLeft: 20,
                marginRight: 20,
                textAlign: "right"
              }}
            >
              <OutlinedInput
                id="component-outlined"
                value={this.state.newMessage}
                onChange={e => {
                  this.setState({ newMessage: e.target.value });
                }}
                labelWidth={0}
                style={{ width: 400, marginTop: 20 }}
                autoComplete="off"
              />
              <Button
                onClick={this.handleSend}
                color="primary"
                variant="outlined"
                style={{ marginLeft: 10, marginBottom: 1, height: 55 }}
              >
                Send
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MessageModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withMobileDialog()(withStyles(styles)(MessageModal));
