import {
    LOAD_MESSAGE_SUMMARIES,

} from "../Constants/ActionTypes";

const intitialState = {
    messageSummaries : [],
    isMessageSummariesLoaded: false,

};

const messageReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_MESSAGE_SUMMARIES:
            return {
                ...state,
                messageSummaries: action.payload,
                isMessageSummariesLoaded: true
            };
        default:
            return state;
    }
};

export default messageReducer;
