import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import AddCard from "../Shared/AddCard";
import EditHealth from "./EditHealth";
import HealthCard from "./HealthCard";
import ConditionList from "./ConditionList";

import PageHeader from "../Shared/PageHeader";

import config from "../../config";

import api from "../../Shared/Functions/APIHelpers";

import { Auth } from "aws-amplify";

import { connect } from "react-redux";
import { TakingCoverage } from "../Shared/Functions/StaticFunctions";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


import {
  clearActiveHealth,
  setActiveCondition,
  clearActiveCondition,
  openAddHealth,
  isLoading,
  loadHealth,
  healthLoaded
} from "../Redux/Actions/index";

const styles = theme => ({});

const mapDispatchToProps = dispatch => {
  return {
    //loadProfiles: profiles => dispatch(loadProfiles(profiles)),
    healthLoaded: () => dispatch(healthLoaded()),
    clearActiveHealth: () => dispatch(clearActiveHealth()),
    setActiveCondition: conditionID =>
      dispatch(setActiveCondition(conditionID)),
    clearActiveCondition: () => dispatch(clearActiveCondition()),
    openAddHealth: () => dispatch(openAddHealth()),
    loadHealth: health => dispatch(loadHealth(health)),
    isLoading: loading => dispatch(isLoading(loading))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    health: state.HealthReducer.health,
    profiles: state.ProfilesReducer.profiles,
    isLoaded: state.HealthReducer.isLoaded
  };
};

class HealthContainer extends React.Component {
  state = {
    waiving: ""
  };

  componentDidMount() {
    this.props.clearActiveHealth();

    if (!this.props.isLoaded) {
      this.getHealthRecords();
    }
  }
  getHealthRecords = () => {
    return new Promise((resolve, reject) => {
      api
        .getHealthRecords(this.props.auth.user_id)
        .then(response => {
          this.props.loadHealth(response);
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Health Records" });
          console.log(err);
        });
    });
  };

  handleAdd = () => {
    this.props.openAddHealth();
    this.setState({ expanded: false });
  };

  goBack = () => {
    this.props.history.push("family");
  };

  goNext = () => {
    this.props.history.push("review");
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <EditHealth />
        <PageHeader
          pageTitle="Health"
          back={this.goBack}
          next={this.goNext}
          summary={this.props.Summary}
          waiving={
            !TakingCoverage(this.props.profiles, this.props.auth.profile_id)
          }
        />
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 20 }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
              onClick={this.handlePanelClick}
            >
              <Typography variant="subtitle2">Health Record Instructions</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ marginTop: 20 }}>
              <Typography variant="body1">
                Please select from the Health Status Conditions List to begin adding health records. You will need to add each applicable
                health status for each family member.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        {!this.props.Summary && (
          <ConditionList
            AddCondition={this.handleAdd}
            Expanded={this.state.expanded}
          />
        )}

        {this.props.health.map(health => {
          return (
            <HealthCard
              Summary={this.props.Summary}
              key={"healthCard_" + health.id}
              healthID={health.id}
              Condition={health.condition}
              ICD={health.icd}
              HasPrognosis={health.has_progosis}
              HasHospitalization={health.has_hospitalization}
              HasSurgery={health.has_surgery}
              HasMedication={health.has_medication}
              Profile={health.profile}
              StartDate={health.start_date}
              EndDate={health.end_date}
              DateLabel={health.date_label}
              Prognosis={health.prognosis}
              CustomDetails={health.details}
              PastHospitalization={health.past_hospitalization}
              FutureHospitalization={health.future_hospitalization}
              PastSurgery={health.past_surgery}
              FutureSurgery={health.future_surgery}
              Medications={health.medications}
              Detail={health.icd_description}
            />
          );
        })}

        {(this.props.health.length > 0 || this.props.Summary) && (
          <AddCard ButtonTitle={"Add Health Record"} Action={this.handleAdd} />
        )}
      </div>
    );
  }
}

HealthContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HealthContainer));
