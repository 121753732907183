import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import blue from "@material-ui/core/colors/blue";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

import {
  updateAddress,
  addAddress,
  deleteAddress
} from "../Redux/Actions/index";

import {
  AddCircleIcon,
  CancelCircleIcon,
  ConfirmCircleIcon
} from "../../Shared/Components/Icons";

const mapDispatchToProps = dispatch => {
  return {
    updateAddress: address => dispatch(updateAddress(address)),
    addAddress: address => dispatch(addAddress(address)),
    deleteAddress: address => dispatch(deleteAddress(address))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    states: state.StateReducer.states,
    addresses: state.ProfilesReducer.addresses,
    activeProfile: state.ProfilesReducer.activeProfile
  };
};

const styles = theme => ({
  title: {
    backgroundColor: blue[800],
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 1.5
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  },
  address: {
    "@media only screen and (max-width: 600px)": {
      maxWidth: 220,
      marginBottom: -5,
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  }
});

const initialState = {
  errorMessage: "",
  showInputs: false,
  isEdit: false,
  stateID: "",
  countyID: "",
  counties: [],
  anchorEl: null,
  showMenu: false,
  addressID: "",
  city: "",
  id: "",
  street: "",
  zip: ""
};

class AddressModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.state.addressID = props.Address;
  }

  reset() {
    this.setState(initialState);
  }

  getCounties = () => {
    return new Promise((resolve, reject) => {
      api
        .getCounties(this.state.stateID)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Counties" });
          console.log(err);
        });
    });
  };

  addAddress = () => {
    var body = JSON.stringify({
      street: this.state.street,
      city: this.state.city,
      state_id: this.state.stateID,
      county_id: this.state.countyID,
      zip: this.state.zip
    });

    return new Promise((resolve, reject) => {
      api
        .addAddress(this.props.auth.user_id, body)
        .then(response => {
          this.props.addAddress({
            id: response.id,
            street: response.street,
            city: response.city,
            state: response.state,
            stateID: response.state_id,
            countyID: response.county_id,
            county: response.county,
            zip: response.zip
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Address" });
          console.log(err);
        });
    });
  };

  deleteAddress = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteAddress(this.state.id)
        .then(r => {
          this.props.deleteAddress(this.state.id);
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Address" });
          console.log(err);
        });
    });
  };

  editAddress = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        street: this.state.street,
        city: this.state.city,
        state_id: this.state.stateID,
        county_id: this.state.countyID,
        zip: this.state.zip
      });

      api
        .updateAddress(this.state.id, body)
        .then(response => {
          this.props.updateAddress({
            id: response.id,
            street: response.street,
            city: response.city,
            stateID: response.state_id,
            state: response.state,
            countyID: response.county_id,
            county: response.county,
            zip: response.zip
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Address" });
          console.log(err);
        });
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = (event, address) => {
    //setting state with selected address information

    this.setState(
      {
        id: address.id,
        city: address.city,
        stateID: address.state.id,
        street: address.street,
        zip: address.zip,
        countyID: address.county.id
      },
      this.openMenu(event)
    );
  };

  openMenu = event => {
    this.setState({
      showMenu: true,
      anchorEl: event.currentTarget
    });
  };

  handleSelectAddress = name => event => {
    this.setState({ [name]: event.target.value });
    this.props.SelectAddress(event.target.value);
  };

  saveAddress = () => {
    if (this.state.isEdit) {
      this.editAddress().then(r => {
        this.reset();
      });
    } else {
      this.addAddress().then(r => {
        this.reset();
      });
    }
  };

  handleEditAddress = event => {
    this.getCounties().then(counties => {
      this.setState({ counties: counties, showInputs: true, isEdit: true }, () => {
        this.props.ScrollToBottom()
      });
    });
  };

  handleNewAddress = () => {
    this.setState({ showInputs: true, isEdit: false}, () => {
      this.props.ScrollToBottom()
    });
  };

  handleDeleteAddress = () => {
    this.deleteAddress().then(r => {
      this.reset();
    });
  };

  cancelAddress = () => {
    this.reset();
  };

  handleStateChange = event => {
    this.setState({ stateID: event.target.value }, () => {
      this.getCounties().then(counties => {
        this.setState({ counties: counties });
      });
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div>
        {!this.state.showInputs && (
          <div id="displayDiv">
            <FormLabel component="legend" style={{ marginTop: 30 }}>
              Address
            </FormLabel>
            <Typography style={{ color: "red" }}>
              {this.state.errorMessage}
            </Typography>
            <div>
              <RadioGroup
                aria-label="Address"
                name="address1"
                value={this.state.addressID}
                onChange={this.handleSelectAddress("addressID")}
              >
                {this.props.addresses.map((address, index) => {
                  return (
                    <FormControlLabel
                      value={address.id}
                      key={"Address_" + address.id}
                      control={<Radio color="primary" />}
                      style={{ width: "100%", float: "left", marginLeft: -12 }}
                      label={
                        <span>
                          <span className={classes.address}>
                            {address.street}, {address.city},{" "}
                            {address.state.name} {address.zip}
                          </span>

                          <IconButton
                            style={{ marginBottom: 0, marginLeft: -8 }}
                            onClick={e => this.handleMenu(e, address)}
                            color="primary"
                            name="addressIconButton"
                          >
                            <MoreIcon />
                          </IconButton>
                        </span>
                      }
                    />
                  );
                })}
              </RadioGroup>
              <Button
                className={classes.button}
                style={{ marginLeft: -8 }}
                onClick={this.handleNewAddress}
                name="addAddressButton"
                color="primary"
              >
                <AddCircleIcon className={classes.leftIcon} />
                Add New Address
              </Button>

              <Menu
                id="menu-editprofile"
                key={"menu_"}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem key={"Menu1_"} onClick={this.handleEditAddress} name="editAddressButon">
                  Edit Address
                </MenuItem>
                <MenuItem key={"Menu2_"} onClick={this.handleDeleteAddress} name="deleteAddressButton">
                  Delete Address
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}

        {this.state.showInputs && (
          <React.Fragment>
          <FormLabel component="legend" style={{ marginTop: 30 }}>
            Add Address
          </FormLabel>
          <div id="hiddenDiv" style={{ marginLeft: 0 }}>
            <TextField
              id="standard-dense"
              label="Street"
              margin="dense"
              name="streetTextField"
              value={this.state.street}
              onChange={e => this.setState({ street: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="City"
              margin="dense"
              name="cityTextField"
              onChange={e => this.setState({ city: e.target.value })}
              defaultValue={this.state.city}
              fullWidth
            />

            <FormControl margin="dense" name="stateSelect">
              <InputLabel htmlFor="states">State</InputLabel>
              <Select
                value={this.state.stateID}
                onChange={this.handleStateChange}
                inputProps={{
                  name: "state",
                  id: "state"
                }}
                style={{ width: 70, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.states.map(state => {
                  return (
                    <MenuItem value={state.id} key={"state_" + state.id}>
                      {state.abbreviation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl margin="dense" name="countySelect">
              <InputLabel htmlFor="county">County</InputLabel>
              <Select
                value={this.state.countyID}
                onChange={this.handleChange("countyID")}
                inputProps={{
                  name: "county",
                  id: "county"
                }}
                style={{ width: 150, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.state.counties.map(county => {
                  return (
                    <MenuItem value={county.id} key={"county_" + county.id}>
                      {county.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              id="standard-dense"
              label="Zip"
              name="zipTextField"
              margin="dense"
              onChange={this.handleChange("zip")}
              value={this.state.zip}
              style={{ width: 100 }}
            />
            <br />
            <IconButton
              className={classes.button}
              style={{ marginTop: 10, marginLeft: -16 }}
              name="cancelAddressButton"
              onClick={this.cancelAddress}
              color="primary"
            >
              <CancelCircleIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              style={{ marginTop: 10, marginLeft: 0 }}
              name="saveAddressButton"
              onClick={this.saveAddress}
              color="primary"
            >
              <ConfirmCircleIcon />
            </IconButton>
          </div>
        </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(AddressModule)));

export { AddressModule as PureAddressModule};
