import {
    OPEN_ADD_PROFILE,
    OPEN_EDIT_PROFILE,
    CLOSE_EDIT_PROFILE,

    OPEN_ADD_EMPLOYMENT,
    OPEN_EDIT_EMPLOYMENT,
    CLOSE_EDIT_EMPLOYMENT,


    OPEN_ADD_HEALTH,
    OPEN_EDIT_HEALTH,
    CLOSE_EDIT_HEALTH,


    OPEN_ADD_OVERRIDE,
    OPEN_EDIT_OVERRIDE,
    CLOSE_EDIT_OVERRIDE,


    OPEN_ADD_EMPLOYER,
    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,


    OPEN_NAV_DRAWER,
    CLOSE_NAV_DRAWER,
    IS_LOADING,
    IS_LOADED,
} from '../Constants/ActionTypes'

const intitialState = {
    profileModalOpen: false,
    employmentModalOpen: false,
    healthModalOpen: false,
    overrideModalOpen: false,
    employerModelOpen: false,
    isEdit : false, 
    navDrawerOpen: false,
    isLoading: false,

}

const UIReducer = ( state = intitialState, action ) => {
    switch (action.type) {
        case OPEN_ADD_PROFILE:
            return{...state, profileModalOpen: action.payload, isEdit: true}
        case OPEN_EDIT_PROFILE:
            return {...state, profileModalOpen: action.payload, isEdit : true}
        case CLOSE_EDIT_PROFILE:
            return {...state, profileModalOpen: action.payload}
        case OPEN_ADD_EMPLOYMENT:
            return{...state, employmentModalOpen: action.payload, isEdit: false}
        case OPEN_EDIT_EMPLOYMENT:
            return {...state, employmentModalOpen: action.payload, isEdit : true}
        case CLOSE_EDIT_EMPLOYMENT:
            return {...state, employmentModalOpen: action.payload} 


        case OPEN_ADD_HEALTH:
            return{...state, healthModalOpen: action.payload, isEdit: false}
        case OPEN_EDIT_HEALTH:
            return {...state, healthModalOpen: action.payload, isEdit : true}
        case CLOSE_EDIT_HEALTH:
            return {...state, healthModalOpen: action.payload} 


        case OPEN_ADD_OVERRIDE:
            return{...state, overrideModalOpen: action.payload, isEdit: false}
        case OPEN_EDIT_OVERRIDE:
            return {...state, overrideModalOpen: action.payload, isEdit : true}
        case CLOSE_EDIT_OVERRIDE:
            return {...state, overrideModalOpen: action.payload} 


        case OPEN_ADD_EMPLOYER:
            return{...state, employerModalOpen: action.payload, isEdit: false}
        case OPEN_EDIT_EMPLOYER:
            return {...state, employerModalOpen: action.payload, isEdit : true}
        case CLOSE_EDIT_EMPLOYER:
            return {...state, employerModalOpen: action.payload} 


        case OPEN_NAV_DRAWER:
            return {...state, navDrawerOpen: action.payload}
        case CLOSE_NAV_DRAWER:
            return {...state, navDrawerOpen: action.payload}

        case IS_LOADING:
            return {...state, isLoading: action.payload}

        default:
            return state        
    }
};


export default UIReducer