import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { EditIcon } from "../../Shared/Components/Icons";

import EditFormGroup from "./EditFormGroup";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setFormGroupTable, openEditFormGroup } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setFormGroupTable: formGroupTable =>
      dispatch(setFormGroupTable(formGroupTable)),
    openEditFormGroup: () => dispatch(openEditFormGroup())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    formGroupTable: state.TableReducer.formGroupTable
  };
};

class FormGroupsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, formGroup) => {
    var tempState = this.props.formGroupTable;
    tempState.selected = formGroup;
    this.props.setFormGroupTable(tempState);

    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  handleEditFormGroup = () => {
    this.props.openEditFormGroup();
    this.handleCloseMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <EditFormGroup refreshTable={this.handleRefreshTable} enqueueSnackbar={this.props.enqueueSnackbar} />
        <CustomTable
          columns={[
            {
              label: "Name",
              dataKey: "name",
              numeric: false,
              width: 300
            },
            {
              label: "Agency Default",
              dataKey: "is_agency_default",
              checkMark: true,
              width: 69
            }
          ]}
          name={'Form Groups'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={
            "customers/" + this.props.auth.customer_id + "/form-groups"
          }
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleEditFormGroup}>
            <EditIcon className={classes.leftIcon} /> Edit Form Group
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormGroupsTable));
