import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import Button from "@material-ui/core/Button";

class CheckoutForm extends Component {
	constructor(props) {
		console.log(props);
		super(props);
		/*
		this.getToken = this.getToken.bind(this);
		*/
	}
	componentDidMount() {
		this.props.setCreateToken(this.getToken);
	}

	getToken = ev => {
		return new Promise((resolve, reject) => {
			// User clicked submit
			var token = {};
			this.props.stripe.createToken().then(result => {
				if (result.error) {
					this.props.enqueueSnackbar(result.error.message, {
						variant: "error"
					});
				} else {
					this.props.enqueueSnackbar("Card Approved", {
						variant: "success"
					});
					resolve(result.token)
					//this.props.SetToken(result.token);
				}
			});
		})
	};

	render() {
		return (
			<div style={{ paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid #9e9e9e" }}>
				<div className="checkout">
					<CardElement />
				</div>
			</div>
		);
	}
}

export default injectStripe(CheckoutForm);