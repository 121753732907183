import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import FormsTable from "./FormsTable";

import { AddIcon } from "../../Shared/Components/Icons";

import { openEditForm } from "../Redux/Actions/index";
import { connect } from "react-redux";

const mapDispatchToProps = dispatch => {
  return {
    openEditForm: () => dispatch(openEditForm())
  };
};

const mapStateToProps = state => {
  return {
    formTable: state.TableReducer.formTable
  };
};

class FormsContainer extends React.Component {
  handleOpen = () => {
    this.props.openEditForm();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <FormsTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FormsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormsContainer));
