import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import ReactSelect from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

//import Checkbox from '@material-ui/core/Checkbox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import {
  AddCircleIcon,
  CancelCircleIcon,
  ConfirmCircleIcon
} from "../../Shared/Components/Icons";

import { connect } from "react-redux";

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  code: "",
  selectedInvite: {},
  useCode: false,
  invitations: [],
  anchorEl: null,
  showInputs: false
};

class BrokerInvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.Open && this.props.Open) {
      this.getEmployerCustomerInvitations();
    }
  }

  getEmployerCustomerInvitations = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerCustomerInvitations(this.props.Employer)
        .then(response => {
          console.log(response);
          this.setState({ invitations: response });
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Getting Employer Broker Invitations"
          });
          console.log(err);
        });
    });
  };

  addEmployerCustomerInvitation = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      customer_code: null,
      email: this.state.email,
      inviter_id: this.props.Inviter,
      employer_id: this.props.Employer
    }); 

    return new Promise((resolve, reject) => {
      api
        .addEmployerCustomerInvitation(this.props.Employer, body)
        .then(r => {
          if(!r.added_customer)
          {
            var temp = this.state.invitations;
            temp.push(r);
            this.setState(
              { invitations: temp, showInputs: false },
              resolve("done")
            );
          }
          else{
            this.props.enqueueSnackbar("Employer associated with customer: " + r.added_customer, {
              variant: "success"
            });
            this.handleClose()
            this.props.RefreshTable()
            resolve('done')
          }
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Adding Broker Employee Invitation"
          });
          console.log(err);
        });
    });
  };

  associateEmployerWithCustomerCode = () => {
    console.log(this.state.code)
    var body = JSON.stringify({
      customer_code: this.state.code,
      inviter_id: this.props.Inviter,
      employer_id: this.props.Employer
    });

    return new Promise((resolve, reject) => {
      api
        .associateEmployerWithCustomerCode(this.props.Employer, body)
        .then(r => {
            this.props.enqueueSnackbar("Employer associated with customer: " + this.state.code, {
              variant: "success"
            });
            resolve("done")
        })
        .catch(err => {
          this.props.enqueueSnackbar("Customer Code Not Found", {
            variant: "error"
          });
          console.log(err);
        });
    });

  };

  resendInvitation = () => {
    return new Promise((resolve, reject) => {
      api
        .resendEmployerBrokerInvitation(
          this.state.selectedInvite.id
        )
        .then(response => {
          this.props.enqueueSnackbar("Invitation Email Resent to " + this.state.selectedInvite.email, {
            variant: "success"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Resneding Invitation" });
          console.log(err);
        });
    });
  };

  deleteEmployerCustomerInvitation = inviteID => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployerCustomerInvitation(inviteID)
        .then(r => {
          var invitations = this.state.invitations;
          invitations = invitations.filter(
            i => i.id != this.state.selectedInvite.id
          );
          this.setState(
            {
              invitations: invitations,
              selectedInvite: {},
              firstName: "",
              lastName: "",
              email: ""
            },
            resolve("done")
          );
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Deleting Employer Broker Invitation"
          });
          console.log(err);
        });
    });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleMenu = (event, invite) => {
    this.setState(
      {
        selectedInvite: invite,
        firstName: invite.first_name
      },
      this.openMenu(event)
    );
  };

  openMenu = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleDeleteInvite = () => {
    this.deleteEmployerCustomerInvitation(this.state.selectedInvite.id).then(
      r => {
        this.handleCloseMenu();
      }
    );
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, firstName: "", lastName: "", email: "", selectedInvite: "" });
  };

  cancelAddInvite = () => {
    this.setState({
      showInputs: false,
      firstName: "",
      lastName: "",
      email: "",
      code: "",
      useCode: false
    });
  };

  handleAddInvite = () => {
    this.setState({ showInputs: true });
  };

  handleSubmitBroker = () => {
    if(!this.state.useCode)
    {
      this.addEmployerCustomerInvitation().then(r => {
        this.cancelAddInvite()
      })
    }
    else{
      this.associateEmployerWithCustomerCode().then(r=> {
        this.handleClose()
        this.props.RefreshTable()
      });
    }
  };

  handleResendInvite = () => {
    this.resendInvitation().then(r => {
      this.handleCloseMenu()
    })
  };

  render() {
    const { fullScreen, classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Invite Broker</DialogTitle>

          <DialogContent style={{ height: 300, width: 400 }}>
            {!this.state.showInputs && (
              <React.Fragment>
                <div>
                  <List>
                    {this.state.invitations.map((invite, index) => {
                      return (
                        <ListItem
                          key={index}
                          style={{ borderBottom: "1px solid #E9E9E9" }}
                        >
                          <ListItemText
                            primary={
                              <React.Fragment>
                                {invite.first_name} {invite.last_name}
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>{invite.email}</React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={e => this.handleMenu(e, invite)}
                              aria-label="Menu"
                            >
                              <MoreIcon color={"primary"} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Menu
                    id="menu-location"
                    key={"menu_"}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                  >
                    <MenuItem key={"Menu1_"} onClick={this.handleResendInvite} name="resentInviteButton">
                      Resend Invite
                    </MenuItem>
                    <MenuItem key={"Menu2_"} onClick={this.handleDeleteInvite} name="deleteInviteButton">
                      Delete Invite
                    </MenuItem>
                  </Menu>
                </div>
                <div>
                  <Button
                    className={classes.button}
                    style={{ marginLeft: -8 }}
                    onClick={this.handleAddInvite}
                    name="addInviteButton"
                    color="primary"
                  >
                    <AddCircleIcon className={classes.leftIcon} />
                    Invite Broker
                  </Button>
                </div>
              </React.Fragment>
            )}
            {this.state.showInputs && (
              <React.Fragment>
                <div>
                  <Typography variant="caption">
                    Use Email
                    <Switch
                      value="fit"
                      color="default"
                      name="emailCodeToggle"
                      onChange={
                        e => {
                          this.setState({useCode: e.target.checked})
                        }}
                    />
                    Use Code
                  </Typography>
                </div>
                {!this.state.useCode && (
                  <React.Fragment>
                    <TextField
                      id="standard-dense"
                      label="First Name"
                      value={this.state.firstName}
                      margin="dense"
                      onChange={e =>
                        this.setState({ firstName: e.target.value })
                      }
                      style={{ marginBottom: 20 }}
                      fullWidth
                    />
                    <TextField
                      id="standard-dense"
                      label="Last Name"
                      value={this.state.lastName}
                      margin="dense"
                      onChange={e =>
                        this.setState({ lastName: e.target.value })
                      }
                      fullWidth
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      id="standard-dense"
                      label="Email"
                      value={this.state.email}
                      margin="dense"
                      onChange={e => this.setState({ email: e.target.value })}
                      fullWidth
                    />
                  </React.Fragment>
                )}
                {this.state.useCode && (
                  <TextField
                    id="standard-dense"
                    label="Code"
                    value={this.state.code}
                    margin="dense"
                    onChange={e => this.setState({ code: e.target.value })}
                    fullWidth
                  />
                )}
                <IconButton
                  className={classes.button}
                  style={{ marginTop: 10, marginLeft: -17 }}
                  onClick={this.cancelAddInvite}
                  color="primary"
                >
                  <CancelCircleIcon />
                </IconButton>
                <IconButton
                  className={classes.button}
                  style={{ marginTop: 10, marginLeft: -5 }}
                  onClick={this.handleSubmitBroker}
                  color="primary"
                >
                  <ConfirmCircleIcon />
                </IconButton>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

BrokerInvitationModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

BrokerInvitationModal.defaultProps = {
  isOpen: false
};

export default withMobileDialog()(withStyles(styles)(BrokerInvitationModal));

export { BrokerInvitationModal as PureBrokerInvitationModal};
