import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import FormLabel from "@material-ui/core/FormLabel";

import MobileStepper from "@material-ui/core/MobileStepper";

import Agreement from "../Agreement";

import Button from "@material-ui/core/Button";

import api from "../../Shared/Functions/APIHelpers";

import { Auth } from "aws-amplify";

import { styles } from "../Styles/AuthStyles";

import config from "../../config";

class EmployeeSetup extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    inviteCode: "",
    step: 1,
    finishDisabled: true,
    agreementModalOpen: false,
    invite: "",
    firstName: "",
    lastName: "",
    checkedAgree: false,
    userID: "",
    employerCode: null,
    employerLocation: null,
    locations: [],
    newUser: "",
    employerID: null,
    employerName: "",
    employerStreet: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    employerPhone: "",
    renewalMonth: "",
    renewalDay: "",
    complete: false,
    isLoading: true
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let employerCode = params.get("employer");
    console.log(this.props);
    this.getUser().then(response => {
      if (this.props.flowBypass !== "agreement") {
        if (inviteCode) {
          this.GetInvitation(inviteCode).then(r => {
            this.getEmployerLocations();
          });
        } else if (employerCode) {
          this.getEmployerByCode(employerCode).then(r => {
            this.getEmployerLocations();
          });
        }
      } else {
        this.setState({ step: 1 });
      }
    });
  }

  getUser = () => {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then(response => {
          console.log(response);
          this.setState({
            isLoading: false,
            userID: response.id,
            firstName: response.first_name,
            lastName: response.last_name,
            newUser: response.new_user
            //step: (response.new_user) ? 1 : 2
          });
          resolve(response);
        })
        .catch(err => {
          console.log("in the error flow");
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  GetInvitation = inviteCode => {
    console.log("getting invitation - employee setup");
    return new Promise((resolve, reject) => {
      api
        .getInvitationByCodeAccepted(inviteCode)
        .then(response => {
          this.setState({
            isLoading: false,
            employerID: response.employer.id,
            firstName: response.first_name,
            lastName: response.last_name
          });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Invite" });
          console.log(err);
        });
    });
  };

  getEmployerByCode = employerCode => {
    console.log("get by " + employerCode);
    return new Promise((resolve, reject) => {
      api
        .getEmployerByCode(employerCode)
        .then(response => {
          this.setState({
            isLoading: false,
            employerID: response.id,
            employerCode: employerCode
          });
          resolve(response);
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Getting Employer by Employer Code"
          });
          console.log(err);
        });
    });
  };

  getEmployerLocations = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerLocations(this.state.employerID)
        .then(response => {
          console.log(response);
          console.log(response[0].id);
          this.setState({
            locations: response,
            employerLocation: response[0].id //set default when only one location
          });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Locations" });
          console.log(err);
        });
    });
  };

  updateUser = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        id: this.state.userID,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        employer_id: this.state.employerID,
        location_id: this.state.employerLocation,
        checked_agree: this.state.checkedAgree
      });
      api
        .updateUser(body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating User" });
          console.log(err);
        });
    });
  };

  step2 = () => {
    //if carrier skip to end
    if (this.props.app === "carrier") {
      this.setState({ step: 4 });
    } else {
      //if employer code exists check for locations otherwise, ask for code
      if (this.state.employerID !== null) {
        //if there are no locations, skip this step
        if (this.state.locations.length === 1) {
          this.setState({ step: 4 });
        } else {
          this.setState({ step: 3 });
        }
      } else {
        this.setState({ step: 2 });
      }
    }
  };

  step3 = () => {
    // if an employer code was entered, get locations otherwise skip step 3
    if (this.state.employerCode !== null) {
      this.getEmployerByCode(this.state.employerCode).then(r => {
        //get locations and determine whether to skip step 3
        this.getEmployerLocations().then(r => {
          if (r.length === 1) {
            this.setState({ step: 4 });
          } else {
            this.setState({ step: 3 });
          }
        });
      });
    } else {
      this.setState({ step: 4 });
    }
  };

  step4 = () => {
    this.setState({ step: 4 });
  };

  finish = () => {
    this.updateUser().then(r => {
      console.log(this.props);
      if (this.props.app === "broker") this.props.history.push("/broker/home");
      this.props.history.push("home");
    });
  };

  closeTerms = () => {
    this.setState({ agreementModalOpen: false });
  };
  openTerms = () => {
    this.setState({ agreementModalOpen: true });
  };

  handleSelectChange = name => event => {
    console.log(name);
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div className={classes.basecontent}>
        <Agreement
          isOpen={this.state.agreementModalOpen}
          Close={this.closeTerms}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root_md} elevation={1}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Setup
              </Typography>
              <div style={{ height: 350, marginTop: 20 }}>
                {this.state.isLoading && (
                  <div style={{ height: 250, marginTop: 100 }}>
                    <Typography variant="body1">Loading...</Typography>
                    <br />
                    <br />
                    <CircularProgress className={classes.progress} />
                  </div>
                )}
                {!this.state.isLoading && this.state.step === 1 && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      Let's finish setting up your account!
                    </Typography>
                    <TextField
                      id="standard-dense"
                      label="First Name"
                      autoFocus
                      margin="dense"
                      onChange={e =>
                        this.setState({ firstName: e.target.value })
                      }
                      value={this.state.firstName}
                      className={classes.field}
                      fullWidth
                    />
                    <TextField
                      id="standard-dense"
                      label="Last Name"
                      margin="dense"
                      onChange={e =>
                        this.setState({ lastName: e.target.value })
                      }
                      value={this.state.lastName}
                      className={classes.field}
                      fullWidth
                    />
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.step2}
                        style={{ width: 100 }}
                      >
                        Next
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading && this.state.step === 2 && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      If you have your company's employer code, please enter it
                      now. If not, you can do this later.
                    </Typography>
                    <TextField
                      id="standard-dense"
                      label="Employer Code"
                      autoFocus
                      margin="dense"
                      onChange={e =>
                        this.setState({ employerCode: e.target.value })
                      }
                      value={this.state.employerCode}
                      className={classes.field}
                      fullWidth
                    />
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.step3}
                        style={{ width: 100 }}
                      >
                        Next
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading && this.state.step === 3 && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      Please select your employer location...
                    </Typography>

                    <FormControl margin="dense" fullWidth>
                      <InputLabel htmlFor="location">Location</InputLabel>
                      <Select
                        value={this.state.employerLocation}
                        onChange={this.handleSelectChange("employerLocation")}
                        inputProps={{
                          name: "employerLocation",
                          id: "employerLocation"
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.state.locations.map(location => {
                          return (
                            <MenuItem
                              value={location.id}
                              key={"location_" + location.id}
                            >
                              {location.name +
                                ": " +
                                location.street +
                                ", " +
                                location.city}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.step4}
                        style={{ width: 100 }}
                      >
                        Next
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading && this.state.step === 4 && (
                  <React.Fragment>
                    <Typography variant="body1" />
                    <Button
                      onClick={this.openTerms}
                      style={{ marginTop: 20 }}
                      color="primary"
                    >
                      View Terms and Conditions
                    </Button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedAgree}
                          onChange={this.handleChange("checkedAgree")}
                          value="checkedSameDates"
                          color="primary"
                        />
                      }
                      label="I have read and agree to the terms and conditions "
                    />
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <Button
                        disabled={!this.state.checkedAgree}
                        variant="contained"
                        color="primary"
                        onClick={this.finish}
                        style={{ width: 250 }}
                      >
                        Finish
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                <br />
              </div>
            </div>
            <div style={{ justifyText: "center" }}>
              {!this.state.isLoading && (
                <MobileStepper
                  variant="progress"
                  steps={5}
                  position="static"
                  activeStep={this.state.step}
                  className={classes.stepper}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

EmployeeSetup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployeeSetup);