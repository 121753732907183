export const getApp = () => {
  let url = window.location.href;
  console.log('from static functions')
  console.log(url)
  if (url.includes("/user/") || url.includes("/user") ) {
    return "user";
  } else if (url.includes("/employer/") || url.includes("/employer") ) {
    return "employer";
  } else if (url.includes("/broker/") || url.includes("/broker")  ) {
    return "broker";
  } else if (url.includes("/carrier/") || url.includes("/carrier") ) {
    return "carrier";
  } else if (url.includes("/admin/") || url.includes("/admin")  ) {
    return "admin";
  } else {
    return null;
  }
};


export const createCode = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  let inviteCode = params.get("invite");
  let employerCode = params.get("employer");
  let customerCode = params.get("customer");

  let code = "";

  if (inviteCode) {
    code = "invite=" + inviteCode;
  } else if (employerCode) {
    code = "employer=" + employerCode;
  } else if (customerCode) {
    code = "customer=" + customerCode;
  } else {
    code = "null";
  }
  return code;
};

export const parseCodeObject = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let codeObj = {};

  let inviteCode = params.get("invite");
  let employerCode = params.get("employer");
  let customerCode = params.get("customer");

  if(inviteCode){
    codeObj.type = 'invite'
    codeObj.code = inviteCode
  }
  if(employerCode){
    codeObj.type = 'employer'
    codeObj.code = employerCode
  }
  if(customerCode){
    codeObj.type = 'customer'
    codeObj.code = customerCode
  }

  return codeObj

};
