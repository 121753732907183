import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { pdfjs } from "react-pdf";
window.PDFJS.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.js";

ReactDOM.render(
    <SnackbarProvider maxSnack={8}>
        <App />
    </SnackbarProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
