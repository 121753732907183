export default {
  s3: {
    REGION: "us-east-2",
    BUCKET: "app.reformhealth.io",
    FILE_BUCKET: "store.reformhealth.io"
  },
  apiGateway: {
    REGION: "YOUR_API_GATEWAY_REGION",
    URL: process.env.REACT_APP_GATEWAY_URL,
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_RsdgyKiGZ",
    APP_CLIENT_ID: "7s6bspvloesr0l52i0lpq4dbgd",
    IDENTITY_POOL_ID: "us-east-2:1a479e1c-2344-4c0e-9ad4-df66931384d1"
  },
  oauth : {
    domain : 'auth.reformhealth.io',      
    scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
    responseType: 'code',
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : true
    }
  }
};