import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import EmployeesTable from "./EmployeesTable";
import Paper from "@material-ui/core/Paper";

import { AddIcon } from "../../Shared/Components/Icons";

class EmployeesContainer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <EmployeesTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EmployeesContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployeesContainer);
