import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import SubmissionsTable from "./SubmissionsTable";

//import EditEmployer from './EditEmployer'

import Paper from "@material-ui/core/Paper";

import config from "../../config";

import { Auth } from "aws-amplify";

import {
  loadEmployers,
  employersLoaded,
  clearActiveEmployer
} from "../Redux/Actions/index";
import { AutoSizer } from "react-virtualized";

const styles = theme => ({
  adminRoot: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexFlow: "column"
  },
  adminContent: {
    flex: 2,
    overflow: "auto",
    height: "100%",
    width: "100%"
    //flex: 2,
  },
  waitCircle: {
    width: "100%",
    textAlign: "center",
    float: "left",
    marginTop: "30vh"
  },
  fab: {
    margin: theme.spacing.unit,
    position: "relative",
    left: -16,
    //top: 20,
    top: 68,
    zIndex: 500,
    "@media only screen and (max-width: 400px)": {
      left: -5
    }
  },
  adminTitle: {
    width: "100%",
    textAlign: "left",
    fontSize: 28,
    color: "grey",
    marginLeft: 20
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

class SubmissionContainer extends React.Component {
  state = {
    inviteModalOpen: false
  };

  handleOpen = () => {
    this.setState({ inviteModalOpen: true });
  };

  handleClose = () => {
    this.setState({ inviteModalOpen: false });
    //this.requestMap();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <React.Fragment>
          <div className={classes.adminRoot}>
            <div style={{ height: 0 }}>
              <Fab
                color="primary"
                aria-label="Add"
                className={classes.fab}
                onClick={this.handleOpen}
              >
                <AddIcon />
              </Fab>
            </div>
            <div className={classes.adminContent}>
              <div style={{ height: 0 }} />
              <Paper
                style={{
                  flex: 3,
                  height: "95%",
                  overflow: "hidden",
                  marginTop: 5,
                  marginLeft: 20,
                  marginRight: 20
                }}

              >
                <SubmissionsTable history={this.props.history} />
              </Paper>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

SubmissionContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SubmissionContainer);
