import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";

import { openEditHealth, setActiveHealth } from "../Redux/Actions/index";

import {
  formatDate,
  getMonth,
  getMonthFromDate,
  getYearFromDate
} from "../../Shared/Functions/Format";

const styles = theme => ({
  container: {
    float: "left",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: 330,
    "@media only screen and (max-width: 600px)": {
      //width: 335,
      float: "none",
      marginRight: 0
    }
  },
  container_expanded: {
    float: "left",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      minWidth: 335,
      float: "none",
      marginRight: 0
    }
  },
  card: {
    minWidth: 330,
    "@media only screen and (max-width: 600px)": {
      minWidth: 335,
      width: "100%"
    },
    minHeight: 250
  },
  cardContent: {
    minHeight: 175
  },
  fieldGroup: {
    float: "left",
    marginRight: 100,
    marginBottom: 12,
    "@media only screen and (max-width: 600px)": {
      width: 250,
      marginRight: 0
    }
  },
  fieldName: {
    fontSize: 12,
    color: "grey"
  },
  fieldValue: {
    fontSize: 20
  },
  pos: {
    marginBottom: 12
  }
});

const mapDispatchToProps = dispatch => {
  return {
    openEditHealth: () => dispatch(openEditHealth()),
    setActiveHealth: healthID => dispatch(setActiveHealth(healthID))
  };
};

const mapStateToProps = state => {
  return {
    profiles: (state.ProfilesReducer) ? state.ProfilesReducer.profiles : null
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if(ownProps.isBrokerRendered){
    return { ...stateProps, ...dispatchProps, ...ownProps, profiles: ownProps.profiles}  
  }
  return { ...stateProps, ...dispatchProps, ...ownProps}  
};

class HealthCard extends React.Component {
  //function SimpleCard(props) {
  constructor() {
    super();

    this.handleEdit = this.handleEdit.bind(this);
  }

  state = {
    isExpanded: false
  };
  
  getName() {
    var profile = this.props.profiles.find(
      profile => profile.id === this.props.ProfileID
    );

    return profile.first_name + " " + profile.last_name;
  }
  
  handleEdit() {
    this.props.setActiveHealth(this.props.healthID);
    this.props.openEditHealth();
  }

  handleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={
          (this.props.Summary && classes.container_expanded) ||
          classes.container
        }
      >
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="row">
              <Grid item xs style={{ maxWidth: 335 }}>
                <Typography variant="caption" gutterBottom>
                  {this.props.Profile.first_name +
                    " " +
                    this.props.Profile.last_name}
                </Typography>
                <Typography variant="h6">
                  {this.props.Condition.name}
                </Typography>
                {this.props.Condition.date_label && (
                  <React.Fragment>
                    <Typography variant="body1" className={classes.pos}>
                      {this.props.Condition.date_label +
                        ": " +
                        formatDate(this.props.EndDate)}
                    </Typography>
                    {this.props.Condition.id ===
                      "013fc4b7134948858376cf9140d44ded" && (
                      <React.Fragment>
                        <img
                          src={require("../Images/bottle_250.png")}
                          alt="bottle"
                          height="50"
                          width="50"
                        />
                        <Typography variant="body2">
                          Congratulations!
                        </Typography>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <Typography variant="body1" className={classes.pos}>
                  {!this.props.Condition.date_label &&
                    formatDate(this.props.StartDate, "mmm yyyy") +
                      " - " +
                      formatDate(this.props.EndDate, "mmm yyyy")}
                </Typography>
                <Typography variant="body1">
                  {this.props.Condition.has_medication &&
                    "Current Medications: "}
                  {this.props.Medications.map((medication, index) => {
                    return (
                      (index ? ", " : "") + medication.medication.brand_name
                    );
                  })}
                </Typography>
              </Grid>
              {(this.state.isExpanded || this.props.Summary) && (
                <Grid item xs style={{ marginTop: 10 }}>
                  <div className={classes.fieldGroup}>
                    <div className={classes.fieldName}>Detail</div>
                    <div className={classes.fieldValue}>
                      {this.props.ICD.description}
                    </div>
                  </div>

                  {(this.props.Condition.has_hospitalization ||
                    this.props.Condition.has_surgery) && (
                    <div>
                      <div style={{ clear: "both", height: 12 }} />
                      <Typography variant="caption">
                        Hospitalizations & Surgeries
                      </Typography>
                      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                      <div className={classes.fieldGroup}>
                        <div className={classes.fieldName}>
                          Required hospitalization
                        </div>
                        <div className={classes.fieldValue}>
                          {(this.props.PastHospitalization && "Yes") || "No"}
                        </div>
                      </div>
                      <div className={classes.fieldGroup}>
                        <div className={classes.fieldName}>
                          May require hospitalization in the future
                        </div>
                        <div className={classes.fieldValue}>
                          {(this.props.FutureHospitalization && "Yes") || "No"}
                        </div>
                      </div>
                      <div className={classes.fieldGroup}>
                        <div className={classes.fieldName}>
                          Required surgery
                        </div>
                        <div className={classes.fieldValue}>
                          {(this.props.PastSurgery && "Yes") || "No"}
                        </div>
                      </div>
                      <div className={classes.fieldGroup}>
                        <div className={classes.fieldName}>
                          May require surgery in the future
                        </div>
                        <div className={classes.fieldValue}>
                          {(this.props.FutureSurgery && "Yes") || "No"}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.props.Condition.has_medication && (
                    <div>
                      <div style={{ clear: "both", height: 12 }} />
                      <Typography variant="caption">Medications</Typography>
                      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                      {this.props.Medications.map(medication => {
                        return (
                          <div
                            key={"div_" + medication.id}
                            className={classes.fieldGroup}
                          >
                            <div className={classes.fieldValue}>
                              {medication.medication.brand_name}
                            </div>
                            <div className={classes.fieldName}>
                              {medication.dosage.form +
                                " " +
                                medication.dosage.ingredients +
                                " (" +
                                formatDate(medication.start_date, "mmm yyyy") +
                                " - " +
                                formatDate(medication.end_date, "mmm yyyy") +
                                ")"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {this.props.CustomDetails.length > 0 && (
                    <div>
                      <div style={{ clear: "both", height: 12 }} />
                      <Typography variant="caption">
                        Additional Details
                      </Typography>
                      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                      {this.props.CustomDetails.map(detail => {
                        return (
                          <div
                            key={"div_" + detail.id}
                            className={classes.fieldGroup}
                          >
                            <div className={classes.fieldName}>
                              {detail.custom_detail.name}
                            </div>
                            <div className={classes.fieldValue}>
                              {detail.value}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            { (!this.props.isBrokerRendered) && (
            <Button
              size="small"
              color="primary"
              style={{ minWidth: 32 }}
              onClick={this.handleEdit}
            >
              Edit
            </Button>
            )}
          </CardActions>
        </Card>
      </div>
    );
  }
}

HealthCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(HealthCard));
