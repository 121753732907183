import {
   LOAD_FIELDS,
   UPDATE_FIELD,
   ADD_FIELD,
   DELETE_FIELD,

   LOAD_CONDITION_RULES,
   DELETE_CONDITION_RULE,
   ADD_CONDITION_RULE,

   LOAD_CONDITION_GROUP_RULES,
   DELETE_CONDITION_GROUP_RULE,
   ADD_CONDITION_GROUP_RULE,

   LOAD_MODELS,
   LOAD_MODEL_FIELDS,

   CLOSE_EDIT_FORM_REVISION,
   OPEN_EDIT_FORM_REVISION,

   CLOSE_EDIT_FORM,
   OPEN_EDIT_FORM,   

   SET_FORM_TABLE,
   SET_FORM_REVISION_TABLE,

} from '../Constants/ActionTypes';

export const loadFields = fields => ({type: LOAD_FIELDS, payload: fields})
export const updateField = field => ({type: UPDATE_FIELD, payload: field})
export const addField = field => ({type:ADD_FIELD, payload: field})
export const deleteField = field => ({type: DELETE_FIELD, payload: field})

export const loadConditionRules = conditionRules => ({type: LOAD_CONDITION_RULES, payload: conditionRules})
export const deleteConditionRule = conditionRuleID => ({type: DELETE_CONDITION_RULE, payload: conditionRuleID})
export const addConditionRule = conditionRule => ({type: ADD_CONDITION_RULE, payload: conditionRule})

export const loadConditionGroupRules = conditionGroupRules => ({type: LOAD_CONDITION_GROUP_RULES, payload: conditionGroupRules})
export const deleteConditionGroupRule = conditionGroupRuleID => ({type: DELETE_CONDITION_GROUP_RULE, payload: conditionGroupRuleID})
export const addConditionGroupRule = conditionGroupRule => ({type: ADD_CONDITION_GROUP_RULE, payload: conditionGroupRule})

export const loadModels = models => ({ type: LOAD_MODELS, payload : models})
export const loadModelFields = modelFields => ({ type: LOAD_MODEL_FIELDS, payload : modelFields})

export const closeEditFormRevision = () => ({type: CLOSE_EDIT_FORM_REVISION, payload: false})
export const openEditFormRevision = () => ({type: OPEN_EDIT_FORM_REVISION, payload: true})

export const closeEditForm = () => ({type: CLOSE_EDIT_FORM, payload: false})
export const openEditForm = () => ({type: OPEN_EDIT_FORM, payload: true})

export const setFormTable = (formTable) => ({type:SET_FORM_TABLE, payload: formTable})
export const setFormRevisionTable = (formRevisionTable) => ({type: SET_FORM_REVISION_TABLE, payload: formRevisionTable})




