import React from "react";
import PropTypes from "prop-types";

import SummaryContainer from "../Summary/SummaryContainer";
import FormReviewContainer from "../FormReview/FormReviewContainer";
import SignatureContainer from "../Signature/SignatureContainer";

import { Redirect } from "react-router";

class ReviewContainer extends React.Component {
  state = {
    reviewPage: "summary"
  };

  switchState = reviewPage => {
    this.setState({
      reviewPage
    });
  };

  renderSwitch(param) {
    switch (param) {
      case "health":
        this.props.history.push("health");
      case "summary":
        return <SummaryContainer action={this.switchState} {...this.props} />;
      case "form":
        return <FormReviewContainer action={this.switchState} {...this.props} />;
      case "signature":
        return <SignatureContainer action={this.switchState} {...this.props} />;
      default:
        return <SummaryContainer action={this.switchState} {...this.props}/>;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSwitch(this.state.reviewPage)}
      </React.Fragment>
    );
  }
}

export default ReviewContainer;
