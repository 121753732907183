import {
    OPEN_EDIT_FORM_REVISION,
    CLOSE_EDIT_FORM_REVISION,
    OPEN_EDIT_FORM,
    CLOSE_EDIT_FORM,    
} from '../Constants/ActionTypes'

const intitialState = {
    editFormModalOpen: false,
    editFormRevisionModalOpen: false,
    isLoading: false,

}

const UIReducer = ( state = intitialState, action ) => {
    switch (action.type) {
        case OPEN_EDIT_FORM_REVISION:
            return{...state, editFormRevisionModalOpen: action.payload}
        case CLOSE_EDIT_FORM_REVISION:
            return {...state, editFormRevisionModalOpen: action.payload}
        case OPEN_EDIT_FORM:
            return{...state, editFormModalOpen: action.payload}
        case CLOSE_EDIT_FORM:
            return {...state, editFormModalOpen: action.payload}            
        default:
            return state        
    }
};


export default UIReducer