import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { styles } from "./Styles/AuthStyles";

import PasswordInput from "./Shared/Components/PasswordInput";
import CognitoSignUp from "./CognitoSignUp";
import CustomerSignUp from "./Customer/CustomerSignUp";

import { Auth } from "aws-amplify";
import {
  createCode,
  parseCodeObject
} from "./Shared/Functions/StaticFunctions";

import config from "../config";

class SignUp extends React.Component {
  state = {
    open: false,
    showConfirmation: false,
    errorMessage: "",
    emailError: false,
    passwordError: false,
    emailLabel: "Work Email",
    loginType: "Employer Login",
    subheader: ""
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
  }

  getCode = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let employerCode = params.get("employer");
    let customerCode = params.get("customer");
    let code = "";

    if (inviteCode) {
      code = "invite=" + inviteCode;
    } else if (employerCode) {
      code = "employer=" + employerCode;
    } else if (customerCode) {
      code = "customer=" + customerCode;
    } else {
      code = "null";
    }

    return code;
  };

  goGoogle = () => {
    window.location =
      "https://" +
      config.oauth.domain +
      "/oauth2/authorize?response_type=" +
      config.oauth.responseType +
      "&client_id=" +
      config.cognito.APP_CLIENT_ID +
      "&redirect_uri=" +
      config.oauth.redirectSignIn +
      "&state=app=" +
      this.props.app +
      "+code=" +
      createCode() +
      "&identity_provider=Google" +
      "&custom:app=" +
      this.props.app;
  };

  goFacebook = () => {
    window.location =
      "https://" +
      config.oauth.domain +
      "/oauth2/authorize?response_type=" +
      config.oauth.responseType +
      "&client_id=" +
      config.cognito.APP_CLIENT_ID +
      "&redirect_uri=" +
      config.oauth.redirectSignIn +
      "&state=app=" +
      this.props.app +
      "+code=" +
      createCode() +
      "&identity_provider=Facebook" +
      "&custom:app=" +
      this.props.app;
  };

  SignUp = (email, password) => {
    if (this.isValidForm(email, password)) {
      const username = email;
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
          "custom:app": this.props.app
        }
      })
        .then(() => this.setState({ showConfirmation: true }))
        .catch(err => {
          this.setState({emailError: false, passwordError: false})
          if (err.message) {
            if (err.code === "UsernameExistsException") {
              //try to resend confirmation, else show error that account confirmed
              Auth.resendSignUp(email).then(() => {
                this.props.enqueueSnackbar("Confirmation Code Resent", {
                  variant: "success"
                });
                this.setState({ showConfirmation: true });
                console.log('code resent successfully');
              })
              .catch(error => {
                this.props.enqueueSnackbar(err.message, {
                  variant: "error"
                });
              })
              //this.props.action("signIn");
            }
          } else {
            this.props.enqueueSnackbar(err, {
              variant: "error"
            });
          }
        });
    }
  };

  Back = () => {
    console.log('in here')
    //this.props.action("signUp");
    this.setState({ showConfirmation: false });
  };

  SignIn = (email, password) => {
    Auth.signIn(email, password)
      .then(user => {
        this.redirectNewUser(user);
        //this.props.history.push("user-router?" + createCode());
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  ConfirmSignUp = (email, password, code) => {
    Auth.confirmSignUp(email, code)
      .then(data => {
        this.SignIn(email, password);
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  isValidForm = (email, password) => {
    if (email && password) {
      return true;
    }
    else{
      this.setState({
        emailError: (email === ""),
        passwordError: (password === "")
      })
    }
  };

  redirectNewUser = user => {
    var code = parseCodeObject();
    var app = this.props.app;
    if (this.props.app === "broker") app = "broker-user";
    if (code.code) {
      this.props.history.push(
        "/" + app + "/setup-complete?" + code.type + "=" + code.code
      );
    } else {
      console.log(app);
      console.log(code.code);
      this.props.history.push("/" + app + "/setup-complete");
    }
  };

  render() {
    const { showConfirmation } = this.state;
    const { classes, app } = this.props;

    return (
      <React.Fragment>
        {(app == "user" ||
          app == "employer" ||

          app == "carrier" ||
          (app == "broker" && !this.props.newAgency)) && (
          <CognitoSignUp
            showConfirmation={this.state.showConfirmation}
            emailError={this.state.emailError}
            passwordError={this.state.passwordError}
            employer={this.props.employer}
            customer={this.props.customer}
            action={this.props.action}
            back={this.Back}
            goGoogle={this.goGoogle}
            SignUp={this.SignUp}
            ConfirmSignUp={this.ConfirmSignUp}
            app={this.props.app}
          />
        )}

        {app == "broker" && this.props.newAgency && (
          <CustomerSignUp
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            showConfirmation={this.state.showConfirmation}
            errorMessage={this.state.errorMessage}
            employer={this.props.employer}
            customer={this.props.customer}
            action={this.props.action}
            back={this.back}
            goGoogle={this.goGoogle}
            SignUp={this.SignUp}
            ConfirmSignUp={this.ConfirmSignUp}
          />
        )}
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignUp);