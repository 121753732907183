import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import blue from "@material-ui/core/colors/blue";

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
    hasChanged: false,
    newValue: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    if (this.state.hasChanged) {
      console.log("save: " + this.state.newValue);
      this.props.returnValue(this.state.newValue);
    }
    this.setState({ open: false });
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" size="small">
          {this.props.ButtonTitle}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
          >
            <Typography
              variant="body2"
              color="inherit"
            >
              {this.props.Title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              id="standard-dense"
              label={this.props.label}
              margin="dense"
              onChange={e =>
                this.setState({ newValue: e.target.value, hasChanged: true })
              }
              defaultValue={this.props.defaultValue}
              style={{ width: 300 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialog);
