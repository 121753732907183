import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/TableStyles";

import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import CustomTable from "../../Shared/Components/Table";
import SelectEmployerForm from "../FormReview/SelectEmployerForm";
import AccountDetailModal from "./AccountDetailModal";

import api from "../../Shared/Functions/APIHelpers";

import {
  AccountIcon,
  SummaryIcon,
  FormIcon
} from "../../Shared/Components/Icons";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setEmployeeTable } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    setEmployeeTable: employeeTable => dispatch(setEmployeeTable(employeeTable))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    employerTable: state.TableReducer.employerTable,
    employeeTable: state.TableReducer.employeeTable
  };
};

const initialState = {
    menuOpen: false,
    anchorEl: null,
    employerForms:[],
    selectEmployerFormsOpen: false,
    accountDetailModalOpen: false,
};

class EmployeesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  getCustomerEmployerForms = () => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerEmployerFormList(this.props.auth.customer_id, this.props.employerTable.selected.id)
        .then(response => {
          this.setState({employerForms: response})
          resolve(response)
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.reset();
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
      anchorEl: null,
    })
  };

  handleCloseMenu = () => {
    this.closeMenu();
  };

  openMenu = (e, employee) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.employeeTable;
    tempState.selected = employee;
    this.props.setEmployeeTable(tempState);
  };

  handleAccountDetails = () => {
    console.log('herer')
    this.setState({accountDetailModalOpen: true})
    this.closeMenu();
  };

  handleInformationSummary = () => {
    this.props.history.push("/broker/employee-summary");
    this.closeMenu();
  };

  handleFormReview = () => {
    console.log(this.props.employeeTable.selected)
    this.getCustomerEmployerForms().then(forms => {
      if(this.state.employerForms.length === 1){
        this.props.history.push({
          pathname: '/broker/form-review',
          state: { employer: this.props.employerTable.selected.id, 
                   employee: this.props.employeeTable.selected.user_id,
                   form:this.state.employerForms[0].id, 
                   type: 'brokerEmployee'
          }
        })
      }
      else{
        this.setState({selectEmployerFormsOpen: true})
      }
    });
    this.handleCloseMenu();
    this.closeMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AccountDetailModal
          isOpen={this.state.accountDetailModalOpen}
          Close={() => {this.setState({accountDetailModalOpen: false})} } 
          UserID={this.props.employeeTable.selected.user_id}
        />
        <SelectEmployerForm 
          EmployerForms={this.state.employerForms} 
          Employee={this.props.employeeTable.selected.user_id}
          Employer={this.props.employerTable.selected.id}
          Type={"brokerEmployee"}
          isOpen={this.state.selectEmployerFormsOpen}
          Close={() => {this.setState({selectEmployerFormsOpen: false})}}
          history={this.props.history} 
        />
        <CustomTable
          columns={[
            {
              label: "First Name",
              dataKey: "first_name",
              numeric: false,
              width: 300
            },
            {
              label: "Last Name",
              dataKey: "last_name",
              numeric: false,
              width: 300
            },
            {
              label: "Covered",
              dataKey: "cover_medical",
              numeric: false,
              width: 100
            }
          ]}
          name={'Employees'}
          rowHeight={70}
          setRefresh={func => (this.refreshTable = func)}
          getDataURL={
            "employers/" + this.props.employerTable.selected.id + "/employees"
          }
          rowCountEndpoint={
            "/api/v1/employers/" +
            this.props.employerTable.selected.id +
            "/employees/count"
          }
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleAccountDetails}>
            <AccountIcon className={classes.leftIcon} />
            Account Details
          </MenuItem>
          <MenuItem onClick={this.handleInformationSummary}>
            <SummaryIcon className={classes.leftIcon} />
            Information Summary
          </MenuItem>
          <MenuItem onClick={this.handleFormReview}>
            <FormIcon className={classes.leftIcon} />
            Form Review
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmployeesTable));
