export const styles = theme => ({
  '@page': { margin: 0 },
  containerRoot: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexFlow: "column"
  },
   containerHeader: {
    width: "100%",
    textAlign: "left",
    marginLeft: 20,
    '@media only screen and (max-width: 400px)': {
      margin: theme.spacing.unit * 1,       
    },    
    '@media print': {
      backgroundColor: 'red'
    }
  }, 
  containerTitle: {
    fontSize: 24,
    color: "grey",
    //marginLeft: 10,
  },
  containerContent: {
    flex: 2,
    overflow: "auto",
    height: "100%",
    width: "100%"
    //flex: 2,
  },
  containerPaper: {
    flex: 3,
    height: "95%",
    overflow: "auto",
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
    '@media only screen and (max-width: 400px)': {
      margin: theme.spacing.unit * 1,       
    },        
  },
  fab: {
    margin: theme.spacing.unit,
    position: "relative",
    left: -16,
    //top: 20,
    top: 40,
    zIndex: 500,
    "@media only screen and (max-width: 400px)": {
      left: -25
    }
  },
  waitCircle: {
    width: "100%",
    textAlign: "center",
    float: "left",
    marginTop: "30vh"
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  basecontent: {
    display: 'flex', 
    flexFlow: 'column', 
    backgroundColor: theme.palette.background.default, 
    width: '100vw', 
    minHeight: '100vh',
    alignItems: 'center',
    '@media only screen and (max-width: 400px)': {
      backgroundColor: 'white',
    },  
    zIndex: 300,  
  },
  basecontentFixed: {
    height: '100vh',
  },
  basecontentExpand: {

  },
  baseroot: {
    flex: 2,
    //overflow: 'auto', //*
    minWidth: '80vw',
    padding: theme.spacing.unit * 3, 
    margin: theme.spacing.unit * 3, 
    backgroundColor: theme.palette.background.paper,
    minHeight: '50vh',
    maxWidth: 1500,
    '@media only screen and (min-width: 1600px)': {
      minWidth: 0,
      width: 1500,
    },
    '@media only screen and (max-width: 400px)': {
      padding: theme.spacing.unit * 1, 
      margin: theme.spacing.unit * 1,       
      marginTop: theme.spacing.unit * 2,  
      //padding: 50,
      //margin: 50,
      //marginLeft: 100,
      //marginRight: 100,
      //margin:0,
      //minWidth: '100vw',
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
    },
  },
  baserootFixed: {
    overflow: 'auto',
  },
  baserootExpand: {

  }
});