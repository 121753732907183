import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { 
  EditIcon, 
  SendPlaneIcon,
} from "../../Shared/Components/Icons";

import SubmissionDetail from "./SubmissionDetail";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setFormGroupTable, openEditFormGroup } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});


const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
  };
};

class SubmissionsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null,
    submissionDetailOpen: false,
    selectedSubmissionRequest: "",
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, submission) => {  
    this.setState({ anchorEl: e.currentTarget, menuOpen: true, selectedSubmissionRequest: submission.id });
  };

  handleEditFormGroup = () => {
    this.props.openEditFormGroup();
    this.handleCloseMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  
  closeSubmissionDetail = () => {
    this.setState({submissionDetailOpen: false, selectedSubmissionRequest: ""});
  };

  openSubmissionDetail = () => {
    this.setState({submissionDetailOpen: true});
    this.handleCloseMenu();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <SubmissionDetail Open={this.state.submissionDetailOpen} Close={this.closeSubmissionDetail} Submission={this.state.selectedSubmissionRequest} />
        <CustomTable
          columns={[
            {
              label: "Employer",
              dataKey: "employer",
              numeric: false,
              width: 300
            },
            {
              label: "Carrier",
              dataKey: "form.carrier_name",
              nested: true,
              numeric: false,
              width: 150
            },
            {
              label: "Form",
              dataKey: "form.name",
              nested: true,
              numeric: false,
              width: 150
            },
            {
              label: "Recipient",
              dataKey: "recipient.email",
              nested: true,
              numeric: false,
              width: 150
            },
            {
              label: "Status",
              dataKey: "status",
              nested: false,
              numeric: false,
              width: 150
            },
          ]}
          name={'Submissions'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={
            "customers/"+ this.props.auth.customer_id +"/submission-requests"
          }
          filters={[
            {column:"status", modelColumn:"status",options:['Archived','Closed','Opened','Sent','Pending']},
          ]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.openSubmissionDetail}>
            <EditIcon className={classes.leftIcon} /> Details
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SubmissionsTable));
