import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import OutlineFlagIcon from "@material-ui/icons/OutlinedFlag";
import FlagIcon from "@material-ui/icons/Flag";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import DoneIcon from "@material-ui/icons/Done";
import DoneIconBorder from "@material-ui/icons/DoneOutline";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import TextField from "@material-ui/core/TextField";
import ReactSelect from "react-select";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import api from "../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";
import config from "../../config";

import {
	TextIcon,
	CheckboxIcon,
	LockIcon
} from "../../Shared/Components/Icons";

import { updateField } from "../Redux/Actions/index";

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto"
	},
	multiRow: {
		backgroundColor: "red"
	}
});

class MapFields extends React.Component {
	state = {
		rowsPerPage: 50,
		page: 0,
		filter: "",
		showInverseDialog: false
	};

	componentDidMount() {}

	getMappingRule = (mappingRuleID) => {
		return new Promise((resolve, reject) => {
		api
			.getMappingRule(mappingRuleID)
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				this.setState({ errorMessage: "Error Getting Mapping Rule" });
				console.log(err);
			});
		});
	}
	persistAddRule = (field, type) => {
		console.log("add rule");
		if (type === 1) {
			var body = JSON.stringify({
				field_id: field.id,
				type: type
			});
		} else if (type === 2 || type === 3 ) {
	      var body = JSON.stringify({
	        field_id: field.id,
	        type: type,
	        relationship_type: 4,
	      });      
	    } else if (type === 4) {
	    	var body = JSON.stringify({
	        field_id: field.id,
	        inverse_mapping_rule_id: field.inverse_mapping_rule_id,
	        type: type,
	        relationship_type: 4,
	      }); 
	    }
		return new Promise((resolve, reject) => {
			api
				.addMappingRule(this.props.revisionID, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Adding Rule" });
					console.log(err);
				});
		});
	};

	persistUpdateRule = (field, type) => {
		var body = JSON.stringify({
			id: field.mapping_rule_id,
			type: type,
			field_id: field.id,
			inverse_mapping_rule_id : (field.inverse_mapping_rule_id) ? field.inverse_mapping_rule_id : null
		});
		return new Promise((resolve, reject) => {
			api
				.updateMappingRule(field.mapping_rule_id, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Updating Rule" });
					console.log(err);
				});
		});
	};

	persistRemoveRule = mappingRuleID => {
		return new Promise((resolve, reject) => {
			api
				.deleteMappingRule(mappingRuleID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Deleting Mapping Rule"
					});
					console.log(err);
				});
		});
	};

	setFieldRule(e, field, type) {
		console.log(type)
		console.log(field)
		if (field.in_use) return;
		if (
			!field.has_mapping_rule &&
			!field.has_condition_mapping_rule &&
			!field.has_condition_group_mapping_rule &&
			!field.has_inverse_mapping_rule
		) {
			this.persistAddRule(field, type).then(rule => {
				field.mapping_rule_id = rule.id;
				if (type === 1) field.has_mapping_rule = true;
				if (type === 2) field.has_condition_mapping_rule = true;
				if (type === 3) field.has_condition_group_mapping_rule = true;
				if (type === 4) field.has_inverse_mapping_rule = true;
				this.props.updateField(field);
			});
		} else {
			if (type === 1 && field.has_mapping_rule) {
				this.persistRemoveRule(field.mapping_rule_id).then(r => {
					field.mapping_rule_id = null;
					field.has_mapping_rule = false;
					this.props.updateField(field);
				});
			} else if (type === 2 && field.has_condition_mapping_rule) {
				this.persistRemoveRule(field.mapping_rule_id).then(r => {
					field.mapping_rule_id = null;
					field.has_condition_mapping_rule = false;
					this.props.updateField(field);
				});
			} else if (type === 3 && field.has_condition_group_mapping_rule) {
				this.persistRemoveRule(field.mapping_rule_id).then(r => {
					field.mapping_rule_id = null;
					field.has_condition_group_mapping_rule = false;
					this.props.updateField(field);
				});
			} else {
				this.persistUpdateRule(field, type).then(rule => {
					if (type === 1) {
						field.has_mapping_rule = true;
						field.has_condition_mapping_rule = false;
						field.has_condition_group_mapping_rule = false;
						field.has_inverse_mapping_rule = false;
					}
					if (type === 2) {
						field.has_condition_mapping_rule = true;
						field.has_mapping_rule = false;
						field.has_condition_group_mapping_rule = false;
						field.has_inverse_mapping_rule = false;
					}
					if (type === 3) {
						field.has_condition_group_mapping_rule = true;
						field.has_mapping_rule = false;
						field.has_condition_mapping_rule = false;
						field.has_inverse_mapping_rule = false;
					}
					this.props.updateField(field);
				});
			}
		}
	}
	setInverseRule = (field) => {
		if (
			!field.has_mapping_rule &&
			!field.has_condition_mapping_rule &&
			!field.has_condition_group_mapping_rule &&
			!field.has_inverse_mapping_rule 
		) {
			this.persistAddRule(field, 4).then(rule => {
				field.mapping_rule_id = rule.id;
				field.has_inverse_mapping_rule = true;
				this.props.updateField(field);
			});
		}
		else{
			console.log('calling update')
			this.persistUpdateRule(field, 4).then(rule => {
				field.has_inverse_mapping_rule = true;
				field.has_mapping_rule = false;
				field.has_condition_mapping_rule = false;
				field.has_condition_group_mapping_rule = false;
				this.props.updateField(field);
			});
		}

	}
	close = () => {
		this.setState({showInverseDialog: false, selectedInverseField: {}, selectedInverseRule: {}})
	}
	handleClose = () => {
		this.close()
	}
	handleSave = () => {
		var selectedInverseField = this.state.selectedInverseField
		selectedInverseField.inverse_mapping_rule_id = this.state.selectedInverseRule.id
		this.setInverseRule(selectedInverseField)
		this.close()
	}
	setSelectedInverseRule = (val, type) => {
		console.log('setSelectedInverseRule')
		console.log(val)
		this.setState({selectedInverseRule: val})
	}
	handleInverseSelected = (event, field) => {
		console.log('handleInverseSelected')
		console.log(field)

		if (field.mapping_rule_id){
			this.getMappingRule(field.mapping_rule_id).then(mr => {
				var inverseField = this.props.fields.find(f => f.mapping_rule_id === mr.inverse_mapping_rule.id)
				this.setState({showInverseDialog: true, selectedInverseField: field, selectedInverseRule: {id: mr.inverse_mapping_rule.id, name: inverseField.name} })
			})
		} else {
			this.setState({showInverseDialog: true, selectedInverseField: field })
		}
		
	}
	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};
	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	render() {
		const { classes } = this.props;
		const { page, rowsPerPage } = this.state;
		return (
			<div>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: 25 }}>Type</TableCell>
							<TableCell style={{ width: 25 }}>Page</TableCell>
							<TableCell style={{ width: 25 }}>In Use</TableCell>
							<TableCell style={{ width: 25 }}>Form Field</TableCell>
							<TableCell style={{ width: 25 }}>Rule</TableCell>
							<TableCell style={{ width: 25 }}>Medical</TableCell>
							<TableCell style={{ width: 25 }}>Medical Group</TableCell>
							<TableCell style={{ width: 25 }}>Inverse</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{this.props.fields
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((field, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											{(field.field_type === "Text" && <TextIcon />) || (
												<CheckboxIcon />
											)}
										</TableCell>
										<TableCell>{field.page_number}</TableCell>
										<TableCell>{field.in_use && <LockIcon />}</TableCell>
										<TableCell>{field.name}</TableCell>
										<TableCell>
											<FormControlLabel
												control={
													<Checkbox
														icon={<DoneIconBorder />}
														checkedIcon={<DoneIcon />}
														checked={field.has_mapping_rule}
														onChange={e => this.setFieldRule(e, field, 1)}
													/>
												}
												label=""
											/>
										</TableCell>
										<TableCell>
											<FormControlLabel
												control={
													<Checkbox
														icon={<FavoriteBorder />}
														checkedIcon={<Favorite />}
														checked={field.has_condition_mapping_rule}
														onChange={e => this.setFieldRule(e, field, 2)}
													/>
												}
												label=""
											/>
										</TableCell>
										<TableCell>
											<FormControlLabel
												control={
													<Checkbox
														icon={<OutlineFlagIcon />}
														checkedIcon={<FlagIcon />}
														checked={field.has_condition_group_mapping_rule}
														onChange={e => this.setFieldRule(e, field, 3)}
													/>
												}
												label=""
											/>
										</TableCell>
										<TableCell style={{ width: 25 }}>
											<FormControlLabel
												control={
													<Checkbox
														icon={<SwapHoriz />}
														checkedIcon={<SwapHorizontalCircle />}
														checked={field.has_inverse_mapping_rule}
														onChange={e => {this.handleInverseSelected(e,field)}}
													/>
												}
												label=""
											/>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[50, 100, 200]}
					component="div"
					count={this.props.fields.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						"aria-label": "Previous Page"
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page"
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>

				<Dialog
		          open={!!this.state.showInverseDialog}
		          aria-labelledby="form-dialog-title"
		          disableBackdropClick={true}
		          fullScreen={false}
		          scroll="paper"
		        >
	          <DialogTitle id="form-dialog-title">
	          	Inverse Field
	          </DialogTitle>

	          <DialogContent style={{ height: 300, width: 500 }}>
	            <ReactSelect
	              styles={customStyles}
	              fullWidth
	              onChange={(val, type) => this.setSelectedInverseRule(val, type)}
	              value={this.state.selectedInverseRule}
	              options={this.props.fields.map(f => {
	              	return {name: f.name, id: f.mapping_rule_id}
	              })}
	              getOptionValue={option => option["id"]}
	              getOptionLabel={option => option["name"]}
	            />
	          </DialogContent>
	          <DialogActions>
	            <Button onClick={this.handleClose} color="primary">
	              Cancel
	            </Button>
	            <Button onClick={this.handleSave} color="primary">
	              Save
	            </Button>
	          </DialogActions>
	        </Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(MapFields);
