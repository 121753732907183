import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { Auth } from "aws-amplify";
import config from "../config";

import { connect } from "react-redux";

import BaseContainer from "../Shared/Layouts/BaseContainer";
import NavList from "./Nav/NavList";
import SettingsMenu from "./Nav/SettingsMenu";

import FormsContainer from "./Forms/FormsContainer";
import FormRevisionsContainer from "./FormRevisions/FormRevisionsContainer";
import ICDContainer from "./ICD/ICDContainer";
import MappingContainer from "./Mapping/MappingContainer";
import SecurityContainer from "../Shared/UserSettings/SecurityContainer";

//Broker Containers
import EmployersContainer from "../Broker/Employers/EmployersContainer";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/Store/index";

import { loadUser, loadStates, loadForms } from "../Shared/Redux/Actions/index";
import {
  getUser,
  getStates,
  getForms
} from "../Shared/Functions/StaticFunctions";

const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
    loadStates: states => dispatch(loadStates(states)),
    loadForms: forms => dispatch(loadForms(forms))
  };
};

const mapStateToProps = state => {
  return {
    isUserLoaded: state.AuthReducer.isLoaded,
    isStatesLoaded: state.StateReducer.isLoaded,
    isFormsLoaded: state.FormReducer.isLoaded
  };
};

class PrivateRoute extends React.Component {
  componentDidMount() {
    if (!this.props.isUserLoaded) {
      getUser(Auth).then(user => {
        this.props.loadUser(user);
      });
    }

    if (!this.props.isStatesLoaded) {
      getStates(Auth).then(states => {
        this.props.loadStates(states);
      });
    }
    if (!this.props.isFormsLoaded) {
      getForms(Auth).then(forms => {
        this.props.loadForms(forms);
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          return (
            <BaseContainer
              {...props}
              user={this.props.user}
              reformUser={{}}
              messageSummaries={[]}
              fixed={this.props.fixed}
              component={Component}
              navList={NavList}
              home={"/admin"}
              settingsMenu={SettingsMenu}
              isLoaded={this.props.isUserLoaded}
            />
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute)
PrivateRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivateRoute)
);

const Routes = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/admin"
            user={props.user}
            fixed={true}
            component={FormsContainer}
          />
          <PrivateRoute
            exact
            path="/admin/home"
            fixed={true}
            user={props.user}
            component={FormsContainer}
          />
          <PrivateRoute
            exact
            path="/admin/user-settings"
            user={props.user}
            component={SecurityContainer}
          />
          <PrivateRoute
            path="/admin/forms"
            fixed={true}
            user={props.user}
            component={FormsContainer}
          />
          <PrivateRoute
            path="/admin/form-revisions"
            fixed={true}
            user={props.user}
            component={FormRevisionsContainer}
          />
          <PrivateRoute
            path="/admin/icd"
            user={props.user}
            component={ICDContainer}
          />
          <PrivateRoute
            path="/admin/mapping"
            fixed={true}
            user={props.user}
            component={MappingContainer}
          />
          <PrivateRoute
            path="/admin/broker/employers"
            fixed={true}
            user={props.user}
            component={EmployersContainer}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
//export default connect(null, mapDispatchToProps)(Routes);
