import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";

import Divider from "@material-ui/core/Divider";

import { styles } from "./Styles/HomeStyles";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import config from "../../config";
import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import {
  formatDateForRenewal
} from "../../Shared/Functions/Format";

import {
  isLoading,
  setActiveProfile,
  updateProfile,
  clearActiveProfile
} from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    setActiveProfile: profileID => dispatch(setActiveProfile(profileID)),
    updateProfile: profile => dispatch(updateProfile(profile)),
    clearActiveProfile: () => dispatch(clearActiveProfile())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    profiles: state.ProfilesReducer.profiles,
    profilesLoaded: state.ProfilesReducer.profilesLoaded,
    activeProfile: state.ProfilesReducer.activeProfile
  };
};

class Home extends React.Component {
  state = {
    requireCoverage: "",
    firstName: "",
    lastName: "",
    SSN: "",
    dateOfBirth: "",
    waiveReason: "",
    applicantProfile: "",
    expanded: "",
  };

  componentDidMount() {
    var profile = this.props.profiles.find(c => c.relationship_type === 1);

    this.setState({
      applicantProfile: profile.id,
      firstName: profile.first_name,
      lastName: profile.last_name,
      ssn: profile.ssn,
      dateOfBirth: profile.date_of_birth
    });
    if (profile.cover_medical === true) {
      this.setState({ requireCoverage: "Yes" });
    } else if (profile.cover_medical === false) {
      this.setState({ requireCoverage: "No" });
    }
  }

  updateDependent = () => {
    if (this.state.requireCoverage === "Yes") {
      var cover = true;
    } else {
      var cover = false;
    }

    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        ssn: this.state.ssn,
        date_of_birth: this.state.dateOfBirth,
        cover_medical: cover
      });
      api
        .updateProfile(this.props.auth.profile_id, body)
        .then(response => {
          this.props.updateProfile({
            id: response.id,
            ssn: response.ssn,
            first_name: response.first_name,
            last_name: response.last_name,
            cover_medical: response.cover_medical,
            relationship_type: response.relationship_type,
            sex: response.sex,
            height_feet: response.height_feet,
            height_inches: response.height_inches,
            date_of_birth: response.date_of_birth,
            phone: response.phone,
            weight: response.weight,
            user_address: response.user_address
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating User" });
          console.log(err);
        });
    });
  };

  enableMFA = () => {
    this.props.history.push("/user/security");
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  goNext = () => {

    this.updateDependent().then(r => {
      if (this.state.requireCoverage === "Yes") {
        this.props.history.push("/user/family");
      } else {
        this.props.history.push("/user/review");
      }
    });
  };

  handlePanelChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };


  render() {
    const { classes, ...rest } = this.props;

    return (
      <React.Fragment>
        {!this.props.auth.federatedUser &&
          this.props.auth.preferredMFA === "NOMFA" && (
            <React.Fragment>
              <ExpansionPanel
                expanded={this.state.expanded === "panel1"}
                onChange={this.handlePanelChange("panel1")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    Enable stronger security with 2-Step Verification!
                  </Typography>
                  <Typography className={classes.secondaryHeading} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <Typography variant="body1" gutterBottom>
                        With 2-Step Verification, you'll protect your account with both a password and your phone.
                      </Typography>
                      <Typography variant='body2' gutterBottom>
                        How it works
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        When logging into your account, in addition to your password,
                        you will be prompted to enter a single use pin. This pin will be texted to your phone or you can use a mobile
                        app to generate one. This means that even if someone were able to guess your password, they could still not
                        log into your account!
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.enableMFA}
                        variant="contained"
                        color="primary"
                      >
                        Go
                      </Button>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Divider className={classes.homedivider} />
            </React.Fragment>
          )}

        {/*<div style={{ height: 20 }} />*/}
        <Typography className={classes.welcomeheader}>
          Welcome to Reform Health!{" "}
        </Typography>
        <Typography variant="body1">
          Your employer is using the Reform Health app to collect your
          healthcare status and coverage preferences in order to obtain the best
          rates for you and your family. If anyone requires coverage, you will
          need information like each individual's SSN, health status/history to
          complete this process.
        </Typography>
        <Divider className={classes.homedivider} />
        <Typography variant="h6" gutterBottom>
          Will you or anyone in your family require medical insurance in{" "}
          {new Date().getFullYear()}?
        </Typography>
        <RadioGroup
          aria-label={
            "Will you or anyone in your family require medical insurance in " +
            new Date().getFullYear() +
            "?"
          }
          name="requireCoverage"
          value={this.state.requireCoverage}
          onChange={this.handleChange}
        >
          <FormControlLabel
            value={"Yes"}
            control={<Radio color="primary" />}
            label="Yes, I will require coverage"
          />
          <FormControlLabel
            value={"No"}
            control={<Radio color="primary" />}
            label="No, I'd like to waive all coverage"
          />
        </RadioGroup>

        {this.state.requireCoverage === "No" && (
          <React.Fragment>
            <TextField
              id="standard-dense"
              label="First Name"
              margin="dense"
              onChange={e => this.setState({ firstName: e.target.value })}
              defaultValue={this.state.firstName}
              className={classes.homefield}
            />
            <TextField
              id="standard-dense"
              label="Last Name"
              margin="dense"
              onChange={e => this.setState({ lastName: e.target.value })}
              defaultValue={this.state.lastName}
              className={classes.homefield}
            />
            <TextField
              id="standard-dense"
              label="SSN"
              margin="dense"
              onChange={e => this.setState({ ssn: e.target.value })}
              defaultValue={this.state.ssn}
              className={classes.homefield}
            />
            <TextField
              id="date"
              label="Date of Birth"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={this.state.dateOfBirth}
              onChange={e => this.setState({ dateOfBirth: e.target.value })}
              className={classes.homefield}
              margin="dense"
            />
            <br />

            <FormControl margin="dense" className={classes.homewavefield}>
              <InputLabel htmlFor="waiveReason">
                Reason for waiving coverage
              </InputLabel>
              <Select
                value={this.state.waiveReason}
                onChange={this.handleChange}
                inputProps={{
                  name: "waiveReason",
                  id: "waiveReason"
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>
                  Coverage through my spouse's employer
                </MenuItem>
                <MenuItem value={1}>Covered by individual plan</MenuItem>
                <MenuItem value={2}>
                  Covered by COBRA from prior employer
                </MenuItem>
                <MenuItem value={3}>Covered by Medicare</MenuItem>
                <MenuItem value={4}>Covered by Medicaid</MenuItem>
                <MenuItem value={5}>VA Eligibility</MenuItem>
                <MenuItem value={6}>No other coverage at this time</MenuItem>
              </Select>
            </FormControl>
            <br />
            <Button
              variant="contained"
              color="primary"
              className={classes.homebutton}
              onClick={this.goNext}
            >
              Review my forms
            </Button>
          </React.Fragment>
        )}

        {this.state.requireCoverage === "Yes" && (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              className={classes.homebutton}
              onClick={this.goNext}
              id="getStartedButton"
            >
              Get Started
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));

export { Home as PureHome};

