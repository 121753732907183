import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import blue from "@material-ui/core/colors/blue";

import { Auth } from "aws-amplify";

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
    hasChanged: false,
    newValue: "",
    oldPassword: "",
    newPassword: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        );
      })
      .then(data => {
        this.props.enqueueSnackbar("Password Changed Successfully", {
          variant: "success"
        });
        this.setState({ open: false });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error"
        });
      });
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" size="small">
          Change Password
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
          >
            <Typography
              variant="body2"
              color="inherit"
            >
              Change Your Password
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="title" gutterBottom>
              Password requirements
            </Typography>
            <div>
              <ul>
                <li>At least 8 characters</li>
                <li>At least 1 number</li>
                <li>At least 1 special character</li>
              </ul>
            </div>
            <form>
              <TextField
                id="standard-dense-old-password"
                label="Old Password"
                margin="dense"
                type="Password"
                onChange={e =>
                  this.setState({
                    oldPassword: e.target.value,
                    hasChanged: true
                  })
                }
                fullWidth
              />
              <br />
              <TextField
                id="standard-dense-new-password"
                label="New Password"
                margin="dense"
                type="Password"
                onChange={e =>
                  this.setState({
                    newPassword: e.target.value,
                    hasChanged: true
                  })
                }
                fullWidth
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialog);