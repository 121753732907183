import React, { Component } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Select, { components } from "react-select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OutlineFlagIcon from "@material-ui/icons/OutlinedFlag";
import FlagIcon from "@material-ui/icons/Flag";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import api from "../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";
import config from "../../config";

const styles = theme => ({
	title: {
		fontSize: 28,
		color: "grey"
	}
});

const customStyles = {
	control: (provided, state) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		width: 800
	})
};

class MapGroup extends React.Component {
	state = {
		mappingRules: [],
		conditionGroupRules: [],
		deleteMappingRule: {},
		confirmAlertOpen: false
	};

	componentDidMount() {
		this.getRules().then(rules => {
			var temp = rules.map((rule, index) => {
				return {
					...rule,
					sort_order: index,
					isChanged: false,
					selected: false
				};
			});
			this.setState({ mappingRules: temp });
		});

		this.getConditionGroupMappingRules().then(cgr => {
			this.setState({ conditionGroupRules: cgr });
		});
	}

	getRules = () => {
		return new Promise((resolve, reject) => {
			api
				.getMappingRules(this.props.revisionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Mapping Rules" });
					console.log(err);
				});
		});
	};

	persistRemoveRule = mappingRuleID => {
		return new Promise((resolve, reject) => {
			api
				.deleteMappingRule(mappingRuleID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Deleting Mapping Rule" });
					console.log(err);
				});
		});
	};

	getConditionGroupMappingRules = () => {
		return new Promise((resolve, reject) => {
			api
				.getConditionGroupMappingRules(this.props.revisionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Getting Condition Group Mapping Rules"
					});
					console.log(err);
				});
		});
	};

	persistAddConditionGroupMappingRule = (rule, mappingRuleID) => {
		var body = JSON.stringify({
			field_id: rule.field.id,
			parent_mapping_rule_id: rule.id,
			mapping_rule_id: mappingRuleID
		});
		console.log(body);
		return new Promise((resolve, reject) => {
			api
				.addConditionGroupMappingRule(this.props.revisionID, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Adding Condition Group Rule" });
					console.log(err);
				});
		});
	};

	persistRemoveConditionGroupMappingRule = groupID => {
		return new Promise((resolve, reject) => {
			api
				.deleteConditionGroupMappingRule(groupID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Deleting Condition Group Rule"
					});
					console.log(err);
				});
		});
	};

	handleGroupChange = rule => {
		var field = rule.field;

		this.persistRemoveRule(rule.id).then(r => {
			field.has_condition_group_mapping_rule = false;
			field.in_use = false;
			this.props.updateField(field);

			//remove from state
			var temp = this.state.mappingRules;
			temp = temp.filter(mr => mr.id != rule.id);
			this.setState({ mappingRules: temp, deleteMappingRule: {} });

			//remove condition group rules
			var temp = this.state.conditionGroupRules.map(cgr => {
				if (cgr.mapping_rule != rule.id) return cgr;
			});
			this.setState({ conditionGroupRules: temp });
		});
	};

	handleChange = (val, type, rule) => {
		if (type.action === "select-option") {
			this.persistAddConditionGroupMappingRule(rule, type.option.id).then(
				group => {
					console.log(group);
					var temp = this.state.conditionGroupRules;
					temp.push(group);
					this.setState({ conditionGroupRules: temp });
				}
			);
		}

		if (type.action === "remove-value") {
			console.log(type.removedValue)
			this.persistRemoveConditionGroupMappingRule(type.removedValue.id).then(
				r => {
					var temp = this.state.conditionGroupRules.filter(
						cgr => cgr.id != type.removedValue.id
					);
					this.setState({ conditionGroupRules: temp });
				}
			);
		}
	};

	handleConfirmDelete = rule => {
		this.setState({ confirmAlertOpen: true, deleteMappingRule: rule });
	};

	DoNotDelete = () => {
		this.setState({ confirmAlertOpen: false });
	};

	DoDelete = () => {
		this.setState({ confirmAlertOpen: false });
		this.handleGroupChange(this.state.deleteMappingRule);
	};

	render() {
		const { classes, theme } = this.props;
		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			})
		};
		return (
			<div>
				<ConfirmAlert
					isOpen={this.state.confirmAlertOpen}
					isNo={this.DoNotDelete}
					isYes={this.DoDelete}
					AlertTitle={"Confirm Delete"}
					AlertText={"Are you sure you wish to delete this mapping rule"}
				/>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Form Field</TableCell>
							<TableCell style={{ width: 50 }}>Group</TableCell>
							<TableCell style={{ width: 800 }}>Mapping Rules</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{this.state.mappingRules.map((rule, index) => {
							var values = [];
							values = this.state.conditionGroupRules
								.filter(
									cgr => cgr.parent_mapping_rule.field.id === rule.field.id
								)
								.map(cgr => {
									return {
										mr: { ...cgr.mapping_rule },
										id: cgr.id,
										name: cgr.mapping_rule.field.name
									};
								});

							var options = this.state.mappingRules
								.filter(f => f.type === 2)
								.map(mr => {
									return { mr: { ...mr }, id: mr.id, name: mr.field.name };
								});
							if (rule.type === 3) {
								return (
									<TableRow key={index}>
										<TableCell>{rule.field.name}</TableCell>
										<TableCell>
											<FormControlLabel
												control={
													<Checkbox
														icon={<OutlineFlagIcon />}
														checkedIcon={<FlagIcon />}
														onChange={() => this.handleConfirmDelete(rule)}
														checked={rule.type === 3}
													/>
												}
												label=""
											/>
										</TableCell>
										<TableCell>
											<Select
												styles={customStyles}
												onChange={(val, type) =>
													this.handleChange(val, type, rule)
												}
												value={values}
												options={options}
												getOptionValue={option => option["id"]}
												getOptionLabel={option => option["name"]}
												isMulti
											/>
										</TableCell>
									</TableRow>
								);
							}
						})}
					</TableBody>
				</Table>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(MapGroup);
