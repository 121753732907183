import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/TableStyles";

import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import CustomTable from "../../Shared/Components/Table";

import {
  AccountIcon,
  SummaryIcon,
  FormIcon
} from "../../Shared/Components/Icons";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setEmployeeTable } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    setEmployeeTable: employeeTable => dispatch(setEmployeeTable(employeeTable))
  };
};

const mapStateToProps = state => {
  return {
    employeeTable: state.TableReducer.employeeTable,
    auth: state.AuthReducer
  };
};

class EmployeesTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  closeMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleCloseMenu = () => {
    this.closeMenu();
  };

  openMenu = (e, employee) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.employeeTable;
    tempState.selected = employee;
    this.props.setEmployeeTable(tempState);
  };

  handleAccountDetails = () => {
    console.log("Account Details");
    this.closeMenu();
  };

  handleInformationSummary = () => {
    this.props.history.push("/employer/employee-summary");
    this.closeMenu();
  };

  handleFormReview = () => {
    console.log("Form Review");
    this.closeMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CustomTable
          columns={[
            {
              label: "First Name",
              dataKey: "first_name",
              numeric: false,
              width: 300
            },
            {
              label: "Last Name",
              dataKey: "last_name",
              numeric: false,
              width: 300
            },
            {
              label: "Covered",
              dataKey: "cover_medical",
              numeric: false,
              boolean: true,
              width: 100
            },
            {
              label: "Login",
              dataKey: "last_login",
              isDatetime: true,
              width: 300
            },
            {
              label: "Signature",
              dataKey: "last_signature",
              isDatetime: true,
              width: 300
            },
          ]}
          name={'Employees'}
          rowHeight={70}
          setRefresh={func => (this.refreshTable = func)}
          getDataURL={"employers/" + this.props.auth.employer_id + "/employees"}
          rowCountEndpoint={
            "/api/v1/admin/employers/" +
            this.props.auth.employer_id +
            "/employees/count"
          }
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleInformationSummary}>
            <SummaryIcon className={classes.leftIcon} />
            Information Summary
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmployeesTable));
