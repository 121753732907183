import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { Auth } from "aws-amplify";
import config from "../config";

import BaseContainer from "../Shared/Layouts/BaseContainer";
import NavList from "./Nav/NavList";
import SettingsMenu from "./Nav/SettingsMenu";

import UsersContainer from "./Users/UsersContainer";
import EmployersContainer from "./Employers/EmployersContainer";
import EmployeesContainer from "./Employees/EmployeesContainer";
import RecipientsContainer from "./Recipients/RecipientsContainer";
import SecurityContainer from "../Shared/UserSettings/SecurityContainer";
import FormGroupsContainer from "./FormGroups/FormGroupsContainer";
import SubmissionsContainer from "./Submissions/SubmissionsContainer";
import InvitationsContainer from "./Invitations/InvitationsContainer";
import SummaryContainer from "./EmployeeSummary/SummaryContainer";
import ReviewContainer from "./FormReview/FormReviewContainer";
import WelcomePage from "./Welcome/WelcomePage";

import { connect } from "react-redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/Store/index";
import api from "../Shared/Functions/APIHelpers";

import { loadMessageSummaries } from "./Redux/Actions/index";
import {
  loadUser,
  loadStates,
  loadForms,
  loadCarriers
} from "../Shared/Redux/Actions/index";
import {
  getBroker,
  getStates,
  getForms,
  getCarriers
} from "../Shared/Functions/StaticFunctions";

const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
    loadStates: states => dispatch(loadStates(states)),
    loadForms: forms => dispatch(loadForms(forms)),
    loadCarriers: carriers => dispatch(loadCarriers(carriers)),
    loadMessageSummaries: messageSummaries =>
      dispatch(loadMessageSummaries(messageSummaries))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    isUserLoaded: state.AuthReducer.isLoaded,
    isStatesLoaded: state.StateReducer.isLoaded,
    isFormsLoaded: state.FormReducer.isLoaded,
    isCarriersLoaded: state.CarrierReducer.isLoaded,
    isMessageSummariesLoaded: state.MessageReducer.isMessageSummariesLoaded,
    messageSummaries: state.MessageReducer.messageSummaries
  };
};

class PrivateRoute extends React.Component {
  componentDidMount() {
    console.log(this.props.isUserLoaded)
    if (!this.props.isUserLoaded) {
      getBroker(Auth).then(user => {
        this.props.loadUser(user);
        this.loadEverythingElse(user.user_id);
      })
      .catch(err => {
        //if 401 comes back from get user, they have not agreed to terms
        this.props.history.push("/setup");
      });
      
    }

    if (!this.props.isStatesLoaded) {
      getStates(Auth).then(states => {
        this.props.loadStates(states);
      });
    }

    if (!this.props.isFormsLoaded) {
      getForms(Auth).then(forms => {
        this.props.loadForms(forms);
      });
    }

    if (!this.props.isCarriersLoaded) {
      getCarriers(Auth).then(carriers => {
        this.props.loadCarriers(carriers);
      });
    }
  }

  loadEverythingElse = userID => {
    if (!this.props.isMessageSummariesLoaded) {
      api
        .getMessageSummaries(userID)
        .then(response => {
          this.props.loadMessageSummaries(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Message Summaries" });
          console.log(err);
        });
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <BaseContainer
              {...props}
              user={this.props.user}
              reformUser={this.props.auth}
              hasMessageBar={false}
              hasMessageMenu={false}
              messageSummaries={this.props.messageSummaries}
              fixed={this.props.fixed}
              component={Component}
              navList={NavList}
              home={"/broker"}
              settingsMenu={SettingsMenu}
              isLoaded={this.props.isUserLoaded}
            />
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute)
PrivateRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivateRoute)
);

const Routes = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/broker"
            fixed={true}
            user={props.user}
            component={WelcomePage}
          />
          <PrivateRoute
            exact
            path="/broker/home"
            fixed={true}
            user={props.user}
            component={WelcomePage}
          />
          <PrivateRoute
            exact
            path="/broker/users"
            fixed={true}
            user={props.user}
            component={UsersContainer}
          />
          <PrivateRoute
            exact
            path="/broker/user-settings"
            user={props.user}
            component={SecurityContainer}
          />
          <PrivateRoute
            path="/broker/employers"
            fixed={true}
            user={props.user}
            component={EmployersContainer}
          />
          <PrivateRoute
            path="/broker/employees"
            fixed={true}
            user={props.user}
            component={EmployeesContainer}
          />
          <PrivateRoute
            path="/broker/employee-summary"
            fixed={true}
            user={props.user}
            component={SummaryContainer}
          />
          <PrivateRoute
            path="/broker/form-review"
            user={props.user}
            fixed={true}
            component={ReviewContainer}
          />
          <PrivateRoute
            path="/broker/recipients"
            fixed={true}
            user={props.user}
            component={RecipientsContainer}
          />
          <PrivateRoute
            path="/broker/submissions"
            fixed={true}
            user={props.user}
            component={SubmissionsContainer}
          />
          <PrivateRoute
            path="/broker/invitations"
            fixed={true}
            user={props.user}
            component={InvitationsContainer}
          />
          <PrivateRoute
            path="/broker/form-groups"
            fixed={true}
            user={props.user}
            component={FormGroupsContainer}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
//export default connect(mapStateToProps, mapDispatchToProps)(Routes);
