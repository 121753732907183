import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/TableStyles";

import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import CustomTable from "../../Shared/Components/Table";

import api from "../../Shared/Functions/APIHelpers";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";


import {
  AccountIcon,
  SummaryIcon,
  FormIcon
} from "../../Shared/Components/Icons";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setEmployeeTable } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    setEmployeeTable: employeeTable => dispatch(setEmployeeTable(employeeTable))
  };
};

const mapStateToProps = state => {
  return {
    employeeTable: state.TableReducer.employeeTable,
    auth: state.AuthReducer
  };
};

class EmployeesTable extends React.Component {
  componentDidUpdate() {
    this.props.setRefresh(this.handleRefreshTable);
  };

  state = {
    menuOpen: false,
    anchorEl: null,
    confirmAlertOpen: false
  };

  deleteEmployerSetup = (customerID, employerID) => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployerSetup(customerID, employerID)
        .then(r => {
          this.props.enqueueSnackbar("Broker deleted successfully", {
            variant: "success"
          });
          resolve('success')
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Deleting Employer Broker Setup"
          });
          console.log(err);
        });
    });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleCloseMenu = () => {
    this.closeMenu();
  };

  openMenu = (e, employee) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.employeeTable;
    tempState.selected = employee;
    this.props.setEmployeeTable(tempState);
  };

  handleAccountDetails = () => {
    console.log("Account Details");
    this.closeMenu();
  };

  handleRemoveBroker = () => {
    this.deleteEmployerSetup(this.props.employeeTable.selected.id, this.props.auth.employer_id).then(() => {
      this.handleRefreshTable()
    })
  };

  handleConfirmDelete = () => {
    this.handleCloseMenu()
    this.setState({ confirmAlertOpen: true});
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleRemoveBroker();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.DoNotDelete}
          isYes={this.DoDelete}
          AlertTitle={"Confirm Delete"}
          AlertText={"Are you sure you want to remove this broker's access to your employer?"}
        />
        <CustomTable
          columns={[
            {
              label: "Agency Name",
              dataKey: "name",
              numeric: false,
              width: 300
            },
            {
              label: "Phone",
              dataKey: "primary_phone",
              numeric: false,
              width: 300
            },
          ]}  
          name={'Brokers'}
          rowHeight={70}
          setRefresh={func => (this.refreshTable = func)}
          getDataURL={"employers/" + this.props.auth.employer_id + "/customers"}
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleAccountDetails}>
            <AccountIcon className={classes.leftIcon} />
            Broker Details
          </MenuItem>
          <MenuItem onClick={this.handleConfirmDelete}>
            <SummaryIcon className={classes.leftIcon} />
            Remove Broker
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmployeesTable));
