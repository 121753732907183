import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Nav/Styles/NavStyles";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import {
  HomeIcon,
  MessageIcon,
  EmployerIcon,
  InvitationIcon,
  SendIcon
} from "../../Shared/Components/Icons";

class NestedList extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  goHome = () => {
    this.props.history.push("/broker/home");
  };

  goEmployers = () => {
    this.props.history.push("/broker/employers");
  };

  goRecipients = () => {
    this.props.history.push("/broker/recipients");
  };

  goSubmissions = () => {
    this.props.history.push('/broker/submissions');
  };

  goInvitations = () => {
    this.props.history.push('/broker/invitations');
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">Reform Health</ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText inset primary="Home" />
          </ListItem>

          <ListItem button onClick={this.goEmployers}>
            <ListItemIcon>
              <EmployerIcon />
            </ListItemIcon>
            <ListItemText inset primary="Employers" />
          </ListItem>

          <ListItem button onClick={this.goInvitations}>
            <ListItemIcon>
              <InvitationIcon />
            </ListItemIcon>
            <ListItemText inset primary="Invitations" />
          </ListItem>

          <ListItem button onClick={this.goSubmissions}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText inset primary="Submissions" />
          </ListItem>
        </List>
      </div>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NestedList);
