import {
    LOAD_USER,
    LOAD_CARRIERS,
    LOAD_CONDITION_CATEGORIES,
    LOAD_STATES,
    LOAD_FORMS,
    UPDATE_CUSTOMER_CODE
} from "../Constants/ActionTypes";

export const loadUser = user => ({ type: LOAD_USER, payload: user });
export const loadCarriers = carriers => ({
    type: LOAD_CARRIERS,
    payload: carriers
});
export const loadConditionCategories = conditionCategories => ({
    type: LOAD_CONDITION_CATEGORIES,
    payload: conditionCategories
});
export const loadStates = states => ({ type: LOAD_STATES, payload: states });
export const loadForms = forms => ({ type: LOAD_FORMS, payload: forms });

export const updateCustomerCode = code => ({type: UPDATE_CUSTOMER_CODE, payload: code});