import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import {
  formatDateTime,
  formatDate
} from "../../Shared/Functions/Format";


import api from "../../Shared/Functions/APIHelpers";

import { Auth } from "aws-amplify";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
  };
};

function TabContainer(props) {
  return <div style={{ padding: 8 * 3 }}>{props.children}</div>;
}

const messages = [
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
  {
    time: "2/28/2019 11:05 AM  ",
    message:
      "A broker from Acme Agency requested health record information for Charlie Smith"
  },
];

const styles = theme => ({});

const initialState = {
  value: 0,
  messages: [],
  infoMessages: [],
};

class HipaaLog extends React.Component {

  constructor(props) {
      super(props)
      this.state = initialState;
      this.getMappingLog()
      this.getHipaaLog()
  }
  
  reset() {
    this.setState(initialState);
  }


  getMappingLog = () => {
    return new Promise((resolve, reject) => {
      api
        .getMappingLog(this.props.auth.user_id)
        .then(response => {
          console.log(response)
          this.setState({messages: response.map( r => {
              return {
                date: r.date_added,
                //message: 'A Broker from ' + r.requesting_user_employer + ' has requested health record information for ' +'Joe'
                message: 'A ' + r.requesting_user_type + ' from ' + r.requesting_user_employer + ' viewed your form, ' + r.requested_form
              }
          })

          })
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Getting Mapping Log"
          });
          console.log(err);
        });
    });
  };

  getHipaaLog = () => {
    return new Promise((resolve, reject) => {
      api
        .getHipaaLog(this.props.auth.user_id)
        .then(response => {
          console.log(response)
          this.setState({infoMessages: response.map( r => {
              return {
                date: r.date_added,
                //message: 'A Broker from ' + r.requesting_user_employer + ' has requested health record information for ' +'Joe'
                message: 'A ' + r.requesting_user_type + ' from ' + r.requesting_user_employer + ' viewed your ' + r.info_type
              }
          })
          

          })
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Getting Mapping Log"
          });
          console.log(err);
        });
    });
  };  
/*
A broker from Acme Agency requested health record information for Charlie Smith"
date_added: "2019-03-26T10:36:07.795283-04:00"
id: "9b1250870064461fa50f9360282d2301"
request_user: "c22248c9-37b5-4ecd-a809-c42aefaddcce"
requested_form: "All Savers"
requesting_user_employer: "ABC Agency"
requesting_user_type: "Broker"
*/
  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div style={{height: 600}}>
        <Typography variant="h5">
          Health Information Access Log
        </Typography>
        <div style={{marginTop: 30}}>
        <ExpansionPanel defaultExpanded style={{ marginBottom: 20 }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon/>}
            style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
          >
            <Typography variant="subtitle2">Details</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ marginTop: 20 }}>
            <Typography variant="body1">
              Below is a log of all activity regarding you and your family's
              health information in Reform Health. By default, only authorized
              individuals have access to your data and each time it is accessed,
              it is logged. Certain actions, like viewing a health application,
              may create one log entry. Other actions, like viewing a summary of
              your health information may result in many records being created
              at once as these involve many individual data requests. If you
              have specific questions about how and why your data are being
              accessed, please contact your company's benefits administrator.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>

        <React.Fragment>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleTabChange}>
              <Tab label="Health Form Access" />
              <Tab label="Other" />
            </Tabs>
          </AppBar>

          {value === 0 && (
            <TabContainer>
              <div style={{ display: "flex", flexWrap:'wrap', overflow: 'scroll',height: '100%' }}>
                {this.state.messages.map( (m, index) => {
                  return (
                    <React.Fragment key={index} >
                      <div style={{ display:'flex', flexGrow: 1, width: '100%', padding:10}}>
                        <div style={{ flex: "1", textAlign:'left', padding: 10,verticalAlign:'top' }}>
                          <Typography variant='body2'>
                          {formatDateTime(m.date)}
                          </Typography>
                        </div>
                        <div style={{ flex: "5", padding: 10, verticalAlign:'top', }}>
                        <Typography variant='body1'>
                          {m.message}
                          </Typography>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </TabContainer>
          )}
          {value === 1 && <TabContainer>
              <div style={{ display: "flex", flexWrap:'wrap', overflow: 'scroll',height: '100%' }}>
                {this.state.infoMessages.map( (m, index) => {
                  return (
                    <React.Fragment key={index} >
                      <div style={{ display:'flex', flexGrow: 1, width: '100%', padding:10}}>
                        <div style={{ flex: "1", textAlign:'left', padding: 10,verticalAlign:'top' }}>
                          <Typography variant='body2'>
                          {formatDateTime(m.date)}
                          </Typography>
                        </div>
                        <div style={{ flex: "5", padding: 10, verticalAlign:'top', }}>
                        <Typography variant='body1'>
                          {m.message}
                          </Typography>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>            
          </TabContainer>}
        </React.Fragment>
      </div>
    );
  }
}

HipaaLog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(HipaaLog));

//export default withStyles(styles)(HipaaLog);
