import { LOAD_STATES } from "../Constants/ActionTypes";

const intitialState = {
    states: [],
    isLoaded: false
};

const StateReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_STATES:
            return { ...state, states: action.payload, isLoaded: true };
        default:
            return state;
    }
};

export default StateReducer;
