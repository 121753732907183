import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

//import Checkbox from '@material-ui/core/Checkbox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

import { closeEditRecipient, setRecipientTable } from "../Redux/Actions/index";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const mapDispatchToProps = dispatch => {
  return {
    closeEditRecipient: () => dispatch(closeEditRecipient()),
    setRecipientTable: recipientTable =>
      dispatch(setRecipientTable(recipientTable))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    carriers: state.CarrierReducer.carriers,
    forms: state.FormReducer.forms,
    recipientTable: state.TableReducer.recipientTable,
    isOpen: state.UIReducer.editRecipientModalOpen
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if(ownProps.welcomePage){
    console.log('from welcome page')
    return { 
      ...stateProps, 
      ...dispatchProps, 
      ...ownProps, 
      isOpen: ownProps.isOpen,
      refreshTable: ownProps.refreshTable,
      recipientTable: ownProps.recipientTable,
      closeEditRecipient: ownProps.closeEditRecipient
    }  
  }
  return { ...stateProps, ...dispatchProps, ...ownProps}  
};


const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  carriers: [],
  selectedCarrier: "",
  forms: [],
  selectedForms: [],
  status: NOT_LOADED,
  searchFormValue: ""
};

class EditRecipient extends React.Component {
  state={selectedForms:[]}
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING &&
      this.props.recipientTable.selected.id
    ) {
      this.setState({ status: IS_LOADING });
      this.getRecipient();
    }
  }

  getRecipient = () => {
    return new Promise((resolve, reject) => {
      api
        .getRecipient(this.props.recipientTable.selected.id)
        .then(response => {
          this.setState({
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.email,
            phone: response.phone,
            selectedCarrier: response.carrier ? response.carrier.id : null,
            selectedForms: response.forms,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Recipient" });
          console.log(err);
        });
    });
  };

  replacer = (key, value) => {
    if (key === "carrier_id" && value === "") {
      return undefined;
    }
    return value;
  };

  addRecipient = () => {
    var body = JSON.stringify(
      {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        carrier_id: this.state.selectedCarrier,
        forms: this.state.selectedForms
      },
      this.replacer
    );

    return new Promise((resolve, reject) => {
      api
        .addRecipient(this.props.auth.customer_id, body)
        .then(r => {
          this.props.enqueueSnackbar("Recipient Added" , {
              variant: "success"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Recipient" });
          console.log(err);
        });
    });
  };

  updateRecipient = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      carrier_id: this.state.selectedCarrier,
      forms: this.state.selectedForms
    });

    return new Promise((resolve, reject) => {
      api
        .updateRecipient(this.props.recipientTable.selected.id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Employer Setup" });
          console.log(err);
        });
    });
  };

  deleteRecipient = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteRecipient(this.props.recipientTable.selected.id)
        .then(r => {
          resolve("done");
        })
        .catch(err => {
          this.props.enqueueSnackbar("Can't delete recipient, submission request in progress", {
            variant: "error"
          });
        });
    });
  };

  close = () => {
    var tempTable = this.props.recipientTable;
    tempTable.selected = {};
    this.props.setRecipientTable(tempTable);

    this.reset();
    this.props.closeEditRecipient();
  };

  handleClose = () => {
    this.close();
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleDelete = () => {
    this.deleteRecipient().then(r => {
      this.props.refreshTable();
      this.close();
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleAddForm = (val, type) => {
    var tempSelectedForms = this.state.selectedForms;
    tempSelectedForms.push(val);
    this.setState({ selectedForms: tempSelectedForms });
  };

  handleDeleteForm = form => {
    var tempSelectedForms = this.state.selectedForms;
    tempSelectedForms = tempSelectedForms.filter(f => f.id != form.id);
    this.setState({ selectedForms: tempSelectedForms });
  };

  handleSave = () => {
    if (this.props.recipientTable.selected.id !== undefined) {
      this.updateRecipient().then(r => {
        this.props.refreshTable();
        this.close();
      });
    } else {
      this.addRecipient().then(r => {
        this.props.refreshTable();
        this.close();
      });
    }
  };

  render() {
    const { fullScreen, isOpen, classes } = this.props;
    console.log(this.props)
    return (
      <div>
        <Dialog
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {(this.props.recipientTable.selected.id === undefined && "Add") ||
              "Edit"}{" "}
            Recipient {this.props.recipientTable.selected.name}
          </DialogTitle>

          <DialogContent style={{ height: 700 }}>
            <TextField
              id="standard-dense"
              label="First Name"
              value={this.state.firstName}
              margin="dense"
              onChange={e => this.setState({ firstName: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="Last Name"
              value={this.state.lastName}
              margin="dense"
              onChange={e => this.setState({ lastName: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="Phone"
              value={this.state.phone}
              margin="dense"
              onChange={e => this.setState({ phone: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="Email"
              value={this.state.email}
              margin="dense"
              onChange={e => this.setState({ email: e.target.value })}
              fullWidth
            />
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="carrier-simple">Carrier</InputLabel>
              <Select
                value={this.state.selectedCarrier}
                onChange={this.handleChange("selectedCarrier")}
                inputProps={{
                  name: "carrier",
                  id: "carrier-simple"
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.carriers.map((carrier, index) => {
                  return (
                    <MenuItem key={index} value={carrier.id}>
                      {carrier.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography variant={"button"} style={{ marginTop: 30 }}>
              Forms
            </Typography>
            <Typography variant={"caption"} style={{ marginBottom: 5 }}>
              Select each form this recipient can receive
            </Typography>
            <List dense={false}>
              {this.state.selectedForms.map((form, index) => {
                return (
                  <ListItem key={"form_item" + index}>
                    <ListItemText
                      primary={form.name}
                      secondary={form.carrier_name}
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                        value={index}
                        onClick={() => {
                          this.handleDeleteForm(form);
                        }}
                        aria-label="Delete"
                      >
                        <DeleteIcon className={classes.primaryIcon} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <ReactSelect
              styles={customStyles}
              fullWidth
              onChange={(val, type) => this.handleAddForm(val, type)}
              value={this.state.searchFormValue}
              options={this.props.forms}
              getOptionValue={option => option["id"]}
              getOptionLabel={option => option["name"]}
            />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this recipient? This cannot be undone."
              }
            />
            {this.props.recipientTable.selected.id && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditRecipient.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

EditRecipient.defaultProps = {
  isOpen: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withMobileDialog()(withStyles(styles)(EditRecipient)));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));
