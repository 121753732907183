import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Auth } from "aws-amplify";
import api from "../Shared/Functions/APIHelpers";

import { styles } from "./Styles/AuthStyles";

class FederatedAuth extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    app: "",
    codeType: "",
    code: ""
  };

  componentDidMount() {
    //waiting for everything to load
    setTimeout(
      function() {
        this.authenticate();

        this.unlisten = this.props.history.listen(() => {
          Auth.currentAuthenticatedUser()
            .then(user => {})
            .catch(() => {
              if (this.state.isAuthenticated)
                this.setState({ isAuthenticated: false });
            });
        });
      }.bind(this),
      1000
    );
  }

  getCode() {
    let codeObj = {};

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let state = params.get("state");

    let code = "";

    if (state) {
      var split = state.split(" ");
      code = split[1];
    }

    if (code) {
      var split = code.split("=");
      codeObj.type = split[1];
      codeObj.code = split[2];
    }

    return codeObj;
  }

  getApp() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let state = params.get("state");

    let app = "";

    if (state) {
      var split = state.split(" ");
      app = split[0];
    }

    if (app) {
      var split = app.split("=");
      return split[1];
    } else {
      return "user";
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ loaded: true, isAuthenticated: true });
        this.getUser()
      })
      .catch(err => {
        console.log("FederatedAuth - Authentication error: " + err);
        //this.props.history.push("/auth");
      });
  }

  getUser = () => {
    api
      .getUser()
      .then(user => {
        this.redirectUser(user, this.getApp(), this.getCode())
      })
      .catch(err => {
        this.setState({ errorMessage: "Error Getting User" });
        console.log(err);
      });
  };

  redirectUser = (user, app, code) => {
    if(!code.code){
      //if no code is provided then login normally. Else need to link or setup
      if(user.new_user)
        this.props.history.push("/" + this.getApp() + "/setup-complete");
      else
        this.props.history.push("/" + this.getApp() + "/home");
    }
    else{
      if(user.new_user)
        this.props.history.push("/" + this.getApp() + '/setup-complete?'+code.type +'='+code.code)
      else  
        this.props.history.push("/" + this.getApp() + '/link-complete?'+code.type +'='+code.code)
    }
  };

  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div className={classes.basecontent}>   
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root} elevation={5} style={{zIndex:10}}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Reform Health
              </Typography>
              <div style={{ height: 250, marginTop: 100 }}>
                <Typography variant="body1">
                  Signing into your account...
                </Typography>
                <br />
                <br />
                <CircularProgress className={classes.progress} />
              </div>
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

FederatedAuth.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FederatedAuth);
