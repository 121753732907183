import {
    LOAD_EMPLOYERS,
    ADD_EMPLOYER,
    UPDATE_EMPLOYER,
    DELETE_EMPLOYER,
    SET_ACTIVE_EMPLOYER,
    CLEAR_ACTIVE_EMPLOYER,
    EMPLOYERS_LOADED
} from "../Constants/ActionTypes";

const intitialState = {
    employers: [],
    isLoaded: false,
    isLoading: false,
    activeEmployer: {}
};

const employerReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_EMPLOYERS:
            return {
                ...state,
                employers: state.employers.concat(action.payload)
            };
        case ADD_EMPLOYER:
            return {
                ...state,
                employer: state.employers.concat(action.payload)
            };
        case UPDATE_EMPLOYER:
            return {
                ...state,
                employer: state.employers.map(employer =>
                    employer.id === action.payload.id
                        ? action.payload
                        : employer
                )
            };
        case DELETE_EMPLOYER:
            return {
                ...state,
                employer: state.employers.filter(
                    employer => employer.id !== action.payload
                )
            };
        case SET_ACTIVE_EMPLOYER:
            return {
                ...state,
                activeEmployer: state.employers.find(employer =>
                    employer.id === action.payload ? employer : null
                )
            };
        case CLEAR_ACTIVE_EMPLOYER:
            return { ...state, activeEmployer: {} };
        case EMPLOYERS_LOADED:
            return { ...state, isLoaded: true };
        default:
            return state;
    }
};

export default employerReducer;
