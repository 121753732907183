import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import LinkIcon from "@material-ui/icons/Link";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIconBorder from "@material-ui/icons/DoneOutline";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterListIcon from "@material-ui/icons/FilterList";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import OutlineFlagIcon from "@material-ui/icons/OutlinedFlag";
import FlagIcon from "@material-ui/icons/Flag";
import AddIcon from "@material-ui/icons/Add";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import api from "../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";
import config from "../../config";

const styles = theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.text.error,
		overflowX: "auto"
	},
	spacer: {
		flex: "1 1 100%"
	},
	actions: {
		color: theme.palette.text.secondary
	},
	actionsSelected: {
		display: "flex",
		flex: 1
	},
	title: {
		flex: "0 0 auto"
	},
	titleSelected: {
		flex: 1
	}
});

class MapRules extends React.Component {
	state = {
		relationshipTypes: ["Applicant", "Spouse", "Children", "Family"],
		rowsPerPage: 50,
		page: 0,
		mappingRules: [],
		models: [],
		modelFields: [],
		filter: "",
		ruleSelected: false,
		selectedRule: {},
		selectedCondition: "",
		deleteMappingRule: {}
	};

	componentDidMount() {
		this.getRules().then(rules => {
			var temp = rules.map((rule, index) => {
				return {
					...rule,
					sort_order: index,
					isChanged: false,
					selected: false
				};
			});
			this.setState({ mappingRules: temp });
		});
		this.loadModels().then(models => {
			this.setState({ models: models });
		});
		this.loadModelFields().then(modelFields => {
			this.setState({ modelFields: modelFields });
		});
	}

	getRules = () => {
		return new Promise((resolve, reject) => {
			api
				.getMappingRules(this.props.revisionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Mapping Rules" });
					console.log(err);
				});
		});
	};

	persistCopyRule = rule => {
		var body = JSON.stringify({
			field: rule.field,
			field_id: rule.field.id,
			type: 1
		});
		return new Promise((resolve, reject) => {
			api
				.copyMappingRule(this.props.revisionID, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Copying Rule" });
					console.log(err);
				});
		});
	};

	persistUpdateRule = rule => {
		var body = JSON.stringify({
			id: rule.field,
			//field: rule.field,
			field_id: rule.field.id,
			model_id: rule.model.id,
			model_field_id: rule.model_field.id,
			relationship_type: rule.relationship_type,
			order: rule.order,
			comparison_value: rule.comparison_value,
			profile_num: rule.profile_num,
			timeframe: rule.timeframe
		});
		return new Promise((resolve, reject) => {
			api
				.updateMappingRule(rule.id, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Updating Rule" });
					console.log(err);
				});
		});
	};

	persistRemoveRule = mappingRuleID => {
		return new Promise((resolve, reject) => {
			api
				.deleteMappingRule(mappingRuleID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Deleting Mapping Rule" });
					console.log(err);
				});
		});
	};

	loadModels = () => {
		return new Promise((resolve, reject) => {
			api
				.getModels()
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Models" });
					console.log(err);
				});
		});
	};

	loadModelFields = () => {
		return new Promise((resolve, reject) => {
			api
				.getModelFields()
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Model Fields" });
					console.log(err);
				});
		});
	};

	saveRules = () => {
		var count = 0;
		var promises = [];
		this.state.mappingRules.forEach(rule => {
			if (rule.isChanged) {
				promises.push(this.persistUpdateRule(rule));
				++count;
			}
		});
		Promise.all(promises).then(r => {
			r.forEach(rule => {
				var temp = this.state.mappingRules.map(mr => {
					if (mr.id === rule.id) return { ...rule, isChanged: false };
					else return mr;
				});
				this.props.enqueueSnackbar("UPDATED " + rule.field.name, {
					variant: "success"
				});
				//this.props.openSnack('updated ' + rule.field.name)
				this.setState({ mappingRules: temp });
			});
		});
	};
	handleChange(e, rule, property) {
		var temp = this.state.mappingRules.map(mr => {
			if (mr.id === rule.id && property === "model")
				return {
					...mr,
					model: { id: e.target.value },
					model_field: null,
					isChanged: true
				};
			else if (mr.id === rule.id && property === "model_field")
				return {
					...mr,
					model_field: { id: e.target.value },
					isChanged: true
				};
			else if (mr.id === rule.id)
				return { ...mr, [property]: e.target.value, isChanged: true };
			else return mr;
		});

		console.log(temp);
		this.setState({ mappingRules: temp });
	}

	selectRule = (e, rule) => {
		if (rule.selected) {
			rule.selected = false;
			this.setState({ ruleSelected: false, selectedRule: {} });
		} else {
			rule.selected = true;
			this.setState({ ruleSelected: true, selectedRule: rule });
		}

		var temp = this.state.mappingRules.map(mr => {
			if (mr.id === rule.id) return rule;
			else return mr;
		});
		this.setState({ mappingRules: temp });
	};

	handleRuleChange = rule => {
		var field = rule.field;
		this.persistRemoveRule(rule.id).then(r => {
			field.has_condition_mapping_rule = false;
			field.in_use = false;
			this.props.updateField(field);

			//remove from state
			var temp = this.state.mappingRules;
			temp = temp.filter(mr => mr.id != rule.id);
			this.setState({ mappingRules: temp });
		});
	};

	copyRule = rule => {
		this.persistCopyRule(rule).then(newRule => {
			//add new rule to state and turn of selected on the rule that was copied
			var temp = this.state.mappingRules;
			var updateNewRule = { ...newRule, sort_order: rule.sort_order };
			temp.push(updateNewRule);
			temp = temp.map(r => {
				if (r.id === rule.id) return { ...r, selected: false };
				else return r;
			});
			temp.sort((a, b) => a.sort_order - b.sort_order);
			this.setState({
				mappingRules: temp,
				ruleSelected: false,
				selectedRule: {}
			});
		});
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};
	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleConfirmDelete = rule => {
		this.setState({ confirmAlertOpen: true, deleteMappingRule: rule });
	};

	DoNotDelete = () => {
		this.setState({ confirmAlertOpen: false });
	};

	DoDelete = () => {
		this.setState({ confirmAlertOpen: false });
		this.handleRuleChange(this.state.deleteMappingRule);
	};

	render() {
		const { classes } = this.props;
		const { anchorEl, page, rowsPerPage } = this.state;
		const emptyRows =
			rowsPerPage -
			Math.min(
				rowsPerPage,
				this.state.mappingRules.length - page * rowsPerPage
			);

		return (
			<div className={classes.root}>
				<ConfirmAlert
					isOpen={this.state.confirmAlertOpen}
					isNo={this.DoNotDelete}
					isYes={this.DoDelete}
					AlertTitle={"Confirm Delete"}
					AlertText={"Are you sure you wish to delete this mapping rule"}
				/>
				<Toolbar>
					{(!this.state.ruleSelected && (
						<React.Fragment>
							<div className={classes.spacer} />

							<div className={classes.actions}>
								<IconButton onClick={this.saveRules} aria-label="Filter list">
									<SaveIcon />
								</IconButton>
							</div>
						</React.Fragment>
					)) || (
						<React.Fragment>
							<div>
								<Typography variant="subtitle1">
									{this.state.selectedRule.field.name}
								</Typography>
							</div>
							<div className={classes.spacer} />
							<div className={classes.actionsSelected}>
								<IconButton
									onClick={() => {
										this.copyRule(this.state.selectedRule);
									}}
									aria-label="copy"
								>
									<CopyIcon />
								</IconButton>
								{/*<IconButton aria-label="delete">
							<DeleteIcon onClick={this.deleteRule} />
						</IconButton>*/}
							</div>
						</React.Fragment>
					)}
				</Toolbar>

				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>Form Field</TableCell>
							<TableCell>Rule</TableCell>
							<TableCell>Model</TableCell>
							<TableCell>Field </TableCell>
							<TableCell>Relationship Type</TableCell>
							<TableCell>Profile Number</TableCell>
							<TableCell>Order</TableCell>
							<TableCell>Comparison Value</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{this.state.mappingRules
							.sort((a, b) => a.sort_order - b.sort_order)
							.map((rule, index) => {
								if (rule.type === 1) {
									return (
										<TableRow key={index}>
											<TableCell>
												<Checkbox
													style={{ width: 5 }}
													checked={!!rule.selected}
													onClick={e => {
														this.selectRule(e, rule);
													}}
												/>
											</TableCell>
											<TableCell>
												<Typography>{rule.field.name}</Typography>
											</TableCell>
											<TableCell>
												<FormControlLabel
													control={
														<Checkbox
															icon={<DoneIconBorder />}
															checkedIcon={<DoneIcon />}
															checked={rule.type === 1}
															onChange={() => this.handleConfirmDelete(rule)}
														/>
													}
													label=""
												/>
											</TableCell>
											<TableCell>
												<FormControl margin="dense">
													<InputLabel htmlFor="model-picker" />
													<Select
														value={rule.model ? rule.model.id : null}
														onChange={e => this.handleChange(e, rule, "model")}
														inputProps={{
															name: "modelPicker",
															id: "model-picker"
														}}
														style={{ width: 150, marginRight: 10 }}
													>
														<MenuItem value="">
															<em />
														</MenuItem>
														{this.state.models.map((model, index) => {
															return (
																<MenuItem key={index} value={model.id}>
																	{model.name}
																</MenuItem>
															);
														})}
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												<FormControl margin="dense">
													<InputLabel htmlFor="field-picker" />
													<Select
														value={
															rule.model_field ? rule.model_field.id : null
														}
														onChange={e =>
															this.handleChange(e, rule, "model_field")
														}
														inputProps={{
															name: "fieldPicker",
															id: "field-picker"
														}}
														style={{ width: 150, marginRight: 10 }}
													>
														<MenuItem value="">
															<em />
														</MenuItem>
														{this.state.modelFields.map((modelField, index) => {
															if (
																rule.model &&
																modelField.model.id === rule.model.id
															) {
																return (
																	<MenuItem key={index} value={modelField.id}>
																		{modelField.name}
																	</MenuItem>
																);
															}
														})}
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												<FormControl margin="dense">
													<InputLabel htmlFor="relationship-picker" />
													<Select
														value={rule.relationship_type || ""}
														onChange={e =>
															this.handleChange(e, rule, "relationship_type")
														}
														inputProps={{
															name: "relationshipPicker",
															id: "relationship-picker"
														}}
														style={{ width: 50, marginRight: 10 }}
													>
														<MenuItem value="">
															<em />
														</MenuItem>
														<MenuItem value={"1"}>A</MenuItem>
														<MenuItem value={"2"}>S</MenuItem>
														<MenuItem value={"3"}>C</MenuItem>
														<MenuItem value={"4"}>F</MenuItem>
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												<TextField
													id="standard-dense"
													label=""
													margin="dense"
													style={{ width: 50 }}
													value={rule.profile_num || ""}
													onChange={e => {
														this.handleChange(e, rule, "profile_num");
													}}
												/>
											</TableCell>
											<TableCell>
												<FormControl margin="dense">
													<InputLabel htmlFor="order-picker" />
													<Select
														value={rule.order || ""}
														onChange={e => this.handleChange(e, rule, "order")}
														inputProps={{
															name: "orderPicker",
															id: "order-picker"
														}}
														style={{ width: 50, marginRight: 10 }}
													>
														<MenuItem value={null}>
															<em />
														</MenuItem>
														<MenuItem value={"1"}>1</MenuItem>
														<MenuItem value={"2"}>2</MenuItem>
														<MenuItem value={"3"}>3</MenuItem>
														<MenuItem value={"4"}>4</MenuItem>
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												<TextField
													id="standard-dense"
													defaultValue={rule.comparison_value}
													margin="dense"
													onChange={e =>
														this.handleChange(e, rule, "comparison_value")
													}
													style={{ width: 50 }}
												/>
											</TableCell>
										</TableRow>
									);
								}
							})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 49 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[50, 100, 200]}
					component="div"
					count={this.state.mappingRules.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						"aria-label": "Previous Page"
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page"
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
			</div>
		);
	}
}

export default withSnackbar(withStyles(styles)(MapRules));
