import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import SingleFieldDialog from "./SingleFieldDialog";
import MFADialog from "./MFADialog";
import PasswordChangeDialog from "./PasswordChangeDialog";

import { Auth } from "aws-amplify";

//import { connect } from 'react-redux';
//import { loadUser } from '../Redux/Actions/index'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    //float: 'left',
    marginBottom: theme.spacing.unit * 3
  },
  pos: {
    marginBottom: 12
  },
  divider: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper
  }
});

/*
const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
  }
}
*/
class SecurityContainer extends React.Component {
  /*
  constructor(props) {
      super(props)

      this.updateEmail = this.updateEmail.bind(this);
      this.updatePhone = this.updatePhone.bind(this);
      this.handleMFAChange = this.handleMFAChange.bind(this);
  }
  */

  state = {
    user: {},
    userAttributes: {},
    mfaType: ''
  };

  componentDidMount() {
    this.getAttributes();
  }

  getMFAType = () => {
    //Auth.currentAuthenticatedUser()
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        console.log('Get Attributes from current authenticated user')
        console.log(user)
        this.setState({
          mfaType: user.preferredMFA
        });
      })
      .catch(err => console.log(err));
  };

  getAttributes = () => {
    //Auth.currentAuthenticatedUser()
    Auth.currentUserInfo()
      .then(user => {
        console.log('Get Attributes from get attribiutes')
        console.log(user)
        this.setState({
          user: this.props.user,
          userAttributes: user.attributes
        });
        this.getMFAType();
      })
      .catch(err => console.log(err));
  };

  sendPhoneCode = () => {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {
        this.props.enqueueSnackbar("Verification Code Sent", {
          variant: "success"
        });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, {
          variant: "error"
        });
      });
  };

  sendEmailCode = () => {
    Auth.verifyCurrentUserAttribute("email")
      .then(() => console.log("a verification code is sent"))
      .catch(err => console.log("failed with error", err));
  };

  updateEmail = email => {
    Auth.updateUserAttributes(this.state.user, { email: email }).then(() => {
      this.getAttributes();
    });
  };

  updatePhone = phone_number => {
    Auth.updateUserAttributes(this.state.user, {
      phone_number: phone_number
    }).then(() => {
      this.getAttributes();
      this.sendPhoneCode();
    });
  };

  verifyEmail = code => {
    Auth.verifyCurrentUserAttributeSubmit("email", code)
      .then(() => {
        this.props.enqueueSnackbar("Email Confirmed", {
          variant: "success"
        });
        this.getAttributes();
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, {
          variant: "error"
        });
      });
  };

  verifyPhone = code => {
    Auth.verifyCurrentUserAttributeSubmit("phone_number", code)
      .then(() => {
        this.props.enqueueSnackbar("Phone Number Confirmed", {
          variant: "success"
        });
        this.getAttributes();
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, {
          variant: "error"
        });
      });
  };

  handleMFAChange = () => {
    console.log("MFA - triggered change");
    this.getAttributes();
  };

  render() {
    const { classes } = this.props;
    //const attributes = this.props.user.user.attributes;

    return (
      <div>
        <Typography variant="display1">Account Settings</Typography>
        <br />

        {!this.props.user.federatedUser && (
          <Paper className={classes.paper}>
            <Typography variant="headline" color="textSecondary" gutterBottom>
              Email
            </Typography>
            <Typography variant="title" gutterBottom>
              {(this.state.userAttributes.email !== undefined &&
                this.state.userAttributes.email) ||
                "-"}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {(this.state.userAttributes.email_verified === true &&
                "Verified") ||
                "Not verified - you must verify this email before it can be used!"}
            </Typography>
            <Typography variant="body1">
              Your email is required for logging in, notifications and resetting
              your password.
            </Typography>
            <br />
            <div style={{ marginLeft: -17, marginBottom: 15 }}>
              <SingleFieldDialog
                Title={"Email Address"}
                defaultValue={this.state.userAttributes.email}
                returnValue={this.updateEmail}
                label={"Email"}
                ButtonTitle={"Edit"}
                enqueueSnackbar={this.props.enqueueSnackbar}
              />
              {this.state.userAttributes.email_verified === false && (
                <SingleFieldDialog
                  Title={"Verification Code"}
                  defaultValue={""}
                  label={"Verification Code"}
                  returnValue={this.verifyEmail}
                  ButtonTitle={"Verify"}
                  enqueueSnackbar={this.props.enqueueSnackbar}
                />
              )}
            </div>
          </Paper>
        )}

        {!this.props.user.federatedUser && (
          <Paper className={classes.paper}>
            <Typography variant="headline" color="textSecondary" gutterBottom>
              Cell Phone
            </Typography>
            <Typography variant="title" gutterBottom>
              {(this.state.userAttributes.phone_number !== undefined &&
                this.state.userAttributes.phone_number) ||
                "-"}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {(this.state.userAttributes.phone_number_verified === true &&
                "Verified") ||
                "Not verified - you must verify this phone number before it can be used!"}
            </Typography>
            <Typography ariant="body1">
              Your cell phone number is required if you wish to use SMS
              multi-factor authentication.
            </Typography>
            <br />
            <div style={{ marginLeft: -17, marginBottom: 15 }}>
              <SingleFieldDialog
                Title={"Cell Phone Number"}
                defaultValue={this.state.userAttributes.phone_number}
                returnValue={this.updatePhone}
                label={"Phone +1##########"}
                ButtonTitle={"Edit"}
                enqueueSnackbar={this.props.enqueueSnackbar}
              />
              {this.state.userAttributes.phone_number !== undefined &&
                this.state.userAttributes.phone_number_verified === false && (
                  <SingleFieldDialog
                    Title={"Verification Code"}
                    defaultValue={""}
                    label={"Verification Code"}
                    returnValue={this.verifyPhone}
                    ButtonTitle={"Verify"}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                  />
                )}
            </div>
          </Paper>
        )}

        {!this.props.user.federatedUser && (
          <React.Fragment>
            <Typography variant="display1">Security Settings</Typography>
            <br />
          </React.Fragment>
        )}

        {!this.props.user.federatedUser && (
          <Paper className={classes.paper}>
            <Typography variant="headline" color="textSecondary" gutterBottom>
              Multi-factor Authentication
            </Typography>
            <Typography variant="title" gutterBottom>
              {(this.state.mfaType === "SOFTWARE_TOKEN_MFA" &&
                "Software Token") ||
                (this.state.mfaType === "SMS_MFA" && "SMS") ||
                "-"}
            </Typography>
            {this.state.mfaType === "NOMFA" && (
              <Typography className={classes.pos} style={{color:'red'}}>
                Not Enabled!
              </Typography>
            )}
            {this.state.mfaType !== "NOMFA" && (
              <Typography className={classes.pos} color="textSecondary">
                Enabled
              </Typography>
            )}
           
            <Typography ariant="body1">
              Multi-factor authentication provides a second layer of security
              beyond your password. When enabled, a special one-time password
              sent to your cell phone or generated by an app will be required to
              log in.
              <br />
              <br />
              IT IS HIGHLY RECOMMENDED THAT YOU ENABLE THIS FEATURE!
            </Typography>
            <br />
            <div style={{ marginLeft: -17, marginBottom: 15 }}>
              <MFADialog 
                  handleSave={this.handleMFAChange} 
                  user={this.props.user}
                  userAttributes={this.state.userAttributes}
                  enqueueSnackbar={this.props.enqueueSnackbar}
                />
            </div>
          </Paper>
        )}

        {!this.props.user.federatedUser && (
          <Paper className={classes.paper}>
            <Typography variant="headline" color="textSecondary" gutterBottom>
              Additional Settings
            </Typography>
            <br />
            <div style={{ marginLeft: -10, marginBottom: 15 }}>
              <PasswordChangeDialog 
                enqueueSnackbar={this.props.enqueueSnackbar}
              />
            </div>
          </Paper>
        )}

        {this.props.user.federatedUser && (
          <Paper className={classes.paper}>
            <Typography variant="headline" color="textSecondary" gutterBottom>
              Additional Settings
            </Typography>
            <Typography ariant="body1">
              Because you are using a social login through Google,
              you must make changes to your account there, including changing
              your password. <br />
              If not currently enabled, we recommend you enabled Multi-Factor
              Authentication.
            </Typography>
            <br />
          </Paper>
        )}
      </div>
    );
  }
}

SecurityContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

//export default withStyles(styles)(Home);

export default withStyles(styles)(SecurityContainer);
