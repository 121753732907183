import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Nav/Styles/NavStyles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import HealthIcon from "@material-ui/icons/Favorite";
import SummaryIcon from "@material-ui/icons/AssignmentTurnedIn";

class NestedList extends React.Component {
  goHome = () => {
    this.props.history.push("/user/home");
  };

  goFamily = () => {
    this.props.history.push("/user/family");
  };

  goHealth = () => {
    this.props.history.push("/user/health");
  };

  goSummary = () => {
    this.props.history.push("/user/review");
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">Reform Health</ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText inset primary="Home" />
          </ListItem>

          <ListItem button onClick={this.goFamily}>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText inset primary="Family" />
          </ListItem>

          <ListItem button onClick={this.goHealth}>
            <ListItemIcon>
              <HealthIcon />
            </ListItemIcon>
            <ListItemText inset primary="Health" />
          </ListItem>

          <ListItem button onClick={this.goSummary}>
            <ListItemIcon>
              <SummaryIcon />
            </ListItemIcon>
            <ListItemText inset primary="Review" />
          </ListItem>
        </List>
      </div>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NestedList);
