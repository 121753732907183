import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";

import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import MenuIcon from "@material-ui/icons/Menu";
import MappingIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";

import { Auth } from "aws-amplify";
import config from "../../config";

import {
  setFormRevisionTable,
  openEditFormRevision
} from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setFormRevisionTable: formRevisionTable =>
      dispatch(setFormRevisionTable(formRevisionTable)),
    openEditFormRevision: () => dispatch(openEditFormRevision())
  };
};

const mapStateToProps = state => {
  return {
    formTable: state.TableReducer.formTable,
    formRevisionTable: state.TableReducer.formRevisionTable
  };
};

class FormsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  getDataFromServer = ({ startIndex, stopIndex }) => {
    console.log("get data from server");
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;

          fetch(
            config.apiGateway.URL +
              "/api/v1/forms/" +
              this.props.formTable.selected.id +
              "/form-revisions",
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/coreapi+json"
              }),
              method: "GET",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(result => {
              console.log(result);
              resolve(result);
            })
            .catch(err => {
              console.log("fetch error" + err);
            });
        })
        .catch(err => {
          console.log("error happened", err);
          return;
        });
    });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleOpenMenu = (e, formRevision) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.formRevisionTable;
    tempState.selected = formRevision;
    this.props.setFormRevisionTable(tempState);
  };

  handleCloseMenu = () => {
    this.closeMenu();
  };

  handleViewMappingRules = () => {
    this.props.history.push("/admin/mapping");
    this.closeMenu();
  };

  handleEditRevision = () => {
    this.props.openEditFormRevision();
    this.closeMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable()
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CustomTable
          columns={[
            {
              label: "Name",
              dataKey: "form_name",
              numeric: false,
              width: 300
            },
            {
              label: "Carrier Name",
              dataKey: "carrier_name",
              numeric: false,
              width: 150
            },
            {
              label: "Revision",
              dataKey: "revision",
              numeric: false,
              width: 150
            },
            {
              label: "Testing",
              dataKey: "is_testing",
              numeric: false,
              width: 150
            },
            {
              label: "Comment",
              dataKey: "comment",
              numeric: false,
              width: 150
            },
            {
              label: "Valid From",
              dataKey: "valid_from",
              numeric: false,
              width: 150
            },
            {
              label: "Valid To",
              dataKey: "valid_to",
              numeric: false,
              width: 150
            }
          ]}
          name={"Form Revisions - " + this.props.formTable.selected.name}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={"forms/" + this.props.formTable.selected.id +"/form-revisions"}
          //getDataFromServer={this.getDataFromServer}
          filters={[]}
          rowCountEndpoint={""}
          openMenu={this.handleOpenMenu}
          onActionClick={this.onActionClick}
          infinite={false}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleViewMappingRules}>
            <MappingIcon className={classes.leftIcon} />
            View Mapping Rules
          </MenuItem>
          <MenuItem onClick={this.handleEditRevision}>
            <EditIcon className={classes.leftIcon} />
            Edit Revision
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormsTable));
