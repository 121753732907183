import config from "../../config";
import { Auth } from "aws-amplify";

function getWithURL(url) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/coreapi+json"
          //"Content-Type": "application/json",
          //"Access-Control-Allow-Origin" : "*"
        }),
        method: "GET",
        mode: "cors"
      })
        .then(response => {
          return response.json();
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

function getWithURLUnauthenticated(url) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        "Content-Type": "application/coreapi+json"
        //"Content-Type": "application/json",
        //"Access-Control-Allow-Origin" : "*"
      }),
      method: "GET",
      mode: "cors"
    })
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        resolve(responseData);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function postWithURL(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          "Content-Type": "application/json"
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

function postWithURLUnauthenticated(url, body) {
  return new Promise((resolve, reject) => {
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          //Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          "Content-Type": "application/json"
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
  });
}

function postWithURLUnauthenticatedPublic(url, body) {
  return new Promise((resolve, reject) => {
      fetch(url, {
        headers: new Headers({
          //Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          //"Content-Type": "application/json"
          //"Content-Type": "multipart/form-data",
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
  });
}

function postWithURLForAttachment(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token
        }),
        body: body,
        method: "POST",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

function updateWithURL(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          "Content-Type": "application/json"
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "PATCH",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

function deleteWithURL(url) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }),
        method: "DELETE",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            throw new Error(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

const api = {
  getUser: function() {
    var url = "/api/v1/user/user";
    return getWithURL(url);
  },

  updateUser: function(body) {
    var url = "/api/v1/user/user";
    return updateWithURL(url, body);
    console.log("done");
  },

  getUserAccountDetails: function(userID) {
    var url = "/api/v1/user/"+userID+"/account-details";
    return getWithURL(url);
  },

  // user setup
  getEmployerByCodeToLink: function(code) {
    var url = "/api/v1/user/employer-link?code=" + code;
    return getWithURL(url);
  },
  getInvitationByCode: function(code) {
    var url = "/api/v1/admin/invitation?code=" + code;
    return getWithURLUnauthenticated(url);
  },
  getInvitationByCodeAccepted: function(code) {
    var url = "/api/v1/admin/invitation?code=" + code + "&accepted=true";
    return getWithURLUnauthenticated(url);
  },

  // Employer Setup
  addEmployer: function(body) {
    var url = "/api/v1/user/employer";
    return postWithURL(url, body);
  },

  getCustomerByCodeToLink: function(code) {
    var url = "/api/v1/user/customer-link?code=" + code;
    return getWithURL(url);
  },

  // Employers
  getEmployer: function(employerID) {
    var url = "/api/v1/employers/" + employerID;
    return getWithURL(url);
  },
  updateEmployer: function(employerID, body) {
    var url = "/api/v1/employers/" + employerID;
    return updateWithURL(url, body);
  },

  getEmployerByCode: function(code) {
    console.log("GETTING BY CODE " + code);
    var url = "/api/v1/employer?code=" + code;
    return getWithURLUnauthenticated(url);
  },
  getCustomerByCode: function(code) {
    var url = "/api/v1/customer?code=" + code;
    return getWithURLUnauthenticated(url);
  },

  getEmployerLocations: function(employerID) {
    var url = "/api/v1/employers/" + employerID + "/locations";
    return getWithURL(url);
  },

  addEmployerLocation: function(employerID, body) {
    var url = "/api/v1/employers/" + employerID + "/locations";
    return postWithURL(url, body);
  },
  updateEmployerLocation: function(locationID, body) {
    var url = "/api/v1/employer-locations/" + locationID;
    return updateWithURL(url, body);
  },
  deleteEmployerLocation: function(locationID) {
    var url = "/api/v1/employer-locations/" + locationID;
    return deleteWithURL(url);
  },
  //messages
  getMessageSummaries: function(userID) {
    var url = "/api/v1/users/" + userID + "/messages/summary";
    return getWithURL(url);
  },
  getNewMessageCount: function(userID) {
    var url = "/api/v1/users/" + userID + "/messages/count";
    return getWithURL(url);
  },
  getMessages: function(userID, senderID) {
    var url =
      "/api/v1/users/" + userID + "/messages?other_profile_id=" + senderID;
    return getWithURL(url);
  },
  addMessage: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/messages";
    return postWithURL(url, body);
  },

  //get
  addProfile: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/family-members";
    return postWithURL(url, body);
  },
  updateProfile: function(profileID, body) {
    var url = "/api/v1/family-members/" + profileID;
    return updateWithURL(url, body);
  },
  deleteProfile: function(profileID) {
    var url = "/api/v1/family-members/" + profileID;
    return deleteWithURL(url);
  },
  getStates: function() {
    var url = "/api/v1/states";
    return getWithURLUnauthenticated(url);
  },
  getCounties: function(stateID) {
    var url = "/api/v1/states/" + stateID + "/counties";
    return getWithURL(url);
  },
  addAddress: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/user-addresses";
    return postWithURL(url, body);
  },
  updateAddress: function(addressID, body) {
    var url = "/api/v1/user-addresses/" + addressID;
    return updateWithURL(url, body);
  },
  deleteAddress: function(addressID) {
    var url = "/api/v1/user-addresses/" + addressID;
    return deleteWithURL(url);
  },

  getEmploymentHistory: function(profileID) {
    var url = "/api/v1/family-members/" + profileID + "/employment-history";
    return getWithURL(url);
  },
  addEmploymentHistory: function(profileID, body) {
    var url = "/api/v1/family-members/" + profileID + "/employment-history";
    return postWithURL(url, body);
  },
  updateEmploymentHistory: function(employmentHistoryID, body) {
    var url = "/api/v1/employment-history/" + employmentHistoryID;
    return updateWithURL(url, body);
  },
  deleteEmploymentHistory: function(employmentHistoryID) {
    var url = "/api/v1/employment-history/" + employmentHistoryID;
    return deleteWithURL(url);
  },

  getMappingLog: function(userID) {
    var url = "/api/v1/users/" + userID + "/mapping-log";
    return getWithURL(url);
  },

  getHipaaLog: function(userID) {
    var url = "/api/v1/users/" + userID + "/hipaa-log";
    return getWithURL(url);
  },

  getHealthRecords: function(userID) {
    var url = "/api/v1/users/" + userID + "/health-records";
    return getWithURL(url);
  },

  addHealthRecord: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/health-records";
    return postWithURL(url, body);
  },
  updateHealthRecord: function(healthRecordID, body) {
    var url = "/api/v1/health-records/" + healthRecordID;
    return updateWithURL(url, body);
  },
  deleteHealthRecord: function(healthRecordID) {
    var url = "/api/v1/health-records/" + healthRecordID;
    return deleteWithURL(url);
  },

  addMedicationRecord: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/medication-records";
    return postWithURL(url, body);
  },
  deleteMedicationRecord: function(medicationRecordID) {
    var url = "/api/v1/medication-records/" + medicationRecordID;
    return deleteWithURL(url);
  },
  getMedicationOptions: function(medicationSearchValue) {
    var url = "/api/v1/medications?search_term=" + medicationSearchValue;
    return getWithURL(url);
  },
  getDosageList: function(medicationID) {
    var url = "/api/v1/medications/" + medicationID + "/dosages";
    return getWithURL(url);
  },

  getCondition: function(conditionID) {
    var url = "/api/v1/conditions/" + conditionID;
    return getWithURL(url);
  },

  getICD: function(conditionID, detail1, detail2, detail3) {
    var url =
      "/api/v1/condition-icds?condition_id=" +
      conditionID +
      "&detail_1_id=" +
      detail1 +
      "&detail_2_id=" +
      detail2 +
      "&detail_3_id=" +
      detail3;
    return getWithURL(url);
  },

  getDetail2List: function(detail1ID) {
    var url = "/api/v1/icd-detail-2-list/" + detail1ID;
    return getWithURL(url);
  },

  getSingleFormByID: function(userID, formID) {
    var url =
      "/api/v1/users/" + userID + "/current-forms/" + formID + "?isForm=True";
    return getWithURL(url);
  },

  getSingleFormByRevision: function(userID, revisionID) {
    var url = "/api/v1/users/" + userID + "/current-forms/" + revisionID;
    return getWithURL(url);
  },
  getCurrentForms: function(userID, signed) {
    if (signed) {
      var url = "/api/v1/users/" + userID + "/current-forms?sign=True";
    } else {
      var url = "/api/v1/users/" + userID + "/current-forms";
    }

    return getWithURL(url);
  },
  getCustomerEmployerFormList: function(customerID, employerID) {
    var url =
      "/api/v1/customers/" + customerID + "/employers/" + employerID + "/forms";
    return getWithURL(url);
  },
  getCurrentEmployeeForms: function(employerID, formID) {
    var url = "/api/v1/employers/" + employerID + "/current-forms/" + formID;
    return getWithURL(url);
  },

  getMappingRuleOverrides: function(userID) {
    var url = "/api/v1/users/" + userID + "/mapping-rule-overrides";
    return getWithURL(url);
  },
  addMappingRuleOverride: function(userID, body) {
    var url = "/api/v1/users/" + userID + "/mapping-rule-overrides";
    return postWithURL(url, body);
  },
  updateMappingRuleOverride: function(overrideID, body) {
    var url = "/api/v1/mapping-rule-overrides/" + overrideID;
    return updateWithURL(url, body);
  },
  deleteMappingRuleOverride: function(overrideID) {
    var url = "/api/v1/mapping-rule-overrides/" + overrideID;
    return deleteWithURL(url);
  },

  getFields: function(formRevisionID) {
    var url = "/api/v1/form-revisions/" + formRevisionID + "/fields";
    return getWithURL(url);
  },
  getMappingRule: function(mappingRuleID) {
    var url = "/api/v1/mapping-rules/" + mappingRuleID;
    return getWithURL(url);
  },
  getMappingRules: function(formRevisionID) {
    var url = "/api/v1/form-revisions/" + formRevisionID + "/mapping-rules";
    return getWithURL(url);
  },
  addMappingRule: function(formRevisionID, body) {
    var url = "/api/v1/form-revisions/" + formRevisionID + "/mapping-rules";
    return postWithURL(url, body);
  },
  copyMappingRule: function(formRevisionID, body) {
    var url = "/api/v1/form-revisions/" + formRevisionID + "/mapping-rules";
    return postWithURL(url, body);
  },
  updateMappingRule: function(mappingRuleID, body) {
    var url = "/api/v1/mapping-rules/" + mappingRuleID;
    return updateWithURL(url, body);
  },
  deleteMappingRule: function(mappingRuleID) {
    var url = "/api/v1/mapping-rules/" + mappingRuleID;
    return deleteWithURL(url);
  },

  // Form Revisions

  getFormRevision: function(formID) {
    var url = "/api/v1/form-revisions/" + formID;
    return getWithURL(url);
  },
  updateFormRevision: function(formRevisionID, body) {
    var url = "/api/v1/form-revisions/" + formRevisionID;
    return updateWithURL(url, body);
  },

  // Employers

  getEmployerSetup: function(customerID, employerID) {
    var url = "/api/v1/customers/" + customerID + "/employers/" + employerID;
    return getWithURL(url);
  },
  deleteEmployerSetup: function(customerID, employerID) {
    var url = "/api/v1/customers/" + customerID + "/employers/" + employerID;
    return deleteWithURL(url);
  },
  addEmployerInvite: function(customerID, body) {
    var url = "/api/v1/customers/" + customerID + "/employer-invitations";
    return postWithURL(url, body);
  },
  updateEmployerSetup: function(customerID, employerID, body) {
    var url = "/api/v1/customers/" + customerID + "/employers/" + employerID;
    return updateWithURL(url, body);
  },

  // Billing Setup
  getCustomerPlanInfo: function(planID) {
    var url = "/api/v1/customer-plans/" + planID;
    return getWithURLUnauthenticated(url);
  },
  createCustomerBilling: function(body) {
    var url = "/api/v1/customer-signup";
    return postWithURL(url, body);
  },

  // Customer Info
  getBroker: function(brokerID) {
    var url = "/api/v1/customers/" + brokerID;
    return getWithURL(url);
  },
  updateBroker: function(brokerID, body) {
    var url = "/api/v1/customers/" + brokerID;
    return updateWithURL(url, body);
  },

  // Customer Counts
  getBrokerCounts: function(customerID) {
    var url = "/api/v1/customers/" + customerID + "/counts";
    return getWithURL(url);
  },
  // Upload Form From Customer
  uploadCustomerForm: function(customerID, body) {
    var url = "/api/v1/customers/" + customerID + "/upload-form";
    return postWithURLForAttachment(url, body);
  },

  // Upload Form From Carrier
  uploadCarrierForm: function(body) {
    var url = "/api/v1/carriers/upload-form";
    return postWithURLForAttachment(url, body);
  },

  // Recipients

  getRecipient: function(recipientID) {
    var url = "/api/v1/recipients/" + recipientID;
    return getWithURL(url);
  },
  addRecipient: function(customerID, body) {
    var url = "/api/v1/customers/" + customerID + "/recipients";
    return postWithURL(url, body);
  },
  updateRecipient: function(recipientID, body) {
    var url = "/api/v1/recipients/" + recipientID;
    return updateWithURL(url, body);
  },
  deleteRecipient: function(recipientID) {
    var url = "/api/v1/recipients/" + recipientID;
    return deleteWithURL(url);
  },

  //Form Groups
  getFormGroup: function(formGroupID) {
    var url = "/api/v1/form-groups/" + formGroupID;
    return getWithURL(url);
  },
  getFormGroups: function(customerID) {
    var url = "/api/v1/customers/" + customerID + "/form-groups";
    return getWithURL(url);
  },
  addFormGroup: function(customerID, body) {
    var url = "/api/v1/customers/" + customerID + "/form-groups";
    return postWithURL(url, body);
  },
  updateFormGroup: function(formGroupID, body) {
    var url = "/api/v1/form-groups/" + formGroupID;
    return updateWithURL(url, body);
  },
  deleteFormGroup: function(formGroupID) {
    var url = "/api/v1/form-groups/" + formGroupID;
    return deleteWithURL(url);
  },

  getConditionMappingRules: function(formRevisionID) {
    var url =
      "/api/v1/form-revisions/" + formRevisionID + "/condition-mapping-rules";
    return getWithURL(url);
  },
  addConditionMappingRule: function(formRevisionID, body) {
    var url =
      "/api/v1/form-revisions/" + formRevisionID + "/condition-mapping-rules";
    return postWithURL(url, body);
  },
  deleteConditionMappingRule: function(conditionMappingRuleID) {
    var url = "/api/v1/condition-mapping-rules/" + conditionMappingRuleID;
    return deleteWithURL(url);
  },

  getConditionGroupMappingRules: function(formRevisionID) {
    var url =
      "/api/v1/form-revisions/" +
      formRevisionID +
      "/condition-group-mapping-rules";
    return getWithURL(url);
  },
  addConditionGroupMappingRule: function(formRevisionID, body) {
    var url =
      "/api/v1/form-revisions/" +
      formRevisionID +
      "/condition-group-mapping-rules";
    return postWithURL(url, body);
  },
  deleteConditionGroupMappingRule: function(conditionGroupMappingRuleID) {
    var url =
      "/api/v1/condition-group-mapping-rules/" + conditionGroupMappingRuleID;
    return deleteWithURL(url);
  },

  getModels: function() {
    var url = "/api/v1/form-models";
    return getWithURL(url);
  },
  getModelFields: function() {
    var url = "/api/v1/form-model-fields";
    return getWithURL(url);
  },

  getConditions: function() {
    var url = "/api/v1/conditions";
    return getWithURL(url);
  },
  getICDForCondition: function(conditionID) {
    var url = "/api/v1/conditions/" + conditionID + "/condition-icds";
    return getWithURL(url);
  },

  // Carrier Submissions
  getCarrierSubmissionDetails: function(submissionID) {
    var url = "/api/v1/carrier-submissions/" + submissionID;
    return getWithURL(url);
  },

  // Customer Submissions
  getCustomerSubmissionRequest: function(submissionID) {
    var url = "/api/v1/customer-submission-requests/" + submissionID;
    return getWithURL(url);
  },
  updateCustomerSubmissionRequest: function(submissionID) {
    //need to add archive so SUBMISSION REQUEST
    var url = "/api/v1/customer-submission-requests/" + submissionID;
    return updateWithURL(url);
  },

  // Invitations
  getEmployerEmployeeInvitations: function(employerID) {
    var url = "/api/v1/employers/" + employerID + "/employee-invitations";
    return getWithURL(url);
  },
  addEmployerEmployeeInvitations: function(employerID, body) {
    var url = "/api/v1/employers/" + employerID + "/employee-invitations";
    return postWithURL(url, body);
  },
  deleteEmployerEmployeeInvitations: function(invitationID) {
    var url = "/api/v1/employee-invitations/" + invitationID;
    return deleteWithURL(url);
  },
  resendEmployeEmployeeInvitation: function(invitationID) {
    var url = "/api/v1/employee-invitations/" + invitationID + "/resend";
    return getWithURL(url);
  },
  getBrokerEmployeeInvitations: function(customerID) {
    var url = "/api/v1/customers/" + customerID + "/user-invitations";
    return getWithURL(url);
  },
  addBrokerEmployeeInvitations: function(customerID, employerID, body) {
    var url = "/api/v1/customers/" + customerID + "/user-invitations";
    return postWithURL(url, body);
  },
  deleteBrokerEmployeeInvitations: function(invitationID) {
    var url = "/api/v1/user-invitations/" + invitationID;
    return deleteWithURL(url);
  },
  getCustomerEmployerInvitations: function(customerID) {
    var url = "/api/v1/customers/" + customerID + "/employer-invitations";
    return getWithURL(url);
  },
  resendBrokerEmployerInvitation: function(invitationID) {
    var url = "/api/v1/employer-invitations/" + invitationID + "/resend";
    return getWithURL(url);
  },
  getCustomerEmployerInvitation: function(invitationID) {
    var url = "/api/v1/employer-invitations/" + invitationID;
    return getWithURL(url);
  },
  updateCustomerEmployerInvitation: function(invitationID, body) {
    var url = "/api/v1/employer-invitations/" + invitationID;
    return updateWithURL(url, body);
  },
  deleteBrokerEmployerInvitation: function(invitationID) {
    var url = "/api/v1/employer-invitations/" + invitationID;
    return deleteWithURL(url);
  },

  // Employer to Broker Invitations
  getEmployerCustomerInvitations: function(employerID) {
    var url = "/api/v1/employers/" + employerID + "/broker-invitations";
    return getWithURL(url);
  },
  resendEmployerBrokerInvitation: function(invitationID) {
    var url = "/api/v1/broker-invitations/" + invitationID + "/resend";
    return getWithURL(url);
  },
  addEmployerCustomerInvitation: function(employerID, body) {
    var url = "/api/v1/employers/" + employerID + "/broker-invitations";
    return postWithURL(url, body);
  },
  associateEmployerWithCustomerCode: function(employerID, body) {
    var url = "/api/v1/employers/" + employerID + "/broker-invitations-code";
    return postWithURL(url, body);
  },
  deleteEmployerCustomerInvitation: function(invitationID) {
    var url = "/api/v1/broker-invitations/" + invitationID;
    return deleteWithURL(url);
  },

  //consoliplex 

  getPreloadMedicare: function(id, pin){
    var url = "/api/v1/preloaded-medicare-pin/" + id + "?pin=" + pin;
    return getWithURLUnauthenticated(url);
  },

  addMedicare: function(id, pin, body) {
    var url = "/api/v1/medicare-pin/" + id + "?pin=" + pin;
    return postWithURLUnauthenticated(url, body);
  },

  storePublicSignature : function(url,body) {
    return postWithURLUnauthenticatedPublic(url, body);
  },

};

export default api;
