import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/TableStyles";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import {
  AccountIcon,
  RevisionIcon,
  SummaryIcon,
  EditIcon
} from "../../Shared/Components/Icons";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setFormTable, openEditForm } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    setFormTable: formTable => dispatch(setFormTable(formTable)),
    openEditForm: () => dispatch(openEditForm())
  };
};

const mapStateToProps = state => {
  return {
    formTable: state.TableReducer.formTable
  };
};

class FormsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  getDataFromServer = ({ startIndex, stopIndex }) => {
    console.log("get data from server");
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;

          fetch(config.apiGateway.URL + "/api/v1/forms", {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(result => {
              resolve(result);
            })
            .catch(err => {
              console.log("fetch error" + err);
            });
        })
        .catch(err => {
          console.log("error happened", err);
          return;
        });
    });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleCloseMenu = () => {
    this.closeMenu();
  };

  handleOpenMenu = (e, form) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.formTable;
    tempState.selected = form;
    this.props.setFormTable(tempState);
  };

  handleViewRevisions = () => {
    this.props.history.push("/admin/form-revisions");
    this.closeMenu();
  };

  handleEditForm = () => {
    this.props.openEditForm();
    this.closeMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable()
  }
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CustomTable
          columns={[
            {
              label: "Name",
              dataKey: "name",
              numeric: false,
              width: 300
            },
            {
              label: "Carrier Name",
              dataKey: "carrier.name",
              nested: true,
              numeric: false,
              width: 150,
            }
          ]}
          name={'Forms'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={"forms"}
          //getDataFromServer={this.getDataFromServer}
          filters={[]}
          rowCountEndpoint={""}
          openMenu={this.handleOpenMenu}
          onActionClick={this.onActionClick}
          infinite={false}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleViewRevisions}>
            <RevisionIcon className={classes.leftIcon} />
            View Revisions
          </MenuItem>
          <MenuItem onClick={this.handleEditForm} style={{ display: "none" }}>
            <EditIcon className={classes.leftIcon} />
            Edit Form
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormsTable));
