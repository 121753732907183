import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import FormLabel from "@material-ui/core/FormLabel";

import MobileStepper from "@material-ui/core/MobileStepper";

import Agreement from "../Agreement";

import Button from "@material-ui/core/Button";

import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";

import { styles } from "../Styles/AuthStyles";

import config from "../../config";

class EmployerSetup extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    inviteCode: "",
    step: 1,
    finishDisabled: true,
    agreementModalOpen: false,
    invite: "",
    firstName: "",
    lastName: "",
    newUser: "",
    employerName: "",
    employerStreet: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    employerPhone: "",
    renewalMonth: "",
    renewalDay: "",
    selectedLocation:"",
    complete: false,
    isLoading: true
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let employerCode = params.get("employer");
    this.getUser();

    if (employerCode) {
      this.handleGetEmployer(employerCode);
    }
  }

  getUser = () => {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then(response => {
          this.setState({
            firstName: response.first_name,
            lastName: response.last_name,
            newUser: response.new_user
          });

          if (!response.new_user) {
            this.setState({ step: 2 });
          }

          resolve(response);
        })
        .catch(err => {
          console.log("in the error flow");
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  updateUser = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        employer_id: this.state.employerID,
        location_id: this.state.selectedLocation
      });
      api
        .updateUser(body)
        .then(response => {
          this.props.history.push("/user/home");
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating User" });
          console.log(err);
        });
    });
  };

  getEmployerByCodeToLink = code => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerByCodeToLink(code)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  getEmployerLocations = employerID => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerLocations(employerID)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  handleGetEmployer = code => {
    this.getEmployerByCodeToLink(code).then(employerResponse => {
      if (employerResponse.employerNotExists) {
        console.log("bad code");
      } else if (employerResponse.historyExists) {
        this.setState({
          isLoading: false,
          step: 2,
          complete: true,
          employer: employerResponse.employer_name
        });
      } else {
        this.getEmployerLocations(employerResponse.employer_id).then(locationResponse => {
          this.setState({
            employer: employerResponse.employer_name,
            employerID: employerResponse.employer_id,
            locations:locationResponse, 
            isLoading: false, 
            step: 2, 
            complete: false})
        });
      }
    });
  };

  step2 = () => {
    this.setState({ step: 2 });
  };

  step3 = () => {
    this.setState({ step: 3 });
  };

  step4 = () => {
    this.setState({ step: 4 });
  };

  finish = () => {
    this.updateUser()
  };

  closeTerms = () => {
    this.setState({ agreementModalOpen: false });
  };
  openTerms = () => {
    this.setState({ agreementModalOpen: true });
  };

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div>
        <Agreement
          isOpen={this.state.agreementModalOpen}
          Close={this.closeTerms}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root_md} elevation={1}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Linking...
              </Typography>
              <div style={{ height: 350, marginTop: 20 }}>
                {this.state.isLoading && (
                  <div style={{ height: 250, marginTop: 100 }}>
                    <Typography variant="body1">
                      Checking invitation...
                    </Typography>
                    <br />
                    <br />
                    <CircularProgress className={classes.progress} />
                  </div>
                )}
                {!this.state.isLoading && this.state.complete && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      Your account is already linked to {this.state.employer}!
                    </Typography>
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.finish}
                        style={{ width: 250 }}
                      >
                        Continue
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 2 && (
                    <React.Fragment>
                      <Typography variant="subtitle2" color="primary">
                        Select your location from {this.state.employer}
                      </Typography>
                      <FormControl margin="dense">
                        <InputLabel htmlFor="location">Location</InputLabel>
                        <Select
                          value={this.state.selectedLocation}
                          onChange={e => {
                            this.setState({
                              selectedLocation: e.target.value
                            });
                          }}
                          inputProps={{
                            name: "location",
                            id: "location"
                          }}
                          style={{ width: 200 }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {this.state.locations.map((location, index) => {
                            return (
                              <MenuItem key={index} value={location.id}>
                                {location.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div style={{ textAlign: "right", marginTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.finish}
                          style={{ width: 100 }}
                        >
                          Finish
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                <br />
              </div>
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

EmployerSetup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployerSetup);