import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
//import orange from '@material-ui/core/colors/orange';

import pink from "@material-ui/core/colors/pink";
//import purple from '@material-ui/core/colors/purple';
//import yellow from '@material-ui/core/colors/yellow';

export default createMuiTheme({
  palette: {
    primary: {
      light: blue[600],
      //main: blue[800],
      main: "#2996cc",
      dark: blue[1000]
    },
    disabled: grey,
    secondary: {
      light: '#FF9C29',
      main: '#f57921',
      dark: '#FF9C29',
    },
    error: red
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 24px 4px 16px"
      }
    }
  }
});
