import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from '@material-ui/core/ListSubheader';

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

import { closeEditRecipient, setRecipientTable } from "../Redux/Actions/index";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const initialState = {
  status: NOT_LOADED,
  employer: "",
  dateAdded: "",
  dateSent: "",
  dateOpened: "",
  isClosed: false,
  isArchived: false,
  form: "",
  email: "",
  submissionStatus: "",
  brokers: []
};

class SubmissionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.Open &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING &&
      this.props.Submission
    ) {
      this.setState({ status: IS_LOADING });
      this.getCarrierSubmissionDetails();
    }
  }

  getCarrierSubmissionDetails = () => {
    return new Promise((resolve, reject) => {
      api
        .getCarrierSubmissionDetails(this.props.Submission)
        .then(response => {
          console.log(response);
          var brokers = response.map((sub, index) => {
            if (!index) {
              this.setState({
                employer: sub.employer.name,
                dateAdded: sub.date_added
              });
            }
            return sub.customer.name;
          });
          console.log(brokers);
          brokers.push('Joe')
          brokers.push('Bill')
          brokers.push('Joe')
          brokers.push('Joe')
          brokers.push('Joe')
          this.setState({
            /*
            employer: response.employer,
            dateAdded: response.submission.date_added,
            dateSent: response.submission.date_sent,
            dateOpened: response.submission.date_opened,
            isClosed: response.submission.is_closed,
            isArchived: response.submission.is_archived,
            form: response.form.name,
            email: response.submission.email,
            submissionStatus: response.submission.status,
            */
            brokers: brokers,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Recipient" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  render() {
    const { fullScreen, Open, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Submission Details</DialogTitle>

          <DialogContent style={{ height: 350, width: 400 }}>
            <Typography variant="subtitle2" style={{ marginTop: 20 }}>
              Employer: {this.state.employer}
            </Typography>

            <Typography variant="subtitle2" style={{ marginTop: 20 }}>
              Date Added: {this.state.dateAdded}
            </Typography>

            <Typography variant="subtitle2" style={{ marginTop: 20 }}>
              Brokers: 
            </Typography>
            <div style={{maxHeight:200, overflow: 'scroll'}}>

            <List
              dense={true}
            >
              {this.state.brokers.map( (b,index) => {
                return(
                <ListItem key={index}>
                  <ListItemText primary={b} />
                </ListItem>
              )
              })}
            </List>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(SubmissionDetail));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));
