import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    states: state.StateReducer.states,
  };
};


const initialState = {
  status : NOT_LOADED,
  code: "",
  name: "",
  billingPhone: "",
  primaryPhone: "",

  street: "",
  city:"",
  zip: "", 

  stateID: "",
  countyID: "",

  states: [],
  counties : []
};

class EditInfo extends React.Component {
  state={}

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      //this.setState({ status: IS_LOADING,});
      this.getBroker().then( () => {
        //need to get counties for state if no default county is set for agency
        if(this.state.stateID !== "" )
          this.getCounties().then(counties => {
            this.setState({ counties: counties });
          });
      })
    }
  }

  getBroker = () => {
    console.log(this.props.states)
    return new Promise((resolve, reject) => {
      api
        .getBroker(this.props.auth.customer_id)
        .then(response => {
          this.setState({
            code: response.code,
            name: response.name,
            billingPhone: response.billing_phone,
            primaryPhone: response.primary_phone,
            street: response.street,
            city: response.city, 
            zip : response.zip, 
            stateID : response.state_id ? response.state_id : "",
            countyID: response.county_id ? response.county_id : "",
            status : IS_LOADED

          }, resolve("done"))
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Broker" });
          console.log(err);
        });
    });
    
  };

  updateBroker = () => {
    var body = JSON.stringify({
      code : this.state.code,
      name : this.state.name,
      billing_phone : this.state.billingPhone,
      primary_phone : this.state.primaryPhone,
      street : this.state.street,
      city : this.state.city,
      zip : this.state.zip,
      state_id : this.state.stateID ? this.state.stateID : null,
      county_id : this.state.countyID ? this.state.countyID : null,
    });

    return new Promise((resolve, reject) => {
      api
        .updateBroker(this.props.auth.customer_id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Broker" });
          console.log(err);
        });
    });
  };

  getCounties = () => {
    return new Promise((resolve, reject) => {
      api
        .getCounties(this.state.stateID)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Counties" });
          console.log(err);
        });
    });
  };  

  close = () => {
    this.reset();
    this.props.close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    this.updateBroker().then(r => {
      console.log(r)
      console.log('JUST UPDATE')
      this.props.updateCustomerCode(this.state.code)
      this.close();
    });
  };

  handleStateChange = (event) => { 
    this.setState({ stateID: event.target.value }, () => {
      this.getCounties().then(counties => {
        this.setState({ counties: counties });
      });
    });
  }

  render() {
    const { fullScreen, isOpen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Agency Information
          </DialogTitle>
          <DialogContent style={{ height: 550, width: 400 }}>
            <TextField
              id="standard-dense"
              label="Agency Name"
              value={this.state.name}
              margin="dense"
              onChange={e => this.setState({ name: e.target.value })}
              fullWidth
            />
            <Typography variant="caption" style={{marginTop: 15, marginBottom: 15}}>
            The invitation code is used by empoloyers who wish to add your agency as their broker.
            </Typography>
            <TextField
              id="standard-dense"
              label="Agency Code"
              fullWidth
              value={this.state.code}
              margin="dense"
              onChange={e => this.setState({ code: e.target.value })}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="Billing Phone"
              fullWidth
              value={this.state.billingPhone}
              margin="dense"
              onChange={e => this.setState({ billingPhone: e.target.value })}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="Primary Phone"
              fullWidth
              value={this.state.primaryPhone}
              margin="dense"
              onChange={e => this.setState({ primaryPhone: e.target.value })}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="Street"
              fullWidth
              value={this.state.street}
              margin="dense"
              onChange={e => this.setState({ street: e.target.value })}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="City"
              fullWidth
              value={this.state.city}
              margin="dense"
              onChange={e => this.setState({ coty: e.target.value })}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="Street"
              fullWidth
              value={this.state.zip}
              margin="dense"
              onChange={e => this.setState({ zip: e.target.value })}
            />
            <br/>

            <FormControl margin="dense">
              <InputLabel htmlFor="states">State</InputLabel>
              <Select
                value={this.state.stateID}
                onChange={this.handleStateChange}
                inputProps={{
                  name: "state",
                  id: "state"
                }}
                style={{ width: 70, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.states.map(state => {
                  return (
                    <MenuItem value={state.id} key={"state_" + state.id}>
                      {state.abbreviation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl margin="dense">
              <InputLabel htmlFor="county">County</InputLabel>
              <Select
                value={this.state.countyID}
                onChange={ (e) => { this.setState({countyID: e.target.value}) } }
                inputProps={{
                  name: "county",
                  id: "county"
                }}
                style={{ width: 150, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.state.counties.map(county => {
                  return (
                    <MenuItem value={county.id} key={"county_" + county.id}>
                      {county.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditInfo.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

EditInfo.defaultProps = {
  isOpen: false
};

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(EditInfo)));
