import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import blue from "@material-ui/core/colors/blue";

import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";

/*
import { connect } from 'react-redux';
import { loadUser } from '../Redux/Actions/index'
*/

const styles = theme => ({});
/*

const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
  }
}

*/

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
    hasChanged: false,
    preferredMFA: "",
    user: {},
    userAttributes: {},
    qrCode: "",
    code: "",
    useSMS: false,
    noMFA: false
  };

  getAttributes = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          user: user,
          preferredMFA: user.preferredMFA,
          userAttributes: user.attributes,
          email: user.attributes.email,
          email_verified: user.attributes.email_verified
        });
        console.log(
          "Dialog - Got attributes - Email: " +
            this.state.email +
            " PreferredMFA: " +
            this.state.user.preferredMFA
        );
      })
      .catch(err => console.log(err));
  };

  sendPhoneCode = () => {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => console.log("a verification code is sent"))
      .catch(err => console.log("failed with error", err));
  };

  addTTOP = () => {
    Auth.setupTOTP(this.state.user).then(code => {
      const authCode =
        "otpauth://totp/" +
        this.state.email +
        "?secret=" +
        code +
        "&issuer=Reform+Health";
      this.setState({ qrCode: authCode });
    });
  };

  setPreferredMFA = authType => {
    Auth.verifyTotpToken(this.state.user, this.state.code).then(() => {
      Auth.setPreferredMFA(this.state.user, authType)
        .then(data => console.log("data from verify...: ", data))
        .catch(err => console.log("error: ", err));
    });
  };

  handleClickOpen = () => {
    this.getAttributes();
    this.setState({ open: true });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    if (this.state.hasChanged) {
      if (this.state.preferredMFA === "SOFTWARE_TOKEN_MFA") {
        Auth.verifyTotpToken(this.state.user, this.state.code).then(() => {
          Auth.setPreferredMFA(this.state.user, "TOTP")
            .then(data => {
              this.props.enqueueSnackbar(
                "Account secured with Software Token",
                {
                  variant: "success"
                }
              );
              this.props.handleSave();
            })
            .catch(err => {
              this.props.enqueueSnackbar(err, {
                variant: "error"
              });
            });
        });
      } else if (
        this.props.userAttributes.phone_number_verified &&
        this.state.preferredMFA === "SMS_MFA"
      ) {
        //if verified phone number switch
        Auth.setPreferredMFA(this.state.user, "SMS").then(r => {
          this.props.enqueueSnackbar("Account secured with SMS Code", {
            variant: "success"
          });
          this.props.handleSave();
        });
      } else {
        if (this.state.user.preferredMFA === "SOFTWARE_TOKEN_MFA") {
          //if you want to disable, but you PREVIOUSLY had a software code, you
          // need to enter it here
          Auth.verifyTotpToken(this.state.user, this.state.code).then(() => {
            Auth.setPreferredMFA(this.state.user, "NOMFA")
              .then(data => {
                this.props.enqueueSnackbar(
                  "Account set with no extra security",
                  {
                    variant: "warning"
                  }
                );
                this.props.handleSave();
              })
              .catch(err => console.log("error: ", err));
          });
        } else {
          Auth.setPreferredMFA(this.props.user, "NOMFA").then(r => {
            this.props.enqueueSnackbar("Account set with no extra security", {
              variant: "warning"
            });
            this.props.handleSave();
          });
        }
      }
    }

    this.setState({ open: false });
  };

  handleMFATypeChange = event => {
    if (event.target.value === this.state.preferredMFA) {
      this.setState({ hasChanged: false });
    } else {
      console.log("Dialog - option has changed");
      if (event.target.value === "SOFTWARE_TOKEN_MFA") {
        this.addTTOP();
      }
      this.setState({ hasChanged: true });
    }
    this.setState({ preferredMFA: event.target.value });
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" size="small">
          Edit
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
          >
            <Typography
              variant="body2"
              color="inherit"
            >
              Multi-Factor Authentication Settings
            </Typography>
          </DialogTitle>
          <DialogContent>
            <br />
            <FormControl>
              <InputLabel htmlFor="relationship-type-simple">
                MFA Type
              </InputLabel>
              <Select
                value={this.state.preferredMFA}
                onChange={this.handleMFATypeChange}
                inputProps={{
                  name: "MFAType",
                  id: "MFA-type-simple"
                }}
                style={{ width: 150, marginRight: 10 }}
              >
                <MenuItem value={"NOMFA"}>Disabled</MenuItem>
                <MenuItem value={"SOFTWARE_TOKEN_MFA"}>Software Token</MenuItem>
                <MenuItem value={"SMS_MFA"}>SMS</MenuItem>
              </Select>
            </FormControl>
            {this.state.hasChanged &&
              this.state.preferredMFA === "SOFTWARE_TOKEN_MFA" && (
                <div style={{ maxWidth: 400 }}>
                  <br />
                  <Typography variant="body1">
                    Use a Software Token app such as Google Authenticator or
                    Authy to scan this code, then enter the code in the field
                    below.
                  </Typography>
                  <br />
                  <QRCode size={256} value={this.state.qrCode} />
                  <br />
                  <br />
                </div>
              )}
            {this.state.hasChanged && this.state.preferredMFA === "SMS_MFA" && (
              <div style={{ maxWidth: 400 }}>
                <br />
                <Typography variant="body1">
                  {(this.props.userAttributes.phone_number_verified && (
                    <React.Fragment>
                      SMS codes will sent to{" "}
                      {this.state.userAttributes.phone_number}.
                    </React.Fragment>
                  )) || (
                    <React.Fragment>
                      You must enter <u>and</u> verify your cell phone number in
                      order to use SMS Multi-Factor Authentication.
                    </React.Fragment>
                  )}
                </Typography>
              </div>
            )}

            {this.state.hasChanged &&
            (this.state.user.preferredMFA === "SOFTWARE_TOKEN_MFA" || //old
              this.state.preferredMFA === "SOFTWARE_TOKEN_MFA") && ( //or new requires code
                <div>
                  <br />
                  <Typography variant="body1">
                    Enter the code from your Software Token App.
                  </Typography>
                  <TextField
                    id="standard-dense"
                    label={this.props.label}
                    margin="dense"
                    onChange={e =>
                      this.setState({ code: e.target.value, hasChanged: true })
                    }
                    defaultValue={""}
                    style={{ width: 200 }}
                  />
                </div>
              )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialog);
//export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(ResponsiveDialog)));