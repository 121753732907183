import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { styles } from "./Styles/AuthStyles";

import { Auth } from "aws-amplify";

class ForgotPassword extends React.Component {
  state = {
    open: false,
    email: "",
    showConfirmation: false,
    deliveryDetails: {},
    data: {},
    deliveryMedium: "",
    destination: ""
  };

  sendCode = () => {
    const username = this.state.email;
    Auth.forgotPassword(username)
      .then(data => {
        //this.setState({ deliveryMedium: data.CodeDeliveryDetails.DeliveryMedium, destination: data.CodeDeliveryDetails.Destination }),
        this.setState({ showConfirmation: true });
      })
      .catch(err => console.log("error resetting password: ", err));
  };

  changePassword = () => {
    const username = this.state.email;
    Auth.forgotPasswordSubmit(username, this.state.code, this.state.password)
      .then(data => {
        console.log(data);
        this.props.enqueueSnackbar("Password Changed Successfully", {
          variant: "success"
        });
        this.redirectUser();
        //this.props.history.push("/"); // + this.props.app + "/auth");
      })
      .catch(err => console.log(err));
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  Next = () => {
    //this.sendCode();
    this.changePassword();
    //this.setState({showConfirmation: true})
  };

  redirectUser = user => {
    console.log(this.props)
    var app = this.props.app;
    this.props.action('signIn')
  };

  render() {
    const { showConfirmation } = this.state;
    const { classes } = this.props;

    return (
      <div>
        {!showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                Reset Password
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter your email to receive a temporary code which you can use reset your
                Reform Health password.
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-email-dense"
                label="Email"
                className={classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                margin="dense"
                onChange={e => this.setState({ email: e.target.value })}
                defaultValue={this.state.email}
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={() => this.props.action("signIn")}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.sendCode}
                  style={{ width: 100 }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                Confirmation
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter the code you received via text message or from your
                authenticator application. {this.state.code}
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={e => this.setState({ code: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <div>
              <TextField
                id="standard-password-input"
                label="Password"
                className={classes.textField}
                type="Password"
                margin="dense"
                onChange={e => this.setState({ password: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <div>
              <TextField
                id="standard-repeat-password-input"
                label="Repeat Password"
                className={classes.textField}
                type="Password"
                margin="dense"
                onChange={e =>
                  this.setState({ repeatPassword: e.target.value })
                }
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={this.Back}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.Next}
                  style={{ width: 170 }}
                >
                  Change my Password
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ForgotPassword);