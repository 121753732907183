import {
    SET_EMPLOYER_TABLE,
    SET_EMPLOYEE_TABLE,
    SET_RECIPIENT_TABLE,
    SET_FORMGROUP_TABLE,
    SET_EMPLOYER_INVITATION_TABLE,

    OPEN_INVITE_EMPLOYER,
    CLOSE_INVITE_EMPLOYER,
    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,
    OPEN_EDIT_RECIPIENT,
    CLOSE_EDIT_RECIPIENT,
    OPEN_EDIT_FORMGROUP,
    CLOSE_EDIT_FORMGROUP,
    LOAD_MESSAGE_SUMMARIES
} from "../Constants/ActionTypes";

export const setEmployerTable = employerTable => ({
    type: SET_EMPLOYER_TABLE,
    payload: employerTable
});
export const setEmployeeTable = employeeTable => ({
    type: SET_EMPLOYEE_TABLE,
    payload: employeeTable
});
export const setRecipientTable = recipientTable => ({
    type: SET_RECIPIENT_TABLE,
    payload: recipientTable
});
export const setFormGroupTable = formGroupTable => ({
    type: SET_FORMGROUP_TABLE,
    payload: formGroupTable
});
export const setEmployerInvitationTable = employerInvitationTable => ({
    type: SET_EMPLOYER_INVITATION_TABLE,
    payload: employerInvitationTable
});

export const openInviteEmployer = () => ({
    type: OPEN_INVITE_EMPLOYER,
    payload: true
});
export const closeInviteEmployer = () => ({
    type: CLOSE_INVITE_EMPLOYER,
    payload: false
});

export const openEditEmployer = () => ({
    type: OPEN_EDIT_EMPLOYER,
    payload: true
});
export const closeEditEmployer = () => ({
    type: CLOSE_EDIT_EMPLOYER,
    payload: false
});

export const openEditRecipient = () => ({
    type: OPEN_EDIT_RECIPIENT,
    payload: true
});
export const closeEditRecipient = () => ({
    type: CLOSE_EDIT_RECIPIENT,
    payload: false
});

export const openEditFormGroup = () => ({
    type: OPEN_EDIT_FORMGROUP,
    payload: true
});
export const closeEditFormGroup = () => ({
    type: CLOSE_EDIT_FORMGROUP,
    payload: false
});

export const loadMessageSummaries = messageSummaries => ({
    type: LOAD_MESSAGE_SUMMARIES,
    payload: messageSummaries
});