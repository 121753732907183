import {
    LOAD_MESSAGE_SUMMARIES,
    SET_NEW_MESSAGE_COUNT,
} from "../Constants/ActionTypes";

const intitialState = {
    messageSummaries : [],
    isMessageSummariesLoaded: false,
    newMessageCount: 0
};

const messageReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_MESSAGE_SUMMARIES:
            return {
                ...state,
                messageSummaries: action.payload,
                isMessageSummariesLoaded: true
            };
        case SET_NEW_MESSAGE_COUNT:
            return {
                ...state,
                newMessageCount: action.payload
            }
        default:
            return state;
    }
};

export default messageReducer;
