import {
    SET_FORM_TABLE,
    SET_FORM_REVISION_TABLE
} from '../Constants/ActionTypes'

const initialState = {
    formTable : {
        selected : {},
        filters : {},
        sort : {}
    },
    formRevisionTable: {
        selected : {},
        filters : {},
        sort : {}
    }

}

const TableReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_FORM_TABLE:
            return {...state, formTable : action.payload }
        case SET_FORM_REVISION_TABLE: 
            return {...state, formRevisionTable : action.payload }
        default: 
            return state;
    }

}

export default TableReducer