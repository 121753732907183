import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

const initialState = {
  uploadedFile: {
    name: ""
  },
  effectiveStart:"",
  effectiveEnd: "",

};

class FormUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  };

  reset() {
    this.setState(initialState);
  };

  uploadCustomerForm = () => {

    const formData = new FormData();
    formData.append("file", this.state.uploadedFile);

    return new Promise((resolve, reject) => {
      api
        .uploadCustomerForm(this.props.Customer, formData)
        .then(r => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Uploading Form" });
          console.log(err);
        });
    });
  };

  uploadCarrierForm = () => {
    const formData = new FormData();
    formData.append("file", this.state.uploadedFile);
    
    return new Promise((resolve, reject) => {
      api
        .uploadCarrierForm(formData)
        .then(r => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Uploading Form" });
          console.log(err);
        });
    });
  };

  handleClickFormUpload = () => {
    if (this.props.UploadType === "Customer") {
      this.uploadCustomerForm().then(r => {
        this.reset()
      });
    }
    if (this.props.UploadType === "Carrier") {
      this.uploadCarrierForm().then(r => {
        this.reset()
      });
    }
  };

  render() {
    const { fullScreen, classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Upload a Form</DialogTitle>

          <DialogContent style={{ height: 300, width: 400 }}>
            <React.Fragment>
              {this.state.uploadedFile.name !== "" && (
                <React.Fragment>
                  <div>
                    {String(this.state.uploadedFile.name)}
                  </div>
                </React.Fragment>
              )}
              <input
                accept="pdf/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={e => {
                  console.log(e.target.files[0]);
                  this.setState({ uploadedFile: e.target.files[0] });
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    marginRight: 40,
                    marginLeft: 0,
                    marginTop: 20,
                    marginBottom: 20
                  }}
                >
                  Select File
                </Button>
              </label>
              {this.state.uploadedFile.name !== "" && (
                <Button
                  onClick={this.handleClickFormUpload}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              )}

              {this.props.UploadType === "Carrier" && (
                <React.Fragment>
                  <br/>
                  <TextField
                    id="date"
                    label="Date Effective Start"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    defaultValue={this.state.effectiveStart}
                    onChange={e => this.setState({ effectiveStart: e.target.value })}
                    style={{ width: 150, marginRight: 10 }}
                    margin="dense"
                  />
                  <br/>
                  <TextField
                    id="date"
                    label="Date Effective End"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    defaultValue={this.state.effectiveEnd}
                    onChange={e => this.setState({ effectiveEnd: e.target.value })}
                    style={{ width: 150, marginRight: 10 }}
                    margin="dense"
                  />
                </React.Fragment>
              )}

            </React.Fragment>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.Close} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FormUploadModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

export default withMobileDialog()(withStyles(styles)(FormUploadModal));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));