import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { Route, withRouter } from "react-router-dom";

import api from "../../Shared/Functions/APIHelpers";
import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class Pin extends React.Component {
  state = {
    pin: ""
  };

  verifyPin = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get("id");
    
    if(this.props.type === 'Medicare'){
      this.verifyMedicarePin(id,this.state.pin).then(result => {
        if(result.verified){
          this.props.isVerified(result, this.state.pin, id)
        }
        else{
          this.props.notVerified()
        }
      })
    }


  };

  verifyMedicarePin = (id, pin) => {
    return new Promise((resolve, reject) => {
      api
        .getPreloadMedicare(id, this.state.pin)
        .then(r => {
          console.log(r)
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Medicare" });
          console.log(err);
        });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <TextField
          id="standard-dense"
          label="Pin"
          margin="dense"
          onChange={e => {
            this.setState({ pin: e.target.value });
          }}
          value={this.state.pin}
        />
        <Button id="verifyPinButton" onClick={this.verifyPin}>
          Verify
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Pin);