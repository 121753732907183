import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { Route, withRouter } from "react-router-dom";

import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppBar from "../Nav/AppBar";
import NavDrawer from "../Nav/NavDrawer";


class HomeContainer extends React.Component {
  state = {
    NavOpen: false
  };

  componentDidUpdate(prevProps) {}

  toggleNavDrawer = () => {
    //console.log('open nav')
    this.setState({ NavOpen: !this.state.NavOpen });
  };

  render() {
    const { component: Component, classes, ...rest } = this.props;

    return (
      <div
        className={classNames(classes.basecontent, {
          [classes.basecontentFixed]: this.props.fixed
        })}
      >
        <header>
          <AppBar
            history={this.props.history}
            openNavDrawer={this.toggleNavDrawer}
            reformUser={this.props.reformUser}
            user={this.props.user}
            home={this.props.home}
            settingsMenu={this.props.settingsMenu}
            newMessageCount={this.props.newMessageCount}
            setNewMessageCount={this.props.setNewMessageCount}
            messageSummaries={this.props.messageSummaries}
            hasMessageBar={this.props.hasMessageBar}
            hasMessageMenu={this.props.hasMessageMenu}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
          <NavDrawer
            history={this.props.history}
            reformUser={this.props.reformUser}
            navDrawerOpen={this.state.NavOpen}
            closeNavDrawer={this.toggleNavDrawer}
            navList={this.props.navList}
          />
        </header>
        <Paper
          className={classNames(classes.baseroot, {
            [classes.baserootFixed]: this.props.fixed
          })}
          elevation={1}
          square={true}
          
        >
          {!this.props.isLoaded && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 300
              }}
            >
              <div>
                <CircularProgress className={classes.progress} />
              </div>
            </div>
          )}
          {this.props.isLoaded && <Component {...rest} />}
        </Paper>
      </div>
    );
  }
}

HomeContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(HomeContainer));
