import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import InvitationsTable from "./InvitationsTable";
import Paper from "@material-ui/core/Paper";

import { AddIcon } from "../../Shared/Components/Icons";

import { connect } from "react-redux";
import { openEditFormGroup } from "../Redux/Actions/index";

class InvitationsContainer extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <InvitationsTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

InvitationsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (withStyles(styles)(InvitationsContainer));
