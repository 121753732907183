import config from "../../config";

export const getMe = Auth => {
 
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/me", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            reject(err)
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const getMeBroker = Auth => {
 
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/me-broker", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            reject(err)
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
}; 

export const getMeEmployer = Auth => {
 
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/me-employer", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            reject(err)
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
}; 

export const getEmployer = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
      getMeEmployer(Auth).then(me => {
        user.user_id = me.user_id;
        user.profile_id = me.profile_id;
        user.employer_id = me.employer_id;
        user.employer_code = me.employer_code;
        user.employer_name = me.employer_name;
        user.quoting_end_date = me.quoting_end_date;
        user.customer_id = me.customer_id;
        user.setup_complete = me.setup_complete;
        resolve(user);
      })
      .catch(err => {
        reject(err)
      });
    });
  });
}; 
export const getBroker = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
      getMeBroker(Auth).then(me => {
        user.user_id = me.user_id;
        user.profile_id = me.profile_id;
        user.employer_id = me.employer_id;
        user.customer_id = me.customer_id;
        user.customer_code = me.customer_code;
        user.setup_complete = me.setup_complete;
        resolve(user);
      })
      .catch(err => {
        reject(err)
      });
    });
  });
}; 
export const getUser = Auth => {
  console.log('in the get user')
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
      getMe(Auth).then(me => {
        user.user_id = me.user_id;
        user.profile_id = me.profile_id;
        user.employer_id = me.employer_id;
        user.customer_id = me.customer_id;
        user.setup_complete = me.setup_complete;
        resolve(user);
      })
      .catch(err => {
        reject(err)
      });
    });
  });
};

export const getCarriers = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/carriers", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const getConditionCategories = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/categories-conditions", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const getStates = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/states", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const getForms = Auth => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(config.apiGateway.URL + "/api/v1/forms", {
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/coreapi+json"
          }),
          method: "GET",
          mode: "cors"
        })
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};
