import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";

import api from "../../Functions/APIHelpers";

import EditOverride from "./EditOverride";

import { connect } from "react-redux";

import { Document, Page } from "react-pdf";

import { Storage } from "aws-amplify";
import { Auth } from "aws-amplify";

import config from "../../../config";

const styles = theme => ({
  overrideDiv: { 
    float: "right", 
    '@media only screen and (max-width: 400px)': {
      display: 'none',
    },
  }
});

class ReviewContainer extends React.Component {
  state = {
    numPages: null,
    showOverrides: false,
    canvasWidth: 0,
    canvasHeight: 0,
    pageHeight: 0,
    pageWidth: 0,
    pageNumber: 1,
    formNumber: 1,
    editModalOpen: false,
    isLoaded: false,
    override: {},
    field: "",
    fieldName: "",
    fieldType: "",
    overrides: [],
    fit: "width"
  };

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    if (this.props.overrides) this.getOverrides();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoaded: true }, () => {
      this.updateDimensions();
    });
  };

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;

    element.scrollIntoView({ behavior: "auto", block: "end" });
  };

  getOverrides = () => {
    return new Promise((resolve, reject) => {
      api
        .getMappingRuleOverrides(this.props.User)
        .then(response => {
          this.setState({ overrides: response });
          resolve(response);
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Getting Mapping Rule Overrides"
          });
          console.log(err);
        });
    });
  };

  maxWidth(width) {

    var maxWidth = 0;
    var margin = 0;

    if (this.props.MaxWidth === 1400) {
      margin = 100;
    } else {
      margin = 300;
    }

    if (width > 1600) {
      maxWidth =  this.props.MaxWidth;
    } else if (width > 850) {
      maxWidth = (width - margin) * 0.9;
    } else if (width > 400) {
      maxWidth =  width * 0.9;
    } else {
      maxWidth =  335;
    }

    if (maxWidth > this.props.MaxWidth) {
      return this.props.MaxWidth;
    } else {
      return maxWidth;
    }

  }

  maxHeight(height) {
    if (height > 1800) {
      return 1350;
    } else if (height > 800) {
      return height - 450;
    } else {
      return 900;
    }
  }

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName("body")[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height =
        w.innerHeight || documentElement.clientHeight || body.clientHeight,
      pageWidth = 0,
      pageHeight = 0;

    this.setState({ width, height });

    var maxWidth = this.maxWidth(width);
    var maxHeight = this.maxHeight(height);

    if (this.state.fit === "width") {
      pageWidth = maxWidth;
      pageHeight = maxWidth * 1.2928;
    } else {
      if (maxHeight * 0.773 < maxWidth && maxWidth > 330) {
        pageHeight = maxHeight;
        pageWidth = maxHeight * 0.773;
      } else {
        pageWidth = maxWidth;
        pageHeight = maxWidth * 1.2928;
      }
    }

    var canvasHeight = (pageHeight + 12) * this.state.numPages;
    var canvasWidth = pageWidth + 2;

    this.setState(
      {
        pageHeight: pageHeight,
        pageWidth: pageWidth,
        canvasHeight: canvasHeight,
        canvasWidth: canvasWidth
      },
      () => {
        this.highlightOverrides();
      }
    );
  };

  renderHighlights = (fields, action, e) => {
    if (e) {
      var xCoor = e.nativeEvent.offsetX;
      var yCoor = e.nativeEvent.offsetY;
    }

    var scale = 1.465 * (this.state.canvasWidth / 900);
    var pageSize = this.state.pageHeight;

    var highlight_layer = this.refs.highlight_layer;
    const ctx = this.refs.highlight_layer.getContext("2d");

    var overField = false;

    for (var i = 0; i < fields.length; i++) {
      var left = fields[i].left;
      var top = fields[i].top;

      var height = fields[i].height;
      var width = fields[i].width;

      var preceding_page_height = (pageSize + 14) * (fields[i].page_number - 1);
      var page_location = pageSize - top * scale;
      var top_resized = preceding_page_height + page_location;

      var left_resized = left * scale + 2;

      var height_resized = height * scale;
      var width_resized = width * scale;

      var right_resized = left_resized + width_resized;
      var bottom_resized = top_resized + height_resized;

      if (
        e &&
        (xCoor > left_resized &&
          xCoor < right_resized &&
          yCoor > top_resized &&
          yCoor < bottom_resized)
      ) {
        overField = true;

        if (action === "HighlightSingle") {
          ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
          ctx.fillStyle = "rgba(41, 150, 204, 0.33)";
          ctx.fillRect(
            left_resized,
            top_resized,
            width_resized,
            height_resized
          );
        } else if (action === "Click") {
          var override = this.state.overrides.find(
            override => override.field.id === fields[i].id
          );

          if (override) {
            this.setState({
              isEdit: true,
              override: override
            });
          } else {
            this.setState({
              isEdit: false,
              override: {}
            });
          }

          this.setState({
            fieldID: fields[i].id,
            fieldName: fields[i].name,
            fieldType: fields[i].field_type,
            editModalOpen: true
          });
        }
      } else if (action == "HighlightAll") {
        ctx.fillStyle = "rgba(41, 150, 204, 0.33)";
        ctx.fillRect(left_resized, top_resized, width_resized, height_resized);
      }
    }

    if (!overField) {
      if (action == "HighlightSingle") {
        ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
      }
    }
  };

  onMouseMove = e => {
    if (!this.props.overrides || this.props.fields.length === 0) return;
    var fields = this.props.fields;

    var highlight_layer = this.refs.highlight_layer;
    const ctx = this.refs.highlight_layer.getContext("2d");

    this.renderHighlights(fields, "HighlightSingle", e);

    this.highlightOverrides();
  };

  onClick = e => {
    if (!this.props.overrides || this.props.fields.length === 0) return;
    var fields = this.props.fields;
    var xCoor = e.nativeEvent.offsetX;
    var yCoor = e.nativeEvent.offsetY;

    this.renderHighlights(fields, "Click", e);
  };

  clearHighlights() {
    var highlight_layer = this.refs.highlight_layer;
    const ctx = this.refs.highlight_layer.getContext("2d");

    ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
  }

  highlightOverrides() {
 
    if (!this.state.showOverrides) {
      return;
    }

    var fields = this.props.fields;
    var overrides = this.state.overrides;

    var filtered_fields = fields.filter(function(item) {
      return overrides.map(f => f.field.id).includes(item.id);
    });

    this.renderHighlights(filtered_fields, "HighlightAll", null);
  }

  reload = () => {
    console.log('Calling Reload in Form Viewer')
    this.getOverrides().then(r=> {
      this.props.Reload();
    });
    
  };

  handleClose = () => {
    this.setState({ editModalOpen: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });

    if (name !== "showOverrides") {
      if (this.state.fit === "width") {
        this.setState(
          {
            fit: "height"
          },
          () => {
            this.updateDimensions();
          }
        );
      } else {
        this.setState(
          {
            fit: "width"
          },
          () => {
            this.updateDimensions();
          }
        );
      }
      this.updateDimensions();
    } else {
      if (this.state.showOverrides) {
        this.setState(
          {
            showOverrides: false
          },
          () => {
            this.clearHighlights();
          }
        );
      } else {
        this.setState(
          {
            showOverrides: true
          },
          () => {
            this.highlightOverrides();
          }
        );
      }
    }
  };

  createPages = (numPages, width) => {
    var pages = [];

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div
          key={"page_" + i}
          style={{ border: "1px solid #f2f2f2", marginBottom: 10 }}
        >
          <Page
            pageNumber={i}
            width={width}
            renderTextLayer={false}
            loading={<CircularProgress />}
          />
        </div>
      );
    }

    return pages;
  };

  render() {
    const { pageNumber, numPages } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <EditOverride
          isOpen={this.state.editModalOpen}
          isEdit={this.state.isEdit}
          ReloadOverrides={this.getOverrides}
          ReloadForm={this.reload}
          Close={this.handleClose}
          Override={this.state.override}
          fieldID={this.state.fieldID}
          fieldName={this.state.fieldName}
          fieldType={this.state.fieldType}
        />

        <div style={{ textAlign: "center" }}>
          <div
            id="myRef1"
            ref="myRef1"
            style={{ height: 0, marginTop: 0, display: "block" }}
          />

          <div
            style={{
              marginBottom: 20,
              display: this.state.isLoaded ? "none" : "block"
            }}
          >
            <CircularProgress style={{ marginTop: 20 }} />
            <Typography variant="body1" style={{ marginTop: 30 }}>
              Loading forms...
            </Typography>
          </div>

          {/*Use inline-block to keep aligned with form */}
          <div
            style={{
              border: "0px solid #f2f2f2",
              width: "100%",
              verticalAlign: "middle",
              marginBottom: 0,
              position: "relative",
              textAlign: "center",
              display: this.state.isLoaded ? "block" : "none"
            }}
          >
            <div style={{ display: "inline-block", width: "100%" }}>
              <div style={{ float: "left" }}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: 12, marginRight: 15 }}
                  >
                    Reviewing form {this.props.FormNumber} of{" "}
                    {this.props.FormTotal}
                  </Typography>
                  <div className={classes.mobile}>
                    <Typography variant="caption">
                      Fit Height
                      <Switch
                        defaultChecked
                        value="fit"
                        color="default"
                        onChange={this.handleChange("fit")}
                      />
                      Fit Width
                    </Typography>
                  </div>
                </div>
              </div>
              {this.props.overrides && (
                <div className={classes.overrideDiv}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.showOverrides}
                        onChange={this.handleChange("showOverrides")}
                        value="showOverrides"
                        color="primary"
                      />
                    }
                    label="Highlight Overrides"
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              border: "0px solid #f2f2f2",
              width: this.state.canvasWidth,
              height: this.state.canvasHeight,
              marginBottom: 10,
              position: "relative",
              textAlign: "center",
              display: "inline-block"
            }}
          >
            <div style={{ display: this.state.isLoaded ? "block" : "none" }}>
              <canvas
                ref="highlight_layer"
                width={this.state.canvasWidth}
                height={this.state.canvasHeight}
                style={{ position: "absolute", left: 0, top: 0, zIndex: 500 }}
                onMouseMove={this.onMouseMove}
                onClick={this.onClick}
              />

              <Document
                id="doc_id"
                style={{ position: "absolute", left: 0, top: 0 }}
                file={this.props.Form.url}
                loading={<CircularProgress />}
                onLoadSuccess={this.onDocumentLoad}
              >
                {this.createPages(this.state.numPages, this.state.pageWidth)}
              </Document>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReviewContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReviewContainer);
