import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";

import { AddIcon } from "../../Shared/Components/Icons";

import Menu from "@material-ui/core/Menu";

import IconButton from "@material-ui/core/IconButton";

import CollapseLeft from "@material-ui/icons/ChevronLeft";

import blue from "@material-ui/core/colors/blue";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";
import EmploymentHistoryModule from "./EmploymentHistoryModule";
import AddressModule from "./AddressModule";

import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Clear";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/HighlightOff";

import api from "../../Shared/Functions/APIHelpers";

import { Auth } from "aws-amplify";

import config from "../../config";

import { connect } from "react-redux";

import {
  addProfile,
  updateProfile,
  deleteProfile,
  closeEditProfile,
  openAddProfile,
  clearActiveProfile,
  setActiveProfile,
  updateAddress,
  addAddress,
  deleteAddress
} from "../Redux/Actions/index";

const styles = theme => ({
  title: {
    backgroundColor: "#2996cc",
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 2
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  }
});

const mapDispatchToProps = dispatch => {
  return {
    addProfile: profile => dispatch(addProfile(profile)),
    updateProfile: profile => dispatch(updateProfile(profile)),
    deleteProfile: profileID => dispatch(deleteProfile(profileID)),
    closeEditProfile: () => dispatch(closeEditProfile()),
    openAddProfile: () => dispatch(openAddProfile()),
    clearActiveProfile: () => dispatch(clearActiveProfile()),
    setActiveProfile: profileID => dispatch(setActiveProfile(profileID)),
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    activeProfile: state.ProfilesReducer.activeProfile,
    profiles: state.ProfilesReducer.profiles,
    profileModalOpen: state.UIReducer.profileModalOpen,
    isEdit: state.UIReducer.isEdit,
    isAdd: state.UIReducer.isAdd
  };
};

const initialState = {
  firstName: "",
  coverMedical: true,
  lastName: "",
  dateOfBirth: "",
  heightFeet: "",
  heightInches: "",
  relationshipType: "",
  sex: "",
  phone: "",
  addressID: "",
  weight: "",
  ssn: "",
  errorMessage: "",
  confirmAlertOpen: false,
  disableButtons: false,
  isAdd: false
};

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeProfile.id !== prevProps.activeProfile.id) {
      if (this.props.activeProfile.id !== undefined) {
        this.setState({
          coverMedical: this.props.activeProfile.cover_medical
            ? this.props.activeProfile.cover_medical
            : false,
          firstName: this.props.activeProfile.first_name,
          lastName: this.props.activeProfile.last_name,
          ssn: this.props.activeProfile.ssn,
          relationshipType: this.props.activeProfile.relationship_type
            ? this.props.activeProfile.relationship_type
            : "",
          sex: this.props.activeProfile.sex,
          heightFeet: (this.props.activeProfile.height_feet >= 0)
            ? this.props.activeProfile.height_feet
            : "",
          heightInches: (this.props.activeProfile.height_inches >= 0 )
            ? this.props.activeProfile.height_inches
            : "",
          addressID: this.props.activeProfile.user_address
            ? this.props.activeProfile.user_address.id
            : null,
          dateOfBirth: this.props.activeProfile.date_of_birth,
          weight: this.props.activeProfile.weight,
          phone: this.props.activeProfile.phone
        });
      } else {
        this.reset();
      }
    }

    //add dummy profile when opening for a new dependent
    if( (this.props.profileModalOpen && !prevProps.profileModalOpen) && this.props.activeProfile.id === undefined) {
    {
      this.addDependent().then( r => {
        console.log(r)
        this.props.setActiveProfile(r)
        this.setState({isAdd : true})
      })
    }
    }
  }

  updateDependent = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        cover_medical: this.state.coverMedical,
        relationship_type: this.state.relationshipType,
        set_ssn: this.state.ssn,
        sex: this.state.sex,
        height_feet: (this.state.heightFeet >= 0 ) ? this.state.heightFeet : null,
        height_inches: (this.state.heightInches >= 0) ? this.state.heightInches : null,
        date_of_birth: this.state.dateOfBirth,
        phone: this.state.phone,
        weight: this.state.weight,
        user_address_id:
          this.state.addressID !== "" ? this.state.addressID : null
      });
      api
        .updateProfile(this.props.activeProfile.id, body)
        .then(response => {
          console.log(response)
          this.props.updateProfile({
            id: response.id,
            ssn: response.ssn,
            first_name: response.first_name,
            last_name: response.last_name,
            cover_medical: response.cover_medical,
            relationship_type: response.relationship_type,
            sex: response.sex,
            height_feet: response.height_feet,
            height_inches: response.height_inches,
            date_of_birth: response.date_of_birth,
            phone: response.phone,
            weight: response.weight,
            user_address: response.user_address
          });
          resolve("done");
        })
        .catch(err => {
          this.props.enqueueSnackbar("Error Updating User", {
            variant: "error"
          });
          console.log(err);
        });
    });
  };

  addDependent = () => {

    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        first_name: this.state.firstName ? this.state.firstName : null,
        last_name: this.state.lastName ? this.state.lastName : null,
        cover_medical: this.state.coverMedical,
        relationship_type: this.state.relationshipType ? this.state.relationshipType : 3,
        set_ssn: this.state.ssn ? this.state.ssn : "",
        sex: this.state.sex ? this.state.sex : null,
        height_feet: this.state.heightFeet ? this.state.heightFeet : null,
        height_inches: this.state.heightInches ? this.state.heightInches : null,
        date_of_birth: this.state.dateOfBirth ? this.state.dateOfBirth : null,
        phone: this.state.phone ? this.state.phone : null,
        weight: this.state.weight ? this.state.weight : null,
        user_address_id:
          this.state.addressID !== "" ? this.state.addressID : null
      });
      api
        .addProfile(this.props.auth.user_id, body)
        .then(response => {
          this.props.addProfile({
            id: response.id,
            ssn: response.ssn,
            first_name: response.first_name,
            last_name: response.last_name,
            cover_medical: response.cover_medical,
            relationship_type: response.relationship_type,
            sex: response.sex,
            height_feet: response.height_feet,
            height_inches: response.height_inches,
            date_of_birth: response.date_of_birth,
            phone: response.phone,
            weight: response.weight,
            user_address: response.user_address
          });
          resolve(response.id);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding User" });
          console.log(err);
        });
    });
    
  };

  deleteDependent = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteProfile(this.props.activeProfile.id)
        .then(r => {
          this.props.deleteProfile(this.props.activeProfile.id);
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting User" });
          console.log(err);
        });
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  close = () => {
    this.reset();
    this.props.closeEditProfile();
    this.props.clearActiveProfile();
  };

  handleDelete = () => {
    this.deleteDependent().then(r => {
      this.close();
    });
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  handleCancel = () => {
    if(this.state.isAdd){
      //before cancelling, if a new profile has just been added, we want to delete it on cancel
      // since its a dummy
      this.deleteDependent().then(r => {
        this.close();
      });
    }
    else{
      this.close();
    }
    
  };

  handleSave = () => {
    this.checkFormState(); //show appropriate warnings
    if (this.props.isEdit) {
      this.updateDependent().then(r => {
        this.props.clearActiveProfile();
        this.close();
      });
    } else {
      this.addDependent().then(r => {
        this.props.clearActiveProfile();
        this.close();
      });
    }
  };

  checkFormState = () => {
    var message = ""
    if (this.state.addressID === null) {
      message = message + "No Address Selected"
    }


    if(message != ""){
      var header = "Your Profile Saved, but the following warnings occurred:\n "
      var test = header + message
       this.props.enqueueSnackbar(header+ "\n" +message, {
        variant: "warning"
      });
    }
  };

  handleAddressChange = event => {
    this.setState({ address: event.target.value });
  };

  handleSetAddress = address => () => {
    this.setState({
      addressObject: address,
      showMenu: true
    });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleAddressSelected = selectedAddressID => {
    this.setState({ addressID: selectedAddressID });
  };

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  scrollToMyRef2 = () => {
    var element = this.refs.myRef2;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  toggleButtons = isDisabled => {
    this.setState({ disableButtons: isDisabled });
  };

  applicantIsWaiving = () => {
    var applicant = this.props.profiles.find(p => p.relationship_type === 1);
    return !applicant.cover_medical;
  };

  showApplicantWaivingMessage = () => {
    if(this.applicantIsWaiving() && this.state.relationshipType !== 1){
      this.props.enqueueSnackbar("You can not elect medical coverage becuase the applicant is waiving", {
        variant: "warning"
      });
    }
  }

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;
    var { coverMedical } = this.state;
    if (this.applicantIsWaiving() && this.state.relationshipType !== 1) {
      coverMedical = false;
    }
    return (
      <div>
        <Dialog
          open={this.props.profileModalOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {(this.state.isAdd && "Add") || "Update"} Family Member
          </DialogTitle>
          <DialogContent>
            <Typography color="secondary">{this.state.errorMessage}</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={coverMedical}
                  onChange={e => {
                    this.showApplicantWaivingMessage()
                    this.setState({ coverMedical: e.target.checked });
                  }}
                  value="coverMedical"
                  color="primary"
                />
              }
              name="coverMedicalSwitch"
              label="Cover Medical"
            />
            <TextField
              id="standard-dense"
              label="First Name"
              name="firstNameTextField"
              margin="dense"
              onChange={e => this.setState({ firstName: e.target.value })}
              defaultValue={this.state.firstName}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="Last Name"
              name="lastNameTextField"
              margin="dense"
              onChange={e => this.setState({ lastName: e.target.value })}
              defaultValue={this.state.lastName}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="SSN"
              name="ssnTextField"
              margin="dense"
              onChange={e => this.setState({ ssn: e.target.value })}
              defaultValue={this.state.ssn}
              fullWidth
            />

            <FormControl margin="dense" name="relationshipTypeSelect">
              <InputLabel htmlFor="relationship-type-simple">
                Relationship Type
              </InputLabel>
              <Select
                value={this.state.relationshipType}
                onChange={this.handleChange("relationshipType")}
                inputProps={{
                  name: "relationshipType",
                  id: "relationship-type-simple"
                }}
                style={{ width: 170, marginRight: 10 }}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Applicant</MenuItem>
                <MenuItem value={2}>Spouse</MenuItem>
                <MenuItem value={3}>Child</MenuItem>
                <MenuItem value={4}>Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="date"
              label="Date of Birth"
              name="dateOfBirthTextField"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={this.state.dateOfBirth}
              onChange={e => this.setState({ dateOfBirth: e.target.value })}
              style={{ width: 150, marginRight: 10 }}
              margin="dense"
            />
            <br />
            <FormLabel component="legend" style={{ marginTop: 20 }}>
              Sex
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="genderRadioGroup"
              value={this.state.sex}
              onChange={this.handleChange("sex")}
            >
              <FormControlLabel
                value="M"
                control={<Radio color="primary" />}
                label="Male"
              />
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                label="Female"
              />
            </RadioGroup>

            <FormControl margin="dense" name="heightFeetSelect">
              <InputLabel htmlFor="height-feet-simple">Height Feet</InputLabel>
              <Select
                value={this.state.heightFeet}
                onChange={this.handleChange("heightFeet")}
                inputProps={{
                  name: "heightFeet",
                  id: "height-feet-simple"
                }}
                style={{ width: 120, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="dense" name="heightInchesSelect">
              <InputLabel htmlFor="height-feet-simple">
                Height Inches
              </InputLabel>
              <Select
                value={this.state.heightInches}
                onChange={this.handleChange("heightInches")}
                inputProps={{
                  name: "heightInches",
                  id: "height-inches-simple"
                }}
                style={{ width: 130, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="standard-dense"
              label="Weight (lbs)"
              name="weightTextField"
              defaultValue={this.state.weight}
              type="text"
              margin="dense"
              style={{ width: 100 }}
              onChange={e => this.setState({ weight: e.target.value })}
            />

            <TextField
              id="standard-dense"
              defaultValue={this.state.phone}
              name="phoneTextField"
              label="Phone"
              margin="dense"
              onChange={e => this.setState({ phone: e.target.value })}
              fullWidth
            />

            
            <EmploymentHistoryModule
              ScrollToBottom={this.scrollToMyRef1}
              Profile={this.props.activeProfile.id}
              ToggleButtons={this.toggleButtons}
            />
            <div
              id="myRef1"
              ref="myRef1"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block"
              }}
            />
            <AddressModule
              Address={this.state.addressID}
              SelectAddress={this.handleAddressSelected}
              ScrollToBottom={this.scrollToMyRef2}
              ToggleButtons={this.toggleButtons}
            />
            <div
              id="myRef2"
              ref="myRef2"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block"
              }}
            />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this family member's record? This cannot be undone."
              }
            />
            {this.props.isEdit && (
              <Button
                disabled={this.state.disableButtons}
                onClick={this.handleConfirmDelete}
                color="primary"
                name="deleteButton"
              >
                Delete
              </Button>
            )}
            <Button
              disabled={this.state.disableButtons}
              onClick={this.handleCancel}
              color="primary"
              name="cancelButton"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.disableButtons}
              onClick={this.handleSave}
              color="primary"
              name="saveButton"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditProfile.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(EditProfile)));

export { EditProfile as PureEditProfile};
