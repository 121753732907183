import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Auth } from "aws-amplify";

import config from "../../config";

import { styles } from "../../Shared/Styles/ModalStyles";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  closeInviteEmployer,
  setEmployerInvitationTable
} from "../Redux/Actions/index";

import api from "../../Shared/Functions/APIHelpers";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    states: state.StateReducer.states,
    isOpen: state.UIReducer.inviteEmployerModalOpen,
    employerInvitationTable: state.TableReducer.employerInvitationTable
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeInviteEmployer: () => dispatch(closeInviteEmployer()),
    setEmployerInvitationTable: employerInvitationTable =>
      dispatch(setEmployerInvitationTable(employerInvitationTable))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if (ownProps.welcomePage) {
    console.log("from welcome page");
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      isOpen: ownProps.isOpen,
      refreshTable: ownProps.refreshTable,
      employerInvitationTable: ownProps.employerInvitationTable,
      closeInviteEmployer: ownProps.closeInviteEmployer
    };
  }
  return { ...stateProps, ...dispatchProps, ...ownProps };
};

const initialState = {
  open: false,
  isEdit: false,
  confirmAlertOpen: false,
  firstName: "",
  lastName: "",
  email: "",
  employerName: "",
  employerStreet: "",
  employerCity: "",
  employerState: "",
  employerZip: "",
  employerPhone: "",
  value: "",
  details: "",
  reason: "",
  confirmAlertOpen: false,
  inviteCode: "",
  inviteStatus: "",
  status: NOT_LOADED
};

class InviteEmployer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING &&
      this.props.employerInvitationTable.selected.id
    ) {
      this.setState({ status: IS_LOADING, isEdit: true });
      this.getInvitation();
    }
  }

  getInvitation = () => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerEmployerInvitation(
          this.props.employerInvitationTable.selected.id
        )
        .then(response => {
          console.log(response);
          this.setState({
            status: IS_LOADED,
            isEdit: true,
            inviteID: response.id,
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.email,
            employerName: response.employer_name,
            employerStreet: response.employer_street,
            employerCity: response.employer_city,
            employerState: response.employer_state_id
              ? response.employer_state_id
              : "",
            employerZip: response.employer_zip,
            employerPhone: response.employer_phone,
            inviteCode: response.code,
            inviteStatus: response.status
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Invitation" });
          console.log(err);
        });
    });
  };

  addInvitation = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      //employer: this.props.auth.employer_id,
      inviter_id: this.props.auth.profile_id,
      employer_name: this.state.employerName,
      employer_street: this.state.employerStreet,
      employer_city: this.state.employerCity,
      employer_state_id: (this.state.employerState !== "") ? this.state.employerState : null,
      employer_zip: this.state.employerZip,
      employer_phone: this.state.employerPhone
    });

    return new Promise((resolve, reject) => {
      api
        .addEmployerInvite(this.props.auth.customer_id, body)
        .then(r => {
          this.props.enqueueSnackbar("Invitation Added", {
            variant: "success"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Invite" });
          console.log(err);
        });
    });
  };

  updateInvitation = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      employer_name: this.state.employerName,
      employer_street: this.state.employerStreet,
      employer_city: this.state.employerCity,
      employer_state_id: (this.state.employerState !== "") ? this.state.employerState : null,
      employer_zip: this.state.employerZip,
      employer_phone: this.state.employerPhone
    });

    return new Promise((resolve, reject) => {
      api
        .updateCustomerEmployerInvitation(
          this.props.employerInvitationTable.selected.id,
          body
        )
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Invitation" });
          console.log(err);
        });
    });
  };

  resendInvitation = () => {
    return new Promise((resolve, reject) => {
      api
        .resendBrokerEmployerInvitation(
          this.props.employerInvitationTable.selected.id
        )
        .then(response => {
          this.props.enqueueSnackbar("Invitation Email Resent to ", {
            variant: "success"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Resneding Invitation" });
          console.log(err);
        });
    });
  };

  archiveInvitation = () => {
    var body = JSON.stringify({
      date_archived: new Date().toISOString()
    });

    return new Promise((resolve, reject) => {
      api
        .updateCustomerEmployerInvitation(
          this.props.employerInvitationTable.selected.id,
          body
        )
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Invitation" });
          console.log(err);
        });
    });
  };

  deleteBrokerEmployerInvitation = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteBrokerEmployerInvitation(
          this.props.employerInvitationTable.selected.id
        )
        .then(r => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Invitation" });
          console.log(err);
        });
    });
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleDelete = () => {
    this.deleteBrokerEmployerInvitation().then(r => {
      this.props.refreshTable();
      this.close();
    });
  };

  close = () => {
    var tempTable = this.props.employerInvitationTable;
    tempTable.selected = {};
    this.props.setEmployerInvitationTable(tempTable);
    this.reset();
    this.props.closeInviteEmployer();
  };

  handleClose = () => {
    this.close();
  };

  handleArchive = () => {
    this.archiveInvitation().then(r => {
      this.handleClose();
      this.props.refreshTable();
    });
  };

  handleSave = () => {
    if (this.props.isEdit) {
      this.updateInvitation().then(r => {
        this.handleClose();
        this.props.refreshTable();
      });
    } else {
      this.addInvitation().then(r => {
        this.handleClose();
      });
    }
  };

  handleResend = () => {
    console.log("resending apps");
    this.resendInvitation().then(r => {
      console.log("in here");
      console.log(r);
    });
    //call update inivite only setting the date archived date
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;
    console.log(this.props.states);
    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Invite Employer</DialogTitle>

          <DialogContent>
            {!this.state.isEdit && (
              <React.Fragment>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ marginTop: 10 }}
                >
                  Invite an employer to link to your agency
                </Typography>

                <Typography variant="caption" style={{ marginTop: 10 }}>
                  Send an invite to any employer for them to create a Reform
                  Health Account or link their existing account to your agency.
                  The employer will be able to correct or complete any fields
                  left blank.
                </Typography>

                <Typography variant="subtitle2" color="primary">
                  http://localhost:3000/auth?invite={this.state.inviteCode}
                </Typography>
              </React.Fragment>
            )}
            {this.state.isEdit && (
              <Typography>
                Once sent or expired, you will have the option to resend. Once
                accepted, you will be able to archive
                <br />
                {this.state.isEdit &&
                  (this.state.inviteStatus === "Sent" ||
                    this.state.inviteStatus === "Expired") && (
                    <Button
                      onClick={this.handleResend}
                      color="primary"
                      style={{ marginTop: 20 }}
                    >
                      Resend
                    </Button>
                  )}
                {this.state.isEdit && this.state.inviteStatus === "Accepted" && (
                  <Button
                    onClick={this.handleArchive}
                    color="primary"
                    style={{ marginTop: 20 }}
                  >
                    Archive
                  </Button>
                )}
              </Typography>
            )}
            <Typography variant="subtitle2" style={{ marginTop: 20 }}>
              Group Contact
            </Typography>
            <TextField
              id="standard-dense"
              label="First Name"
              margin="dense"
              multiline
              onChange={e => this.setState({ firstName: e.target.value })}
              value={this.state.firstName}
              fullWidth
            />

            <TextField
              id="standard-dense"
              label="Last Name"
              margin="dense"
              multiline
              onChange={e => this.setState({ lastName: e.target.value })}
              value={this.state.lastName}
              fullWidth
            />

            {!this.props.isEdit && (
              <TextField
                id="standard-dense"
                label="Email"
                margin="dense"
                multiline
                onChange={e => this.setState({ email: e.target.value })}
                value={this.state.email}
                fullWidth
              />
            )}

            {this.props.isEdit && (
              <Typography variant="subtitle2" style={{ marginTop: 30 }}>
                Email: {this.state.email}
              </Typography>
            )}

            <Typography variant="subtitle2" style={{ marginTop: 30 }}>
              Optional
            </Typography>
            <TextField
              id="standard-dense"
              label="Employer Name"
              margin="dense"
              multiline
              onChange={e => this.setState({ employerName: e.target.value })}
              value={this.state.employerName}
              fullWidth
            />

            <TextField
              id="standard-dense"
              label="Street"
              margin="dense"
              multiline
              onChange={e => this.setState({ employerStreet: e.target.value })}
              value={this.state.employerStreet}
              fullWidth
            />

            <TextField
              id="standard-dense"
              label="City"
              margin="dense"
              multiline
              onChange={e => this.setState({ employerCity: e.target.value })}
              value={this.state.employerCity}
              fullWidth
            />

            <FormControl margin="dense">
              <InputLabel htmlFor="states">State</InputLabel>
              <Select
                value={this.state.employerState}
                onChange={this.handleChange("employerState")}
                inputProps={{
                  name: "state",
                  id: "state"
                }}
                style={{ width: 70, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.states.map(state => {
                  return (
                    <MenuItem value={state.id} key={"state_" + state.id}>
                      {state.abbreviation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              id="standard-dense"
              label="Zip"
              margin="dense"
              onChange={e => this.setState({ employerZip: e.target.value })}
              value={this.state.employerZip}
              style={{ width: 100 }}
            />

            <TextField
              id="standard-dense"
              label="Phone"
              margin="dense"
              onChange={e => this.setState({ employerPhone: e.target.value })}
              value={this.state.employerPhone}
              fullWidth
            />
            <div className={classes.divider} />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this override? This cannot be undone."
              }
            />
            {this.props.isEdit && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            {!this.props.isEdit && (
              <Button onClick={this.handleSave} color="primary">
                Send
              </Button>
            )}
            {this.props.isEdit && (
              <Button onClick={this.handleSave} color="primary">
                Save
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InviteEmployer.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withMobileDialog()(withStyles(styles)(InviteEmployer)));
