import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import FamilyContainer from "../Family/ProfilesContainer";
import HealthContainer from "../Health/HealthContainer";
import PageHeader from "../Shared/PageHeader";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const styles = theme => ({
  summarySection: {
    display: "flex",
    marginTop: 50
  }
});

class SummaryContainer extends React.Component {
  goBack = () => {
    this.props.action("health");
  };

  goNext = () => {
    this.props.action("form");
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PageHeader pageTitle="Summary" back={this.goBack} next={this.goNext} />

        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 20 }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
              onClick={this.handlePanelClick}
            >
              <Typography variant="subtitle2">Summary Instructions</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ marginTop: 20 }}>
              <Typography variant="body1">
                Please review the information you've provided to ensure it's
                complete and correct. On the next page, you'll be able to review
                each form and verify how this information was applied. If
                something doesn't look correct, you may come back to this page
                to make changes.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className={classes.summarySection}>
          <FamilyContainer Summary={true} enqueueSnackbar={this.props.enqueueSnackbar} />
        </div>
        <div className={classes.summarySection}>
          <HealthContainer Summary={true} enqueueSnackbar={this.props.enqueueSnackbar}/>
        </div>
      </React.Fragment>
    );
  }
}

SummaryContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SummaryContainer));
