import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";

import {
  clearActiveHealth,
  setActiveCondition,
  openAddHealth,
  isLoading,
  loadHealth,
  healthLoaded
} from "../Redux/Actions/index";

const styles = theme => ({
  button: {
    marginLeft: theme.spacing.unit * 1,
    textAlign: "left"
  },
  divider: {
    display: "none",
    "@media only screen and (max-width: 850px)": {
      display: "block"
    }
  }
});

const mapDispatchToProps = dispatch => {
  return {
    //loadProfiles: profiles => dispatch(loadProfiles(profiles)),
    healthLoaded: () => dispatch(healthLoaded()),
    clearActiveHealth: () => dispatch(clearActiveHealth()),
    setActiveCondition: conditionID =>
      dispatch(setActiveCondition(conditionID)),
    openAddHealth: () => dispatch(openAddHealth()),
    loadHealth: health => dispatch(loadHealth(health)),
    isLoading: loading => dispatch(isLoading(loading))
  };
};

const mapStateToProps = state => {
  return {
    health: state.HealthReducer.health,
    isLoaded: state.HealthReducer.isLoaded,
    conditionCategories: state.ConditionCategoryReducer.conditionCategories
  };
};

class ConditionList extends React.Component {
  state = {
    expanded: false
  };

  handleAdd = () => {
    this.props.openAddHealth();
  };

  handlePanelClick = event => {
    if (this.state.expanded === true) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: true });
    }
  };

  handleClick = event => {
    console.log("handleClick");
    console.log(event.currentTarget.value);
    this.props.setActiveCondition(event.currentTarget.value);
    //this.props.setActiveCondition(parseInt(event.currentTarget.value, 10))
    this.props.AddCondition();
  };

  returnValue() {
    return false;
  }

  goBack() {
    this.props.history.push("employment");
  }

  goNext() {
    this.props.history.push("summary");
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <ExpansionPanel
          expanded={!this.props.health.length > 0 || this.state.expanded}
          style={{ marginBottom: 20 }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ minHeight: 30, height: 45, backgroundColor: "#f2f2f2" }}
            onClick={this.handlePanelClick}
          >
            <Typography variant="subtitle2">
              Health Status Conditions & Instructions
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ marginTop: 20 }}>
            <div>
            {/*
              <Typography variant="body1" color="primary" style={{ marginBottom: 20 }}>
                Please select from the list below to begin adding health records
                for you and your family. You will need to add each applicable
                health status for each family member.
              </Typography>
              <Divider style={{marginTop: 30, marginBottom: 50}}/>
            */}
              {this.props.conditionCategories.map(category => {
                return (
                  <div key={"div_" + category.id} style={{ textAlign: "left" }}>
                    <Typography
                      key={"typography_" + category.id}
                      variant="subtitle2"
                      style={{ marginTop: 15 }}
                    >
                      {category.name}
                    </Typography>
                    {category.conditions.map(condition => {
                      return (
                        <React.Fragment key={"fragment_" + condition.id}>
                          <Button
                            value={condition.id}
                            key={"condition_" + condition.id}
                            className={classes.button}
                            onClick={this.handleClick}
                            color="primary"
                          >
                            {condition.name}
                          </Button>
                          <div
                            key={"div_" + condition.id}
                            className={classes.divider}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ConditionList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConditionList));
