import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/lib/Async";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import { Auth } from "aws-amplify";
import config from "../../config";

import { AddIcon, DeleteIcon } from "../../Shared/Components/Icons";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  selectedCell: {
    backgroundColor: "red"
  }
});

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 500
  })
};

const customDefaultStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 500
  })
};

class ICDContainer extends React.Component {
  state = {
    details: [],
    icd: [],
    isLoading: false,
    defaultOptions: [{ value: 1, label: "test" }],
    conditions: [],
    condition_id: 0,
    editList: [],
    editListType: 0,
    editListParent: 0,
    editListAdd: "",
    showEditModal: false,
    icdSearchValue: "",
    icdNoDetailSearchValue: ""
  };

  componentDidMount() {
    this.getConditions().then(c => {
      this.setState({ conditions: c }, () => {
        console.log(c);
      });
    });
  }

  handleConditionChange = event => {
    this.setState({ condition_id: event.target.value, isLoading: true }, () => {
      this.load();
    });
  };

  getConditions = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + "/api/v1/conditions", {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  getDetails = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(
            config.apiGateway.URL +
              "/api/v1/conditions/" +
              this.state.condition_id +
              "/details",
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/coreapi+json"
              }),
              method: "GET",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  getICDs = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(
            config.apiGateway.URL +
              "/api/v1/conditions/" +
              this.state.condition_id +
              "/condition-icds",
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/coreapi+json"
              }),
              method: "GET",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  loadICDOptions = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(
            config.apiGateway.URL +
              "/api/v1/icds?search_term=" +
              this.state.icdSearchValue,
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/coreapi+json"
              }),
              method: "GET",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              var foundICDs = [];
              responseData.forEach(r => {
                r.value = r.id;
                r.label = r.code + " " + r.description;
                foundICDs.push(r);
              });
              resolve(foundICDs);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  loadICDOptionsNoDetails = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(
            config.apiGateway.URL +
              "/api/v1/icds?search_term=" +
              this.state.icdNoDetailSearchValue,
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/coreapi+json"
              }),
              method: "GET",
              mode: "cors"
            }
          )
            .then(response => {
              console.log(response);
              return response.json();
            })
            .then(responseData => {
              var foundICDs = [];
              console.log("in here");
              console.log(responseData);
              responseData.forEach(r => {
                r.value = r.id;
                r.label = r.code + " " + r.description;
                foundICDs.push(r);
              });
              resolve(foundICDs);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistUpdateICD = (id, icd, d1, d2, d3) => {
    return new Promise((resolve, reject) => {
      var obj = {};
      obj["id"] = id;
      obj["icd"] = icd;
      obj["condition"] = this.state.condition_id;
      obj["detail_1_id"] = d1 ? d1 : null;
      obj["detail_2_id"] = d2 ? d2 : null;
      obj["detail_3_id"] = d3 ? d3 : null;
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + "/api/v1/condition-icds/" + id, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }),
            body: JSON.stringify(obj),
            method: "PUT",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              console.log(responseData);
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistRemoveICD = icd => {
    console.log(icd);
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + "/api/v1/condition-icds/" + icd.id, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }),
            method: "DELETE",
            mode: "cors"
          })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistAddICD = (icd, d1, d2, d3) => {
    return new Promise((resolve, reject) => {
      var obj = {};
      obj["icd"] = icd;
      obj["condition"] = this.state.condition_id;
      obj["detail_1_id"] = d1 ? d1 : null;
      obj["detail_2_id"] = d2 ? d2 : null;
      obj["detail_3_id"] = d3 ? d3 : null;
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(
            config.apiGateway.URL +
              "/api/v1/conditions/" +
              this.state.condition_id +
              "/condition-icds",
            {
              headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/json"
              }),
              body: JSON.stringify(obj),
              method: "POST",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistEditDetail = (detail_type, detail_id, detail_name, parent_id) => {
    return new Promise((resolve, reject) => {
      var obj = {};
      var url = "";
      obj["id"] = detail_id;
      obj["name"] = detail_name;
      if (detail_type == 1) {
        url = "/api/v1/condition-detail-1-list/" + detail_id;
      }
      if (detail_type == 2) {
        url = "/api/v1/condition-detail-2-list/" + detail_id;
      }
      if (detail_type == 3) {
        url = "/api/v1/condition-detail-3-list/" + detail_id;
      }

      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + url, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }),
            body: JSON.stringify(obj),
            method: "PUT",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistAddDetail = (detail_type, detail_name, parent_id) => {
    return new Promise((resolve, reject) => {
      var obj = {};
      var url = "";
      obj["name"] = detail_name;
      if (detail_type == 1) {
        obj["condition"] = this.state.condition_id;
        url =
          "/api/v1/conditions/" +
          this.state.condition_id +
          "/condition-detail-1-list";
      }
      if (detail_type == 2) {
        obj["detail_1"] = parent_id;
        url =
          "/api/v1/condition-detail-1-list/" +
          parent_id +
          "/condition-detail-2-list";
      }
      if (detail_type == 3) {
        obj["detail_2"] = parent_id;
        url =
          "/api/v1/condition-detail-2-list/" +
          parent_id +
          "/condition-detail-3-list";
      }
      console.log(JSON.stringify(obj));
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + url, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }),
            body: JSON.stringify(obj),
            method: "POST",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  persistRemoveDetail = (detail_type, detail_id) => {
    return new Promise((resolve, reject) => {
      var url = "";
      if (detail_type == 1) {
        url = "/api/v1/condition-detail-1-list/" + detail_id;
      }
      if (detail_type == 2) {
        url = "/api/v1/condition-detail-2-list/" + detail_id;
      }
      if (detail_type == 3) {
        url = "/api/v1/condition-detail-3-list/" + detail_id;
      }
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;
          fetch(config.apiGateway.URL + url, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }),
            method: "DELETE",
            mode: "cors"
          })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error: " + err);
            });
        })
        .catch(err => {
          console.log("Auth current session error: ", err);
          return;
        });
    });
  };

  findICD = (d1, d2, d3) => {
    var icd = this.state.icd.find(icd => {
      var icd_d1 = icd.detail_1 ? icd.detail_1.id : "";
      var icd_d2 = icd.detail_2 ? icd.detail_2.id : "";
      var icd_d3 = icd.detail_3 ? icd.detail_3.id : "";
      if (icd_d1 == d1 && icd_d2 == d2 && icd_d3 == d3) return icd;
    });
    if (icd) return icd.icd_code;
    else return "";
  };

  getICDDefaulOptions = (d1, d2, d3) => {
    var icd = this.state.icd.find(icd => {
      //if no detail in row, the variable sent in is undefined. Setting it to '' to avoid improper comparison
      d1 = d1 ? d1 : "";
      d2 = d2 ? d2 : "";
      d3 = d3 ? d3 : "";
      var icd_d1 = icd.detail_1 ? icd.detail_1.id : "";
      var icd_d2 = icd.detail_2 ? icd.detail_2.id : "";
      var icd_d3 = icd.detail_3 ? icd.detail_3.id : "";

      if (icd_d1 == d1 && icd_d2 == d2 && icd_d3 == d3) return icd;
    });
    if (icd)
      return [{ value: 1, label: icd.icd.code + " " + icd.icd.description }];
    else return "";
  };

  getNoDetailDefaultOptions = condition_id => {
    var icd = this.state.icd.find(icd => {
      if (
        icd.detail_1 === null &&
        icd.detail_2 === null &&
        icd.detail_3 === null &&
        icd.condition === condition_id
      ) {
        return icd;
      }
    });

    if (icd) {
      return [{ value: 1, label: icd.icd.code + " " + icd.icd.description }];
    } else {
      return "";
    }
  };

  handleNoDetailICDChange = newValue => {
    this.setState({ icdNoDetailSearchValue: newValue });
    return newValue;
  };

  saveNoDetailICD = e => {
    var icd = this.state.icd.find(icd => {
      if (
        icd.detail_1 === null &&
        icd.detail_2 === null &&
        icd.detail_3 === null &&
        icd.condition === this.state.condition_id
      ) {
        return icd;
      }
    });

    if (!e) {
      this.persistRemoveICD(icd)
    }
    else{
      if (icd) {
        this.persistUpdateICD(icd.id, e.id, null, null, null);
      } else {
        this.persistAddICD(e.id, null, null, null);
      }
    }
  };

  saveICD = (e, d1, d2, d3, icd) => {
    //if icd is found then updated, otherwise add
    if (icd) {
      this.persistUpdateICD(icd.id, e.id, d1, d2, d3);
    } else {
      this.persistAddICD(e.id, d1, d2, d3);
    }
  };
  handleICDSelectedChange = (e, d1, d2, d3) => {
    var icd = this.state.icd.find(icd => {
      d1 = d1 ? d1 : "";
      d2 = d2 ? d2 : "";
      d3 = d3 ? d3 : "";
      var icd_d1 = icd.detail_1 ? icd.detail_1.id : "";
      var icd_d2 = icd.detail_2 ? icd.detail_2.id : "";
      var icd_d3 = icd.detail_3 ? icd.detail_3.id : "";

      if (icd_d1 == d1 && icd_d2 == d2 && icd_d3 == d3) return icd;
    });

    if (e) this.saveICD(e, d1, d2, d3, icd);
    else this.persistRemoveICD(icd);
  };
  handleICDInputChange = newValue => {
    this.setState({ icdSearchValue: newValue });
    return newValue;
  };
  setupICD = () => {
    return new Promise((resolve, reject) => {
      this.getICDs().then(icd => {
        var icd_array = [];
        icd.forEach(i => {
          i.icd.old_code = i.icd.code;
          i.condition = this.state.condition_id;
          icd_array.push(i);
        });
        this.setState({ icd: icd_array }, resolve("finished"));
      });
    });
  };

  load = () => {
    return new Promise((resolve, reject) => {
      this.getDetails().then(r => {
        var rows = [];
        var details = r.detail_1_list;
        details.forEach((d1, in_1) => {
          //if there are any detail 2s
          if (d1.detail_2_list.length != 0) {
            d1.detail_2_list.forEach((d2, in_2) => {
              //setting top of d1 tree using logical ! operator if in_2 is not 0
              d1.isTop = !in_2;
              if (d2.detail_3_list.length != 0) {
                d2.detail_3_list.forEach((d3, in_3) => {
                  d1.isTop = !in_2 && !in_3;
                  d2.isTop = !in_3;

                  rows.push({
                    d1: {
                      isTop: d1.isTop,
                      condition_id: this.state.condition_id,
                      id: d1.id,
                      name: d1.name
                    },
                    d2: {
                      isTop: d2.isTop,
                      d1_id: d1.id,
                      id: d2.id,
                      name: d2.name
                    },
                    d3: { d2_id: d2.id, id: d3.id, name: d3.name }
                  });
                });
              }
              //no detail 3s
              else {
                rows.push({
                  d1: {
                    isTop: d1.isTop,
                    condition_id: this.state.condition_id,
                    id: d1.id,
                    name: d1.name
                  },
                  d2: { isTop: true, d1_id: d1.id, id: d2.id, name: d2.name },
                  d3: {}
                });
              }
            });
          } else {
            rows.push({
              d1: {
                isTop: true,
                condition_id: this.state.condition_id,
                id: d1.id,
                name: d1.name
              },
              d2: {},
              d3: {}
            });
          }
        });

        //dont set state with rows until all ICDs are fetched to avoid race condition between each row looking for existing ICDs
        this.setupICD().then(r => {
          this.setState(
            { details: rows, isLoading: false },
            resolve("finished")
          );
        });
      });
    });
  };

  handleEditOpen = (edit, parent) => {
    var detailsToEdit = [];
    var editListType = 0;
    var editListParentID = 0;
    if (edit == "detail_1") {
      this.state.details.forEach(d => {
        if (!detailsToEdit.find(det => det.id == d.d1.id)) {
          editListType = 1;
          editListParentID = this.state.condition_id;
          detailsToEdit.push({
            detail_type: 1,
            parent_id: this.state.condition_id,
            id: d.d1.id,
            isDeleted: false,
            name: d.d1.name,
            old_name: d.d1.name
          });
        }
      });
    }
    if (edit == "detail_2") {
      this.state.details.forEach(d => {
        if (d.d1.id === parent.id) {
          //only shows detail2s for parent clicked
          //need to set these before the loop in case there are no details to push in
          editListParentID = parent.id;
          editListType = 2;
          if (
            !detailsToEdit.find(det => det.id == d.d2.id) &&
            d.d2.id != undefined
          ) {
            detailsToEdit.push({
              detail_type: 2,
              parent_id: parent.id,
              id: d.d2.id,
              isDeleted: false,
              name: d.d2.name,
              old_name: d.d2.name
            });
          }
        }
      });
    }
    if (edit == "detail_3") {
      this.state.details.forEach(d => {
        //only shows detail 3s for parent clicked
        if (d.d2.id === parent.id) {
          editListParentID = parent.id;
          editListType = 3;
          if (
            !detailsToEdit.find(det => det.id == d.d3.id) &&
            d.d3.id != undefined
          )
            detailsToEdit.push({
              detail_type: 3,
              parent_id: parent.id,
              id: d.d3.id,
              isDeleted: false,
              name: d.d3.name,
              old_name: d.d3.name
            });
        }
      });
    }
    if (edit == "detail_1") editListType = 1;
    if (edit == "detail_2") editListType = 2;
    if (edit == "detail_3") editListType = 3;
    this.setState({
      editList: detailsToEdit,
      showEditModal: true,
      editListType: editListType,
      editListParent: editListParentID
    });
  };

  handleEditClose = () => {
    this.setState({
      showEditModal: false,
      editList: [],
      editListType: 0,
      editListParent: 0,
      editListAdd: ""
    });
  };

  editDetail = (e, d) => {
    d.name = e.target.value;
    this.setState({
      editList: this.state.editList.map(existingDetail =>
        existingDetail.id === d.id ? d : existingDetail
      )
    });
  };

  addDetail = () => {
    var oldEdit = this.state.editList;

    oldEdit.push({
      detail_type: this.state.editListType,
      parent_id: this.state.editListParent,
      id: -1,
      isDeleted: false,
      name: this.state.editListAdd,
      old_name: this.state.editListAdd
    });
    this.setState({ editList: oldEdit, editListAdd: "" });
  };

  removeDetail = (e, d) => {
    d.isDeleted = true;
    this.setState({
      editList: this.state.editList.map(det => (det.name === d.name ? d : det))
    });
  };

  saveDetails = () => {
    var promises = [];
    console.log(this.state.editList);
    this.state.editList.forEach(d => {
      if (d.id == -1) {
        //then its a new detail
        promises.push(
          this.persistAddDetail(d.detail_type, d.name, d.parent_id)
        );
      } else if (d.isDeleted) {
        promises.push(this.persistRemoveDetail(d.detail_type, d.id));
      } else {
        //only hit server if name changed

        if (d.name != d.old_name) {
          promises.push(
            this.persistEditDetail(d.detail_type, d.id, d.name, d.parent_id)
          );
        }
      }
    });
    Promise.all(promises).then(forms => {
      this.load().then(r => this.handleEditClose());
    });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="condition-type-simple">Conditions</InputLabel>
          <Select
            value={this.state.condition_id}
            onChange={e => {
              this.handleConditionChange(e);
            }}
            inputProps={{
              name: "condition",
              id: "condition-type-simple"
            }}
            style={{ width: 450 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {this.state.conditions.map(c => {
              return (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {(this.state.condition_id != "" && !this.state.isLoading && (
          <div>
            <br />
            <Typography>Default ICD: </Typography>
            <AsyncSelect
              cacheOptions
              loadOptions={this.loadICDOptionsNoDetails}
              defaultValue={
                this.getNoDetailDefaultOptions(this.state.condition_id)[0]
              }
              onInputChange={this.handleNoDetailICDChange}
              onChange={e => {
                this.saveNoDetailICD(e, "", "", "");
              }}
              defaultOptions={this.getNoDetailDefaultOptions(
                this.state.condition_id
              )}
              styles={customDefaultStyles}
              isClearable
            />
          </div>
        )) ||
          ""}

        <Table style={{ marginTop: 50 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Detail 1{" "}
                <IconButton
                  onClick={() => {
                    this.handleEditOpen("detail_1");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell>Detail 2</TableCell>
              <TableCell>Detail 3</TableCell>
              <TableCell>ICD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.details.map(row => {
              return (
                <TableRow key={row.d1.id + " " + row.d2.id + " " + row.d3.id}>
                  <TableCell style={{ border: 0, width: 300 }}>
                    {row.d1.isTop && (
                      <div>
                        {row.d1.name}{" "}
                        <IconButton
                          style={{ marginBottom: 3 }}
                          onClick={() => {
                            this.handleEditOpen("detail_2", row.d1);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ border: 0, width: 350 }}>
                    {row.d2.isTop && (
                      <div>
                        {row.d2.name}
                        <IconButton
                          style={{ marginBottom: 3 }}
                          onClick={() => {
                            this.handleEditOpen("detail_3", row.d2);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ border: 0 }}>
                    <div>{row.d3.name}</div>
                  </TableCell>
                  <TableCell style={{ border: 0 }}>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={this.loadICDOptions}
                      defaultValue={
                        this.getICDDefaulOptions(
                          row.d1.id,
                          row.d2.id,
                          row.d3.id
                        )[0]
                      }
                      onInputChange={this.handleICDInputChange}
                      onChange={e => {
                        this.handleICDSelectedChange(
                          e,
                          row.d1.id,
                          row.d2.id,
                          row.d3.id
                        );
                      }}
                      defaultOptions={this.getICDDefaulOptions(
                        row.d1.id,
                        row.d2.id,
                        row.d3.id
                      )}
                      styles={customStyles}
                      isClearable
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {/* THIS IS CODE TO CONTROL THE DIALOG POPUP WINDOW*/}
        <Dialog
          open={this.state.showEditModal}
          aria-labelledby="form-dialog-title"
          scroll="paper"
          onClose={this.handleEditClose}
        >
          <DialogTitle id="form-dialog-title">Details</DialogTitle>

          <DialogContent style={{minWidth: 400, minHeight: 300}}>
            {
              <List>
                {this.state.editList.map(d => {
                  if (!d.isDeleted) {
                    return (
                      <ListItem key={d.id}>
                        <ListItemText
                          primary={
                            <TextField
                              id="standard-dense"
                              value={d.name}
                              onChange={e => {
                                this.editDetail(e, d);
                              }}
                              label=""
                              margin="dense"
                              fullWidth
                              state
                            />
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="Delete"
                            onClick={e => {
                              this.removeDetail(e, d);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }
                })}
                <ListItem>
                  <ListItemText
                    primary={
                      <TextField
                        id="standard-dense"
                        value={this.state.editListAdd}
                        onChange={e => {
                          this.setState({ editListAdd: e.target.value });
                        }}
                        label=""
                        fullWidth
                        margin="dense"
                        state
                      />
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton aria-label="Add" onClick={this.addDetail}>
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            }
          </DialogContent>

          <DialogActions>
            <Button onClick={this.saveDetails} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ICDContainer);
