import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

//import InviteGreeting from '../Auth/InviteGreeting'
import SignIn from "../Auth/SignIn";
import SignUp from "../Auth/SignUp";
import ForgotPassword from "../Auth/ForgotPassword";

import { styles } from "../Auth/Styles/AuthStyles";
import { Redirect } from "react-router";

import { Auth } from "aws-amplify";
import config from "../config";

import { getApp } from "./Shared/Functions/StaticFunctions";

import api from "../Shared/Functions/APIHelpers";

class Authenticator extends React.Component {
  state = {
    showSignIn: true,
    authPage: "signIn",
    goHome: false,
    inviteCode: "",
    invitation: {},
    inviteType: "",
    continue: false,
    employer: null,
    customer: null,
    app: "",
    hasCode: false,
    errorMessage: "",
    emailLabel: "Email",
    accountType: "a User"
  };

  componentDidMount() {
    console.log("Version: 070219b")
    console.log("making checks in Auth Container");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let employerCode = params.get("employer");
    let customerCode = params.get("customer");

    let state = params.get("state");
    let url = window.location.href;
    
    this.setState({ app: this.props.app });
    //redirect if from signout
    if(this.props.app === "init")
    {
      this.redirectFromSignout()
    }
    if (inviteCode) {
      this.setState({ hasCode: true });
      this.handleGetInvitation(inviteCode);
    } else if (employerCode) {
      this.setState({ hasCode: true });
      this.GetEmployer(employerCode);
    } else if (customerCode) {
      this.setState({ hasCode: true });
      this.GetCustomer(customerCode);
    }

    if (this.props.flowBypass)
      this.setState({ authPage: this.props.flowBypass });
  }

  GetInvitation = code => {
    return new Promise((resolve, reject) => {
      api
        .getInvitationByCode(code)
        .then(response => {
          console.log(response)
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  GetEmployer = code => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerByCode(code)
        .then(response => {
          this.setState({ employer: response.name });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  GetCustomer = code => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerByCode(code)
        .then(response => {
          this.setState({ customer: response.name });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  redirectFromSignout = () => {
    var currentApp = localStorage.getItem("reform_health_app")
    if(currentApp){
      this.props.history.push("/" + currentApp + "/auth")
    }    
  };

  handleGetInvitation = code => {
    this.GetInvitation(code).then(response => {
      if (!response.inviter.id) {
        this.props.enqueueSnackbar("Invalid or Expired Invitation", {
          variant: "warning"
        });
      } else {
        if(response.type==1) //employer -> employee invite
        {
          this.setState({
            inviteType: response.type,
            employer: response.employer.name,
            customer: ''
          });
        }
        if(response.type == 2)//broker -> employer invite
        {
          this.setState({
            inviteType: response.type,
            employer: '',
            customer: response.customer.name
          });
        }
        if(response.type === 3)
        {
          this.setState({
            inviteType: response.type,
            employer: '',
            customer: response.customer.name
          });
        }
      }
    });
  };

  handler() {
    this.setState({
      showSignIn: false
    });
  }

  switchState = authPage => {
    this.setState({
      authPage
    });
  };

  renderSwitch = param => {
    switch (param) {
      case "signIn":
        return (
          <SignIn
            enqueueSnackbar={this.props.enqueueSnackbar}
            hasCode={this.state.hasCode}
            history={this.props.history}
            app={this.props.app}
            employer={this.state.employer}
            customer={this.state.customer}
            action={this.switchState}
          />
        );
      case "signUp":
        return (
          <SignUp
            history={this.props.history}
            app={this.props.app}
            newAgency={this.props.newAgency}
            employer={this.state.employer}
            customer={this.state.customer}
            action={this.switchState}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            history={this.props.history}
            action={this.switchState}
            enqueueSnackbar={this.props.enqueueSnackbar}
            app={this.props.app}
          />
        );
      case "Home":
        this.setState({ goHome: true });
        return "";
      default:
        return (
          <SignIn history={this.props.history} action={this.switchState} />
        );
    }
  };

  render() {
    const { classes } = this.props;
    const { authPage } = this.state;
    //Temporary hack
    if (this.state.goHome) {
      return <Redirect push to="/home" />;
    }

    return (
      <div className={classes.basecontent}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root} elevation={5} style={{ zIndex: 10 }}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              {this.renderSwitch(authPage)}
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

Authenticator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(Authenticator));