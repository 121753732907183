import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Checkbox from "@material-ui/core/Checkbox";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

//import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

import {
  closeEditFormRevision,
  setFormRevisionTable
} from "../Redux/Actions/index";

import api from "../../Shared/Functions/APIHelpers";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const mapDispatchToProps = dispatch => {
  return {
    closeEditFormRevision: () => dispatch(closeEditFormRevision()),
    setFormRevisionTable: formRevisionTable =>
      dispatch(setFormRevisionTable(formRevisionTable))
  };
};

const mapStateToProps = state => {
  return {
    formTable: state.TableReducer.formTable,
    formRevisionTable: state.TableReducer.formRevisionTable,
    isOpen: state.UIReducer.editFormRevisionModalOpen
  };
};

const initialState = {
  uploadedFile: {
    name: ""
  },
  majorRevision: "",
  minorRevision: "",
  checkedMaterialChange: false,
  checkedTesting: false,
  validFrom: null,
  validTo: null,
  fileName: "",
  fileVersion: "",
  comment: "",
  status: NOT_LOADED
};

class EditFormRevision extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    console.log(initialState);
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    console.log({ isOpen: this.props.isOpen, status: this.state.status });
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING &&
      this.props.formRevisionTable.selected.id
    ) {
      this.setState({ status: IS_LOADING });
      this.getRevision();
    }
  }

  getRevision = () => {
    return new Promise((resolve, reject) => {
      api
        .getFormRevision(this.props.formRevisionTable.selected.id)
        .then(response => {
          this.setState({
            majorRevision: response.major_revision,
            minorRevision: response.minor_revision,
            checkedMaterialChange: response.is_material_change,
            checkedTesting: response.is_testing,
            validFrom: response.valid_from
              ? response.valid_from.substr(
                  0,
                  response.valid_from.lastIndexOf("-")
                )
              : null,
            validTo: response.valid_to
              ? response.valid_to.substr(0, response.valid_to.lastIndexOf("-"))
              : null,
            comment: response.comment,
            fileName: response.file_name,
            fileVersion: response.file_version,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Form Revision" });
          console.log(err);
        });
    });
  };

  updateRevision = () => {
    var body = JSON.stringify({
      major_revision: this.state.majorRevision,
      minor_revision: this.state.minorRevision,
      is_material_change: this.state.checkedMaterialChange,
      is_testing: this.state.checkedTesting,
      valid_from: this.state.validFrom,
      valid_to: this.state.validTo,
      comment: this.state.comment,
      fileName: this.state.fileName,
      fileVersion: this.state.fileVersion
    });

    return new Promise((resolve, reject) => {
      api
        .updateFormRevision(this.props.formRevisionTable.selected.id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Revision" });
          console.log(err);
        });
    });
  };

  uploadForm = () => {
    console.log(this.state.checkedMaterialChange);
    console.log(this.state.checkedTesting);
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        major_revision: this.state.majorRevision,
        minor_revision: this.state.minorRevision,
        is_material_change: this.state.checkedMaterialChange,
        is_testing: this.state.checkedTesting,
        valid_from: this.state.validFrom,
        valid_to: this.state.validTo,
        comment: this.state.comment
      });

      const formData = new FormData();
      formData.append("file", this.state.uploadedFile);
      formData.append("meta_data", body);

      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;

          //fetch(config.apiGateway.URL + '/api/v1/load-form', {
          fetch(
            config.apiGateway.URL +
              "/api/v1/forms/" +
              this.props.formTable.selected.id +
              "/form-revisions",
            {
              headers: new Headers({
                Authorization: "Bearer " + token
                //'content-type': 'multipart/form-data'
              }),
              body: formData,
              method: "POST",
              mode: "cors"
            }
          )
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              console.log(responseData);
              return responseData;
            })
            .then(data => {
              resolve(data);
            })
            .catch(err => {
              console.log("fetch error" + err);
            });
        })
        .catch(err => {
          console.log("error happened", err);
          return;
        });
    });
  };

  close = () => {
    this.reset();
    this.props.closeEditFormRevision();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    if (this.props.formRevisionTable.selected.id !== undefined) {
      this.updateRevision().then(r => {
        this.close();
      });
    } else {
      this.uploadForm().then(r => {
        this.close();
      });
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {(this.props.formRevisionTable.selected.id === undefined &&
              "Add") ||
              "Edit"}{" "}
            Form Revision for {this.props.formTable.selected.name}
          </DialogTitle>

          <DialogContent>
            <TextField
              id="standard-dense"
              label="Major Revision"
              margin="dense"
              value={this.state.majorRevision}
              onChange={e => this.setState({ majorRevision: e.target.value })}
              style={{ width: 200 }}
            />

            <TextField
              id="standard-dense"
              label="Minor Revision"
              margin="dense"
              value={this.state.minorRevision}
              onChange={e => this.setState({ minorRevision: e.target.value })}
              style={{ width: 200, marginLeft: 20 }}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedMaterialChange}
                  onChange={this.handleChange("checkedMaterialChange")}
                  //value={this.state.checkedMaterialChange}
                  color="primary"
                />
              }
              label="Material Change"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedTesting}
                  onChange={this.handleChange("checkedTesting")}
                  //value={this.state.checkedTesting}
                  color="primary"
                />
              }
              label="In Testing"
            />
            <br />
            <TextField
              id="datetime-local"
              label="Valid From"
              type="datetime-local"
              value={this.state.validFrom}
              className={classes.textField}
              onChange={e => this.setState({ validFrom: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: 250 }}
            />
            <TextField
              id="datetime-local"
              label="Valid To"
              type="datetime-local"
              value={this.state.validTo}
              className={classes.textField}
              onChange={e => this.setState({ validTo: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
              style={{ marginLeft: 20, width: 250 }}
            />
            <TextField
              id="standard-dense"
              label="File Name"
              margin="dense"
              value={this.state.fileName}
              onChange={e => this.setState({ fileName: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="File Version"
              margin="dense"
              value={this.state.fileVersion}
              onChange={e => this.setState({ fileVersion: e.target.value })}
              fullWidth
            />
            <TextField
              id="standard-dense"
              label="Comment"
              value={this.state.comment}
              margin="dense"
              multiline
              onChange={e => this.setState({ comment: e.target.value })}
              fullWidth
            />

            <input
              accept="pdf/*"
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={e => {
                this.setState({ uploadedFile: e.target.files[0] });
              }}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                style={{ marginLeft: 0, marginTop: 20, marginBottom: 10 }}
              >
                Select File
              </Button>
            </label>

            {this.state.uploadedFile.name !== "" && (
              <React.Fragment>
                <div>File uploaded: {String(this.state.uploadedFile.name)}</div>
              </React.Fragment>
            )}
            <div className={classes.divider} />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this override? This cannot be undone."
              }
            />
            {this.props.isEdit && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditFormRevision.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(EditFormRevision)));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));
