import { LOAD_FORMS } from "../Constants/ActionTypes";

const intitialState = {
    forms: [],
    isLoaded: false
};

const FormReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_FORMS:
            return { ...state, forms: action.payload, isLoaded: true };
        default:
            return state;
    }
};

export default FormReducer;
