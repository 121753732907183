import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

//import Checkbox from '@material-ui/core/Checkbox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";
import InviteTypeModal from "../../Employer/Welcome/InviteTypeModal";


import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import { 
  AddCircleIcon,
  CancelCircleIcon,
  ConfirmCircleIcon,
} from "../../Shared/Components/Icons";

import { connect } from "react-redux";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  selectedInvite: [],
  invitations: [],
  anchorEl: null,
  status: NOT_LOADED,
  showInputs: false,

  invitationModalOpen: false
};

class InvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.Open && this.props.Open){
      if(this.props.Type === 1)
        this.getEmployerEmployeeInvitations();
      if(this.props.Type === 3)
        this.getBrokerEmployeeInvitations();
    }
  }

  getBrokerEmployeeInvitations = () => { 
    return new Promise((resolve, reject) => {
      api
        .getBrokerEmployeeInvitations(this.props.Customer)
        .then(response => {
          this.setState({ invitations: response });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Broker Employee Invitations" });
          console.log(err);
        });
    });
  };

  addBrokerEmployeeInvitations = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      inviter_id: this.props.Inviter,
      employer_id: this.props.Employer,
      
    });
    return new Promise((resolve, reject) => {
      console.log(this.props.Employer);
      api
        .addBrokerEmployeeInvitations(this.props.Customer, this.props.Employer, body)
        .then(r => {
          var temp = this.state.invitations
          temp.push(r)
          this.setState({invitations: temp, showInputs: false}, resolve("done"))    
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Broker Employee Invitation" });
          console.log(err);
        });
    });
  };

  deleteBrokerEmployeeInvitations = (inviteID) => {
    return new Promise((resolve, reject) => {
      api
        .deleteBrokerEmployeeInvitations(inviteID)
        .then(r => {
          var invitations = this.state.invitations;
          invitations = invitations.filter(i => i.id != this.state.selectedInvite.id );
          this.setState({invitations: invitations, selectedInvite: {},firstName: "", lastName:"", email:""}, resolve("done"));
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Broker Employee Invitations" });
          console.log(err);
        });
    });
  };

  getEmployerEmployeeInvitations = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerEmployeeInvitations(this.props.Employer)
        .then(response => {
          this.setState({ invitations: response });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employee Invitations" });
          console.log(err);
        });
    });
  };

  addEmployerEmployeeInvitations = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      inviter_id: this.props.Inviter,
      type: this.props.Type
    });
    return new Promise((resolve, reject) => {
      console.log(this.props.Employer);
      api
        .addEmployerEmployeeInvitations(this.props.Employer, body)
        .then(r => {
          var temp = this.state.invitations
          temp.push(r)
          this.setState({invitations: temp, showInputs: false}, resolve("done"))    
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Employee Invitation" });
          console.log(err);
        });
    });
  };

  resendEmployeEmployeeInvitation = () => {
    return new Promise((resolve, reject) => {
      api
        .resendEmployeEmployeeInvitation(
          this.state.selectedInvite.id,
        )
        .then(response => {/*
          this.props.enqueueSnackbar("Invitation Email Resent to " + this.state.selectedInvite.name , {
            variant: "success"
            });*/
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Resneding Invitation" });
          console.log(err);
        });
    });
  };

  deleteEmployerEmployeeInvitations = (inviteID) => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployerEmployeeInvitations(inviteID)
        .then(r => {
          var invitations = this.state.invitations;
          invitations = invitations.filter(i => i.id != this.state.selectedInvite.id );
          this.setState({invitations: invitations, selectedInvite: {},firstName: "", lastName:"", email:""}, resolve("done"));
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Invitations" });
          console.log(err);
        });
    });
  };


  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleMenu = (event, invite) => {
    this.setState(
      {
        selectedInvite: invite,
        firstName: invite.first_name
      },
      this.openMenu(event)
    );
  };

  openMenu = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleDeleteInvite = () => {
    if(this.props.Type === 1){
      this.deleteEmployerEmployeeInvitations(this.state.selectedInvite.id).then(r => {
        this.handleCloseMenu()
      })
    }
    if(this.props.Type === 3){
      this.deleteBrokerEmployeeInvitations(this.state.selectedInvite.id).then(r => {
        this.handleCloseMenu()
      })
    }

  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, firstName: "", lastName: "", email: "" });
  };

  cancelAddInvite = () => {
    this.setState({ showInputs: false, firstName: "", lastName: "", email: "" });
  };

  handleAddInvite = () => {
    this.setState({ showInputs: true });
  };

  handleAddInvitation = () => {
    if(this.props.Type === 1)
      this.addEmployerEmployeeInvitations();
    if(this.props.Type === 3)
      this.addBrokerEmployeeInvitations();
  };

  handleResendInvite = () => {
    if(this.props.Type === 1)
        this.resendEmployeEmployeeInvitation();
      if(this.props.Type === 3)
        this.resendBrokerEmployeeInvitation();

  };

  print(){
        window.print();
    }

  render() {
    const { fullScreen, classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <InviteTypeModal 
          isOpen={this.state.invitationModalOpen}
          employerName={this.props.employerName}
          employerCode={this.props.employerCode}
          quotingEndDate={this.props.quotingEndDate}
          close={() => {
            this.setState({ invitationModalOpen: false });
          }}
        />
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          {this.state.invitations.length === 0 && (
            <DialogTitle id="form-dialog-title">No Pending Invitations</DialogTitle>
          )}
          {this.state.invitations.length > 0 && (
            <DialogTitle id="form-dialog-title">Pending Invitations</DialogTitle>
          )}

          <DialogContent style={{ height: 300, width: 400 }}>
            {!this.state.showInputs && (
              <React.Fragment>
                <div >
                <List>
                  {this.state.invitations.map((invite, index) => {
                    return (
                      <ListItem key={index} style={{borderBottom: '1px solid #E9E9E9'}}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              {invite.first_name} {invite.last_name}
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              {invite.email}
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={e => this.handleMenu(e, invite)}
                            aria-label="Menu"
                          >
                            <MoreIcon color={"primary"} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>

                    );
                  })}
                </List>
                <Menu
                  id="menu-location"
                  key={"menu_"}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  open={Boolean(anchorEl)}
                  onClose={this.handleCloseMenu}
                >
                  <MenuItem key={"Menu1_"} onClick={this.handleResendInvite}>
                    Resend Invite
                  </MenuItem>
                  <MenuItem key={"Menu2_"} onClick={this.handleDeleteInvite}>
                    Delete Invite
                  </MenuItem>
                </Menu>
                </div>
                <div>
                  <Button
                  className={classes.button}
                  style={{ marginLeft: -8 }}
                  onClick={this.handleAddInvite}
                  color="primary"
                >
                  <AddCircleIcon className={classes.leftIcon} />
                  Invite Employee
                </Button>
                </div>
              </React.Fragment>
            )}
            {this.state.showInputs && (
              <React.Fragment>
                <TextField
                  id="standard-dense"
                  label="First Name"
                  value={this.state.firstName}
                  margin="dense"
                  onChange={e => this.setState({ firstName: e.target.value })}
                  style={{ marginBottom: 20 }}
                  fullWidth
                />
                <TextField
                  id="standard-dense"
                  label="Last Name"
                  value={this.state.lastName}
                  margin="dense"
                  onChange={e => this.setState({ lastName: e.target.value })}
                  fullWidth
                  style={{ marginBottom: 20 }}
                />
                <TextField
                  id="standard-dense"
                  label="Email"
                  value={this.state.email}
                  margin="dense"
                  onChange={e => this.setState({ email: e.target.value })}
                  fullWidth
                />
                <IconButton
                  className={classes.button}
                  style={{ marginTop: 10, marginLeft: -17 }}
                  onClick={this.cancelAddInvite}
                  color="primary"
                >
                  <CancelCircleIcon />
                </IconButton>
                <IconButton
                  className={classes.button}
                  style={{ marginTop: 10, marginLeft: -5 }}
                  onClick={this.handleAddInvitation}
                  color="primary"
                >
                  <ConfirmCircleIcon />
                </IconButton>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
          <div style={{display:'flex', width:'100%', justifyContent: 'space-between'}}>
            <div>
            {this.props.PrintableOption && (
            <Button onClick={() => {this.setState({invitationModalOpen: true}) }} color="primary">
              Printable Version
            </Button>
            )}
            </div>
            <div>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InvitationModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

InvitationModal.defaultProps = {
  isOpen: false
};

export default withMobileDialog()(withStyles(styles)(InvitationModal));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));