import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import MapFields from "./MapFields";
import MapRules from "./MapRules";
import MapMedical from "./MapMedical";
import MapGroup from "./MapGroup";

import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";
import { styles } from "../../Shared/Styles/ContainerStyles";
import config from "../../config";

function TabContainer(props) {
  return <div style={{ padding: 8 * 3 }}>{props.children}</div>;
}

const mapStateToProps = state => {
  return {
    formRevisionTable: state.TableReducer.formRevisionTable
  };
};

class MappingContainer extends React.Component {
  state = {
    value: 0,
    isLoading: false,
    fields: [],
    snackbarOpen: false,
    snackbarMessage: ""
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getFields(this.props.formRevisionTable.selected.id).then(fields => {
      this.setState({ isLoading: false, fields: fields });
    });
  }

  setLoading = isLoading => {
    this.setState({ isLoading: isLoading });
  };

  updateField = field => {
    this.setState({
      fields: this.state.fields.map(f => {
        if (f.id === field.id) return field;
        else return f;
      })
    });
  };
  getFields = revisionID => {
    return new Promise((resolve, reject) => {
      api
        .getFields(revisionID)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Fields" });
          console.log(err);
        });
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <header className={classes.containerHeader}>
            <Typography className={classes.containerTitle}>
              Mapping Rules -{" "}
              {this.props.formRevisionTable.selected.form_name +
                " " +
                this.props.formRevisionTable.selected.major_revision +
                "." +
                this.props.formRevisionTable.selected.minor_revision}
            </Typography>
          </header>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper}>
              {(this.state.isLoading && (
                <div className={classes.waitCircle}>
                  <CircularProgress />
                </div>
              )) || (
                <React.Fragment>
                  <AppBar position="static">
                    <Tabs value={value} onChange={this.handleTabChange}>
                      <Tab label="Field Setup" />
                      <Tab label="Rules" />
                      <Tab label="Medical" />
                      <Tab label="Groups" />
                    </Tabs>
                  </AppBar>

                  {value === 0 && (
                    <TabContainer>
                      <MapFields
                        revisionID={this.props.formRevisionTable.selected.id}
                        fields={this.state.fields}
                        updateField={this.updateField}
                      />
                    </TabContainer>
                  )}
                  {value === 1 && (
                    <TabContainer>
                      <MapRules
                        setLoading={this.setLoading}
                        revisionID={this.props.formRevisionTable.selected.id}
                        updateField={this.updateField}
                      />
                    </TabContainer>
                  )}
                  {value === 2 && (
                    <TabContainer>
                      <MapMedical
                        revisionID={this.props.formRevisionTable.selected.id}
                        updateField={this.updateField}
                      />
                    </TabContainer>
                  )}
                  {value === 3 && (
                    <TabContainer>
                      <MapGroup
                        revisionID={this.props.formRevisionTable.selected.id}
                        fields={this.state.fields}
                        updateField={this.updateField}
                      />
                    </TabContainer>
                  )}
                </React.Fragment>
              )}
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(MappingContainer));
