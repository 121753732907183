import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Select from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import api from "../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";
import config from "../../config";

const styles = theme => ({
	title: {
		fontSize: 28,
		color: "grey"
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		backgroundColor: theme.palette.text.error,
		overflowX: "auto"
	},
	spacer: {
		flex: "1 1 100%"
	},
	actions: {
		color: theme.palette.text.secondary
	},
	actionsSelected: {
		display: "flex",
		flex: 1
	},
	title: {
		flex: "0 0 auto"
	},
	titleSelected: {
		flex: 1
	}
});

class MapMedical extends React.Component {
	state = {
		editConditionRules: false,
		conditions: [],
		mappingRules: [],
		activeConditionRules: [],
		activeRule: { field: { name: "" } },
		selectedCondition: "",
		deleteMappingRule: {},
		confirmAlertOpen: false
	};
	componentDidMount() {
		this.getRules().then(rules => {
			var temp = rules.map((rule, index) => {
				return {
					...rule,
					sort_order: index,
					isChanged: false,
					selected: false
				};
			});
			this.setState({ mappingRules: temp });
		});

		this.getConditions().then(c => {
			var conditionList = [];
			c.forEach(condition => {
				condition.value = condition.id;
				condition.label = condition.name;
				conditionList.push(condition);
			});
			this.setState({ conditions: conditionList });
		});
	}

	getRules = () => {
		return new Promise((resolve, reject) => {
			api
				.getMappingRules(this.props.revisionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Mapping Rules" });
					console.log(err);
				});
		});
	};

	persistRemoveRule = mappingRuleID => {
		return new Promise((resolve, reject) => {
			api
				.deleteMappingRule(mappingRuleID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Deleting Mapping Rule" });
					console.log(err);
				});
		});
	};

	getConditions = () => {
		return new Promise((resolve, reject) => {
			api
				.getConditions()
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting Mapping Rules" });
					console.log(err);
				});
		});
	};

	getConditionMappingRules = () => {
		return new Promise((resolve, reject) => {
			api
				.getConditionMappingRules(this.props.revisionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Getting Condition Mapping Rules"
					});
					console.log(err);
				});
		});
	};

	getICDForCondition = conditionID => {
		return new Promise((resolve, reject) => {
			api
				.getICDForCondition(conditionID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Getting ICD for Condition" });
					console.log(err);
				});
		});
	};

	persistUpdateRule = rule => {
		console.log('update rule!')
		var body = JSON.stringify({
			id: rule.field,
			relationship_type: 4,
			//field: rule.field,
			field_id: rule.field.id,
			model_id: "69fb819d13f3452d8150455ab53c7f98",
			timeframe: rule.timeframe
		});
		return new Promise((resolve, reject) => {
			api
				.updateMappingRule(rule.id, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({ errorMessage: "Error Updating Rule" });
					console.log(err);
				});
		});
	};

	persistAddConditionRule = (rule, icd) => {
		var body = JSON.stringify({
			mapping_rule_id: rule.id,
			icd: icd,
			icd_id: icd.id
		});
		return new Promise((resolve, reject) => {
			api
				.addConditionMappingRule(this.props.revisionID, body)
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Adding Condition Mapping Rule"
					});
					console.log(err);
				});
		});
	};

	persistRemoveConditionRule = crID => {
		return new Promise((resolve, reject) => {
			api
				.deleteConditionMappingRule(crID)
				.then(r => {
					resolve(r);
				})
				.catch(err => {
					this.setState({
						errorMessage: "Error Deleting Condition Mapping Rule"
					});
					console.log(err);
				});
		});
	};

	handleMedicalChange(rule) {
		var field = rule.field;
		this.persistRemoveRule(rule.id).then(r => {
			field.has_condition_mapping_rule = false;
			field.in_use = false;
			this.props.updateField(field);

			//remove from state
			var temp = this.state.mappingRules;
			temp = temp.filter(mr => mr.id != rule.id);
			this.setState({ mappingRules: temp });
		});
	}

	saveRules = () => {
		this.state.mappingRules.forEach(rule => {
			if (rule.isChanged) {
				this.persistUpdateRule(rule).then(r => {
					this.props.enqueueSnackbar("UPDATED " + rule.field.name, {
						variant: "success"
					});
					var temp = this.state.mappingRules.map(mr => {
						if (mr.id === rule.id) return { ...rule, isChanged: false };
						else return mr;
					});
					this.setState({ mappingRules: temp });
				});
			}
		});
	};

	handleChange = (val, type) => {
		console.log("handleChange");
		var rule = this.state.activeRule;
		this.getICDForCondition(val.id).then(icd => {
			console.log(icd);
			var promises = [];
			//check condition rules in redux and make sure that this condition rule isnt in there
			icd.forEach(icd_obj => {
				promises.push(this.persistAddConditionRule(rule, icd_obj.icd));
			});
			Promise.all(promises).then(conditionRules => {
				if (
					rule.model != "69fb819d-13f3-452d-8150-455ab53c7f98" &&
					rule.model != "69fb819d13f3452d8150455ab53c7f98"
				) {
					rule.model = "69fb819d13f3452d8150455ab53c7f98";
					//update the mapping rule in state with new info
					this.persistUpdateRule(rule).then(r => {
						var temp = this.state.mappingRules;
						temp = temp.map(mr => {
							if (mr.id === rule.id) return rule;
							else return mr;
						});
						this.setState({ mappingRules: temp });
					});
				}
				//update the condition rules in state for modal list
				var temp = this.state.activeConditionRules;

				console.log(temp);
				conditionRules.forEach(cr => temp.push(cr));
				this.setState({ activeConditionRules: temp });

				//update parent component b/c field now in use
				var field = rule.field;
				field.in_use = true;
				this.props.updateField(field);
			});
		});
	};

	removeConditionRule = cr => {
		//first remove rule from db, then active state=
		this.persistRemoveConditionRule(cr.id).then(r => {
			var temp = this.state.activeConditionRules.filter(
				cr_in_state => cr_in_state.id != cr.id
			);
			this.setState({ activeConditionRules: temp });
		});
	};

	editConditionRules = rule => {
		console.log("editing rules");
		console.log(rule);
		this.getConditionMappingRules().then(conditionRules => {
			console.log(conditionRules);
			var filterConditionRules = [];
			if (rule.id != -1) console.log("filtering rules");
			filterConditionRules = conditionRules.filter(
				cr => cr.mapping_rule.id === rule.id
			);
			this.setState({
				activeConditionRules: filterConditionRules,
				activeRule: rule,
				editConditionRules: true
			});
		});
	};

	handleTimeFrameChange = (e, rule) => {
		rule.timeframe = e.target.value;
		rule.isChanged = true;
		this.setState({
			mappingRules: this.state.mappingRules.map(mr => {
				if (mr.id === rule.id) return rule;
				else return mr;
			})
		});
	};

	handleICDEditClose = () => {
		this.setState({
			editConditionRules: false,
			activeConditionRules: [],
			activeRule: { field: { name: "" } }
		});
	};

	handleConfirmDelete = rule => {
		this.setState({ confirmAlertOpen: true, deleteMappingRule: rule });
	};

	DoNotDelete = () => {
		this.setState({ confirmAlertOpen: false });
	};

	DoDelete = () => {
		this.setState({ confirmAlertOpen: false });
		this.handleMedicalChange(this.state.deleteMappingRule);
	};

	render() {
		const { classes, theme } = this.props;
		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			})
		};

		return (
			<div>
				<ConfirmAlert
					isOpen={this.state.confirmAlertOpen}
					isNo={this.DoNotDelete}
					isYes={this.DoDelete}
					AlertTitle={"Confirm Delete"}
					AlertText={"Are you sure you wish to delete this mapping rule"}
				/>
				<Toolbar>
					<React.Fragment>
						<div className={classes.spacer} />

						<div className={classes.actions}>
							<IconButton onClick={this.saveRules} aria-label="Filter list">
								<SaveIcon />
							</IconButton>
						</div>
					</React.Fragment>
				</Toolbar>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: 1000 }}>Form Field</TableCell>
							<TableCell>Medical</TableCell>
							<TableCell style={{ width: 50 }}>Time Frame</TableCell>
							<TableCell>Edit ICD</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{this.state.mappingRules.map((rule, index) => {
							if (rule.type === 2) {
								return (
									<TableRow key={index}>
										<TableCell>{rule.field.name}</TableCell>
										<TableCell>
											<FormControlLabel
												control={
													<Checkbox
														icon={<FavoriteBorder />}
														checkedIcon={<Favorite />}
														onChange={() => this.handleConfirmDelete(rule)}
														checked={rule.type === 2}
													/>
												}
												label=""
											/>
										</TableCell>

										<TableCell>
											<TextField
												id="standard-dense"
												label=""
												margin="dense"
												value={rule.timeframe}
												onChange={e => this.handleTimeFrameChange(e, rule)}
											/>
										</TableCell>
										<TableCell>
											<IconButton onClick={() => this.editConditionRules(rule)}>
												<EditIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							}
						})}
					</TableBody>
				</Table>

				<Dialog
					open={this.state.editConditionRules}
					aria-labelledby="form-dialog-title"
					scroll="paper"
					onClose={this.handleICDEditClose}
				>
					<DialogTitle id="form-dialog-title">
						{this.state.activeRule.field.name}
					</DialogTitle>
					<DialogContent style={{ width: 500, minHeight: 600 }}>
						<Select
							styles={selectStyles}
							value={this.state.selectedCondition}
							onChange={(val, type) => this.handleChange(val, type)}
							options={this.state.conditions}
						/>
						{
							<List>
								{this.state.activeConditionRules
									.sort((a, b) => a.icd.code.localeCompare(b.icd.code))
									.map((cr, index) => {
										return (
											<ListItem key={index}>
												<ListItemText
													primary={
														cr.icd.description + " (" + cr.icd.code + ")"
													}
												/>
												<ListItemSecondaryAction>
													<IconButton
														aria-label="Delete"
														onClick={() => this.removeConditionRule(cr)}
													>
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										);
									})}
							</List>
						}
					</DialogContent>

					<DialogActions>
						<Button onClick={this.handleICDEditClose} color="primary">
							Done
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withSnackbar(
	withStyles(styles, { withTheme: true })(MapMedical)
);
