import config from "../../../config";

export const getProfiles = (Auth, userID) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(
          config.apiGateway.URL + "/api/v1/users/" + userID + "/family-members",
          {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          }
        )
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const getUserAddresses = (Auth, userID) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        fetch(
          config.apiGateway.URL + "/api/v1/users/" + userID + "/user-addresses",
          {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          }
        )
          .then(response => {
            return response.json();
          })
          .then(responseData => {
            resolve(responseData);
          })
          .catch(err => {
            console.log("fetch error" + err);
          });
      })
      .catch(err => {
        console.log("error happened", err);
        return;
      });
  });
};

export const TakingCoverage = (profiles, profile_id) => {
  var profile = profiles.find(c => c.id === profile_id);

  if (profile.cover_medical) {
    return true;
  } else {
    return false;
  }
};

export const getEmployeeName = (profiles, profile_id) => {
  var profile = profiles.find(p => p.id === profile_id);

  return profile.first_name + " " + profile.last_name;
};
