import { LOAD_CONDITION_CATEGORIES } from "../Constants/ActionTypes";

const intitialState = {
    conditionCategories: {},
    isLoaded: false
};

const StaticReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_CONDITION_CATEGORIES:
            return {
                ...state,
                conditionCategories: action.payload,
                isLoaded: true
            };
        default:
            return state;
    }
};

export default StaticReducer;
