import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/SettingsMenuStyles";

import { SettingsIcon, ConditionIcon } from "../../Shared/Components/Icons";

class SettingsMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleICDMapping = () => {
    this.props.history.push("/admin/icd");
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        {1 === 1 && (
          <React.Fragment>
            <IconButton
              aria-owns={open ? "menu-settings" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <SettingsIcon className={classes.settingsMenuIcon} />
            </IconButton>

            <Menu
              id="menu-settings"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleICDMapping}>
                <ConditionIcon className={classes.settingsMenuItemIcon} />
                ICD Mapping
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

SettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SettingsMenu);
