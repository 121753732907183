import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import api from "../../Shared/Functions/APIHelpers";

import { DeleteIcon } from "../../Shared/Components/Icons";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import { Auth } from "aws-amplify";
import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";


const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const initialState = {
  selectedForm: ''
};

class SelectEmployerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {

  }


  handleClose = () => {
    this.reset();
    this.props.Close();
  };

  handleView = (selectedForm) => {
    this.props.history.push({
      pathname: '/broker/form-review',
      state: { employer: this.props.Employer, employee: this.props.Employee, form:selectedForm.id, type: this.props.Type}
    })
    this.handleClose();
  };


  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
           Select Employer Form
          </DialogTitle>

          <DialogContent style={{ width: 335}}>

              {this.props.EmployerForms.map( (form, index) => {
              return(
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    key={index}
                    fullWidth
                    style={{marginBottom:10}}
                    onClick={() => {this.handleView(form)}}
                  >
                    {form.name}
                  </Button>
                )
              })}  
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SelectEmployerForm.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default (withMobileDialog()(withStyles(styles)(SelectEmployerForm)));
