import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { Auth } from "aws-amplify";
import config from "../config";

import { connect } from "react-redux";

import SecuritySettings from "../Shared/UserSettings/SecurityContainer";
import EmployerSetup from "../Auth/Employer/EmployerSetup";
//import EmployersContainer from './Employers/EmployersContainer'
import EmployeesContainer from "./Employees/EmployeesContainer";

//import RecipientsContainer from './Recipients/RecipientsContainer'
import NavList from "./Nav/NavList";
import WelcomePage from "./Welcome/WelcomePage";

import BaseContainer from "../Shared/Layouts/BaseContainer";

import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./Redux/Store/index";

import { loadStates, loadForms, loadUser } from "../Shared/Redux/Actions/index";
import {
  getStates,
  getForms,
  getEmployer
} from "../Shared/Functions/StaticFunctions";

import SummaryContainer from "./EmployeeSummary/SummaryContainer";
import BrokersContainer from "./Brokers/BrokersContainer";
import SettingsMenu from "./Nav/SettingsMenu";
import PrintableInvite from "./Printable/PrintableInvite";

const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
    loadStates: states => dispatch(loadStates(states)),
    loadForms: forms => dispatch(loadForms(forms))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    isStatesLoaded: state.StateReducer.isLoaded,
    isFormsLoaded: state.FormReducer.isLoaded,
    isUserLoaded: state.AuthReducer.isLoaded,
  };
};

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {}
  };

  componentDidMount() {
    if (!this.props.isUserLoaded) {
      getEmployer(Auth).then(user => {
        console.log(user)
        this.props.loadUser(user);
      })
      .catch(err => {
        //if 401 comes back from get user, they have not agreed to terms
        this.props.history.push("setup-complete");
      });
    }

    if (!this.props.isStatesLoaded) {
      getStates(Auth).then(states => {
        this.props.loadStates(states);
      });
    }
    if (!this.props.isFormsLoaded) {
      getForms(Auth).then(forms => {
        this.props.loadForms(forms);
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <BaseContainer
              {...props}
              reformUser={this.props.auth}
              hasMessageBar={false}
              hasMessageMenu={false}
              messageSummaries={[]}
              user={this.props.user}
              component={Component}
              navList={NavList}
              home={"/employer"}
              settingsMenu={SettingsMenu}
              isLoaded={this.props.isStatesLoaded && this.props.isUserLoaded} 
              fixed={this.props.fixed}
              
            />
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute)
PrivateRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivateRoute)
);

const Routes = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/employer"
            fixed={true}
            user={props.user}
            component={WelcomePage}
          />
          <Route
            path="/employer/setup-complete"
            render={props => (
              <EmployerSetup
                {...props}
                user={props.user}
                flowBypass="agreement"
              />
            )}
          />
          <PrivateRoute
            exact
            path="/employer/home"
            fixed={true}
            user={props.user}
            component={WelcomePage}
          />
          <PrivateRoute
            path="/employer/security"
            user={props.user}
            component={SecuritySettings}
          />
          <PrivateRoute
            path="/employer/employees"
            fixed={true}
            user={props.user}
            component={EmployeesContainer}
          />
          <PrivateRoute
            path="/employer/printable-invite"
            fixed={true}
            user={props.user}
            component={PrintableInvite}
          />

          <PrivateRoute
            path="/employer/employee-summary"
            fixed={true}
            user={props.user}
            component={SummaryContainer}
          />
          <PrivateRoute
            path="/employer/invitations"
            fixed={true}
            user={props.user}
            component={EmployeesContainer}
          />
          <PrivateRoute
            path="/employer/brokers"
            fixed={true}
            user={props.user}
            component={BrokersContainer}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
//export default connect(mapStateToProps, mapDispatchToProps)(Routes);
