import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AutoSizer,
  Column,
  SortDirection,
  Table,
  InfiniteLoader
} from "react-virtualized";

import { Auth } from "aws-amplify";
import config from "../../config";


const styles = theme => ({
  gridOverride: {
    "&:focus": {
      outline:'none'
    }
  },
 'header': {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    },
    "&:focus": {
      outline:'none'
    }
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  },

  table: {
    fontFamily: theme.typography.fontFamily,
  },
  tableCell: {
    flex: 1,
    "@media only screen and (max-width: 650px)": {
      display: "none !important",
      flexBasis: "0 !important",
      minWidth: "0 !important",
      width: "0 !important"
    }
  },
  tableCellFirst: {
    flex: 1,
    paddingLeft: 40,
    "@media only screen and (max-width: 400px)": {}
  },
  flexContainerFirst: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "@media only screen and (max-width: 400px)": {}
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "@media only screen and (max-width: 650px)": {
      display: "none !important",
      flex: "0 !important",
      minWidth: "0 !important",
      width: "0 !important"
    }
  },
  hiddenHeader: {
    "@media only screen and (max-width: 650px)": {
      display: "none !important",
      flex: "0 !important",
      minWidth: "0 !important",
      width: "0 !important"
    }
  },
  hiddenRows: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box"
  },
  actions: {
    color: theme.palette.text.primary
  },
  spacer: {
    flex: "1 1 100%"
  },
  title: {
    flex: "0 0 auto"
  },
  waitCircle: {
    width: "100%",
    textAlign: "center",
    float: "left",
    marginTop: "30vh"
  },
  column: {
    flex: 1
  },
  chip: {
    margin: 1
  }
});

class CustomTable extends React.Component {
  state = {
    rowHeight: 0,
    loadedData: [],
    rowCount: 10000,
    filtersOpen: false,
    anchorEl: null,
    sortBy: "",
    sortDirection: "ASC",
    providedFilters: []
  };

  componentDidMount() {
    //bind refresh table to passed in function
    this.props.setRefresh(this.refreshTable);
    this.setState({
      rowHeight: 48, //this.props.rowHeight,
      providedFilters: this.props.filters.map(f => {
        return { ...f, value: "default" };
      })
    });

    this.getData();
  }

  getData = () => {
    if (this.props.infinite) {
      this.getRowCount().then(rowCount => {
        this.setState({ rowCount: rowCount }, () => {
          this.loadMoreRows({ startIndex: 0, stopIndex: 10 });
        });
      });
    } else {
      this.getDataFromServer({ startIndex: null, stopIndex: null }).then(
        result => {
          var tempData = this.state.loadedData;
          result.forEach(returnItem => {
            tempData.push(returnItem);
          });
          this.setState({ loadedData: tempData });
        }
      );
    }
  };

  getDataFromServer = ({ startIndex, stopIndex }) => {
    var url = this.props.getDataURL;
    if (this.props.infinite)
      url = url + "?start=" + startIndex + "&end=" + stopIndex;

    this.state.providedFilters.forEach((filter, index) => {
      if (filter.value != "default") {
        if (this.props.infinite)
          url = url + "&" + filter.modelColumn + "=" + filter.value;
        else if (!index)
          url = url + "?" + filter.modelColumn + "=" + filter.value;
        else url = url + "&" + filter.modelColumn + "=" + filter.value;
      }
    });

    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;

          fetch(config.apiGateway.URL + "/api/v1/" + url, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(result => {
              resolve(result);
            })
            .catch(err => {
              console.log("fetch error" + err);
            });
        })
        .catch(err => {
          console.log("error happened", err);
          return;
        });
    });
  };

  getRowCount = () => {
    if (!this.props.infinite) return;
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.idToken.jwtToken;

          fetch(config.apiGateway.URL + this.props.rowCountEndpoint, {
            headers: new Headers({
              Authorization: "Bearer " + token,
              "Content-Type": "application/coreapi+json"
            }),
            method: "GET",
            mode: "cors"
          })
            .then(response => {
              return response.json();
            })
            .then(responseData => {
              resolve(responseData);
            })
            .catch(err => {
              console.log("fetch error" + err);
            });
        })
        .catch(err => {
          console.log("error happened", err);
          return;
        });
    });
  };

  refreshTable = () => {
    if (this.props.infinite) {
      this.setState({ loadedData: [] }, () => {
        this.loadMoreRows({ startIndex: 0, stopIndex: 10 });
      });
    } else {
      this.setState({ loadedData: [] }, () => {
        this.getDataFromServer({ startIndex: 0, stopIndex: 2 }).then(result => {
          var tempData = this.state.loadedData;
          result.forEach(returnItem => {
            tempData.push(returnItem);
          });
          this.setState({ loadedData: tempData });
        });
      });
    }
  };

  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;
    if (index >= 0)
      return classNames(
        classes.tableRow,
        classes.tableRowHover,
        classes.hiddenRows,
        rowClassName
      );
    else return classNames(classes.tableRow, classes.hiddenRows, rowClassName);
  };

  actionCellRenderer = ({ cellData, rowIndex }) => {
    const { classes } = this.props;
    return (
      <TableCell
        component="div"
        variant="body"
        className={classNames(classes.hiddenRows)}
        style={{ height: this.state.rowHeight }}
      >
        <IconButton
          aria-label="Filter list"
          color="primary"
          onClick={e => {
            this.props.openMenu(e, this.state.loadedData[rowIndex]);
          }}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  cellGetter = ({ columnData, dataKey, rowData }) => {
    var col = this.props.columns.find(c => c.dataKey === dataKey);
    if (col.nested) {
      var prop = dataKey.split(".");
      return rowData[prop[0]][prop[1]];
    } else {
      return rowData[dataKey];
    }
  };

  cellRenderer = ({ cellData, columnIndex = null, rowIndex }) => {
    const { classes } = this.props;
    var column = this.props.columns.find( (c, i) => i === columnIndex )
    
    //handling various data types
    if ( (typeof cellData === "boolean") && (!column.checkMark))
      if (cellData) cellData = "Yes";
      else cellData = "No";
    if(column.isDatetime && cellData){
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      var time = new Date(cellData);
      cellData = time.toLocaleString('en-US', { timeZone: timeZone })
    }
    if(column.checkMark){
      if(cellData){
        cellData=(
          <DoneIcon style={{ fontSize: 18 }} />
        )
      }

    }

    var cellClasses = classNames(
      classes.tableCellFirst,
      classes.flexContainerFirst
    );

    if (columnIndex) {
      cellClasses = classNames(classes.tableCell, classes.flexContainer);
    }

    return (
      <TableCell
        component="div"
        variant="body"
        className={cellClasses}
        style={{ height: this.state.rowHeight }}
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ dataKey, label }) => {
    const { classes } = this.props;
    var style = { height: 36 };
    var content = null;
    var headerClasses = {};

    if (dataKey === "actions") {
      style = { width: 85, height: 36 };
      headerClasses = classNames(
        classes.tableCellFirst,
        classes.flexContainerFirst,
        //classes.header
      );
    } else {
      if (dataKey === this.props.columns[0].dataKey) {
        headerClasses = classNames(
          classes.tableCellFirst,
          classes.flexContainerFirst
        );
      } else {
        headerClasses = classNames(classes.tableCell, classes.flexContainer, );
      }
    }

    content = (
      <React.Fragment>
        <Typography variant="caption">{label}</Typography>
        {this.state.sortBy === dataKey &&
          this.state.sortDirection === "ASC" && (
            <UpIcon style={{ fontSize: 14 }} />
          )}
        {this.state.sortBy === dataKey &&
          this.state.sortDirection === "DESC" && (
            <DownIcon style={{ fontSize: 14 }} />
          )}
      </React.Fragment>
    );

    return (
      <TableCell
        className={headerClasses}
        component="div"
        variant="head"
        style={style}
      >
        {content && content}
      </TableCell>
    );
  };

  sortTable = ({ sortBy, sortDirection }) => {
    var col = this.props.columns.find(c => c.dataKey === sortBy);
    var temp = this.state.loadedData;

    if (sortDirection === "ASC") {
      if (col.nested) {
        temp.sort((a, b) => {
          var aSplit = sortBy.split(".");
          var bSplit = sortBy.split(".");
          return a[aSplit[0]][aSplit[1]].localeCompare(b[bSplit[0]][bSplit[1]]);
        });
      } else if (col.boolean) {
        temp.sort((a, b) => (a === b ? 0 : a ? 1 : -1));
      } else {
        temp.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
      }
    }
    if (sortDirection === "DESC") {
      if (col.nested) {
        temp.sort((a, b) => {
          var aSplit = sortBy.split(".");
          var bSplit = sortBy.split(".");
          return b[bSplit[0]][bSplit[1]].localeCompare(a[aSplit[0]][aSplit[1]]);
        });
      } else if (col.boolean) {
        temp.sort((a, b) => (a === b ? 0 : a ? 1 : -1));
      } else {
        temp.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
      }
    }

    this.setState({ loadedData: temp, sortBy, sortDirection });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.props.infinite) {
      this.getDataFromServer({ startIndex, stopIndex }).then(result => {
        var tempData = this.state.loadedData;
        result.forEach(returnItem => {
          tempData.push(returnItem);
        });
        this.setState({ loadedData: tempData });
      });
    }
  };

  isRowLoaded = ({ index }) => {
    return !!this.state.loadedData[index];
  };

  handleOpenFilterMenu = e => {
    this.setState({ anchorEl: e.currentTarget, filtersOpen: true });
  };

  handleCloseFilterMenu = () => {
    this.setState({ anchorEl: null, filtersOpen: false });
  };

  resetFilters = () => {
    var temp = this.state.providedFilters;
    temp = temp.map(f => {
      return { ...f, value: "default" };
    });
    this.setState({ providedFilters: temp, loadedData: [] }, () => {
      this.getData();
    });
  };

  addFilter = (e, filter) => {
    var temp = this.state.providedFilters;
    temp = temp.map(f => {
      if (f.column === filter.column) {
        return { ...f, value: e.target.value };
      } else {
        return f;
      }
    });
    this.setState({ providedFilters: temp, loadedData: [] }, () => {
      this.getData();
    });
  };

  removeFilter = filter => {
    var temp = this.state.providedFilters;
    temp = temp.map(f => {
      if (f.column === filter.column) return { ...f, value: "default" };
      else return f;
    });
    this.setState({ providedFilters: temp, loadedData: [] }, () => {
      this.getData();
    });
  };

  render() {
    const { classes, columns } = this.props;
    return (
      <React.Fragment>
        <Toolbar style={{ minHeight: 0 }}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              {this.props.name}
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title="Filter list">
              <IconButton
                style={{ padding: 8 }}
                onClick={this.handleOpenFilterMenu}
                aria-label="Filter list"
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Popover
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.filtersOpen}
          onClose={this.handleCloseFilterMenu}
        >
          <div style={{ height: 300, width: 450, padding: 10 }}>
            <Typography variant="h6">
              Filters
              <Button
                style={{ marginLeft: 20 }}
                color="primary"
                onClick={this.resetFilters}
              >
                Reset
              </Button>
            </Typography>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around"
              }}
            >
              {this.state.providedFilters.map((filter, index) => {
                return (
                  <FormControl margin="dense" key={index}>
                    <InputLabel htmlFor="filter">{filter.column}</InputLabel>
                    <Select
                      value={filter.value}
                      onChange={e => {
                        this.addFilter(e, filter);
                      }}
                      index={index}
                      inputProps={{
                        name: "filter",
                        id: "filter"
                      }}
                      style={{ width: 200 }}
                    >
                      <MenuItem value="default">All</MenuItem>
                      {filter.options.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              })}
            </div>
          </div>
        </Popover>

        <div id="filterChips" style={{ paddingLeft: 28 }}>
          {this.state.providedFilters.map((filter, index) => {
            if (filter.value != "default") {
              return (
                <Chip
                  key={index}
                  label={filter.column + " : " + filter.value}
                  onDelete={() => {
                    this.removeFilter(filter);
                  }}
                  className={classes.chip}
                />
              );
            }
          })}
        </div>
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          rowCount={this.state.rowCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                  <React.Fragment>
                  <Table
                    ref={registerChild}
                    className={classes.table}
                    rowHeight={this.state.rowHeight}
                    rowCount={this.state.loadedData.length}
                    width={width}
                    height={height}
                    headerHeight={36}
                    rowGetter={({ index }) => this.state.loadedData[index]}
                    onRowsRendered={onRowsRendered}
                    gridClassName={classes.gridOverride}
                    rowClassName={this.getRowClassName}
                    sort={this.sortTable}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    style={{ marginBottom: 75}}
                  >
                    {columns.map((col, index) => {
                      return (
                        <Column
                          key={col.dataKey}
                          label={col.label}
                          dataKey={col.dataKey}
                          headerRenderer={this.headerRenderer}
                          headerClassName={classNames(
                            classes.header, 
                            {[classes.hiddenHeader]: index}
                          )}
                          className={classNames(
                            { [classes.flexContainerFirst]: !index },
                            { [classes.flexContainer]: index }
                          )}
                          cellRenderer={this.cellRenderer}
                          cellDataGetter={this.cellGetter}
                          flexGrow={1}
                          width={col.width}
                          minWidth={75}
                        />
                      );
                    })}

                    <Column
                      //flexGrow={1}
                      width={85}
                      minWidth={85}
                      dataKey="actions"
                      style={{ minHeight: 30 }}
                      //className={classes.flexContainerFirst}
                      cellRenderer={this.actionCellRenderer}
                      headerRenderer={this.headerRenderer}
                      headerClassNames={classNames(classes.header)}
                    />
                  </Table>
                  </React.Fragment>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomTable);