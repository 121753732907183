import { combineReducers } from 'redux'
import TableReducer from './TableReducer'
import UIReducer from './UIReducer'
import MappingReducer from './MappingReducer'
import AuthReducer from '../../../Shared/Redux/Reducers/AuthReducer'
import StateReducer from '../../../Shared/Redux/Reducers/StateReducer'
import FormReducer from '../../../Shared/Redux/Reducers/FormReducer'

const rootReducer = combineReducers({ TableReducer, UIReducer, MappingReducer, AuthReducer, StateReducer, FormReducer });

export default rootReducer;