import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import FormLabel from "@material-ui/core/FormLabel";

import MobileStepper from "@material-ui/core/MobileStepper";

import Agreement from "../Agreement";

import Button from "@material-ui/core/Button";

import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";

import { styles } from "../Styles/AuthStyles";

import config from "../../config";

class EmployerSetup extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    inviteCode: "",
    step: 1,
    finishDisabled: true,
    agreementModalOpen: false,
    invite: "",
    firstName: "",
    lastName: "",
    newUser: "",
    employerName: "",
    employerStreet: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    employerPhone: "",
    renewalMonth: "",
    renewalDay: "",
    complete: false,
    isLoading: true
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let customerCode = params.get("customer");

    this.getUser();

    if (customerCode) {
      this.handleGetCustomer(customerCode);
      //this.GetCustomer(customerCode);
    }
  }

  getUser = () => {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then(response => {
          this.setState({
            firstName: response.first_name,
            lastName: response.last_name,
            newUser: response.new_user
          });

          if (!response.new_user) {
            this.setState({ step: 2 });
          }
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  updateUser = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        first_name: this.state.firstName,
        last_name: this.state.lastName
      });
      api
        .updateUser(body)
        .then(response => {
          this.props.history.push("/home");
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Address" });
          console.log(err);
        });
    });
  };

  GetCustomer = code => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerByCode(code)
        .then(response => {
          console.log(response);
          this.setState({
            isLoading: false,
            complete: response.complete,
            customer: response.name
          });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  GetCustomerByCode = code => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerByCodeToLink(code)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  handleGetCustomer = code => {
    this.GetCustomerByCode(code).then(response => {
      if (response.customerNotExists) {        
      } else if (response.setupExists) {
        this.setState({
          isLoading: false,
          step: 2,
          complete: true,
          customer: response.customer_name
        });
      } else{
        this.setState({
          step:2,
          isLoading: false,
          complete: false,
          customer: response.customer_name
        });
      }

    });
  };

  step2 = () => {
    this.setState({ step: 2 });
  };

  step3 = () => {
    this.setState({ step: 3 });
  };

  step4 = () => {
    this.setState({ step: 4 });
  };

  finish = () => {
    //this.updateUser()
    this.props.history.push("/employer/home");
  };

  closeTerms = () => {
    this.setState({ agreementModalOpen: false });
  };
  openTerms = () => {
    this.setState({ agreementModalOpen: true });
  };

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div>
        <Agreement
          isOpen={this.state.agreementModalOpen}
          Close={this.closeTerms}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root_md} elevation={1}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Linking...
              </Typography>
              <div style={{ height: 350, marginTop: 20 }}>
                {this.state.isLoading && (
                  <div style={{ height: 250, marginTop: 100 }}>
                    <Typography variant="body1">
                      Checking invitation...
                    </Typography>
                    <br />
                    <br />
                    <CircularProgress className={classes.progress} />
                  </div>
                )}
                {!this.state.isLoading && this.state.complete && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      Your employer account is already linked to{" "}
                      {this.state.customer}!
                    </Typography>
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.finish}
                        style={{ width: 250 }}
                      >
                        Continue
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 2 && (
                    <React.Fragment>
                      <Typography variant="subtitle2" color="primary">
                        Your employer account is now linked to{" "}
                        {this.state.customer}
                      </Typography>
                      <div style={{ textAlign: "right", marginTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.finish}
                          style={{ width: 100 }}
                        >
                          Finish
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                <br />
              </div>
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

EmployerSetup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployerSetup);