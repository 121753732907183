import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import UsersTable from "./UsersTable";
import Paper from "@material-ui/core/Paper";

import { AddIcon } from "../../Shared/Components/Icons";
import InvitationModal from "../../Shared/Components/InvitationModal";


class UsersContainer extends React.Component {
  state = {
    invitationModalOpen: false
  };
  
  handleOpen = () => {
    this.setState({ invitationModalOpen: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <InvitationModal
          Type={3}
          Employer={this.props.reformUser.employer_id}
          Customer={this.props.reformUser.customer_id}
          Inviter={this.props.reformUser.profile_id}
          Open={this.state.invitationModalOpen}
          Close={() => {
            this.setState({ invitationModalOpen: false });
          }}
        />
        <div className={classes.containerRoot}>
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <UsersTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UsersContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (withStyles(styles)(UsersContainer));
