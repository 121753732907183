import React from "react";

import Account from "@material-ui/icons/AccountCircle";
import Add from "@material-ui/icons/Add";
import AddCircle from "@material-ui/icons/AddCircleOutline";
import Billing from "@material-ui/icons/CreditCard";
import Broker from "@material-ui/icons/Domain";
import Condition from "@material-ui/icons/Favorite";
import CancelCircle from "@material-ui/icons/HighlightOff";
import Checkbox from "@material-ui/icons/CheckBoxOutlined";
import CheckboxBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import ConfirmCircle from "@material-ui/icons/CheckCircleOutline";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Employer from "@material-ui/icons/LocationCity";
import Form from "@material-ui/icons/Description";
import Home from "@material-ui/icons/Home";
import Info from "@material-ui/icons/Info";
import Invitation from "@material-ui/icons/Link";
import Lock from "@material-ui/icons/LockOutlined";
import Message from "@material-ui/icons/QuestionAnswer";
import People from "@material-ui/icons/People";
import Recipients from "@material-ui/icons/Share";
import Revision from "@material-ui/icons/FileCopy";
import Save from "@material-ui/icons/Save";
import Settings from "@material-ui/icons/Settings";
import Send from "@material-ui/icons/AlternateEmail";
import SendPlane from "@material-ui/icons/Send";
import Share from "@material-ui/icons/Share";
import Star from "@material-ui/icons/Star";
import Summary from "@material-ui/icons/Assignment";
import Team from "@material-ui/icons/SupervisorAccount";
import Text from "@material-ui/icons/TextFields";
import User from "@material-ui/icons/Person";

export const AccountIcon = props => (
    <Account className={props.className} style={props.style} />
);

export const AddIcon = props => (
    <Add className={props.className} style={props.style} />
);

export const AddCircleIcon = props => (
    <AddCircle className={props.className} style={props.style} />
);

export const BillingIcon = props => (
    <Billing className={props.className} style={props.style} />
);

export const BrokerIcon = props => (
    <Broker className={props.className} style={props.style} />
);

export const CancelCircleIcon = props => (
    <CancelCircle className={props.className} style={props.style} />
);

export const CheckboxIcon = props => (
    <Checkbox className={props.className} style={props.style} />
);

export const CheckboxBlankIcon = props => (
    <CheckboxBlank className={props.className} style={props.style} />
);

export const ConditionIcon = props => (
    <Condition className={props.className} style={props.style} />
);

export const ConfirmCircleIcon = props => (
    <ConfirmCircle className={props.className} style={props.style} />
);

export const DeleteIcon = props => (
    <Delete className={props.className} style={props.style} />
);

export const EditIcon = props => (
    <Edit className={props.className} style={props.style} />
);

export const EmployerIcon = props => (
    <Employer className={props.className} style={props.style} />
);

export const FormIcon = props => (
    <Form className={props.className} style={props.style} />
);

export const HomeIcon = props => (
    <Home className={props.className} style={props.style} />
);

export const InfoIcon = props => (
    <Info className={props.className} style={props.style} />
);

export const InvitationIcon = props => (
    <Invitation className={props.className} style={props.style} />
);

export const LockIcon = props => (
    <Lock className={props.className} style={props.style} />
);

export const MessageIcon = props => (
    <Message className={props.className} style={props.style} />
);

export const PeopleIcon = props => (
    <People className={props.className} style={props.style} />
);

export const RecipientsIcon = props => (
    <Share className={props.className} style={props.style} />
);

export const RevisionIcon = props => (
    <Revision className={props.className} style={props.style} />
);

export const SaveIcon = props => (
    <Save className={props.className} style={props.style} />
);

export const SendIcon = props => (
    <Send className={props.className} style={props.style} />
);

export const SendPlaneIcon = props => (
    <SendPlane className={props.className} style={props.style} />
);

export const SettingsIcon = props => (
    <Settings className={props.className} style={props.style} />
);

export const ShareIcon = props => (
    <Share className={props.className} style={props.style} />
);

export const StarIcon = props => (
    <Star className={props.className} style={props.style} color={props.color}/>
);

export const SummaryIcon = props => (
    <Summary className={props.className} style={props.style} />
);

export const TeamIcon = props => (
    <Team className={props.className} style={props.style} />
);

export const TextIcon = props => (
    <Text className={props.className} style={props.style} />
);

export const UserIcon = props => (
    <User className={props.className} style={props.style} />
);
