import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import FormRevisionsTable from "./FormRevisionsTable";
import EditFormRevision from "./EditFormRevision";

import {
  openEditFormRevision,
  setFormRevisionTable
} from "../Redux/Actions/index";

import { connect } from "react-redux";

import { AddIcon } from "../../Shared/Components/Icons";

const mapDispatchToProps = dispatch => {
  return {
    openEditFormRevision: () => dispatch(openEditFormRevision()),
    setFormRevisionTable: formRevisionTable =>
      dispatch(setFormRevisionTable(formRevisionTable))
  };
};

const mapStateToProps = state => {
  return {
    formTable: state.TableReducer.formTable,
    formRevisionTable: state.TableReducer.formRevisionTable
  };
};

class FormRevisionsContainer extends React.Component {
  handleOpen = () => {
    this.props.openEditFormRevision();

    var tempTable = this.props.formRevisionTable;
    tempTable.selected = {};
    this.props.setFormRevisionTable(tempTable);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <EditFormRevision />
        <div className={classes.containerRoot}>
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <FormRevisionsTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FormRevisionsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormRevisionsContainer));
