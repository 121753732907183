import {
    SET_EMPLOYER_TABLE,
    SET_EMPLOYEE_TABLE,
    SET_RECIPIENT_TABLE,

    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,
    
    OPEN_EDIT_RECIPIENT,
    CLOSE_EDIT_RECIPIENT

} from '../Constants/ActionTypes';

//import {store} from "../Store/index";

export const setEmployerTable = (employerTable) => ({type: SET_EMPLOYER_TABLE, payload: employerTable })
export const setEmployeeTable = (employeeTable) => ({type: SET_EMPLOYEE_TABLE, payload: employeeTable })
export const setRecipientTable = (recipientTable) => ({type:SET_RECIPIENT_TABLE, payload: recipientTable})
export const openEditEmployer = () => ({type: OPEN_EDIT_EMPLOYER, payload: true})
export const closeEditEmployer = () => ({type: CLOSE_EDIT_EMPLOYER, payload: false})
export const openEditRecipient = () => ({type: OPEN_EDIT_RECIPIENT, payload: true})
export const closeEditRecipient = () => ({type: CLOSE_EDIT_RECIPIENT, payload: false})



