import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from '@material-ui/core/Divider';

import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";

import MessagesIcon from "@material-ui/icons/QuestionAnswer";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitIcon from "@material-ui/icons/ExitToApp";

import AccountCircle from "@material-ui/icons/AccountCircle";

import { getApp } from "../../Auth/Shared/Functions/StaticFunctions";
import MessageModal from "../../Shared/Components/MessageModal";

import { Auth } from "aws-amplify";

const styles = {
  root: {
    flexGrow: 1,
  },
  title: {
    "@media only screen and (max-width: 400px)": {
      //display:'none',
      fontSize: 16
    },
    textDecoration: "none",
    color: "white",
    alignItems: 'center',
  },
  headerlogo: {
    width: 200,
    "@media only screen and (max-width: 400px)": {
      width: 120,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 10
  },
  avatar: {
    height: 30,
    width: 30,    
    margin: 0
  },
  messageAvatar: {
    width: 30,
    height: 30,
    fontSize: 12
  },
  bigAvatar: {
    width: 60,
    height: 60
  },
  grad: {
    //background: 'linear-gradient(45deg, #2996cc 30%, #53abd6 90%)',
    background: "linear-gradient(45deg, #2996cc 30%, #29AECC 90%)"
  }
};

class MenuAppBar extends React.Component {
  state = {
    anchorElSettings: null,
    anchorElMessages: null,
    anchorEl: null,
    messageModalOpen: false,
    messageSenderID: '',
    messageSenderName: ''
  };

  handleSettingsMenu = event => {
    this.setState({ anchorElSettings: event.currentTarget });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMessagesMenu = event => {
    if(this.props.hasMessageMenu)
      this.setState({ anchorElMessages: event.currentTarget });
  };

  openMessageModal = event => {
    this.setState({messageModalOpen: true, anchorElMessages: null})
  };

  closeMessageModal = event => {
    this.setState({messageModalOpen: false})
  };

  handleMessageSummaryClick = messageSummary => {
    this.setState({messageSenderName: messageSummary.sender.first_name + ' ' + messageSummary.sender.last_name,messageSenderID : messageSummary.sender.id },() => {
      this.openMessageModal()
    })
  };

  handleCloseSettings = () => {
    this.setState({ anchorElSettings: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseMessages = () => {
    this.setState({ anchorElMessages: null });
  };

  goMyAccount = () => {
    var currentApp = localStorage.getItem("reform_health_app")
    this.props.history.push("/"+ currentApp + "/security");
    this.setState({ anchorEl: null });
  };

  handleSignOut = () => {
    this.signOut();
    //this.props.history.push('/carrier/auth')
  };

  signOut() {
    this.setState({ goSignIn: true });
    Auth.signOut()
      .then(data => {
        var currentApp = localStorage.getItem("reform_health_app")
        localStorage.clear();
        localStorage.setItem("reform_health_app", currentApp);
      })
      .catch(() => console.log("error signing out..."));
    this.setState({ goSignIn: true });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, anchorElSettings, anchorElMessages } = this.state;
    const settingsOpen = Boolean(anchorElSettings);
    const messagesOpen = Boolean(anchorElMessages);
    const open = Boolean(anchorEl);
    const { settingsMenu: Component, ...rest } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary" className={classes.grad}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              onClick={this.props.openNavDrawer}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
              <Link to={this.props.home} className={classes.grow}>
                <img
                  src={require("../Images/reform_health_horiz_cutout_300.png")}
                  className={classes.headerlogo}
                  alt="Reform Health"
                />
              </Link>
            <div>
              {(this.props.hasMessageBar )&& (
              <IconButton
                onClick={this.handleMessagesMenu}
                style={{ color: "white" }}
              >
                <Badge className={classes.margin} badgeContent={this.props.newMessageCount}>
                  <MessagesIcon />
                </Badge>
              </IconButton>
              )}
              <Component enqueueSnackbar={this.props.enqueueSnackbar} history={this.props.history} reformUser={this.props.reformUser} />
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                style={{ margin: 5 }}
                color="inherit"
                id="avatarClickButton"
              >
                {(this.props.user.attributes.picture !== undefined && (
                  <Avatar
                    src={this.props.user.attributes.picture}
                    className={classes.avatar}
                  />
                )) || <AccountCircle />}
              </IconButton>

              <Menu
                id="menu-messages"
                anchorEl={anchorElMessages}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={messagesOpen}
                onClose={this.handleCloseMessages}
              >
                <List
                  style={{
                    width: 250,
                    overflow: "auto",
                    maxHeight: 400,
                    outline: "none",
                  }}
                >
                  {this.props.messageSummaries.map((message, index) => {
                    return (
                      <React.Fragment key={index}>
                      <MenuItem onClick={ () => {this.handleMessageSummaryClick(message)} } >
                      <ListItemAvatar>
                        <Avatar className={classes.messageAvatar}>{message.sender.first_name.charAt(0)}{message.sender.last_name.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={message.sender.first_name + ' ' + message.sender.last_name}
                        secondary={"Test message"}
                      />
                      </MenuItem>
                      <Divider style={{marginBottom: 7, marginTop: 7}} variant="inset" />
                      </React.Fragment>
                    )
                  })}
                  {(this.props.messageSummaries.length === 0) && (
                    <React.Fragment>
                        <MenuItem>
                        <ListItemText
                          primary={"No Messages"}
                        />
                        </MenuItem>
                        </React.Fragment>
                  )}
                </List>
              </Menu>
              <MessageModal
                Open={this.state.messageModalOpen}
                Close={this.closeMessageModal}
                SenderID={this.state.messageSenderID}
                setNewMessageCount={this.props.setNewMessageCount}
                User={this.props.reformUser}
                Name={this.state.messageSenderName}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.goMyAccount}>
                  <SettingsIcon style={{ marginRight: 10 }} />
                  My Account
                </MenuItem>
                <MenuItem id="signOutButton" onClick={this.handleSignOut}>
                  <ExitIcon style={{ marginRight: 10 }} />
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MenuAppBar);