import {
    ADD_PROFILE,
    UPDATE_PROFILE,
    DELETE_PROFILE,
    LOAD_PROFILES,
    PROFILES_LOADED,
    SET_ACTIVE_PROFILE,
    SET_ACTIVE_PROFILES,
    CLEAR_ACTIVE_PROFILE,
    LOAD_ADDRESSES,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS
} from "../Constants/ActionTypes";

const intitialState = {
    profiles: [],
    profilesLoaded: false,
    addressesLoaded: false,
    isLoading: false,
    activeProfiles: [],
    activeProfile: {},
    addresses: {}
};

const profilesReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_PROFILES:
            return {
                ...state,
                profiles: state.profiles.concat(action.payload),
                profilesLoaded: true
            };
        case ADD_PROFILE:
            return {
                ...state,
                profiles: state.profiles.concat(action.payload)
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                profiles: state.profiles.map(profile =>
                    profile.id === action.payload.id ? action.payload : profile
                )
            };
        case DELETE_PROFILE:
            return {
                ...state,
                profiles: state.profiles.filter(
                    profile => profile.id !== action.payload
                )
            };
        case PROFILES_LOADED:
            return { ...state, profilesLoaded: true };
        case SET_ACTIVE_PROFILES:
            return {
                ...state,
                activeProfiles: state.profiles.filter(
                    profile =>
                        action.payload.includes(profile.id) ? profile : null
                )
            };
        case SET_ACTIVE_PROFILE:
            return {
                ...state,
                activeProfile: state.profiles.find(profile =>
                    profile.id === action.payload ? profile : null
                )
            };
        case CLEAR_ACTIVE_PROFILE:
            return { ...state, activeProfile: {} };
        case LOAD_ADDRESSES:
            return {
                ...state,
                addresses: action.payload,
                addressesLoaded: true
            };
        case ADD_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.concat(action.payload)
            };
        case UPDATE_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.map(address =>
                    address.id === action.payload.id ? action.payload : address
                )
            };

        case DELETE_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.filter(
                    address => address.id !== action.payload
                )
            };
        default:
            return state;
    }
};

export default profilesReducer;
