import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Auth } from "aws-amplify";
import config from "../config";
import api from "../Shared/Functions/APIHelpers";
import { getApp } from "./Shared/Functions/StaticFunctions";
import { styles } from "./Styles/AuthStyles";

class UserRouter extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    newUser: false,
    codeType: "",
    code: "",
    app: ""
  };

  componentDidMount() {
    this.getUser().then(response => {
      this.setState({ newUser: response.new_user });

      this.HandleCodes();
    });
  }

  HandleCodes = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let employerCode = params.get("employer");
    let customerCode = params.get("customer");
    let url = window.location.href;

    if (inviteCode === "null") {
      inviteCode = null;
    }

    if (inviteCode) {
      if (this.state.newUser) {
        this.props.history.push("setup?invite=" + inviteCode);
      } else {
        this.props.history.push("link?invite=" + inviteCode);
      }
    } else if (employerCode) {
      if (this.state.newUser) {
        this.props.history.push("setup?employer=" + employerCode);
      } else {
        this.props.history.push("link?employer=" + employerCode);
      }
    } else if (customerCode) {
      if (this.state.newUser) {
        this.props.history.push("setup?customer=" + customerCode);
      } else {
        this.props.history.push("link?customer=" + customerCode);
      }
    } else {
      if (this.state.newUser) {
        this.props.history.push("/" + getApp() + "/setup");
      } else {
        this.props.history.push("/" + getApp() + "/home");
      }
    }
  };

  HandleEmployeeLogin() {}

  HandleEmployerLogin() {}

  getUser = () => {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User" });
          console.log(err);
        });
    });
  };

  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div className={classes.basecontent}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root} elevation={5}  style={{zIndex:10}}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Reform Health
              </Typography>
              <div style={{ height: 250, marginTop: 100 }}>
                <Typography variant="body1">
                  Signing into your account...
                </Typography>
                <br />
                <br />
                <CircularProgress className={classes.progress} />
              </div>
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

UserRouter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserRouter);
