import {
	SET_SUBMISSION_TABLE,
	SET_NEW_MESSAGE_COUNT,
	LOAD_MESSAGE_SUMMARIES
} from "../Constants/ActionTypes";

//import {store} from "../Store/index";

export const setSubmissionTable = submissionTable => ({
	type: SET_SUBMISSION_TABLE,
	payload: submissionTable
});

export const setNewMessageCount = messageCount => ({
	type: SET_NEW_MESSAGE_COUNT,
	payload: messageCount
});

export const loadMessageSummaries = messageSummaries => ({
	type: LOAD_MESSAGE_SUMMARIES,
	payload: messageSummaries
});
