export const styles = theme => ({
  title: {
    //backgroundColor: blue[800],
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 2
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  primaryIcon: {
    color: theme.palette.primary.main
  },
  divider: {
    height: 40
  },
  buttonStyle: {
    '@media print' : { 
      display:'none'
    }
  }
});
