import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Components/ConfirmAlert";

import api from "../../Functions/APIHelpers";

import { Auth } from "aws-amplify";

import config from "../../../config";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import blue from "@material-ui/core/colors/blue";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const styles = theme => ({
  title: {
    backgroundColor: blue[800],
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 2
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  }
});

const initialState = {
  open: false,
  value: "",
  details: "",
  reason: "",
  confirmAlertOpen: false
};

class EditOverride extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fieldName !== prevProps.fieldName) {
      this.setState({ value: this.props.Override.value });
    }
  }

  addOverride = () => {
    var body = JSON.stringify({
      field_id: this.props.fieldID,
      value: this.state.value,
      reason: this.state.reason,
      details: this.state.details
    });

    return new Promise((resolve, reject) => {
      api
        .addMappingRuleOverride(this.props.auth.user_id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Override" });
          console.log(err);
        });
    });
  };

  updateOverride = () => {
    var body = JSON.stringify({
      field_id: this.props.fieldID,
      value: this.state.value,
      reason: this.state.reason,
      details: this.state.details
    });

    return new Promise((resolve, reject) => {
      api
        .updateMappingRuleOverride(this.props.Override.id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Override" });
          console.log(err);
        });
    });
  };

  deleteOverride = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteMappingRuleOverride(this.props.Override.id)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Override" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.props.ReloadForm();
    this.reset();
    this.props.Close();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleCancel = () => {
    this.props.isOpen = false;
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleDelete = () => {
    this.deleteOverride().then(r => {
      this.close();
    });
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleSave = () => {
    if (this.props.isEdit) {
      this.updateOverride().then(r => {
        this.close();
      });
    } else {
      this.addOverride().then(r => {
        this.close();
      });
    }
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {(this.props.isEdit && "Update") || "Add"} Form Override
          </DialogTitle>

          <DialogContent>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ marginTop: 10 }}
            >
              Note, this change will apply to this form only
            </Typography>
            {this.props.fieldType === "Text" && (
              <div>
                <TextField
                  id="standard-dense"
                  label={this.props.fieldName}
                  margin="dense"
                  fullWidth
                  onChange={e => this.setState({ value: e.target.value })}
                  defaultValue={this.props.Override.value}
                />
              </div>
            )}

            {this.props.fieldType === "Checkbox" && (
              <div>
                <FormLabel component="legend" style={{ marginTop: 20 }}>
                  {this.props.fieldName}
                </FormLabel>
                <RadioGroup
                  aria-label="Gender"
                  name="gender1"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="On"
                    control={<Radio />}
                    label="Checked"
                  />
                  <FormControlLabel
                    value="Off"
                    control={<Radio />}
                    label="Not Checked"
                  />
                </RadioGroup>
              </div>
            )}

            <Typography variant="caption" style={{ marginTop: 10 }}>
              Please add any details which may be relevant to this change. For
              instance, if enabling a condition, please provide all details
              requested by the form, including treatment dates, medications,
              etc. These details will appear at the end of the form.
              <br />
              <br />
              If there is a mistake, please provide a reason to help us make a
              correction.
            </Typography>
            <TextField
              id="standard-dense"
              label="Details"
              margin="dense"
              multiline
              onChange={e => this.setState({ details: e.target.value })}
              defaultValue={this.props.Override.details}
              fullWidth
            />

            <TextField
              id="standard-dense"
              label="Reason"
              margin="dense"
              onChange={e => this.setState({ reason: e.target.value })}
              defaultValue={this.props.Override.reason}
              fullWidth
            />

            <div className={classes.divider} />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this override? This cannot be undone."
              }
            />
            {this.props.isEdit && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
            <Button onClick={this.props.Close} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditOverride.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(EditOverride)));
