import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({});

class Agreement extends React.Component {
  state = {
    open: false
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          maxWidth={"md"}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Terms of Service</DialogTitle>

          <DialogContent>
            <Typography variant="body1">
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                CONDITIONS OF USE
              </Typography>
              Welcome to our online store! MYCOMPANY and its associates provide
              their services to you subject to the following conditions. If you
              visit or shop within this website, you accept these conditions.
              Please read them carefully. ​
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                PRIVACY
              </Typography>
              Please review our Privacy Notice, which also governs your visit to
              our website, to understand our practices.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                ELECTRONIC COMMUNICATIONS
              </Typography>
              When you visit MYCOMPANY or send e-mails to us, you are
              communicating with us electronically. You consent to receive
              communications from us electronically. We will communicate with
              you by e-mail or by posting notices on this site. You agree that
              all agreements, notices, disclosures and other communications that
              we provide to you electronically satisfy any legal requirement
              that such communications be in writing.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                COPYRIGHT
              </Typography>
              All content included on this site, such as text, graphics, logos,
              button icons, images, audio clips, digital downloads, data
              compilations, and software, is the property of MYCOMPANY or its
              content suppliers and protected by international copyright laws.
              The compilation of all content on this site is the exclusive
              property of MYCOMPANY, with copyright authorship for this
              collection by MYCOMPANY, and protected by international copyright
              laws.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                TRADE MARKS
              </Typography>
              MYCOMPANYs trademarks and trade dress may not be used in
              connection with any product or service that is not MYCOMPANYs, in
              any manner that is likely to cause confusion among customers, or
              in any manner that disparages or discredits MYCOMPANY. All other
              trademarks not owned by MYCOMPANY or its subsidiaries that appear
              on this site are the property of their respective owners, who may
              or may not be affiliated with, connected to, or sponsored by
              MYCOMPANY or its subsidiaries.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                LICENSE AND SITE ACCESS
              </Typography>
              MYCOMPANY grants you a limited license to access and make personal
              use of this site and not to download (other than page caching) or
              modify it, or any portion of it, except with express written
              consent of MYCOMPANY. This license does not include any resale or
              commercial use of this site or its contents: any collection and
              use of any product listings, descriptions, or prices: any
              derivative use of this site or its contents: any downloading or
              copying of account information for the benefit of another
              merchant: or any use of data mining, robots, or similar data
              gathering and extraction tools. This site or any portion of this
              site may not be reproduced, duplicated, copied, sold, resold,
              visited, or otherwise exploited for any commercial purpose without
              express written consent of MYCOMPANY. You may not frame or utilize
              framing techniques to enclose any trademark, logo, or other
              proprietary information (including images, text, page layout, or
              form) of MYCOMPANY and our associates without express written
              consent. You may not use any meta tags or any other "hidden text"
              utilizing MYCOMPANYs name or trademarks without the express
              written consent of MYCOMPANY. Any unauthorized use terminates the
              permission or license granted by MYCOMPANY. You are granted a
              limited, revocable, and nonexclusive right to create a hyperlink
              to the home page of MYCOMPANY so long as the link does not portray
              MYCOMPANY, its associates, or their products or services in a
              false, misleading, derogatory, or otherwise offensive matter. You
              may not use any MYCOMPANY logo or other proprietary graphic or
              trademark as part of the link without express written permission.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                YOUR MEMBERSHIP ACCOUNT
              </Typography>
              If you use this site, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer, and you agree to accept responsibility
              for all activities that occur under your account or password. If
              you are under 18, you may use our website only with involvement of
              a parent or guardian. MYCOMPANY and its associates reserve the
              right to refuse service, terminate accounts, remove or edit
              content, or cancel orders in their sole discretion.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                REVIEWS, COMMENTS, EMAILS, AND OTHER CONTENT
              </Typography>
              Visitors may post reviews, comments, and other content: and submit
              suggestions, ideas, comments, questions, or other information, so
              long as the content is not illegal, obscene, threatening,
              defamatory, invasive of privacy, infringing of intellectual
              property rights, or otherwise injurious to third parties or
              objectionable and does not consist of or contain software viruses,
              political campaigning, commercial solicitation, chain letters,
              mass mailings, or any form of "spam." You may not use a false
              e-mail address, impersonate any person or entity, or otherwise
              mislead as to the origin of a card or other content. MYCOMPANY
              reserves the right (but not the obligation) to remove or edit such
              content, but does not regularly review posted content. If you do
              post content or submit material, and unless we indicate otherwise,
              you grant MYCOMPANY and its associates a nonexclusive,
              royalty-free, perpetual, irrevocable, and fully sublicensable
              right to use, reproduce, modify, adapt, publish, translate, create
              derivative works from, distribute, and display such content
              throughout the world in any media. You grant MYCOMPANY and its
              associates and sublicensees the right to use the name that you
              submit in connection with such content, if they choose. You
              represent and warrant that you own or otherwise control all of the
              rights to the content that you post: that the content is accurate:
              that use of the content you supply does not violate this policy
              and will not cause injury to any person or entity: and that you
              will indemnify MYCOMPANY or its associates for all claims
              resulting from content you supply. MYCOMPANY has the right but not
              the obligation to monitor and edit or remove any activity or
              content. MYCOMPANY takes no responsibility and assumes no
              liability for any content posted by you or any third party.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                RISK OF LOSS
              </Typography>
              All items purchased from MYCOMPANY are made pursuant to a shipment
              contract. This basically means that the risk of loss and title for
              such items pass to you upon our delivery to the carrier.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                PRODUCT DESCRIPTIONS
              </Typography>
              MYCOMPANY and its associates attempt to be as accurate as
              possible. However, MYCOMPANY does not warrant that product
              descriptions or other content of this site is accurate, complete,
              reliable, current, or error-free. If a product offered by
              MYCOMPANY itself is not as described, your sole remedy is to
              return it in unused condition.
              <br />
              <br />
              DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY THIS SITE IS
              PROVIDED BY MYCOMPANY ON AN "AS IS" AND "AS AVAILABLE" BASIS.
              MYCOMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
              EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE
              INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS
              SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR
              SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW,
              MYCOMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. MYCOMPANY DOES NOT WARRANT THAT
              THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM MYCOMPANY ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS. MYCOMPANY WILL NOT BE LIABLE
              FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
              INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
              PUNITIVE, AND CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT
              ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
              LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
              ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
              APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
              <br />
              <br />
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                APPLICABLE LAW
              </Typography>
              By visiting MYCOMPANY, you agree that the laws of the state of
              DEFINE_STATE, DEFINE_COUNTRY, without regard to principles of
              conflict of laws, will govern these Conditions of Use and any
              dispute of any sort that might arise between you and MYCOMPANY or
              its associates.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                DISPUTES
              </Typography>
              Any dispute relating in any way to your visit to MYCOMPANY or to
              products you purchase through MYCOMPANY shall be submitted to
              confidential arbitration in DEFINE_STATE, DEFINE_COUNTRY, except
              that, to the extent you have in any manner violated or threatened
              to violate MYCOMPANYs intellectual property rights, MYCOMPANY may
              seek injunctive or other appropriate relief in any state or
              federal court in the state of DEFINE_STATE, DEFINE_COUNTRY, and
              you consent to exclusive jurisdiction and venue in such courts.
              Arbitration under this agreement shall be conducted under the
              rules then prevailing of the American Arbitration Association. The
              arbitrators award shall be binding and may be entered as a
              judgment in any court of competent jurisdiction. To the fullest
              extent permitted by applicable law, no arbitration under this
              Agreement shall be joined to an arbitration involving any other
              party subject to this Agreement, whether through class arbitration
              proceedings or otherwise.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                SITE POLICIES, MODIFICATION, AND SEVERABILITY
              </Typography>
              Please review our other policies, such as our Shipping and Returns
              policy, posted on this site. These policies also govern your visit
              to MYCOMPANY. We reserve the right to make changes to our site,
              policies, and these Conditions of Use at any time. If any of these
              conditions shall be deemed invalid, void, or for any reason
              unenforceable, that condition shall be deemed severable and shall
              not affect the validity and enforceability of any remaining
              condition.
              <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                QUESTIONS:
              </Typography>
              Questions regarding our Conditions of Usage, Privacy Policy, or
              other policy related material can be directed to our support staff
              by clicking on the "Contact Us" link in the side menu. Or you can
              email us at: info@MYCOMPANY.com
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.Close} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Agreement.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withMobileDialog()(withStyles(styles)(Agreement));
