import {
    LOAD_PROFILES,
    ADD_PROFILE, 
    UPDATE_PROFILE,
    DELETE_PROFILE,
    //------------
    PROFILES_LOADED,
    SET_ACTIVE_PROFILE,
    SET_ACTIVE_PROFILES,
    CLEAR_ACTIVE_PROFILE,

    LOAD_ADDRESSES,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS,

    OPEN_ADD_PROFILE,
    OPEN_EDIT_PROFILE,
    CLOSE_EDIT_PROFILE,

    LOAD_EMPLOYMENT,
    ADD_EMPLOYMENT,
    UPDATE_EMPLOYMENT,
    DELETE_EMPLOYMENT,

    EMPLOYMENT_LOADED,
    SET_ACTIVE_EMPLOYMENT,
    CLEAR_ACTIVE_EMPLOYMENT,

    OPEN_ADD_EMPLOYMENT,
    OPEN_EDIT_EMPLOYMENT,
    CLOSE_EDIT_EMPLOYMENT,

    LOAD_HEALTH,
    ADD_HEALTH,
    UPDATE_HEALTH,
    DELETE_HEALTH,

    HEALTH_LOADED,
    SET_ACTIVE_HEALTH,
    CLEAR_ACTIVE_HEALTH,
    SET_ACTIVE_CONDITION,
    CLEAR_ACTIVE_CONDITION,

    OPEN_ADD_HEALTH,
    OPEN_EDIT_HEALTH,
    CLOSE_EDIT_HEALTH,


    LOAD_OVERRIDE,
    ADD_OVERRIDE,
    UPDATE_OVERRIDE,
    DELETE_OVERRIDE,

    OVERRIDE_LOADED,
    SET_ACTIVE_OVERRIDE,
    CLEAR_ACTIVE_OVERRIDE,

    OPEN_ADD_OVERRIDE,
    OPEN_EDIT_OVERRIDE,
    CLOSE_EDIT_OVERRIDE,


    LOAD_EMPLOYERS,
    ADD_EMPLOYER,
    UPDATE_EMPLOYER,
    DELETE_EMPLOYER,

    EMPLOYERS_LOADED,
    SET_ACTIVE_EMPLOYERS,
    SET_ACTIVE_EMPLOYER,
    CLEAR_ACTIVE_EMPLOYER,

    OPEN_ADD_EMPLOYER,
    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,


    OPEN_NAV_DRAWER,
    CLOSE_NAV_DRAWER,
    IS_LOADING,

    LOAD_MESSAGE_SUMMARIES,
    SET_NEW_MESSAGE_COUNT

} from '../Constants/ActionTypes';

import {store} from "../Store/index";

export const loadProfiles = profiles => ({type: LOAD_PROFILES, payload: profiles})
export const addProfile = profile => ({type: ADD_PROFILE, payload: profile})
export const updateProfile = profile => ({type: UPDATE_PROFILE, payload: profile})
export const deleteProfile = profileID => ({type: DELETE_PROFILE, payload: profileID})

export const profilesLoaded = () => ({type: PROFILES_LOADED, payload: true})
export const setActiveProfiles = profileIDs => ({type: SET_ACTIVE_PROFILES, payload: profileIDs})
export const setActiveProfile = profileID => ({ type: SET_ACTIVE_PROFILE, payload: profileID })
export const clearActiveProfile = () => ({ type: CLEAR_ACTIVE_PROFILE, payload: null})

export const loadAddresses = addresses => ({type: LOAD_ADDRESSES, payload: addresses})
export const addAddress = address => ({type: ADD_ADDRESS, payload: address})
export const updateAddress = address => ({type: UPDATE_ADDRESS, payload: address})
export const deleteAddress = addressID => ({type: DELETE_ADDRESS, payload: addressID})

export const openEditProfile = () => ({type: OPEN_EDIT_PROFILE, payload: true})
export const openAddProfile = function(){
    store.dispatch(clearActiveProfile());
    return {type: OPEN_ADD_PROFILE, payload: true }
}  
export const closeEditProfile = () => ({type: CLOSE_EDIT_PROFILE, payload: false})




export const loadEmployment = employment => ({type: LOAD_EMPLOYMENT, payload: employment})
export const addEmployment = employment => ({type: ADD_EMPLOYMENT, payload: employment})
export const updateEmployment = employment => ({type: UPDATE_EMPLOYMENT, payload: employment})
export const deleteEmployment = employmentID => ({type: DELETE_EMPLOYMENT, payload: employmentID})
export const clearActiveEmployment = () => ({type: CLEAR_ACTIVE_EMPLOYMENT, payload: null})

export const employmentLoaded = () => ({type: EMPLOYMENT_LOADED, payload: true})
export const setActiveEmployment = employmentID => ({type: SET_ACTIVE_EMPLOYMENT, payload: employmentID})
//export const clearActiveEmployement = () => ({type: CLEAR_ACTIVE_EMPLOYMENT, payload: null})

export const openEditEmployment = () => ({type: OPEN_EDIT_EMPLOYMENT, payload: true})
export const openAddEmployment = function(){
    console.log('openAddEmployment')
    //store.dispatch(clearActiveEmployment());
    return {type: OPEN_ADD_EMPLOYMENT, payload: true}
}
export const closeEditEmployment = () => ({type: CLOSE_EDIT_EMPLOYMENT, payload: false})




export const loadHealth = health => ({type: LOAD_HEALTH, payload: health})
export const addHealth = health => ({type: ADD_HEALTH, payload: health})
export const updateHealth = health => ({type: UPDATE_HEALTH, payload: health})
export const deleteHealth = healthID => ({type: DELETE_HEALTH, payload: healthID})

export const healthLoaded = () => ({type: HEALTH_LOADED, payload: true})
export const setActiveHealth = healthID => ({type: SET_ACTIVE_HEALTH, payload: healthID})
export const clearActiveHealth = () => ({type: CLEAR_ACTIVE_HEALTH, payload: null})
export const setActiveCondition = conditionID => ({type: SET_ACTIVE_CONDITION, payload: conditionID})
export const clearActiveCondition = () => ({type: CLEAR_ACTIVE_CONDITION, payload: null})

export const openEditHealth = () => ({type: OPEN_EDIT_HEALTH, payload: true})
export const openAddHealth = function(){
    console.log('openAddHealth')
    //store.dispatch(clearActiveEmployment());
    return {type: OPEN_ADD_HEALTH, payload: true}
}
export const closeEditHealth = () => ({type: CLOSE_EDIT_HEALTH, payload: false})



export const loadOverride = override => ({type: LOAD_OVERRIDE, payload: override})
export const addOverride = override => ({type: ADD_OVERRIDE, payload: override})
export const updateOverride = override => ({type: UPDATE_OVERRIDE, payload: override})
export const deleteOverride = overrideID => ({type: DELETE_OVERRIDE, payload: overrideID})

export const overrideLoaded = () => ({type: OVERRIDE_LOADED, payload: true})
export const setActiveOverride = overrideID => ({type: SET_ACTIVE_OVERRIDE, payload: overrideID})
export const clearActiveOverride = () => ({type: CLEAR_ACTIVE_OVERRIDE, payload: null})

export const openEditOverride = () => ({type: OPEN_EDIT_OVERRIDE, payload: true})
export const openAddOverride = function(){
    console.log('openAddOverride')
    //store.dispatch(clearActiveEmployment());
    return {type: OPEN_ADD_OVERRIDE, payload: true}
}
export const closeEditOverride = () => ({type: CLOSE_EDIT_OVERRIDE, payload: false})


export const loadEmployers = employers => ({type: LOAD_EMPLOYERS, payload: employers})
export const addEmployer = employer => ({type: ADD_EMPLOYER, payload: employer})
export const updateEmployer = employer => ({type: UPDATE_EMPLOYER, payload: employer})
export const deleteEmployer = employerID => ({type: DELETE_EMPLOYER, payload: employerID})

export const employersLoaded = () => ({type: EMPLOYERS_LOADED, payload: true})
export const setActiveEmployers = employerIDs => ({type: SET_ACTIVE_EMPLOYERS, payload: employerIDs})
export const setActiveEmployer = employerID => ({type: SET_ACTIVE_EMPLOYER, payload: employerID})
export const clearActiveEmployer = () => ({type: CLEAR_ACTIVE_EMPLOYER, payload: null})

export const openEditEmployer = () => ({type: OPEN_EDIT_EMPLOYER, payload: true})
export const openAddEmployer = function(){
    console.log('openAddEmployer')
    //store.dispatch(clearActiveEmployment());
    return {type: OPEN_ADD_EMPLOYER, payload: true}
}
export const closeEditEmployer = () => ({type: CLOSE_EDIT_EMPLOYER, payload: false})




export const openNavDrawer = () => ({type: OPEN_NAV_DRAWER, payload: true})
export const closeNavDrawer = () => ({type: CLOSE_NAV_DRAWER, payload: false})
export const isLoading = (isLoading) => ({type: IS_LOADING, payload: isLoading})

export const loadMessageSummaries = (messageSummaries) => ({ type: LOAD_MESSAGE_SUMMARIES, payload: messageSummaries })
export const setNewMessageCount = messageCount => ({
    type: SET_NEW_MESSAGE_COUNT,
    payload: messageCount
});


