import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import api from "../../Shared/Functions/APIHelpers";

import { DeleteIcon } from "../../Shared/Components/Icons";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import { Auth } from "aws-amplify";
import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

import { closeEditEmployer } from "../Redux/Actions/index";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    states: state.StateReducer.states,
    forms: state.FormReducer.forms,
    isOpen: state.UIReducer.editEmployerModalOpen,
    employerTable: state.TableReducer.employerTable
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeEditEmployer: () => dispatch(closeEditEmployer())
  };
};

const initialState = {
  open: false,
  name: "",
  employer: {},
  renewalDate: "",
  quotingOffset: "",
  quotingLength: "",
  enrollmentOffset: "",
  enrollmentLength: "",
  status: NOT_LOADED,
  formTypeOptions : [
    {label: 'Agency Default Form Group',value:'default'},
    {label: 'Other Form Group', value:'formGroup'},
    {label: 'Specify Forms', value:'forms'},
  ],
  formType: '',
  formGroups: [],
  selectedFormGroup: {},
  employerForms: [],
  selectedForm: {}, //clearing out dropdown
  renewal_date: ""
};

class EditEmployer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      this.setState({ status: IS_LOADING, formType: this.state.formTypeOptions[0] });
      this.getEmployer();
      this.getFormGroups();
    }
  }

  getEmployer = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerSetup(
          this.props.auth.customer_id,
          this.props.employerTable.selected.id
        )
        .then(response => {
          var formType = {}
          if(response.use_default_form_group){
            formType = this.state.formTypeOptions[0]
          }
          else if(response.form_group != null){
            formType = this.state.formTypeOptions[1]
            this.setState({selectedFormGroup : response.form_group})
          }
          else {
            formType = this.state.formTypeOptions[2]
          }
          this.setState({
            employer: response,
            name: response.name,
            employerForms: response.broker_forms,
            formType : formType,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employer Setup" });
          console.log(err);
        });
    });
  };

  updateEmployer = () => {
    var body = JSON.stringify({
      form_group_id: (this.state.formType.value === "formGroup") ? this.state.selectedFormGroup : null,
      set_default_form_group : (this.state.formType.value === "default") ? true : false,
      forms: this.state.employerForms
    });
    return new Promise((resolve, reject) => {
      api
        .updateEmployerSetup(
          this.props.auth.customer_id,
          this.props.employerTable.selected.id,
          body
        )
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Employer Setup" });
          console.log(err);
        });
    });
  };

  getFormGroups = () => {
    return new Promise((resolve, reject) => {
      api
        .getFormGroups(this.props.auth.customer_id)
        .then(response => {
          this.setState({
            formGroups: response
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Form Group" });
          console.log(err);
        });
    });
  };

  handleChangeFormType = (val, type) => {
    this.setState({formType:val}, () => {
      if(val.value === "forms"){
        this.scrollToMyRef1()
      }
    });
  };

  handleAddForm = form => {
    this.setState({ selectedForm: form });
    var formList = this.state.employerForms;
    formList.push(form.employerForm);
    this.setState({ employerForms: formList, selectedForm: {} });
  };

  handleDeleteForm = employerForm => {
    var formList = this.state.employerForms;
    formList = formList.filter(f => f.id != employerForm.id);
    this.setState({ employerForms: formList });
  };

  handleClose = () => {
    this.reset();
    this.props.closeEditEmployer();
  };

  handleSave = () => {
    this.updateEmployer();
    this.handleClose();
  };

  quotingStart = () => {
    var dt = new Date(this.state.employer.renewal_date);
    dt.setDate(dt.getDate() - this.state.employer.quoting_offset);
    return dt;
  };

  quotingEnd = () => {
    var dt = new Date(this.quotingStart());
    dt.setDate(dt.getDate() + this.state.employer.quoting_length);
    return dt;
  };

  enrollmentStart = () => {
    var dt = new Date(this.state.employer.renewal_date);
    dt.setDate(dt.getDate() - this.state.employer.enrollment_offset);
    return dt;
  };

  enrollmentEnd = () => {
    var dt = new Date(this.enrollmentStart());
    dt.setDate(dt.getDate() + this.state.employer.enrollment_length);
    return dt;
  };

  renewalDate = () => {
    var dt = new Date(this.state.employer.renewal_date);
    return dt;
  };

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;
    element.scrollIntoView({ behavior: "auto", block: "end" });
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
          {this.state.name} Setup
          </DialogTitle>

          <DialogContent style={{ width:400,height: 600 }}>
            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Quoting Period
            </Typography>
            <div>
              <div style={{ float: "left" }}>
                <Typography variant={"body1"}>Begins</Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography variant={"subtitle1"}>
                  {this.quotingStart().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: "both" }} />
            <div>
              <div style={{ float: "left" }}>
                <Typography variant={"body1"}>Ends</Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography variant={"subtitle1"}>
                  {this.quotingEnd().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: "both" }} />

            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Enrollment Period
            </Typography>
            <div>
              <div style={{ float: "left" }}>
                <Typography variant={"body1"}>Begins</Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography variant={"subtitle1"}>
                  {this.enrollmentStart().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: "both" }} />
            <div>
              <div style={{ float: "left" }}>
                <Typography variant={"body1"}>Ends</Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography variant={"subtitle1"}>
                  {this.enrollmentEnd().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: "both" }} />

            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Renewal
            </Typography>
            <div>
              <div style={{ float: "left" }}>
                <Typography variant={"body1"}>Date</Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography variant={"subtitle1"}>
                  {this.renewalDate().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: "both" }} />

            <Typography
              variant={"button"}
              style={{ marginTop: 30, marginBottom: 5 }}
            >
              Form Setup
            </Typography>
            <Select 
              value={this.state.formType}
              onChange={(val, type) => { this.handleChangeFormType(val,type)}}
              options={this.state.formTypeOptions}
            />
            { (this.state.formType.value === "formGroup") && (
              <React.Fragment>
              <Typography
                variant={"button"}
                style={{ marginTop: 30, marginBottom: 5 }}
              >
                Form Group
              </Typography>
              <Select
                
                value={this.state.formGroups.map(fg => {
                    if(fg.id === this.state.selectedFormGroup)
                      return { label: fg.name, value: fg.id, formGroup: { ...fg } }
                })}
                onChange={(val, type) => this.setState({selectedFormGroup:val.value})}
                options={this.state.formGroups.map(fg => {
                  return { label: fg.name, value: fg.id, formGroup: { ...fg } };
                })}
              />
              </React.Fragment>
            )}

            { (this.state.formType.value === "forms") && (
             <React.Fragment>
              <Typography
                variant={"button"}
                style={{ marginTop: 30, marginBottom: 5 }}
              >
                Employee Forms
              </Typography>
              <List dense={false}>
                {this.state.employerForms.map((employerForm, index) => {
                  return (
                    <ListItem key={"form_item" + index}>
                      <ListItemText
                        primary={employerForm.name}
                        secondary={employerForm.carrier_name}
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          value={index}
                          onClick={() => {
                            this.handleDeleteForm(employerForm);
                          }}
                          aria-label="Delete"
                        >
                          <DeleteIcon className={classes.primaryIcon} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>

              <Select

                value={this.state.selectedForm}
                onMenuOpen={() => { this.scrollToMyRef1() }}
                onChange={(val, type) => this.handleAddForm(val, type)}
                options={this.props.forms.map(f => {
                  return { label: f.name, value: f.id, employerForm: { ...f } };
                })}
              />
              </React.Fragment>
            )}

            <div className={classes.divider} />
            <div
              id="myRef1"
              ref="myRef1"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block"
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
            
          </DialogActions>
          
        </Dialog>

      </div>
    );
  }
}

EditEmployer.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(EditEmployer)));
