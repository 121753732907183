import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";

import api from "../../Shared/Functions/APIHelpers";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import { Auth } from "aws-amplify";
import config from "../../config";

import {
  formatDateTime,
  formatDate
} from "../../Shared/Functions/Format";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const initialState = {
  firstName: "",
  lastName: "",
  lastLogin: "",
  lastSignature: "",
  agreementDate: "",
  status: NOT_LOADED,
};

class AccountDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    console.log('in here')
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      this.setState({
        status: IS_LOADING,
      });
      this.getEmployeeDetails();
    }
  }

  getEmployeeDetails = () => {
    return new Promise((resolve, reject) => {
      api
        .getUserAccountDetails(this.props.UserID)
        .then(response => {
          console.log(response)
          this.setState({
            'firstName': response.first_name,
            'lastName': response.last_name,
            'lastLogin': response.last_login ? response.last_login : "",
            'lastSignature': response.last_signature ? response.last_signature : "" ,
            'agreementDate': response.agreement_date ? response.agreement_date : "",
            status: IS_LOADED 
          })
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Account Details" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.props.Close()
  };

  handleClose = () => {
    this.reset();
    this.close()
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Account Details</DialogTitle>

          <DialogContent style={{ width: 400, height: 400 }}>
            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Name: {this.state.firstName} {this.state.lastName}
            </Typography>

            <div className={classes.divider} />

            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Last Login: {formatDateTime(this.state.lastLogin)}
            </Typography>

            <div className={classes.divider} />

            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Last Signature: {(this.state.lastSignature === "" && "No Signature") || ( formatDateTime(this.state.lastSignature) )}
            </Typography>

            <div className={classes.divider} />

            <Typography
              variant={"button"}
              style={{ marginTop: 20, marginBottom: 5 }}
            >
              Agreement Date: {formatDateTime(this.state.agreementDate)}
            </Typography>

            <div className={classes.divider} />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AccountDetailModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withMobileDialog()(withStyles(styles)(AccountDetailModal));