import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { DeleteIcon, EditIcon } from "../../Shared/Components/Icons";

import EditRecipient from "./EditRecipient";

import { Auth } from "aws-amplify";
import config from "../../config";

import { setRecipientTable, openEditRecipient } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setRecipientTable: recipientTable =>
      dispatch(setRecipientTable(recipientTable)),
    openEditRecipient: () => dispatch(openEditRecipient())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    recipientTable: state.TableReducer.recipientTable
  };
};

class RecipientsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null,
    confirmAlertOpen: false
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, recipient) => {
    var tempState = this.props.recipientTable;
    tempState.selected = recipient;
    this.props.setRecipientTable(tempState);

    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  handleEditRecipient = () => {
    this.props.openEditRecipient();
    this.handleCloseMenu();
  };

  handleDeleteRecipient = () => {
    console.log('NOT SURE IF DELETING FROM HERE YET')
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true});
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDeleteRecipient();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  render() {
    const { classes } = this.props;

    

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.DoNotDelete}
          isYes={this.DoDelete}
          AlertTitle={"Confirm Delete"}
          AlertText={"Are you sure you wish to delete this recipient?"}
        />
        <EditRecipient enqueueSnackbar={this.props.enqueueSnackbar} refreshTable={this.handleRefreshTable} />
        <CustomTable
          columns={[
            {
              label: "Carrier",
              dataKey: "carrier",
              numeric: false,
              width: 300
            },
            {
              label: "First Name",
              dataKey: "first_name",
              numeric: false,
              width: 150
            },
            {
              label: "Last Name",
              dataKey: "last_name",
              numeric: false,
              width: 100
            },
            {
              label: "Email",
              dataKey: "email",
              numeric: false,
              width: 100
            }
          ]}
          name={'Recipients'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={
            "customers/" + this.props.auth.customer_id + "/recipients"
          }
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleEditRecipient}>
            <EditIcon className={classes.leftIcon} /> Edit Recipient
          </MenuItem>
          <MenuItem onClick={this.handleConfirmDelete}>
            <DeleteIcon className={classes.leftIcon} /> Delete Recipient
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RecipientsTable));
