import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import blue from "@material-ui/core/colors/blue";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import {
  AddCircleIcon,
  CancelCircleIcon,
  ConfirmCircleIcon, 
  DeleteIcon, 
  EditIcon,
  SaveIcon
} from "../../Shared/Components/Icons";

import {
  formatDate,
} from "../../Shared/Functions/Format";

import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const styles = theme => ({
  title: {
    backgroundColor: blue[800],
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 1.5
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  },
  address: {
    "@media only screen and (max-width: 600px)": {
      maxWidth: 220,
      marginBottom: -5,
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  }
});

const initialState = {
  errorMessage: "",
  editEmployers: false,
  addEmployer: false,
  isEdit: false,
  employerCode: "",
  isCodeValid: false,
  currentEmployers: [],
  deleteEmployer: {},
  dateStartedAdd: "",
  employerAddID: "",
  employers: [],
  locations: [],
  selectedAddLocation: "",
  confirmAlertOpen: false,
  anchorEl: null,
  showMenu: false
};

class EmploymentHistoryModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    //get all employers, and push current employers to state seperately
    if(this.props.Profile)//only get history if editing an existing profile with an ID
      this.getEmploymentHistory();
  }

  getEmploymentHistory = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmploymentHistory(this.props.Profile)
        .then(r => {
          var currentEmployers = [];
          var employers = [];
          r.forEach(employer => {
            if (employer.date_ended === null) {
              currentEmployers.push(employer);
            }
          });
          this.setState({ currentEmployers: currentEmployers, employers: r });
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employment History" });
          console.log(err);
        });
    });
  };

  getEmployerByCode = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerByCode(this.state.employerCode)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employer Code" });
          console.log(err);
        });
    });
  };

  getEmployerLocations = employerID => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerLocations(employerID)
        .then(response => {
          console.log(response)
          this.setState({
            locations: response,
            selectedAddLocation: response[0].id //set default when only one location
          });
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Locations" });
          console.log(err);
        });
    });
  };

  addEmploymentHistory = () => {
    var body = JSON.stringify({
      date_started: this.state.dateStartedAdd,
      location_id: this.state.selectedAddLocation,
      employer_id: this.state.employerAddID
    });

    return new Promise((resolve, reject) => {
      api
        .addEmploymentHistory(this.props.Profile, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Address" });
          console.log(err);
        });
    });
  };

  deleteEmploymentHistory = employmentHistoryID => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmploymentHistory(employmentHistoryID)
        .then(r => {
          this.setState({ deleteEmployer: {} });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Deleting Address" });
          console.log(err);
        });
    });
  };

  updateEmploymentHistory = employment => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        id: employment.id,
        date_started:
          employment.date_started !== "" ? employment.date_started : null,
        date_ended: employment.date_ended !== "" ? employment.date_ended : null
      });

      api
        .updateEmploymentHistory(employment.id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Address" });
          console.log(err);
        });
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = (event, record) => {
    //set state with selected employer info
    this.openMenu(event);
  };

  openMenu = event => {
    this.setState({
      showMenu: true,
      anchorEl: event.currentTarget
    });
  };

  handleEditEmployment = event => {
    //if no employment history records have been added this makes it go straight to code
    var addEmployer = false
    var editEmployers = true
    if(this.state.employers.length === 0){
      editEmployers = false
      addEmployer = true
    }

    this.setState({ 
      editEmployers: editEmployers,
      addEmployer : addEmployer
    }, () => {
      this.props.ToggleButtons(true)
      this.props.ScrollToBottom();
    });
  };

  handleDoneEdit = () => {
    this.setState({ editEmployers: false, addEmployer: false });
    this.props.ToggleButtons(false)
  };

  handleDeleteEmployer = employer => {
    this.deleteEmploymentHistory(employer.id).then(r => {
      this.getEmploymentHistory();
    });
  };

  handleDateStartedChange = (date, employer) => {
    var temp = this.state.employers.map(e => {
      if (e.id === employer.id) return { ...e, date_started: date };
      else return e;
    });
    this.setState({ employers: temp });
  };

  handleDateEndedChange = (date, employer) => {
    var temp = this.state.employers.map(e => {
      if (e.id === employer.id) return { ...e, date_ended: date };
      else return e;
    });
    this.setState({ employers: temp });
  };

  handleSaveEmployer = employer => {
    this.updateEmploymentHistory(employer).then(r => {
      this.getEmploymentHistory();
    });
  };

  checkEmployerCode = () => {
    this.getEmployerByCode().then(employer => {
      if (employer.name === "Not Found") this.setState({ isCodeValid: false });
      else {
        //get employer locations for found employer
        this.getEmployerLocations(employer.id).then(r => {
          this.setState({ isCodeValid: true, employerAddID: employer.id });
        });
      }
    });
  };
  handleAddEmployer = () => {
    this.setState({ editEmployers: false, addEmployer: true });
  };

  handleCancelAdd = () => {
    //if no employment history records have been added this makes it go back to beggining of list
    var editEmployers = true
    if(this.state.employers.length === 0){
      editEmployers = false
    }

    this.setState({
      addEmployer: false,
      editEmployers: editEmployers,
      isCodeValid: false,
      locations: [],
      employerCode: ""
    });
  };
  handleSaveAdd = () => {
    this.addEmploymentHistory().then(r => {
      this.setState(
        {
          addEmployer: false,
          editEmployers: true,
          isCodeValid: false,
          locations: [],
          employerCode: ""
        },
        () => {
          this.getEmploymentHistory();
        }
      );
    });
  };

  handleConfirmDelete = employer => {
    this.setState({ confirmAlertOpen: true, deleteEmployer: employer });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDeleteEmployer(this.state.deleteEmployer);
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.DoNotDelete}
          isYes={this.DoDelete}
          AlertTitle={"Confirm Delete"}
          AlertText={"If you have left this employer, please add the end date instead. Are you sure you wish to delete this employment history record?"}
        />

        <Typography style={{ color: "red" }}>
          {this.state.errorMessage}
        </Typography>
        {!this.state.editEmployers && !this.state.addEmployer && (
          <React.Fragment>
            <FormLabel component="legend" style={{ marginTop: 30 }}>
              Current Employment
            </FormLabel>
            <List style={{marginLeft: -16}} name="currentEmployerList">
              {this.state.currentEmployers.map((employer, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <span>
                          <span className={classes.address}>
                            {employer.employer.name} ( {formatDate(employer.date_started)} - Current )
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
            <Button
                className={classes.button}
                style={{ marginLeft: -8 }}
                onClick={this.handleEditEmployment}
                color="primary"
              >
                
                { (this.state.currentEmployers.length > 0) && ( <React.Fragment><EditIcon className={classes.leftIcon} /> Edit Employment Records</React.Fragment>) || (<React.Fragment><AddCircleIcon className={classes.leftIcon} /> Add Employment Record</React.Fragment>)}
              </Button>
          </React.Fragment>
        )}

        {/* VIEW FOR EDITING ALL EMPLOYMENT RECORDS */}
        {this.state.editEmployers && !this.state.addEmployer && (
          <React.Fragment>
            <FormLabel component="legend" style={{ marginTop: 30 }}>
              All Employment History
            </FormLabel>
            <List style={{marginLeft: -16}}>
              {this.state.employers.map((employer, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {employer.employer.name} <br />
                          <TextField
                            style={{ verticalAlign: "baseline", width: 150 }}
                            label={"Start Date"}
                            InputLabelProps={{
                              shrink: true
                            }}
                            type="date"
                            value={employer.date_started}
                            onChange={e => {
                              this.handleDateStartedChange(
                                e.target.value,
                                employer
                              );
                            }}
                          />
                          <TextField
                            label={"End Date"}
                            InputLabelProps={{
                              shrink: true
                            }}
                            type="date"
                            style={{ width: 150 }}
                            value={
                              employer.date_ended ? employer.date_ended : ""
                            }
                            onChange={e => {
                              this.handleDateEndedChange(
                                e.target.value,
                                employer
                              );
                            }}
                          />
                          <IconButton
                            style={{ marginTop: 10, marginLeft: -5 }}
                            onClick={() => {
                              this.handleConfirmDelete(employer);
                            }}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            style={{ marginTop: 10, marginLeft: -5 }}
                            onClick={() => {
                              this.handleSaveEmployer(employer);
                            }}
                            color="primary"
                          >
                            <SaveIcon />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>

            <Menu
              id="menu-editprofile"
              key={"menu_"}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              <MenuItem key={"Menu1_"} onClick={this.handleEditEmployment}>
                Edit Employment
              </MenuItem>
            </Menu>
            <IconButton style={{ marginLeft: -16 }} onClick={this.handleDoneEdit} color="primary">
              <CancelCircleIcon />
            </IconButton>            
            <IconButton  onClick={this.handleAddEmployer} color="primary">
              <AddCircleIcon />
            </IconButton>

          </React.Fragment>
        )}

      {/* VIEW FOR ADDING NEW EMPLOYMENT RECORDS */}
        { (!this.state.editEmployers && this.state.addEmployer ) && (
          <React.Fragment>
            <FormLabel component="legend" style={{ marginTop: 30 }}>
              Add Employer
            </FormLabel>
            <TextField
              style={{ verticalAlign: "baseline", width: 350, marginTop: 10 }}
              label={"Employer Code"}
              value={this.state.employerCode}
              onChange={e => {
                this.setState({ employerCode: e.target.value });
              }}
            />
            {!this.state.isCodeValid && (
            <IconButton
              className={classes.button}
              onClick={this.checkEmployerCode}
              style={{marginLeft:0}}
              color="primary"
            >
              <ConfirmCircleIcon />
            </IconButton>
            )}
            <br />

            {this.state.isCodeValid && (
              <React.Fragment>
                {this.state.locations.length > 1 && (
                  <FormControl margin="dense">
                    <InputLabel htmlFor="location">Location</InputLabel>
                    <Select
                      value={this.state.selectedAddLocation}
                      onChange={e => {
                        this.setState({ selectedAddLocation: e.target.value });
                      }}
                      inputProps={{
                        name: "location",
                        id: "location"
                      }}
                      style={{ width: 350 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.locations.map((location, index) => {
                        return (
                          <MenuItem key={index} value={location.id}>
                            <em>{location.name}</em>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                <br />

                <TextField
                  label={"Date Start"}
                  style={{ width: 350 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  value={this.state.dateStartedAdd}
                  onChange={e => {
                    this.setState({ dateStartedAdd: e.target.value });
                  }}
                />
                <br />
                <br />
              
            
            </React.Fragment>
            )}
            <IconButton style={{ marginLeft: -16 }} onClick={this.handleCancelAdd} color="primary">
              <CancelCircleIcon />
            </IconButton>
            {this.state.isCodeValid && (
            <IconButton onClick={this.handleSaveAdd} color="primary">
              <ConfirmCircleIcon />
            </IconButton>
            )}
            

          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmploymentHistoryModule));

export { EmploymentHistoryModule as PureEmploymentHistoryModule};

