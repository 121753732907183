export const ADD_PROFILE = "ADD_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const LOAD_PROFILES = "LOAD_PROFILES";
export const PROFILES_LOADED = "PROFILES_LOADED";

export const SET_ACTIVE_PROFILES = "SET_ACTIVE_PROFILES";
export const SET_ACTIVE_PROFILE = "SET_ACTIVE_PROFILE";

export const OPEN_EDIT_PROFILE = "OPEN_EDIT_PROFILE";
export const CLOSE_EDIT_PROFILE = "CLOSE_EDIT_PROFILE";
export const OPEN_ADD_PROFILE = "OPEN_ADD_PROFILE";
export const CLEAR_ACTIVE_PROFILE = "CLEAR_ACTIVE_PROFILE";

export const OPEN_NAV_DRAWER = "OPEN_NAV_DRAWER";
export const CLOSE_NAV_DRAWER = "CLOSE_NAV_DRAWER";

export const IS_LOADING = "IS_LOADING";

export const LOAD_ADDRESSES = "LOAD_ADDRESSES";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";

export const LOAD_EMPLOYMENT = "LOAD_EMPLOYMENT";
export const ADD_EMPLOYMENT = "ADD_EMPLOYMENT";
export const UPDATE_EMPLOYMENT = "UPDATE_EMPLOYMENT";
export const DELETE_EMPLOYMENT = "DELETE_EMPLOYMENT";
export const SET_ACTIVE_EMPLOYMENT = "SET_ACTIVE_EMPLOYMENT";
export const CLEAR_ACTIVE_EMPLOYMENT = "CLEAR_ACTIVE_EMPLOYMENT";

export const EMPLOYMENT_LOADED = "EMPLOYMENT_LOADED";
export const OPEN_ADD_EMPLOYMENT = "OPEN_ADD_EMPLOYMENT";
export const OPEN_EDIT_EMPLOYMENT = "OPEN_EDIT_EMPLOYMENT";
export const CLOSE_EDIT_EMPLOYMENT = "CLOSE_EDIT_EMPLOYMENT";

export const LOAD_HEALTH = "LOAD_HEALTH";
export const ADD_HEALTH = "ADD_HEALTH";
export const UPDATE_HEALTH = "UPDATE_HEALTH";
export const DELETE_HEALTH = "DELETE_HEALTH";
export const SET_ACTIVE_HEALTH = "SET_ACTIVE_HEALTH";
export const CLEAR_ACTIVE_HEALTH = "CLEAR_ACTIVE_HEALTH";
export const SET_ACTIVE_CONDITION = "SET_ACTIVE_CONDITION";
export const CLEAR_ACTIVE_CONDITION = "CLEAR_ACTIVE_CONDITION";

export const HEALTH_LOADED = "HEALTH_LOADED";
export const OPEN_ADD_HEALTH = "OPEN_ADD_HEALTH";
export const OPEN_EDIT_HEALTH = "OPEN_EDIT_HEALTH";
export const CLOSE_EDIT_HEALTH = "CLOSE_EDIT_HEALTH";

export const LOAD_OVERRIDE = "LOAD_OVERRIDE";
export const ADD_OVERRIDE = "ADD_OVERRIDE";
export const UPDATE_OVERRIDE = "UPDATE_OVERRIDE";
export const DELETE_OVERRIDE = "DELETE_OVERRIDE";
export const SET_ACTIVE_OVERRIDE = "SET_ACTIVE_OVERRIDE";
export const CLEAR_ACTIVE_OVERRIDE = "CLEAR_ACTIVE_OVERRIDE";

export const LOAD_EMPLOYERS = "LOAD_EMPLOYERS";
export const ADD_EMPLOYER = "ADD_EMPLOYER";
export const UPDATE_EMPLOYER = "UPDATE_EMPLOYER";
export const DELETE_EMPLOYER = "DELETE_EMPLOYER";
export const SET_ACTIVE_EMPLOYERS = "SET_ACTIVE_EMPLOYERS";
export const SET_ACTIVE_EMPLOYER = "SET_ACTIVE_EMPLOYER";
export const CLEAR_ACTIVE_EMPLOYER = "CLEAR_ACTIVE_EMPLOYER";

export const EMPLOYERS_LOADED = "EMPLOYERS_LOADED";
export const OPEN_ADD_EMPLOYER = "OPEN_ADD_EMPLOYER";
export const OPEN_EDIT_EMPLOYER = "OPEN_EDIT_EMPLOYER";
export const CLOSE_EDIT_EMPLOYER = "CLOSE_EDIT_EMPLOYER";

export const OVERRIDE_LOADED = "OVERRIDE_LOADED";
export const OPEN_ADD_OVERRIDE = "OPEN_ADD_OVERRIDE";
export const OPEN_EDIT_OVERRIDE = "OPEN_EDIT_OVERRIDE";
export const CLOSE_EDIT_OVERRIDE = "CLOSE_EDIT_OVERRIDE";

export const LOAD_MESSAGE_SUMMARIES = "LOAD_MESSAGE_SUMMARIES";
export const SET_NEW_MESSAGE_COUNT = "SET_NEW_MESSAGE_COUNT";
