import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const initialState = {
  status: NOT_LOADED,
  enrollmentEndDate: "",
  enrollmentLength: 0,
  enrollmentOffset: 0,
  enrollmentStartDate: "",
  nextRenewalDate: "",
  quotingEndDate: "",
  quotingLength: 0,
  quotingOffset: 0,
  quotingStartDate: "",
  renewalDate: ""
};

class EditEnrollment extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      this.setState({ status: IS_LOADING });
      this.getEmployer();
    }
  }

  getEmployer = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployer(this.props.auth.employer_id)
        .then(response => {
          this.setState({
            enrollmentEndDate: response.enrollment_end_date,
            enrollmentLength: response.enrollment_length,
            enrollmentOffset: response.enrollment_offset,
            enrollmentStartDate: response.enrollment_start_date,
            nextRenewalDate: response.next_renewal_date,
            quotingEndDate: response.quoting_end_date,
            quotingLength: response.quoting_length,
            quotingOffset: response.quoting_offset,
            quotingStartDate: response.quoting_start_date,
            renewalDate: response.renewal_date,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employer" });
          console.log(err);
        });
    });
  };

  updateEmployer = () => {
    var body = JSON.stringify({
      enrollment_length: this.state.enrollmentLength,
      enrollment_offset: this.state.enrollmentOffset,
      quoting_length: this.state.quotingLength,
      quoting_offset: this.state.quotingOffset,
      renewal_date: this.state.renewalDate
    });

    return new Promise((resolve, reject) => {
      api
        .updateEmployer(this.props.auth.employer_id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Employer" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.reset();
    this.props.close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    this.updateEmployer().then(r => {
      this.close();
    });
  };

  render() {
    const { fullScreen, isOpen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Enrollment Information
          </DialogTitle>
          <DialogContent style={{ height: 350, width: 400 }}>
            <TextField
              id="renewalDate"
              label="Renewal Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.renewalDate}
              onChange={e => this.setState({ renewalDate: e.target.value })}
              fullWidth
              margin="dense"
            />
            <Typography variant="caption" style={{marginTop: 15, marginBottom: 15}}>
              Quoting length and enrollment length are the number of days each
              period should last. Quoting and enrollment offset are the number
              of days ahead of the renewal date you wish to start each period.
            </Typography>
            <TextField
              id="standard-dense"
              label="Quoting Length"
              value={this.state.quotingLength}
              margin="dense"
              onChange={e => this.setState({ quotingLength: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
            />
            <TextField
              id="standard-dense"
              label="Quoting Offset"
              value={this.state.quotingOffset}
              margin="dense"
              onChange={e => this.setState({ quotingOffset: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
            />
            <TextField
              id="standard-dense"
              label="Enrollment Length"
              value={this.state.enrollmentLength}
              margin="dense"
              onChange={e =>
                this.setState({ enrollmentLength: e.target.value })
              }
              style={{ width: 170, marginRight: 10 }}
            />
            <TextField
              id="standard-dense"
              label="Enrollment Offset"
              value={this.state.enrollmentOffset}
              margin="dense"
              onChange={e =>
                this.setState({ enrollmentOffset: e.target.value })
              }
              style={{ width: 170, marginRight: 10 }}
            />
            <br />
            {/*
            <TextField
              id="enrollmentStartDate"
              label="Enrollment Start Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.enrollmentStartDate}
              onChange={e => this.setState({ enrollmentStartDate: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
              margin="dense"
            />
            <TextField
              id="enrollmentEndDate"
              label="Enrollment End Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.enrollmentEndDate}
              onChange={e => this.setState({ enrollmentEndDate: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
              margin="dense"
            />
            <TextField
              id="nextRenewalDate"
              label="Next Renewal Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.nextRenewalDate}
              onChange={e => this.setState({ nextRenewalDate: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
              margin="dense"
            />
            <TextField
              id="quotingStartDate"
              label="Quoting Start Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.quotingStartDate}
              onChange={e => this.setState({ quotingStartDate: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
              margin="dense"
            />
            <TextField
              id="quotingEndDate"
              label="Quoting End Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.quotingEndDate}
              onChange={e => this.setState({ quotingEndDate: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
              margin="dense"
            />*/}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditEnrollment.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

EditEnrollment.defaultProps = {
  isOpen: false
};

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(EditEnrollment)));
