export const SET_EMPLOYER_TABLE = "SET_EMPLOYER_TABLE";
export const SET_EMPLOYEE_TABLE = "SET_EMPLOYEE_TABLE";
export const SET_RECIPIENT_TABLE = "SET_RECIPIENT_TABLE";
export const SET_FORMGROUP_TABLE = "SET_FORMGROUP_TABLE";
export const SET_EMPLOYER_INVITATION_TABLE = "SET_EMPLOYER_INVITATION_TABLE";

export const OPEN_INVITE_EMPLOYER = "OPEN_INVITE_EMPLOYER";
export const CLOSE_INVITE_EMPLOYER = "CLOSE_INVITE_EMPLOYER";

export const OPEN_EDIT_EMPLOYER = "OPEN_EDIT_EMPLOYER";
export const CLOSE_EDIT_EMPLOYER = "CLOSE_EDIT_EMPLOYER";

export const OPEN_EDIT_RECIPIENT = "OPEN_EDIT_RECIPIENT";
export const CLOSE_EDIT_RECIPIENT = "CLOSE_EDIT_RECIPIENT";

export const OPEN_EDIT_FORMGROUP = "OPEN_EDIT_FORMGROUP";
export const CLOSE_EDIT_FORMGROUP = "CLOSE_EDIT_FORMGROUP";

export const LOAD_MESSAGE_SUMMARIES = "LOAD_MESSAGE_SUMMARIES";
