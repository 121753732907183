import { combineReducers } from "redux";
import ProfilesReducer from "./ProfilesReducer";

import AuthReducer from "../../../Shared/Redux/Reducers/AuthReducer";
import ConditionCategoryReducer from "../../../Shared/Redux/Reducers/ConditionCategoryReducer";
import StateReducer from "../../../Shared/Redux/Reducers/StateReducer";

import UIReducer from "./UIReducer";
import EmploymentReducer from "./EmploymentReducer";
import HealthReducer from "./HealthReducer";
import OverrideReducer from "./OverrideReducer";
import EmployerReducer from "./EmployerReducer";
import MessageReducer from './MessageReducer'

const rootReducer = combineReducers({
    ProfilesReducer,
    AuthReducer,
    ConditionCategoryReducer,
    StateReducer,
    UIReducer,
    EmploymentReducer,
    HealthReducer,
    OverrideReducer,
    EmployerReducer,
    MessageReducer
});

export default rootReducer;
