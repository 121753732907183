import { LOAD_CARRIERS } from "../Constants/ActionTypes";

const intitialState = {
    carriers: [],
    isLoaded: false
};

const CarrierReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_CARRIERS:
            return { ...state, carriers: action.payload, isLoaded: true };
        default:
            return state;
    }
};

export default CarrierReducer;
