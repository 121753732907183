export const styles = theme => ({
  homeroot: {},
  homenested: {},
  homecontent: {},
  homewaitCircle: {},
  homedivider: {
    marginTop: 20,
    marginBottom: 20
  },
  homegrid: {},
  homefield: {
    width: 200,
    marginRight: 10,
    "@media only screen and (max-width: 600px)": {
      width: "100%"
    }
  },
  homewavefield: {
    width: 400,
    marginRight: 10,
    "@media only screen and (max-width: 600px)": {
      width: "100%"
    }
  },
  homebutton: {
    marginTop: theme.spacing.unit * 3
  },
  root: {
    width: '100%',
  },
  welcomeheader: {
    fontSize: theme.typography.pxToRem(30),
    "@media only screen and (max-width: 400px)": {
      fontSize: theme.typography.pxToRem(26),
    },
    marginBottom: 20,
  },
  heading: {
    color: '#2996cc',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '70%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  }    
});
