import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import Typography from "@material-ui/core/Typography";

import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/AddCircleOutline";

const styles = theme => ({
  container: {
    float: "left",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    "@media only screen and (max-width: 600px)": {
      minWidth: 343,
      float: "none",
      marginRight: 0
    }
  },
  card: {
    minWidth: 330,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%"
    },
    minHeight: 255
  },
  content: {
    textAlign: "center",
    marginTop: 50
  },
  icon: {
    fontSize: 50
  }
});

class AddCard extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <IconButton
              size="small"
              color="primary"
              onClick={this.props.Action}
            >
              <AddIcon className={classes.icon} />
            </IconButton>
            <br />
            <Typography variant="subtitle1">
              {this.props.ButtonTitle}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}

AddCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddCard);
