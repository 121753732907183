import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { styles } from "./Styles/AuthStyles";

import PasswordInput from "./Shared/Components/PasswordInput";

import { Auth } from "aws-amplify";

import config from "../config";

class CognitoSignUp extends React.Component {
  state = {
    email: "",
    password:"",
  };

  signUpLabel() {
    switch (this.props.app) {
      case "user":
        return "Employee Sign Up";
      case "employer":
        return "Employer Sign Up";
      default:
        return "Sign Up";
    }
  }

  render() {
    const { classes, showConfirmation } = this.props;
    return (
      <div>
        {!showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                {this.signUpLabel()}
              </Typography>
            </div>
            <br />
            {(this.props.customer !== null || this.props.employer !== null) && (
              <React.Fragment>
                <Typography variant="body1">
                  Create a new{" "}
                  {
                    (this.props.app === "broker" && "broker") 
                    || (this.props.app === "employer" && "employer") 
                    || "user"}{" "}
                  account linked to
                </Typography>
                <Typography variant="h6" color="primary">
                  {( (this.props.app === "employer" || this.props.app==="broker") && this.props.customer) ||
                    this.props.employer}
                </Typography>
                <Typography variant="caption" style={{ display: "none" }}>
                  Already have an account? Simply sign in to link your existing
                  account.
                </Typography>
              </React.Fragment>
            )}

            <form>
              <div>
                <TextField
                  id="standard-email-dense"
                  label="Email"
                  error={this.props.emailError}
                  helperText={(this.props.emailError) ? ("Required") : ""}
                  className={classes.textField}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="dense"
                  onChange={e => this.setState({ email: e.target.value })}
                  defaultValue={this.state.email}
                  fullWidth
                />
              </div>
              <div>
                <PasswordInput
                  id="standard-password-input"
                  label="Password"
                  error={this.props.passwordError}
                  helperText={(this.props.passwordError) ? ("Required") : ""}
                  //name="password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  fullWidth
                />
              </div>
            </form>
            <br />
            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button)}
                  onClick={() => {
                    this.props.SignUp(this.state.email, this.state.password);
                  }}
                  style={{ marginLeft: 0, marginTop: 10 }}
                  fullWidth
                >
                  Create Account
                </Button>
              </div>
              { this.props.app === 'user' && (
                <React.Fragment>
                  <div className={classes.socialTextContainer}>
                    {/*<Typography variant="caption">
                  Or sign in with your social account
                </Typography>*/}
                  </div>
                  <div className={classes.socialButtonContainer}>
                    <Button
                      variant="outlined"
                      className={classNames(
                        classes.button,
                        classes.socialButton
                      )}
                      onClick={this.props.goGoogle}
                    >
                      <img
                        src={require("../Shared/Images/Google__G__Logo.svg")}
                        className={classes.socialIcon}
                        alt="Google Signin"
                      />
                      Sign up with Google
                    </Button>
                    {/*<Button variant="contained" color="default" className={classNames(classes.button, classes.socialButton)} onClick={this.goFacebook}>
                  <img src={require("../Shared/Images/f-ogo_RGB_HEX-58.svg")} className={classes.socialIcon} alt="Facebook Signin"/>
                  Facebook
                </Button>*/}
                  </div>
                </React.Fragment>
              )}

              <div className={classes.needAccountContainer}>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => this.props.action("signIn")}
                >
                  {this.props.app == "user" && "Already have a User Account?"}
                  {this.props.app == "employer" &&
                    "Already have an Employer Account?"}
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                Confirm
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter the code sent to {this.state.email} to confirm your
                account.
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={e => this.setState({ code: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={this.props.back}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={() => {
                    this.props.ConfirmSignUp(
                      this.state.email,
                      this.state.password,
                      this.state.code
                    );
                  }}
                  style={{ width: 170 }}
                >
                  Confirm Signup
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CognitoSignUp);