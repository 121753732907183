import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/SettingsMenuStyles";

import EditInfo from "../Info/EditInfo"; 

import {
  SettingsIcon,
  UserIcon,
  TeamIcon,
  RecipientsIcon,
  FormIcon,
  BillingIcon,
} from "../../Shared/Components/Icons";

class SettingsMenu extends React.Component {
  state = {
    anchorEl: null,
    editInfoOpen: false
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleUsers = () => {
    this.props.history.push("/broker/users");
    this.setState({ anchorEl: null });
  };

  handleTeams = () => {
    console.log("Teams");
    this.setState({ anchorEl: null });
  };

  handleRecipients = () => {
    this.props.history.push("/broker/recipients");
    this.setState({ anchorEl: null });
  };

  handleFormGroups = () => {
    this.props.history.push("/broker/form-groups")
    this.setState({ anchorEl: null });
  };

  handleBilling = () => {
    console.log("Billing");
    this.setState({ anchorEl: null });
  };

  handleAgencyInfo = () => {
    this.setState({editInfoOpen: true})
    this.handleClose();
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <EditInfo enqueueSnackbar={this.props.enqueueSnackbar} isOpen={this.state.editInfoOpen} close={() => {this.setState({editInfoOpen: false})}} />
        {1 === 1 && (
          <React.Fragment>
            <IconButton
              aria-owns={open ? "menu-settings" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <SettingsIcon className={classes.settingsMenuIcon} />
            </IconButton>

            <Menu
              id="menu-settings"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleUsers}>
                <UserIcon className={classes.settingsMenuItemIcon} />
                Users
              </MenuItem>
              {/*
              <MenuItem onClick={this.handleTeams}>
                <TeamIcon className={classes.settingsMenuItemIcon} />
                Teams
              </MenuItem>
              */}
              <MenuItem onClick={this.handleRecipients}>
                <RecipientsIcon className={classes.settingsMenuItemIcon} />
                Recipients
              </MenuItem>
              <MenuItem onClick={this.handleFormGroups}>
                <FormIcon className={classes.settingsMenuItemIcon} />
                Form Groups
              </MenuItem>
              <MenuItem onClick={this.handleAgencyInfo}>
                <TeamIcon className={classes.settingsMenuItemIcon} />
                Agency Info
              </MenuItem>
              {/*
              <MenuItem onClick={this.handleBilling}>
                <BillingIcon className={classes.settingsMenuItemIcon} />
                Billing
              </MenuItem>
            */}
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

SettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SettingsMenu);
