//import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  '@page': { margin: 0 },
  basecontent: {
    backgroundColor: theme.palette.background.default, 
    '@media only screen and (max-width: 400px)': {
      backgroundColor: 'white',
    }, 
  },  
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    width: 400,
    '@media only screen and (max-width: 600px)': {
      boxShadow: 'none',
      width: 300,
    }
  },
  root_md: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    width: 400,
    '@media only screen and (max-width: 600px)': {
      width: 300,
      boxShadow: 'none',
    }
  },
  stepper: {
    width:780, 
    backgroundColor:'transparent',
    '@media only screen and (max-width: 600px)': {
      width: '190%',
    }    
  },
  header: {
    textAlign: 'center'
  },
  logoContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  largeLogo: {
    height: 50,
  },
  button: {
    margin: theme.spacing.unit,
    textTransform: "none"
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  leftButton: {
    marginLeft: theme.spacing.unit * -2,
  },
  rightButton: {
    marginRight: 0,
  },
  leftDiv: {
    float: 'left',
    textAlign: 'left',
  },
  rightDiv: {
    float: 'right',
  },
  socialTextContainer: {
    clear: 'both',
    backgroundColor: 'white',
    textAlign: 'center',
    margin: 0,
    paddingTop: 20,
    paddingBottom: 0,
  },
  socialButtonContainer: {
    justifyContent: 'center',
    textAlign: 'center',
    margin: 0,
  },
  socialIcon: {
    height: 20,
    marginRight: theme.spacing.unit,
  },
  socialButton: {
    width: 250,
    backgroundColor: '#ffffff',
  },
  needAccountContainer: {
    textAlign: 'center',
  },
  inputCenter: {
    textAlign: "center",
  }
});