import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import FormLabel from "@material-ui/core/FormLabel";

import MobileStepper from "@material-ui/core/MobileStepper";

import Agreement from "../Agreement";

import Button from "@material-ui/core/Button";

import { Auth } from "aws-amplify";

import { styles } from "../Styles/AuthStyles";

import api from "../../Shared/Functions/APIHelpers";
import {
  formatDateForRenewal
} from "../../Shared/Functions/Format";


import config from "../../config";

class EmployerSetup extends React.Component {
  state = {
    isAuthenticated: false,
    userAttributes: {},
    inviteCode: null,
    customerCode: null,
    step: 1,
    finishDisabled: true,
    agreementModalOpen: false,
    invite: "",
    states: [],
    firstName: "",
    lastName: "",
    employerName: "",
    employerStreet: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    employerPhone: "",
    renewalMonth: "",
    renewalDay: "",
    complete: false,
    isLoading: true
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteCode = params.get("invite");
    let customerCode = params.get("customer");

    this.getStates();
    console.log(this.props)
    if (this.props.flowBypass !== "agreement") {
      if (inviteCode) {
        this.setState({inviteCode:inviteCode});
        this.getInvitation(inviteCode);
      } else if (customerCode) {
        this.setState({customerCode: customerCode});
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    }
    else{
      this.setState({ step: 1, isLoading:false});
    }
  }

  authenticate() {
    console.log("FederatedAuth - Authenticate");
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(err => {
        this.props.history.push("/employer/auth");
      });
  }

  getInvitation = (code) => {
    return new Promise((resolve, reject) => {
      api
        .getInvitationByCodeAccepted(code)
        .then(response => {
          console.log(response)
          console.log(this.state.states)
          this.setState({
              isLoading: false,
              invite: response,
              complete: response.complete,
              //employer: responseData.inviter.employer.name,
              firstName: response.first_name,
              lastName: response.last_name,
              employerName: response.employer_name,
              employerStreet: response.employer_street,
              employerCity: response.employer_city,
              employerState: response.employer_state_id,
              employerZip: response.employer_zip,
              employerPhone: response.employer_phone
            });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };


  getStates = () => {
    return new Promise((resolve, reject) => {
      api
        .getStates()
        .then(response => {
          this.setState({ states: response });
          resolve("success");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting States" });
          console.log(err);
        });
    });
  };

  addEmployer = () => {
    var renewalDate = formatDateForRenewal(
      this.state.renewalMonth,
      this.state.renewalDay
    );

    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      employer_name: this.state.employerName,
      customer_code: this.state.customerCode ? this.state.customerCode: null,
      invitation_code: this.state.inviteCode ? this.state.inviteCode : null,
      employer_street: this.state.employerStreet,
      employer_city: this.state.employerCity,
      employer_state: this.state.employerState,
      employer_zip: (this.state.employerZip !== "") ? this.state.employerZip: null ,
      employer_phone: this.state.employerPhone,
      renewal_date: renewalDate
    });
    return new Promise((resolve, reject) => {
      api
        .addEmployer(body)
        .then(response => {
          this.props.history.push("/employer/home");
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Health Record" });
          console.log(err);
        });
    });
  };


  step2 = () => {
    this.setState({ step: 2 });
  };

  step3 = () => {
    this.setState({ step: 3 });
  };

  step4 = () => {
    this.setState({ step: 4 });
  };

  finish = () => {
    this.addEmployer();
    //this.props.history.push('/home')
  };

  goHome = () => {
    this.props.history.push("/employer/home");
  };

  closeTerms = () => {
    this.setState({ agreementModalOpen: false });
  };
  openTerms = () => {
    this.setState({ agreementModalOpen: true });
  };

  handleSelectChange = event => {
    console.log(event.target.name);
    console.log(event.target.value);

    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    //const searchParams = new URLSearchParams(window.location.search);
    const { classes } = this.props;

    return (
      <div className={classes.basecontent}>
        <Agreement
          isOpen={this.state.agreementModalOpen}
          Close={this.closeTerms}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root_md} elevation={1}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../../Shared/Images/reform_health_vert_500.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              <Typography variant="display1" color="inherit" noWrap>
                Setup
              </Typography>
              <div style={{ height: 450, marginTop: 20 }}>
                {this.state.isLoading && (
                  <div style={{ height: 250, marginTop: 100 }}>
                    <Typography variant="body1">
                      Checking invitation...
                    </Typography>
                    <br />
                    <br />
                    <CircularProgress className={classes.progress} />
                  </div>
                )}
                {!this.state.isLoading && this.state.complete && (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="primary">
                      Your company is already linked to{" "}
                      {this.state.invite.customer.name}!
                    </Typography>
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.goHome}
                        style={{ width: 250 }}
                      >
                        Continue
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 1 && (
                    <React.Fragment>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        style={{ marginBottom: 20 }}
                      >
                        Let's finish setting up your account!
                      </Typography>
                      <TextField
                        id="standard-dense"
                        label="First Name"
                        autoFocus
                        margin="dense"
                        onChange={e =>
                          this.setState({ firstName: e.target.value })
                        }
                        value={this.state.firstName}
                        className={classes.field}
                        fullWidth
                      />
                      <TextField
                        id="standard-dense"
                        label="Last Name"
                        margin="dense"
                        onChange={e =>
                          this.setState({ lastName: e.target.value })
                        }
                        value={this.state.lastName}
                        className={classes.field}
                        fullWidth
                      />
                      <div style={{ textAlign: "right", marginTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.step2}
                          style={{ width: 100 }}
                        >
                          Next
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 2 && (
                    <React.Fragment>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        style={{ marginBottom: 20 }}
                      >
                        Please tell us about your company
                      </Typography>
                      <div style={{ textAlign: "left" }}>
                        <TextField
                          id="standard-dense"
                          label="Company Name*"
                          autoFocus
                          margin="dense"
                          onChange={e =>
                            this.setState({ employerName: e.target.value })
                          }
                          value={this.state.employerName}
                          className={classes.field}
                          fullWidth
                        />
                        <TextField
                          id="standard-dense"
                          label="Street"
                          margin="dense"
                          onChange={e =>
                            this.setState({ employerStreet: e.target.value })
                          }
                          value={this.state.employerStreet}
                          className={classes.field}
                          fullWidth
                        />
                        <TextField
                          id="standard-dense"
                          label="City"
                          margin="dense"
                          onChange={e =>
                            this.setState({ employerCity: e.target.value })
                          }
                          value={this.state.employerCity}
                          className={classes.field}
                          fullWidth
                        />
                        <FormControl margin="dense">
                          <InputLabel htmlFor="states">State</InputLabel>
                          <Select
                            value={this.state.employerState}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: "employerState",
                              id: "employer-state"
                            }}
                            style={{ width: 70, marginRight: 10 }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {this.state.states.map(state => {
                              return (
                                <MenuItem
                                  value={state.id}
                                  key={"state_" + state.id}
                                >
                                  {state.abbreviation}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          id="standard-dense"
                          label="Zip"
                          margin="dense"
                          onChange={e =>
                            this.setState({ employerZip: e.target.value })
                          }
                          value={this.state.employerZip}
                        />
                        <TextField
                          id="standard-dense"
                          label="Phone"
                          margin="dense"
                          onChange={e =>
                            this.setState({ employerPhone: e.target.value })
                          }
                          value={this.state.employerPhone}
                          className={classes.field}
                          fullWidth
                        />
                        <div style={{ textAlign: "right", marginTop: 20 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.step3}
                            style={{ width: 100 }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 3 && (
                    <React.Fragment>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        style={{ marginBottom: 20 }}
                      >
                        When do your benefits renew every year?
                      </Typography>
                      <div style={{ textAlign: "center" }}>
                        <FormControl
                          margin="dense"
                          style={{ width: 220, marginRight: 20 }}
                        >
                          <InputLabel htmlFor="month">Month</InputLabel>
                          <Select
                            value={this.state.renewalMonth}
                            onChange={this.handleSelectChange}
                            autoFocus
                            inputProps={{
                              name: "renewalMonth",
                              id: "renewal-month"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={0}>January</MenuItem>
                            <MenuItem value={1}>February</MenuItem>
                            <MenuItem value={2}>March</MenuItem>
                            <MenuItem value={3}>April</MenuItem>
                            <MenuItem value={4}>May</MenuItem>
                            <MenuItem value={5}>June</MenuItem>
                            <MenuItem value={6}>July</MenuItem>
                            <MenuItem value={7}>August</MenuItem>
                            <MenuItem value={8}>September</MenuItem>
                            <MenuItem value={9}>October</MenuItem>
                            <MenuItem value={10}>November</MenuItem>
                            <MenuItem value={11}>December</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl
                          margin="dense"
                          style={{ width: 75, marginRight: 20 }}
                        >
                          <InputLabel htmlFor="month">Day</InputLabel>
                          <Select
                            value={this.state.renewalDay}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: "renewalDay",
                              id: "renewal-day"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={21}>21</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={23}>23</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={26}>26</MenuItem>
                            <MenuItem value={27}>27</MenuItem>
                            <MenuItem value={28}>28</MenuItem>
                            <MenuItem value={29}>29</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={31}>31</MenuItem>
                          </Select>
                        </FormControl>

                        <div style={{ textAlign: "right", marginTop: 20 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.step4}
                            style={{ width: 100 }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                {!this.state.isLoading &&
                  !this.state.complete &&
                  this.state.step === 4 && (
                    <React.Fragment>
                      <Typography variant="body1" />
                      <Button
                        onClick={this.openTerms}
                        style={{ marginTop: 20 }}
                        color="primary"
                      >
                        View Terms of Service
                      </Button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkedAgree}
                            onChange={this.handleChange("checkedAgree")}
                            value="checkedSameDates"
                            color="primary"
                          />
                        }
                        label="I have read and agree to the Terms of Service"
                      />
                      <div style={{ textAlign: "center", marginTop: 30 }}>
                        <Button
                          disabled={!this.state.checkedAgree}
                          variant="contained"
                          color="primary"
                          onClick={this.finish}
                          style={{ width: 250 }}
                        >
                          Finish
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                <br />
              </div>
            </div>
            <div style={{ justifyText: "center" }}>
              {!this.state.isLoading && !this.state.complete && (
                <MobileStepper
                  variant="progress"
                  steps={5}
                  position="static"
                  activeStep={this.state.step}
                  className={classes.stepper}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

EmployerSetup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployerSetup);
