import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import FormGroupsTable from "./FormGroupsTable";
import Paper from "@material-ui/core/Paper";

import { AddIcon } from "../../Shared/Components/Icons";

import { connect } from "react-redux";
import { openEditFormGroup } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    openEditFormGroup: () => dispatch(openEditFormGroup())
  };
};

class FormGroupsContainer extends React.Component {
  handleOpen = () => {
    this.props.openEditFormGroup();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <FormGroupsTable enqueueSnackbar={this.props.enqueueSnackbar} history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FormGroupsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(FormGroupsContainer));
