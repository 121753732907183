import React from "react";
import PropTypes from "prop-types";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";



import Paper from "@material-ui/core/Paper";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const initialState = {
};

class PrintableInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }



  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{  height: 20 }} />
        <Typography variant="h5" gutterBottom>
          Works
        </Typography>
      </React.Fragment>
    );
  }
}

export default (PrintableInvite);
