import {
    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,
    OPEN_INVITE_EMPLOYER,
    CLOSE_INVITE_EMPLOYER,
    OPEN_EDIT_RECIPIENT,
    CLOSE_EDIT_RECIPIENT,
    OPEN_EDIT_FORMGROUP,
    CLOSE_EDIT_FORMGROUP
} from "../Constants/ActionTypes";

const intitialState = {
    editEmployerModalOpen: false,
    inviteEmployerModalOpen: false,
    editRecipientModalOpen: false,
    editFormGroupModalOpen: false,
    isLoading: false
};

const UIReducer = (state = intitialState, action) => {
    switch (action.type) {
        case OPEN_EDIT_EMPLOYER:
            return { ...state, editEmployerModalOpen: action.payload };
        case CLOSE_EDIT_EMPLOYER:
            return { ...state, editEmployerModalOpen: action.payload };
        case OPEN_INVITE_EMPLOYER:
            return { ...state, inviteEmployerModalOpen: action.payload };
        case CLOSE_INVITE_EMPLOYER:
            return { ...state, inviteEmployerModalOpen: action.payload };
        case OPEN_EDIT_RECIPIENT:
            return { ...state, editRecipientModalOpen: action.payload };
        case CLOSE_EDIT_RECIPIENT:
            return { ...state, editRecipientModalOpen: action.payload };
        case OPEN_EDIT_FORMGROUP:
            return { ...state, editFormGroupModalOpen: action.payload };
        case CLOSE_EDIT_FORMGROUP:
            return { ...state, editFormGroupModalOpen: action.payload };
        default:
            return state;
    }
};

export default UIReducer;
