import {
    SET_EMPLOYER_TABLE,
    SET_EMPLOYEE_TABLE,
    SET_RECIPIENT_TABLE,
    SET_FORMGROUP_TABLE,
    SET_EMPLOYER_INVITATION_TABLE
} from "../Constants/ActionTypes";

const initialState = {
    employerTable: {
        selected: {},
        filters: {},
        sort: {}
    },
    employeeTable: {
        selected: {},
        filters: {},
        sort: {}
    },
    recipientTable: {
        selected: {},
        filters: {},
        sort: {}
    },
    formGroupTable: {
        selected: {},
        filters: {},
        sort: {}
    },
    employerInvitationTable: {
        selected: {},
        filters: {},
        sort: {}
    }
};

const TableReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EMPLOYER_TABLE:
            return { ...state, employerTable: action.payload };
        case SET_EMPLOYEE_TABLE:
            return { ...state, employeeTable: action.payload };
        case SET_RECIPIENT_TABLE:
            return { ...state, recipientTable: action.payload };
        case SET_FORMGROUP_TABLE:
            return { ...state, formGroupTable: action.payload };
        case SET_EMPLOYER_INVITATION_TABLE:
            return { ...state, employerInvitationTable: action.payload };
        default:
            return state;
    }
};

export default TableReducer;
