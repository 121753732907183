import React, { Component } from "react";

import Router from "./Router";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import theme from "./Shared/Styles/MuiTheme";

import Amplify from "aws-amplify";
import config from "./config";

Amplify.configure({
  Auth: {
    oauth: config.oauth,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.FILE_BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});

class App extends Component {
  componentDidMount() {
    document.title = "Reform Health";
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router />
      </MuiThemeProvider>
    );
  }
}

export default App;
