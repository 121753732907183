import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import EmployersTable from "./EmployersTable";

import { AddIcon } from "../../Shared/Components/Icons";

import { connect } from "react-redux";
import InviteEmployer from "./InviteEmployer";
import { openInviteEmployer } from "../Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    openInviteEmployer: () => dispatch(openInviteEmployer())
  };
};

class EmployersContainer extends React.Component {
  state = {
    modalOpen: false
  };

  handleOpen = () => {
    this.props.openInviteEmployer();
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <InviteEmployer isEdit={false} enqueueSnackbar={this.props.enqueueSnackbar}/>
        <div className={classes.containerRoot}>
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper} style={{overflow: "hidden"}}>
              <EmployersTable history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EmployersContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EmployersContainer));
