import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";
import LocationModule from "./LocationModule"

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;



const initialState = {
  status : NOT_LOADED,
  code: "",
  name: "",
  sic: "",
  naics: "",
  primaryLocationID: ""
};

class EditInfo extends React.Component {
  state={}

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING
    ) {
      this.setState({ status: IS_LOADING,});
      this.getEmployer();
    }
  }

  getEmployer = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployer(this.props.auth.employer_id)
        .then(response => {
          console.log(response)
          this.setState({
            code : response.code ? response.code : "",
            naics: response.naics_code ? response.naics_code : "",
            sic: response.sic_code ? response.sic_code : "",
            name: response.name ? response.name : "",
            primaryLocationID : response.primary_location_id  ? response.primary_location_id  : "",
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employer" });
          console.log(err);
        });
    });
  };

  updateEmployer = () => {
    var body = JSON.stringify({
      code : this.state.code,
      naics_code : this.state.naics,
      sic_code : this.state.sic,
      name: this.state.name,
    });

    return new Promise((resolve, reject) => {
      api
        .updateEmployer(this.props.auth.employer_id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Employer" });
          console.log(err);
        });
    });
  };

  updatePrimaryLocation = (locationID) => {

    var body = JSON.stringify({
      primary_location_id : locationID
    });

    return new Promise((resolve, reject) => {
      api
        .updateEmployer(this.props.auth.employer_id, body)
        .then(response => {
          this.setState({primaryLocationID: locationID})
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Primary Location" });
          console.log(err);
        });
    });
  };

  close = () => {
    this.reset();
    this.props.close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    this.updateEmployer().then(r => {
      this.close();
    });
  };

  render() {
    const { fullScreen, isOpen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Company Information
          </DialogTitle>
          <DialogContent style={{ height: 450, width: 400 }}>
            <TextField
              id="standard-dense"
              label="Company Name"
              value={this.state.name}
              margin="dense"
              onChange={e => this.setState({ name: e.target.value })}
              fullWidth
            />
            <Typography variant="caption" style={{marginTop: 15, marginBottom: 15}}>
            The invitation code is used by employees who wish to add your company as an employer.
            </Typography>
            <TextField
              id="standard-dense"
              label="Employer Code"
              value={this.state.code}
              margin="dense"
              onChange={e => this.setState({ code: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
            />
            <br/>
            <TextField
              id="standard-dense"
              label="NAICS"
              value={this.state.naics}
              margin="dense"
              onChange={e => this.setState({ naics: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
            />
            <TextField
              id="standard-dense"
              label="SIC"
              value={this.state.sic}
              margin="dense"
              onChange={e => this.setState({ sic: e.target.value })}
              style={{ width: 170, marginRight: 10 }}
            />
            <br/>
            <LocationModule enqueueSnackbar={this.props.enqueueSnackbar} isOpen={this.props.isOpen} isLoaded={!!this.state.primaryLocationID} PrimaryLocationID={this.state.primaryLocationID} UpdatePrimaryLocation={this.updatePrimaryLocation} EmployerID={this.props.auth.employer_id}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditInfo.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

EditInfo.defaultProps = {
  isOpen: false
};

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(EditInfo)));
