import {
   LOAD_FIELDS,
   UPDATE_FIELD,
   ADD_FIELD,
   DELETE_FIELD,
   LOAD_CONDITION_RULES,
   DELETE_CONDITION_RULE,
   ADD_CONDITION_RULE,
   LOAD_CONDITION_GROUP_RULES,
   DELETE_CONDITION_GROUP_RULE,
   ADD_CONDITION_GROUP_RULE,

   LOAD_MODELS,
   LOAD_MODEL_FIELDS
} from '../Constants/ActionTypes';


const intitialState = {

   fields: [],
   conditionRules : [],
   conditionGroupRules: [],
   models : [],
   modelFields : []
}

const mappingReducer = ( state = intitialState, action) => {
   switch (action.type) {
       case LOAD_FIELDS:
           return {...state, fields: action.payload}
       case UPDATE_FIELD:
           return {...state, fields: state.fields.map(
               (field) => {
                   if(field.id === action.payload.id)
                   {
                       if(field.isMulti){
                           if(field.mappingRule.id === action.payload.mappingRule.id)
                               return action.payload
                           else
                               return field
                       }
                       return action.payload
                   }
                   return field
               })}
       case ADD_FIELD:
           return {...state, fields: state.fields.concat(action.payload)}
       case DELETE_FIELD:
           return {...state, fields: state.fields.reduce( (acc, cur) => {
               if( (cur.isMulti) && (cur.id === action.payload.id) && (cur.mappingRule.id === action.payload.mappingRule.id) ){
                   //this is the one we want to delete IF its multi so return accumulator
                   return acc
               }
               else if ( (!cur.isMulti) && (cur.id === action.payload.id) ){
                   //this is the one we want to delete if its NOT a multi, so return accumlator
                   return acc
               }
               else{
                   //otherwise just return the object because we want it
                   return acc.concat(cur)
               }
           }, [])
       }
       case LOAD_CONDITION_RULES:
           return { ...state, conditionRules : action.payload}
       case DELETE_CONDITION_RULE:
           return { ...state, conditionRules : state.conditionRules.filter(cr => cr != action.payload)}
       case ADD_CONDITION_RULE:
           return {...state, conditionRules : state.conditionRules.concat(action.payload)}
       case LOAD_CONDITION_GROUP_RULES :
           return { ...state, conditionGroupRules : action.payload}
       case DELETE_CONDITION_GROUP_RULE:
           return { ...state, conditionGroupRules : state.conditionGroupRules.filter(cgr => cgr.id != action.payload)}
       case ADD_CONDITION_GROUP_RULE:
           return { ...state, conditionGroupRules: state.conditionGroupRules.concat(action.payload)}
       case LOAD_MODELS:
           return { ...state, models: action.payload}
       case LOAD_MODEL_FIELDS :
           return { ...state, modelFields : action.payload}
       default:
           return state;
   }
};

export default mappingReducer;