import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { PeopleIcon, EditIcon, FormIcon } from "../../Shared/Components/Icons";

import EditEmployer from "./EditEmployer";
import SelectEmployerForm from "../FormReview/SelectEmployerForm";

import { Auth } from "aws-amplify";
import config from "../../config";
import api from "../../Shared/Functions/APIHelpers";

import { setEmployerTable, openEditEmployer } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setEmployerTable: employerTable =>
      dispatch(setEmployerTable(employerTable)),
    openEditEmployer: () => dispatch(openEditEmployer())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    employerTable: state.TableReducer.employerTable
  };
};
const initialState = {
    menuOpen: false,
    anchorEl: null,
    employerForms:[],
    selectEmployerFormsOpen: false
};


class EmployersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  getCustomerEmployerForms = () => {
    return new Promise((resolve, reject) => {
      api
        .getCustomerEmployerFormList(this.props.auth.customer_id, this.props.employerTable.selected.id)
        .then(response => {
          this.setState({employerForms: response})
          resolve(response)
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  handleCloseMenu = () => {
    this.reset();
  };

  openMenu = (e, employer) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });

    var tempState = this.props.employerTable;
    tempState.selected = employer;
    this.props.setEmployerTable(tempState);
  };

  handleViewEmployees = () => {
    this.props.history.push("/broker/employees");
  };

  handleFormReview = () => {
    this.getCustomerEmployerForms().then(forms => {
      if(this.state.employerForms.length === 1){
        this.props.history.push({
          pathname: '/broker/form-review',
          state: { employer: this.props.employerTable.selected.id, form:this.state.employerForms[0].id, type: 'employer'}
        })
      }
      else{
        this.setState({selectEmployerFormsOpen: true})
      }
    });
    this.handleCloseMenu();
  };

  handleSetupEmployer = () => {
    this.props.openEditEmployer();
    this.handleCloseMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <EditEmployer refreshTable={this.handleRefreshTable} />
        <SelectEmployerForm 
          EmployerForms={this.state.employerForms} 
          Employer={this.props.employerTable.selected.id}
          Type={"employer"}
          isOpen={this.state.selectEmployerFormsOpen}
          Close={() => {this.setState({selectEmployerFormsOpen: false})}}
          history={this.props.history} 
        />
        <CustomTable
          columns={[
            {
              label: "Name",
              dataKey: "name",
              numeric: false,
              width: 300
            },
            {
              label: "Renewal Date",
              dataKey: "renewal_date",
              numeric: false,
              width: 150
            },
            {
              label: "Employee Count",
              dataKey: "employee_count",
              numeric: false,
              width: 100
            },
            {
              label: "Status",
              dataKey: "status",
              numeric: false,
              width: 100
            },
            {
              label: "Active",
              dataKey: "active",
              boolean: false,
              width: 100
            }
          ]}
          name={'Employers'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          infinite={true}
          getDataURL={"customers/" + this.props.auth.customer_id + "/employers"}
          rowCountEndpoint={
            "/api/v1/customers/" +
            this.props.auth.customer_id +
            "/employers/count"
          }
          filters={[
            {column:"status", modelColumn:"status",options:['Quoting', 'Underwriting','Open Enrollment', 'Pre-Renewal', 'Closed']},
            {column:"active", modelColumn:"active",options:['Yes', 'No']},
          ]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleViewEmployees}>
            <PeopleIcon className={classes.leftIcon} />
            Employees
          </MenuItem>
          <MenuItem onClick={this.handleFormReview}>
            <FormIcon className={classes.leftIcon} />
            Form Review
          </MenuItem>
          <MenuItem onClick={this.handleSetupEmployer}>
            <EditIcon className={classes.leftIcon} />
            Setup
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmployersTable));
