import {
    LOAD_OVERRIDE,
    ADD_OVERRIDE,
    UPDATE_OVERRIDE,
    DELETE_OVERRIDE,
    SET_ACTIVE_OVERRIDE,
    CLEAR_ACTIVE_OVERRIDE,
    OVERRIDE_LOADED
} from "../Constants/ActionTypes";

const intitialState = {
    override: [],
    isLoaded: false,
    isLoading: false,
    activeOverride: {}
};

const overrideReducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOAD_OVERRIDE:
            return {
                ...state,
                override: state.override.concat(action.payload)
            };
        case ADD_OVERRIDE:
            return {
                ...state,
                override: state.override.concat(action.payload)
            };
        case UPDATE_OVERRIDE:
            return {
                ...state,
                override: state.override.map(override =>
                    override.id === action.payload.id
                        ? action.payload
                        : override
                )
            };
        case DELETE_OVERRIDE:
            return {
                ...state,
                override: state.override.filter(
                    override => override.id !== action.payload
                )
            };
        case SET_ACTIVE_OVERRIDE:
            return {
                ...state,
                activeOverride: state.override.find(override =>
                    override.id === action.payload ? override : null
                )
            };
        case CLEAR_ACTIVE_OVERRIDE:
            return { ...state, activeOverride: {} };
        case OVERRIDE_LOADED:
            return { ...state, isLoaded: true };
        default:
            return state;
    }
};

export default overrideReducer;
