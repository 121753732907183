import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";


import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import { connect } from "react-redux";

import { closeEditFormGroup, setFormGroupTable } from "../Redux/Actions/index";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const mapDispatchToProps = dispatch => {
  return {
    closeEditFormGroup: () => dispatch(closeEditFormGroup()),
    setFormGroupTable: formGroupTable =>
      dispatch(setFormGroupTable(formGroupTable))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    carriers: state.CarrierReducer.carriers,
    forms: state.FormReducer.forms,
    formGroupTable: state.TableReducer.formGroupTable,
    isOpen: state.UIReducer.editFormGroupModalOpen
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if(ownProps.welcomePage){
    console.log('from welcome page')
    return { 
      ...stateProps, 
      ...dispatchProps, 
      ...ownProps, 
      isOpen: ownProps.isOpen,
      refreshTable: ownProps.refreshTable,
      formGroupTable: ownProps.formGroupTable,
      closeEditFormGroup: ownProps.closeEditFormGroup
    }  
  }
  return { ...stateProps, ...dispatchProps, ...ownProps}  
};


const initialState = {
  name: "",
  carriers: [],
  isAgencyDefault: false,
  forms: [],
  selectedForms: [],
  status: NOT_LOADED,
  searchFormValue: ""
};

class EditFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.state.status === NOT_LOADED &&
      this.state.status !== IS_LOADING &&
      this.props.formGroupTable.selected.id
    ) {
      this.setState({ status: IS_LOADING });
      this.getFormGroup();
    }
  }

  getFormGroup = () => {
    return new Promise((resolve, reject) => {
      api
        .getFormGroup(this.props.formGroupTable.selected.id)
        .then(response => {
          console.log(this.props.enqueueSnackbar)
          this.setState({
            name: response.name,
            isAgencyDefault: response.is_agency_default,
            selectedForms: response.forms,
            status: IS_LOADED
          });
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Form Group" });
          console.log(err);
        });
    });
  };

  replacer = (key, value) => {
    if (key === "customer_id" && value === "") {
      return undefined;
    }
    return value;
  };

  addFormGroup = () => {
    var body = JSON.stringify(
      {
        name: this.state.name,
        set_agency_default: this.state.isAgencyDefault,
        customer_id : this.props.auth.customer_id,
        forms: this.state.selectedForms
      },
      this.replacer
    );

    return new Promise((resolve, reject) => {
      api
        .addFormGroup(this.props.auth.customer_id, body)
        .then(r => {
          this.props.enqueueSnackbar("Form Group Added" , {
              variant: "success"
          });
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Adding Form Group" });
          console.log(err);
        });
    });
  };

  updateFormGroup = () => {
    var body = JSON.stringify({
      name: this.state.name,
      set_agency_default: this.state.isAgencyDefault,
      customer_id : this.props.auth.customer_id,
      forms: this.state.selectedForms
    });

    return new Promise((resolve, reject) => {
      api
        .updateFormGroup(this.props.formGroupTable.selected.id, body)
        .then(response => {
          resolve("done");
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Form Group" });
          console.log(err);
        });
    });
  };

  deleteFormGroup = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteFormGroup(this.props.formGroupTable.selected.id)
        .then(r => {
          resolve("done");
        })
        .catch(err => {
          this.props.enqueueSnackbar("Can't delete Form Group, it is being used as a Customer Default", {
            variant: "error"
          });
        });
    });
  };

  close = () => {
    var tempTable = this.props.formGroupTable;
    tempTable.selected = {};
    this.props.setFormGroupTable(tempTable);

    this.reset();
    this.props.closeEditFormGroup();
  };

  handleClose = () => {
    this.close();
  };

  handleConfirmDelete = () => {
    this.setState({ confirmAlertOpen: true });
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDelete();
  };

  handleDelete = () => {
    this.deleteFormGroup().then(r => {
      this.props.refreshTable();
      this.close();
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleAddForm = (val, type) => {
    var tempSelectedForms = this.state.selectedForms;
    tempSelectedForms.push(val);
    this.setState({ selectedForms: tempSelectedForms });
  };

  handleDeleteForm = form => {
    var tempSelectedForms = this.state.selectedForms;
    tempSelectedForms = tempSelectedForms.filter(f => f.id != form.id);
    this.setState({ selectedForms: tempSelectedForms });
  };

  handleSave = () => {
    if (this.props.formGroupTable.selected.id !== undefined) {
      this.updateFormGroup().then(r => {
        this.props.refreshTable();
        this.close();
      });
    } else {
      this.addFormGroup().then(r => {
        this.props.refreshTable();
        this.close();
      });
    }
  };

  render() {
    const { fullScreen, isOpen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            
            {(this.props.formGroupTable.selected.id === undefined && "Add") ||
              "Edit"}{" "}
            Form Group {this.props.formGroupTable.selected.name}
          </DialogTitle>

          <DialogContent style={{ height: 700, width: 500 }}>
            <TextField
              id="standard-dense"
              label="Form Group Name"
              value={this.state.name}
              margin="dense"
              onChange={e => this.setState({ name: e.target.value })}
              fullWidth
            />

            <FormControlLabel
              control={
                <Switch
                  checked={this.state.isAgencyDefault}
                  onChange={e => {
                    this.setState({ isAgencyDefault: e.target.checked });
                  }}
                  value="isAgencyDefault"
                  color="primary"
                />
              }
              label="Make Agency Default"
            />

            <Typography variant={"button"} style={{ marginTop: 30 }}>
              Forms
            </Typography>
            <Typography variant={"caption"} style={{ marginBottom: 5 }}>
              Select each form in the group
            </Typography>
            <List dense={false}>
              {this.state.selectedForms.map((form, index) => {
                return (
                  <ListItem key={"form_item" + index}>
                    <ListItemText
                      primary={form.name}
                      secondary={form.carrier_name}
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                        value={index}
                        onClick={() => {
                          this.handleDeleteForm(form);
                        }}
                        aria-label="Delete"
                      >
                        <DeleteIcon className={classes.primaryIcon} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <ReactSelect
              styles={customStyles}
              fullWidth
              onChange={(val, type) => this.handleAddForm(val, type)}
              value={this.state.searchFormValue}
              options={this.props.forms}
              getOptionValue={option => option["id"]}
              getOptionLabel={option => option["name"]}
            />
          </DialogContent>
          <DialogActions>
            <ConfirmAlert
              isOpen={this.state.confirmAlertOpen}
              isNo={this.DoNotDelete}
              isYes={this.DoDelete}
              AlertTitle={"Confirm Delete"}
              AlertText={
                "Are you sure you wish to delete this Form Group? This cannot be undone."
              }
            />
            
            {this.props.formGroupTable.selected.id !== undefined && (
              <Button onClick={this.handleConfirmDelete} color="primary">
                Delete
              </Button>
            )}
          
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditFormGroup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

EditFormGroup.defaultProps = {
  isOpen: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withMobileDialog()(withStyles(styles)(EditFormGroup)));

//export default withMobileDialog()(withStyles(styles)(EditFormRevision));
