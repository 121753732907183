import React from "react";
import PropTypes from "prop-types";


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import { styles } from "./Styles/WelcomeStyles";

import FormUploadModal from "../../Shared/Components/FormUploadModal";
import EditRecipient from "../Recipients/EditRecipient";
import EditFormGroup from "../FormGroups/EditFormGroup";
import InviteEmployer from "../Employers/InviteEmployer";
import InvitationModal from "../../Shared/Components/InvitationModal";
import EditInfo from "../Info/EditInfo"; 

import api from "../../Shared/Functions/APIHelpers";

import Paper from "@material-ui/core/Paper";

import {
  updateCustomerCode,
} from "../../Shared/Redux/Actions/index";

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerCode: code =>
      dispatch(updateCustomerCode(code))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const initialState = {
  formUploadOpen: false,
  expanded: "panel1",
  recipientOpen: false,
  formGroupsOpen: false,
  employerInvitationOpen: false,
  userInvitationModalOpen: false,

  numRecipients: 0,
  numEmployersInvited: 0,
  numFormGroups: 0,
  numInternalUsers: 0,

  editInfoOpen: false
};


class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleGetCounts()
  }

  getCounts = () => {
    return new Promise((resolve, reject) => {
      api
        .getBrokerCounts(this.props.auth.customer_id)
        .then(response => {
          this.setState({
            numRecipients: response.num_recipients,
            numEmployersInvited: response.num_employers_invited,
            numFormGroups: response.num_form_groups,
            numInternalUsers: response.num_internal_users
          })
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Customer Counts" });
          console.log(err);
        });
    });
  };
  handleClickFormUpload = () => {
    this.setState({ formUploadOpen: true });
  };

  handleClickRecipient = () => {
    this.props.history.push("/broker/recipients");
  };

  handleClickFormGroups = () => {
    this.props.history.push("/broker/form-groups");
  };

  handleClickInviteUser = () => {
    this.props.history.push("/broker/users");
  };

  handleClickInviteEmployer = () => {
    this.props.history.push("/broker/employers");
  };

  handleClickMultiFactor = () => {
    this.props.history.push("/broker/user-settings");
  };

  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false})
  }; 

  handleGetCounts = () => {
    this.getCounts()
  }

  handleAgencyInfo = () => {
    this.setState({editInfoOpen: true})
  }

  render() {
    const { classes } = this.props;
    const hyperlink = "https://app.reformhealth.io/employer/setup?broker=" + this.props.reformUser.customer_code; 
    return (
      <React.Fragment>
        <EditInfo 
          enqueueSnackbar={this.props.enqueueSnackbar} 
          isOpen={this.state.editInfoOpen} 
          close={() => {this.setState({editInfoOpen: false})}} 
          updateCustomerCode={this.props.updateCustomerCode}
        />
        <FormUploadModal
          Customer={this.props.auth.customer_id}
          UploadType={"Customer"}
          Open={this.state.formUploadOpen}
          Close={() => {
            this.setState({ formUploadOpen: false });
          }}
        />
        <InvitationModal
          Type={3}
          Employer={this.props.reformUser.employer_id}
          Customer={this.props.reformUser.customer_id}
          Inviter={this.props.reformUser.profile_id}
          Open={this.state.userInvitationModalOpen}
          Close={() => {
            this.handleGetCounts()
            this.setState({ userInvitationModalOpen: false });
          }}
        />
        <EditRecipient 
          welcomePage={true}
          enqueueSnackbar={this.props.enqueueSnackbar} 
          refreshTable={() => {console.log('nothing')}} 
          recipientTable={ {'selected':{}} }
          isOpen={this.state.recipientOpen}
          closeEditRecipient={() => {
            this.handleGetCounts()
            this.setState({recipientOpen:false})
          }}
        />
        <EditFormGroup 
          welcomePage={true}
          refreshTable={()=>{console.log('nothing')}} 
          enqueueSnackbar={this.props.enqueueSnackbar} 
          formGroupTable={ {'selected':{}} }
          isOpen={this.state.formGroupsOpen}
          closeEditFormGroup={() => {
            this.handleGetCounts()
            this.setState({formGroupsOpen:false})
          }}
        />
        <InviteEmployer 
          isEdit={false} 
          refreshTable={()=>{console.log('nothing')}} 
          enqueueSnackbar={this.props.enqueueSnackbar}
          employerInvitationTable={ {'selected':{}} }
          isOpen={this.state.employerInvitationOpen}
          closeInviteEmployer={() => {
            this.handleGetCounts()
            this.setState({employerInvitationOpen:false})
          }}
        />

        <div style={{ height: 20 }} />

        <Typography variant="h5" gutterBottom>
          Welcome to Reform Health!
        </Typography>
        <Typography variant="body1">
          Let’s walk you through a few simple steps to get you up and running!
        </Typography>

        <div style={{ height: 20 }} />


      <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Upload copies of the forms you’ll need</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
            Reform Health is new in your area and we want to make sure we have all
            of the forms you’ll need. Please upload them and we’ll get them loaded
            right away, usually within 3 business days. Moving forward, your
            underwriters will be able to notify us of any form updates directly.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            onClick={this.handleClickFormUpload}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Set up your recipients</Typography>
          <Typography className={classes.secondaryHeading}>{this.state.numRecipients} recipient added</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          Recipients are usually underwriters who you wish to review your
          groups. Enter their info here so we know where to deliver your forms.
          </Typography>
          <Typography variant="caption" style={{marginTop:10}}>
          Please wait until all your forms are available before completing this
          step.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            //onClick={this.handleClickRecipient}
            onClick={() => { this.setState({recipientOpen:true})}}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Set up your form groups</Typography>
          <Typography className={classes.secondaryHeading}>{this.state.numFormGroups} form group created</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          Form groups allow you to store sets of forms which you typically shop
          together. For instance, you can set up groups based on group size such
          as “Under 10 lives” or “50+ lives”. You can also set up a default form
          group for your agency to be used every time.
          </Typography>
          <Typography variant="caption" style={{marginTop:10}}>
          Please wait until all your forms are available before completing this
          step.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            //onClick={this.handleClickFormGroups}
            onClick={() => {this.setState({formGroupsOpen: true})}}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={this.state.expanded === 'panel4'} onChange={this.handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Invite your colleagues</Typography>
          <Typography className={classes.secondaryHeading}>{this.state.numInternalUsers} colleagues invited</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          To ensure your groups have the correct contact information and
          underwriters know where to return quotes, please invite any colleagues
          who will be using Reform Health. You have currently used x of y
          licenses. 
          </Typography>
          <Typography variant="caption" style={{marginTop:10}}>
          If you need to upgrade your plan, please email
          billing@reformlabs.io
          </Typography>          
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            //onClick={this.handleClickInviteUser}
            onClick={ () => { this.setState({userInvitationModalOpen: true}) } }
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={this.state.expanded === 'panel5'} onChange={this.handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Invite your first employer</Typography>
          <Typography className={classes.secondaryHeading}>{this.state.numEmployersInvited} employer invited</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          Once you’ve got your agency set up, it’s time to invite your groups.
          We recommend starting with one or two that you’d like to quote right
          away while you’re getting comfortable with Reform Health. After that,
          feel free to invite all your groups - even if they’re months out from
          quoting. Reform Health will start the quoting process automatically
          ahead of renewals.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            //onClick={this.handleClickInviteEmployer}
            onClick={() => { this.setState({employerInvitationOpen: true}) }}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>      


        <div style={{ height: 30 }} />
        <Typography variant="subtitle2" gutterBottom>
          Other things to get you started{" "}
        </Typography>
        <div style={{ height: 10 }} />


      <ExpansionPanel expanded={this.state.expanded === 'panel1a'} onChange={this.handleChange('panel1a')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Get your agency invitation link</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          Your broker code is: <b>{this.props.reformUser.customer_code}</b>
          </Typography>
          <Typography style={{marginTop: 10}}>
          This code will allow employers to link their account to your agency. Use this code over the phone and on printed materials
          where you cannot use an invitation link.
          </Typography>          
          <Typography variant="body1" style={{marginTop:30}}>
          Your invitation Link: <a href={hyperlink}>{hyperlink}</a>
          </Typography>
          <Typography style={{marginTop: 10}}>
          Use this link on your website, email signature or anywhere else
          to allow employers to create an account and be linked automatically to
          your agency.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            onClick={this.handleAgencyInfo}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={this.state.expanded === 'panel2a'} onChange={this.handleChange('panel2a')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Enable stronger security with 2-Step Verification</Typography>
          <Typography className={classes.secondaryHeading}>Done</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ width: '80%'}}>
          <Typography variant="body1">
          Multi-factor authentication adds an extra layer of security to your
          account. Beyond your email and password, you will be required to enter
          a single-use pin upon logging in which can be sent via SMS or
          generated with a multi-factor app like Authy or Google Authenticator.
          This means that, even if someone guessed your username and password,
          they would not be able to log into your account.
          </Typography>
          <Typography variant="body1" style={{marginTop: 10}}>
          We recommend you set this up right away, but it will not be required
          until you have more than 500 lives in Reform Health.
          </Typography>
          </div>
          <div style={{ textAlign: 'right'}}>
          <Button
            onClick={this.handleClickMultiFactor}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
          </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </React.Fragment>
    );
  }
}

WelcomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WelcomePage));