import {
    OPEN_EDIT_EMPLOYER,
    CLOSE_EDIT_EMPLOYER,

    OPEN_EDIT_RECIPIENT,
    CLOSE_EDIT_RECIPIENT
} from '../Constants/ActionTypes'

const intitialState = {
    editEmployerModalOpen: false,
    editRecipientModalOpen: false,
    isLoading: false,

}

const UIReducer = ( state = intitialState, action ) => {
    switch (action.type) {
        case OPEN_EDIT_EMPLOYER:
            return{...state, editEmployerModalOpen: action.payload}
        case CLOSE_EDIT_EMPLOYER:
            return {...state, editEmployerModalOpen: action.payload}
        case OPEN_EDIT_RECIPIENT:
            return {...state, editRecipientModalOpen: action.payload}
        case CLOSE_EDIT_RECIPIENT:
            return {...state, editRecipientModalOpen: action.payload}
        default:
            return state        
    }
};


export default UIReducer