import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import InformationSummary from "./InformationSummary";
import Paper from "@material-ui/core/Paper";

import api from "../../Shared/Functions/APIHelpers";

import {
  getProfiles,
} from "../../User/Shared/Functions/StaticFunctions";


const mapStateToProps = state => {
  return {
    employeeTable: state.TableReducer.employeeTable,
    auth: state.AuthReducer
  };
};


class SummaryContainer extends React.Component {
  state = {
    profiles: [],
    health: []
  }

  componentDidMount() {
    var selectedEmployee = this.props.employeeTable.selected
    getProfiles(Auth, selectedEmployee.user_id).then(r => {
      this.setState({profiles: r});
    })
    this.getHealthRecords().then(healthRecords => {
      this.setState({health: healthRecords})
    })
  }

  getHealthRecords = () => {
    var selectedEmployee = this.props.employeeTable.selected
    return new Promise((resolve, reject) => {
      api
        //.getHealthRecords(this.props.auth.user_id)
        .getHealthRecords(selectedEmployee.user_id)
        .then(response => {
          console.log(response)
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Health Records" });
          console.log(err);
        });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.containerRoot}>
          <header className={classes.containerHeader}>
            <Typography className={classes.containerTitle}>
              Employee Summary
            </Typography>
          </header>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper style={{padding: 15}} className={classes.containerPaper}>
              <InformationSummary profiles={this.state.profiles} healthRecords={this.state.health} history={this.props.history} />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SummaryContainer.propTypes = {
  classes: PropTypes.object.isRequired
};


export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SummaryContainer));
