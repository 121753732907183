import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Nav/Styles/NavStyles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import {
  HomeIcon,
  MessageIcon,
  PeopleIcon,
  BrokerIcon
} from "../../Shared/Components/Icons";

class NestedList extends React.Component {
  goHome = () => {
    this.props.history.push("/employer/home");
  };

  goEmployees = () => {
    this.props.history.push("/employer/employees");
  };

  goBrokers = () => {
    this.props.history.push("/employer/brokers")
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">Reform Health</ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText inset primary="Home" />
          </ListItem>


          <ListItem button onClick={this.goEmployees}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText inset primary="Employees" />
          </ListItem>

          <ListItem button onClick={this.goBrokers}>
            <ListItemIcon>
              <BrokerIcon />
            </ListItemIcon>
            <ListItemText inset primary="Brokers" />
          </ListItem>
        </List>
      </div>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NestedList);
