import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import PeopleIcon from "@material-ui/icons/People";
import EditIcon from "@material-ui/icons/Edit";
import { InfoIcon } from "../../Shared/Components/Icons";


import FormIcon from "@material-ui/icons/Description";
import MessagesIcon from "@material-ui/icons/QuestionAnswer";

import { Auth } from "aws-amplify";
import config from "../../config";

import SubmissionDetail from "./SubmissionDetail";
import { setSubmissionTable } from "../Redux/Actions/index";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapDispatchToProps = dispatch => {
  return {
    setSubmissionTable: submissionTable =>
      dispatch(setSubmissionTable(submissionTable))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    submissionTable: state.TableReducer.submissionTable
  };
};

class SubmissionsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null,
    submissionDetailOpen: false,
  };

  componentDidMount(){
    console.log(this.props.submissionTable.selected)
  }

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, submission) => {
    var tempState = this.props.submissionTable;
    tempState.selected = submission;
    this.props.setSubmissionTable(tempState);

    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  handleViewSubmission = () => {
    this.props.history.push("/carrier/form-review");
  };

  handleViewSubmissionDetails = () => {
    this.openSubmissionDetail();
  };

  handleEditEmployer = () => {
    this.props.openEditEmployer();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };

  closeSubmissionDetail = () => {
    this.setState({submissionDetailOpen: false});
  };

  openSubmissionDetail = () => {
    this.setState({submissionDetailOpen: true});
    this.handleClose();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <SubmissionDetail Open={this.state.submissionDetailOpen} Close={this.closeSubmissionDetail} Submission={this.props.submissionTable.selected.id} />
        <CustomTable
          columns={[
            {
              label: "Employer",
              dataKey: "employer.name",
              nested: true,
              numeric: false,
              width: 300
            },
            {
              label: "Form",
              dataKey: "form.name",
              nested: true,
              numeric: false,
              width: 150
            },
            {
              label: "Status",
              dataKey: "status",
              numeric: false,
              width: 150
            }
          ]}
          name={'Quoting Submissions'}
          rowHeight={70}
          setRefresh={func => (this.refreshTable = func)}
          getDataURL={
            "users/" + this.props.auth.user_id + "/carrier-submissions"
          }
          filters={[]}
          //getDataFromServer={this.getDataFromServer}
          rowCountEndpoint={"/api/v1/admin/employers/count"}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleViewSubmission}>
            <FormIcon className={classes.leftIcon} />
            View Forms
          </MenuItem>
          <MenuItem onClick={this.handleViewSubmissionDetails}>
            <InfoIcon className={classes.leftIcon} />
            Details
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SubmissionsTable));
