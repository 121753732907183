import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import blue from "@material-ui/core/colors/blue";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";
import {mapStateIDFromAbbr} from "../../Shared/Functions/Format";

import {
  AddCircleIcon,
  CancelCircleIcon,
  ConfirmCircleIcon,
  StarIcon
} from "../../Shared/Components/Icons";

const NOT_LOADED = 1;
const IS_LOADING = 2;
const IS_LOADED = 3;

const mapStateToProps = state => {
  return {
    states: state.StateReducer.states
  };
};

const styles = theme => ({
  title: {
    backgroundColor: blue[800],
    color: "white"
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 1.5
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  divider: {
    height: 40
  },
  address: {
    "@media only screen and (max-width: 600px)": {
      maxWidth: 220,
      marginBottom: -5,
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  }
});

const initialState = {
  errorMessage: "",
  showInputs: false,
  isEdit: false,
  employerLocations: [],
  anchorEl: null,
  status: NOT_LOADED,

  counties: [],
  stateID: '',
  county: '',
  countyID: '',
  locationID: '',
  isPrimary: false,
  city: '', 
  name: '', 
  phone: '',
  street: '',
  zip : ''
};

class LocationModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps){
    if (this.props.isOpen && this.props.isLoaded && (this.state.status === NOT_LOADED) ) {
      this.getEmployerLocations();
    }
  };

  getCounties = () => {
    return new Promise((resolve, reject) => {
      api
        .getCounties(this.state.stateID)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Counties" });
          console.log(err);
        });
    });
  };

  getEmployerLocations = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerLocations(this.props.EmployerID)
        .then(r => {
          var employerLocations = r
          employerLocations = employerLocations.map(l => {
            if(l.id === this.props.PrimaryLocationID)
              return {...l, isPrimary : true}
            else
              return {...l, isPrimary : false}
          })
          this.setState({ employerLocations: employerLocations, status: IS_LOADED });
          resolve(r);
        })
        .catch(err => {
          this.props.enqueueSnackbar(err, {
            variant: "error"
          });
        });
    });
  };

  addLocation = () => {
    var body = JSON.stringify({
      state_id: this.state.stateID,
      county_id: this.state.countyID,
      city: this.state.city,
      name: this.state.name,
      phone: this.state.phone,
      street: this.state.street,
      zip : this.state.zip
    });

    return new Promise((resolve, reject) => {
      api
        .addEmployerLocation(this.props.EmployerID, body)
        .then(response => {
          var employerLocations = this.state.employerLocations
          employerLocations.push(response)
          this.setState({employerLocations: employerLocations}, resolve("done"))
        })
        .catch(err => {
          this.props.enqueueSnackbar(err, {
            variant: "error"
          });
        });
    });
  };

  deleteLocation = () => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployerLocation(this.state.locationID)
        .then(r => {
          var employerLocations = this.state.employerLocations;
          employerLocations = employerLocations.filter(l => l.id != this.state.locationID );
          this.setState({employerLocations: employerLocations}, resolve("done"));
        })
        .catch(err => {
          this.props.enqueueSnackbar("Can't Delete Location In Use", {
            variant: "error"
          });
        });
    });
  };

  editLocation = () => {
    return new Promise((resolve, reject) => {
      var body = JSON.stringify({
        state_id: this.state.stateID,
        county_id: this.state.countyID,
        city: this.state.city,
        name: this.state.name,
        phone: this.state.phone,
        street: this.state.street,
        zip : this.state.zip
      });

      api
        .updateEmployerLocation(this.state.locationID, body)
        .then(response => {  
          console.log(response)
          var employerLocations = this.state.employerLocations;
          employerLocations = employerLocations.map(l => {
            if(l.id === this.state.locationID)
              return {...response,state: response.state.abbreviation, county: response.county.name}
            else 
              return l
          })
          employerLocations = employerLocations.map(l => {
            if(l.id === this.props.PrimaryLocationID)
              return {...l, isPrimary : true}
            else
              return {...l, isPrimary : false}
          })
          this.setState({employerLocations: employerLocations}, resolve("done"));
        })
        .catch(err => {
          this.props.enqueueSnackbar(err, {
            variant: "error"
          });
        });
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
    this.clear();
  };

  handleMenu = (event, location) => {
    console.log(location)
    this.setState(
      {

        locationID: location.id,
        isPrimary: location.isPrimary,
        stateID: location.state ? mapStateIDFromAbbr(location.state) : '',
        county: location.county ? location.county : '',
        city: location.city ? location.city : '',
        name: location.name ? location.name : '',
        phone: location.phone ? location.phone : '',
        street: location.street ? location.street : '',
        zip : location.zip ? location.zip : ''
      },
      this.openMenu(event)
    )
  };

  openMenu = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  saveLocation = () => {
    if (this.state.isEdit) {
      this.editLocation().then(r => {
        this.handleCloseMenu();
      });
    } else {
      this.addLocation().then(r => {
        this.handleCloseMenu();
      });
    }
  };

  handleEditLocation = event => {
    this.getCounties().then(counties => {
      //need to find the county ID and put it in state b/c location endpoint sends back the abbreviation
      var county = counties.find(c => c.name === this.state.county)
      if(this.state.county)
        this.setState({countyID: county.id})

      this.setState(
        { counties: counties, showInputs: true, isEdit: true, anchorEl: null },
        this.scrollToMyRef1
      );
    });
  };

  handleSetPrimaryLocation = event => {
    this.props.UpdatePrimaryLocation(this.state.locationID).then(r => {
      var employerLocations = this.state.employerLocations
      employerLocations = employerLocations.map(l => {
        if(l.id === this.state.locationID)
          return {...l, isPrimary : true}
        else
          return {...l, isPrimary : false}
      })
      this.setState({ employerLocations: employerLocations}, this.handleCloseMenu());
    })
  };

  handleAddLocation = () => {
    this.setState({ showInputs: true, isEdit: false }, this.scrollToMyRef1);
  };

  handleDeleteLocation = () => {
    this.deleteLocation().then(r => {
      this.handleCloseMenu();
    });
  };

  clear = () => {
    this.setState({  
      showInputs: false, 
      isEdit: false, 
      counties: [], 
      county: '', 
      countyID: '', 
      stateID:'',
      locationID: '',
      isPrimary: false,
      city: '',
      name: '',
      phone: '',
      street: '',
      zip: ''
    })
  };

  cancelLocation = () => {
    this.clear();
  };

  handleStateChange = event => {
    this.setState({ stateID: event.target.value }, () => {
     console.log(event.target.value)
      this.getCounties().then(counties => {
        this.setState({ counties: counties });
      });
    });
  };

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div>
        {!this.state.showInputs && (
          <React.Fragment>
            <FormLabel component="legend" style={{ marginTop: 20 }}>
              Locations
            </FormLabel>
            <List>
              {this.state.employerLocations.map((location, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {location.name}
                          {location.isPrimary && (
                            <StarIcon color={"primary"} style={{ fontSize: 10, marginLeft: 5 }} />
                          )}
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={e => this.handleMenu(e, location)} aria-label="Menu">
                        <MoreIcon color={"primary"}/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <Button
              className={classes.button}
              style={{ marginLeft: 8 }}
              onClick={this.handleAddLocation}
              color="primary"
            >
              <AddCircleIcon className={classes.leftIcon} />
              Add Location 
            </Button>
            <Menu
              id="menu-location"
              key={"menu_"}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              
              <MenuItem key={"Menu1_"} onClick={this.handleEditLocation}>
                Edit Location
              </MenuItem>
              {!this.state.isPrimary && (
              <MenuItem key={"Menu2_"} onClick={this.handleSetPrimaryLocation}>
                Set Primary Location
              </MenuItem>
              )}
              {!this.state.isPrimary && (
              <MenuItem key={"Menu3_"} onClick={this.handleDeleteLocation}>
                Delete Location
              </MenuItem>
              )}
              
            </Menu>
          </React.Fragment>
        )}

        {this.state.showInputs && (
          <React.Fragment>
            <FormLabel component="legend" style={{ marginTop: 20 }}>
              Add Location
            </FormLabel>
            <div id="hiddenDiv" style={{ marginLeft: 17 }}>
            <TextField
                id="standard-dense"
                label="Name"
                margin="dense"
                onChange={this.handleChange("name")}
                value={this.state.name}
                fullWidth
              />
              <TextField
                id="standard-dense"
                label="Phone"
                margin="dense"
                onChange={this.handleChange("phone")}
                value={this.state.phone}
                fullWidth
              />

              <TextField
                id="standard-dense"
                label="Street"
                margin="dense"
                onChange={this.handleChange("street")}
                value={this.state.street}
                fullWidth
              />

              <TextField
                id="standard-dense"
                label="City"
                margin="dense"
                onChange={this.handleChange("city")}
                value={this.state.city}
                fullWidth
              />
            <FormControl margin="dense">
              <InputLabel htmlFor="states">State</InputLabel>
              <Select
                value={this.state.stateID}
                onChange={this.handleStateChange}
                inputProps={{
                  name: "state",
                  id: "state"
                }}
                style={{ width: 70, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.states.map(state => {
                  return (
                    <MenuItem value={state.id} key={"state_" + state.id}>
                      {state.abbreviation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl margin="dense">
              <InputLabel htmlFor="county">County</InputLabel>
              <Select
                value={this.state.countyID}
                onChange={this.handleChange("countyID")}
                inputProps={{
                  name: "county",
                  id: "county"
                }}
                style={{ width: 150, marginRight: 10 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.state.counties.map(county => {
                  return (
                    <MenuItem value={county.id} key={"county_" + county.id}>
                      {county.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
              
              <TextField
                id="standard-dense"
                label="Zip"
                margin="dense"
                onChange={this.handleChange("zip")}
                value={this.state.zip}
                style={{ width: 100 }}
              />
              <br />
              <IconButton
                className={classes.button}
                style={{ marginTop: 10, marginLeft: -17 }}
                onClick={this.cancelLocation}
                color="primary"
              >
                <CancelCircleIcon />
              </IconButton>
              <IconButton
                className={classes.button}
                style={{ marginTop: 10, marginLeft: -5 }}
                onClick={this.saveLocation}
                color="primary"
              >
                <ConfirmCircleIcon />
              </IconButton>
              <div
              id="myRef1"
              ref="myRef1"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block"
              }}
            />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withMobileDialog()(withStyles(styles)(LocationModule)));

