import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/SettingsMenuStyles";

import FormUploadModal from "../../Shared/Components/FormUploadModal";

import {
  SettingsIcon,
  UserIcon,
  TeamIcon,
  RecipientsIcon,
  FormIcon,
  BillingIcon,
} from "../../Shared/Components/Icons";

class SettingsMenu extends React.Component {
  state = {
    anchorEl: null,
    formUploadOpen: false
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleUploadForm = () => {
  	this.setState({formUploadOpen:true})
    this.handleClose();
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (

      <React.Fragment>
      	<div style={{display:'none'}}>
      		<FormUploadModal
          Customer={'test'}
          UploadType={"Carrier"}
          Open={this.state.formUploadOpen}
          Close={() => {
            this.setState({ formUploadOpen: false });
          }}
        />
      	</div>
        {1 === 1 && (
          <React.Fragment>
            <IconButton
              aria-owns={open ? "menu-settings" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <SettingsIcon className={classes.settingsMenuIcon} />
            </IconButton>

            <Menu
              id="menu-settings"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleUploadForm}>
                <FormIcon className={classes.settingsMenuItemIcon} />
                Upload Form
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

SettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SettingsMenu);
