import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import Typography from "@material-ui/core/Typography";

import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { Storage } from "aws-amplify";

import { Auth } from "aws-amplify";

import api from "../../Shared/Functions/APIHelpers";

import config from "../../config";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import {
  formatDateTimeAsDate,
} from "../../Shared/Functions/Format";

const initialState = {};

class InviteTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    //if ( this.props.isOpen && !prevProps.isOpen)
      //window.print(); 
  }
  
  close = () => {
    this.reset();
    this.props.close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    this.updateEmployer().then(r => {
      this.close();
    });
  };

  render() {
    const { fullScreen, isOpen, classes } = this.props;
    return (
      <div >
        <Dialog 
          open={!!this.props.isOpen}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={true}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title"></DialogTitle>
          <DialogContent style={{textAlign: 'center', marginTop:50, marginLeft:75, marginRight:75}}>
            <Typography variant='h4' gutterBottom>
            Hello!
            </Typography>
            <div style={{height:25}}/>
            <Typography variant='body1'>
            {this.props.employerName} is using a system called Reform Health to
            help us get the best rates for our medical coverage. It is simple,
            secure and controlled by you.
            </Typography>
            <div style={{height:25}}/>
            <Typography variant='body1'>
            Please visit
            </Typography>
            <Typography variant='h6'>
            reformhealth.io/employees
            </Typography>
            <Typography variant='body1' gutterBottom>
            to sign up and learn more
            </Typography>
            <div style={{height:25}}/>
            <Typography variant='body1'>
            When creating your account, use employer code:
            </Typography>
            <Typography variant='h6' gutterBottom>
            {this.props.employerCode}
            </Typography>
            <div style={{height:25}}/>
            <Typography variant='body1'>
            If you already have a Reform Health account, simply use this code to add us as your employer.
            </Typography>
            <div style={{height:25}}/>
            <Typography variant='body1'>
            You must do this by <b>{formatDateTimeAsDate(this.props.quotingEndDate)}</b> to ensure you are able to receive
            coverage.
            </Typography>
            <br/>
            <br/>
                <img 
                  src={require("../../Shared/Images/reform_health_horiz_300.png")}
                  style={{width:'200px'}}
                  alt="Reform Health Logo"
                />
                <br/>
                <br/>

            <Button onClick={this.handleClose} className={classes.buttonStyle}  color="primary">
              Cancel
            </Button>
            <Button onClick={() => {window.print()}} className={classes.buttonStyle} color="primary">
              Print
            </Button>         
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InviteTypeModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

InviteTypeModal.defaultProps = {
  isOpen: false
};

export default withMobileDialog()(withStyles(styles)(InviteTypeModal));