import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { Route, withRouter } from "react-router-dom";

import api from "../../Shared/Functions/APIHelpers";
import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class Form extends React.Component {
  state = {

  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        In Form Viewer
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Form);