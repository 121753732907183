import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import PasswordInput from "./Shared/Components/PasswordInput";

import { styles } from "./Styles/AuthStyles";

import { Auth } from "aws-amplify";
import { createCode, parseCodeObject } from "./Shared/Functions/StaticFunctions";

import config from "../config";

class SignIn extends React.Component {

  state = {
    open: false,
    user: {},
    email:"",
    emailError: false,
    password:"",
    passwordError:false,
    showConfirmation: false,
    challengeParam: {},
    errorMessage: "",
    inviteCode: "",
    emailLabel: "",
    subheader: ""
  };

  handleKey = (event) => {
    if(event.key === "Enter")
      this.SignIn()

  }

  goGoogle = () => {
    window.location =
      "https://" +
      config.oauth.domain +
      "/oauth2/authorize?response_type=" +
      config.oauth.responseType +
      "&client_id=" +
      config.cognito.APP_CLIENT_ID +
      "&redirect_uri=" +
      config.oauth.redirectSignIn +
      "&state=app=" +
      this.props.app +
      "+code=" +
      createCode() +
      "&identity_provider=Google";
  };

  goFacebook = () => {
    window.location =
      "https://" +
      config.oauth.domain +
      "/oauth2/authorize?response_type=" +
      config.oauth.responseType +
      "&client_id=" +
      config.cognito.APP_CLIENT_ID +
      "&redirect_uri=" +
      config.oauth.redirectSignIn +
      "&state=app=" +
      this.props.app +
      "+code=" +
      createCode() +
      "&identity_provider=Facebook";
  };

  SignIn = () => {

    //if (this.state.email && this.state.password) {
    if(this.isValidForm()){
      Auth.signIn(this.state.email, this.state.password)
        .then(user => {
          console.log("Preferred MFA: " + user.challengeName);
          if (
            user.challengeName === "NOMFA" ||
            user.challengeName === undefined
          ) {
            Auth.userAttributes(user)
            .then(attributes => {
              if(attributes[2]['Value']){
                if(attributes[2]['Value'] !== this.props.app) 
                {
                  Auth.signOut().then(() => {
                     var currentApp = localStorage.getItem("reform_health_app")
                    localStorage.clear();
                    localStorage.setItem("reform_health_app", currentApp);
                    this.props.enqueueSnackbar("You're Account Doesn't Have Permission To View This App", {
                      variant: "warning"
                    })
                    //console.log(this.props.app)
                    //this.props.history.push("/" + this.props.app + "/auth");  
                  });
                }
                else{
                  this.redirectLoggedInUser(user)
                }
              }
              else{ 
                //Temporary because some of our test accounts were created without custom app 
                this.redirectLoggedInUser(user)
              }
            })
          } else {
            this.setState({ user, showConfirmation: true });
            this.setState({ challengeParam: user.challengeParam });
            this.setState({ errorMessage: "" });
          }
        })
        .catch(err => {
          this.setState({
            emailError: false,
            passwordError:false
          })
          this.props.enqueueSnackbar(err.message, {
            variant: "warning"
          })
        });
    }
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  ConfirmSignIn = () => {
    Auth.confirmSignIn(
      this.state.user,
      this.state.code,
      this.state.user.challengeName
    )
      .then(user => {
        console.log(user)
        this.redirectLoggedInUser(user)
        //this.props.history.push("/home");
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  isValidForm = () => {
    if (this.state.email && this.state.password) {
      return true;
    }
    else{
      this.setState({
        emailError: (this.state.email === ""),
        passwordError: (this.state.password === "")
      })
    }
  };

  redirectLoggedInUser = ( user ) => {
    var code = parseCodeObject()
    console.log(code)
    if(code.code){
      console.log('in here')
      this.props.history.push("/" + this.props.app + '/link-complete?'+code.type +'='+code.code)
    }
    else{
      this.props.history.push("/" + this.props.app + "/home");  
    }
  };

  welcomeLabel() {
    switch (this.props.app) {
      case "user":
        return "Welcome Employee!";
      case "employer":
        return "Welcome Employer!";
      case "broker":
        return "Welcome Broker!";
      case "carrier":
        return "Welcome Carrier!";
      default:
        return "Welcome!";
    }
  }

  emailLabel() {
    switch (this.props.app) {
      case "user":
        return "Email";
      case "employer":
        return "Work Email";
      case "broker":
        return "Work Email";
      case "carrier":
        return "Work Email";
      default:
        return "Email";
    }
  }

  signUpLabel() {
    switch (this.props.app) {
      case "user":
        return "Need a User Account?";
      case "employer":
        return "Need an Employer Account?";
      case "broker":
        return "Need a Broker Account?";
      case "carrier":
        return "Need a Carrier Account?";
      default:
        return "Need a User Account?";
    }
  }

  render() {
    const { showConfirmation } = this.state;
    const { classes } = this.props;
    return (
      <div onKeyDown={this.handleKey}>
        {!showConfirmation && (
          <div>
            <div>          
              <Typography variant="display1" color="inherit" noWrap>
                {this.welcomeLabel()}
              </Typography>
              <Typography variant="subtitle2" color="primary">
                {this.state.subheader}
              </Typography>
            </div>
            <br />
            {(this.props.customer !== null || this.props.employer !== null) && (
              <React.Fragment>
                <Typography variant="body1">
                  Sign in to link your{" "}
                  {(this.props.app === "employer" && "employer") || "user"}{" "}
                  account with
                </Typography>
                <Typography variant="h6" color="primary">
                  {(this.props.app === "employer" && this.props.customer) ||
                    this.props.employer}
                </Typography>
              </React.Fragment>
            )}

            <Typography variant="body2" color="error" gutterBottom>
              {this.state.errorMessage}
            </Typography>
            <form>
              <div>
                <TextField
                  id="standard-email-dense"
                  label={this.emailLabel()}
                  error={this.state.emailError}
                  helperText={(this.state.emailError) ? ("Required") : ""}
                  className={classes.textField}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="dense"
                  onChange={e => this.setState({ email: e.target.value })}
                  defaultValue={this.state.email}
                  fullWidth
                />
              </div>
              <div>
                <PasswordInput
                  id="standard-password-input"
                  label="Password"
                  error={this.state.passwordError}
                  helperText={(this.state.passwordError) ? ("Required") : ""}
                  //name="password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  fullWidth
                />
              </div>
            </form>
            <br />
            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ marginLeft: 0, marginTop: 10 }}
                  onClick={this.SignIn}
                  fullWidth
                  id="signInButton"
                  name="signInButton"
                >
                  Sign In
                </Button>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  style={{ marginLeft: 0, marginBottom: 5 }}
                  onClick={() => this.props.action("forgotPassword")}
                >
                  Forgot Password?
                </Button>
              </div>
              { this.props.app === 'user' && (
              <React.Fragment>
              <div className={classes.socialTextContainer}>
                {/*<Typography variant="caption">
                  Or sign in with your social account
                </Typography>*/}
              </div>
              <div className={classes.socialButtonContainer}>
                <Button
                  variant="outlined"
                  className={classNames(classes.button, classes.socialButton)}
                  onClick={this.goGoogle}
                >
                  <img
                    src={require("../Shared/Images/Google__G__Logo.svg")}
                    className={classes.socialIcon}
                    alt="Google Signin"
                  />
                  Sign in with Google
                </Button>
                {/*<Button variant="contained" color="default" className={classNames(classes.button, classes.socialButton)} onClick={this.goFacebook}>
                  <img src={require("../Shared/Images/f-ogo_RGB_HEX-58.svg")} className={classes.socialIcon} alt="Facebook Signin"/>
                  Facebook
                </Button>*/}
              </div>
              </React.Fragment>
              )} 
              { this.props.app !== 'broker' && this.props.app !== 'carrier' && this.props.app !== 'admin' && (
                <div className={classes.needAccountContainer}>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => this.props.action("signUp")}
                  >
                    {this.signUpLabel()}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="display1" color="inherit" noWrap>
                Confirm Sign In
              </Typography>
            </div>
            <br />
            <Typography variant="body2" color="error" gutterBottom>
              {this.state.errorMessage}
            </Typography>
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                {this.state.user.challengeName === "SOFTWARE_TOKEN_MFA" && (
                  <div>
                    Enter the code from your software token application.
                  </div>
                )}

                {this.state.user.challengeName === "SMS_MFA" && (
                  <div>
                    Enter the code sent to{" "}
                    {this.state.challengeParam.CODE_DELIVERY_DESTINATION} via
                    SMS.
                  </div>
                )}
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={e => this.setState({ code: e.target.value })}
                inputProps={{
                  hintStyle: {width: 300},
                  style: { width: 300}
                }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={this.Back}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.ConfirmSignIn}
                  style={{ width: 170 }}
                >
                  Confirm Signin
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);