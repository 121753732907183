import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";

import {
  getAddress,
  formatDate,
  mapRelationshipType,
  mapStateFromID
} from "../../Shared/Functions/Format";

import { connect } from "react-redux";

import { openEditProfile, setActiveProfile } from "../Redux/Actions/index";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./Styles/ProfileCardStyles";

const mapDispatchToProps = dispatch => {
  return {
    openEditProfile: () => dispatch(openEditProfile()),
    setActiveProfile: profileID => dispatch(setActiveProfile(profileID))
  };
};

class ProfileCard extends React.Component {
  handleEdit = () => {
    this.props.setActiveProfile(this.props.profile.id);
    this.props.openEditProfile();
  };

  handleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { classes, profile } = this.props;
    return (
      <div
        className={
          (this.props.Summary && classes.container_expanded) ||
          classes.container
        }
      >
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="row">
              <Grid item xs style={{ maxWidth: 343 }}>
                <Typography variant="caption" gutterBottom>
                  {mapRelationshipType(profile.relationship_type)}
                </Typography>
                <Typography variant="headline" component="h2">
                  {(profile.first_name !== "" && profile.first_name) || "-"}{" "}
                  {(profile.last_name !== "" && profile.last_name) || "-"}
                </Typography>
                <Typography variant="body1" className={classes.pos}>
                  {(profile.date_of_birth !== "" &&
                    formatDate(profile.date_of_birth)) ||
                    "-"}
                </Typography>
                <Typography variant="body1" className={classes.pos}>
                  {profile.user_address && (
                    <React.Fragment>
                      {profile.user_address.street} <br />{" "}
                      {profile.user_address.city +
                        ", " +
                        profile.user_address.state +
                        " " +
                        profile.user_address.zip +
                        " " +
                        profile.user_address.county}
                    </React.Fragment>
                  )}
                </Typography>
                <div style={{ display: "flex" }}>
                  <Typography variant="body1">Medical:</Typography>
                  {(profile.cover_medical && (
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ marginLeft: 5, color: "#698e1c" }}
                    >
                      Cover
                    </Typography>
                  )) || (
                    <Typography
                      variant="body1"
                      color="secondary"
                      style={{ marginLeft: 5 /*color:'#ce3d2b'*/ }}
                    >
                      Waive
                    </Typography>
                  )}
                </div>
              </Grid>
              {this.props.Summary && (
                <Grid item xs style={{ marginTop: 10 }} id="thegrid">
                  <Typography variant="caption">Details</Typography>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <div className={classes.fieldGroup}>
                    <div className={classes.fieldName}>SSN</div>
                    <div className={classes.fieldValue}>{profile.ssn}</div>
                  </div>
                  <div className={classes.fieldGroup}>
                    <div className={classes.fieldName}>Height</div>
                    <div className={classes.fieldValue}>
                      {profile.height_feet}'{profile.height_inches}"
                    </div>
                  </div>
                  <div className={classes.fieldGroup}>
                    <div className={classes.fieldName}>Weight</div>
                    <div className={classes.fieldValue}>
                      {profile.weight}lbs
                    </div>
                  </div>
                  <div className={classes.fieldGroup}>
                    <div className={classes.fieldName}>Phone</div>
                    <div className={classes.fieldValue}>
                      {(profile.phone !== "" && profile.phone) || "-"}
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            { (!this.props.isBrokerRendered) && (
            <Button
              size="small"
              color="primary"
              style={{ minWidth: 32 }}
              onClick={this.handleEdit}
              name="handleEditButton"
            >
              Edit
            </Button>
            )}
          </CardActions>
        </Card>
      </div>
    );
  }
}

ProfileCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProfileCard));

export { ProfileCard as PureProfileCard};
