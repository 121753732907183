import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import classNames from "classnames";

import CustomTable from "../../Shared/Components/Table";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { EditIcon } from "../../Shared/Components/Icons";

//import EditFormGroup from "./EditFormGroup";

import { Auth } from "aws-amplify";
import config from "../../config";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
  };
};

class FormGroupsTable extends React.Component {
  state = {
    menuOpen: false,
    anchorEl: null
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openMenu = (e, formGroup) => {
    var tempState = this.props.formGroupTable;
    tempState.selected = formGroup;
    this.props.setFormGroupTable(tempState);

    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  handleEditFormGroup = () => {
    this.props.openEditFormGroup();
    this.handleCloseMenu();
  };

  handleRefreshTable = () => {
    this.refreshTable();
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/*<EditFormGroup refreshTable={this.handleRefreshTable} />*/}
        <CustomTable
          columns={[
            {
              label: "First Name",
              dataKey: "first_name",
              numeric: false,
              width: 300
            },
            {
              label: "Last Name",
              dataKey: "last_name",
              numeric: false,
              width: 300
            },
            {
              label: "Email",
              dataKey: "email",
              numeric: false,
              width: 100
            },
            {
              label: "Type",
              dataKey: "type",
              numeric: false,
              width: 100
            }
          ]}
          name={'Agency Users'}
          setRefresh={func => (this.refreshTable = func)}
          rowHeight={70}
          getDataURL={
            "customers/"+ this.props.auth.customer_id +"/employees"
          }
          filters={[]}
          openMenu={this.openMenu}
          onActionClick={this.onActionClick}
        />

        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleEditFormGroup}>
            <EditIcon className={classes.leftIcon} /> View User
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(FormGroupsTable));
