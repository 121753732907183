import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { Auth } from "aws-amplify";
import config from "./config";

import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import AuthContainer from "./Auth/AuthContainer";
import UserContainer from "./User/UserContainer";
import BrokerContainer from "./Broker/BrokerContainer";
import AdminContainer from "./Admin/AdminContainer";
import CarrierContainer from "./Carrier/CarrierContainer";
import EmployerContainer from "./Employer/EmployerContainer";
import MedicareContainer from "./Consoliplex/Medicare/MedicareContainer";

import FederatedAuth from "./Auth/FederatedAuth";

import EmployeeSetup from "./Auth/Employee/EmployeeSetup";
import LinkEmployer from "./Auth/Employee/LinkToEmployer";

import EmployerSetup from "./Auth/Employer/EmployerSetup";
import LinkCustomer from "./Auth/Employer/LinkToCustomer";

import CustomerSignUp from "./Auth/Customer/CustomerSignUp";

import Agreement from "./Auth/Agreement";
import UserRouter from "./Auth/UserRouter";

import { getApp, getApp2 } from "./Auth/Shared/Functions/StaticFunctions";

import { loadUser } from "./Shared/Redux/Actions/index";

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {}
  };

  componentDidMount() {
    //set cookie for app name
    if (this.props.app != undefined) {
      localStorage.setItem("reform_health_app", this.props.app);
    }

    //check if JWT already exists... AKA user is already logged in
    this.authenticate();

    //define a function that will check if the JWT token exists EVERY TIME the URL changes
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then()
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
  };

  componentWillUnmount() {
    //call the function defined above to active the lsitener
    this.unlisten();
  };

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        user.federatedUser =
          user.username.includes("Google_") ||
          user.username.includes("Facebook_");
        this.setState({ user: user, loaded: true, isAuthenticated: true });
      })
      .catch(err => {
        var app = localStorage.getItem("reform_health_app");
        localStorage.clear();
        //redirect to appropriate app, or user if null
        this.showUnauthorizedWarning(this.props.message, this.props.app)
        
      });
  };

  showUnauthorizedWarning = (message, app) => {
    if(message){
      this.props.enqueueSnackbar(message, {
        variant: "warning"
      });
    }
    if (app) this.props.history.push("/" + app + "/auth");
    else this.props.history.push("/user/auth");
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;

    if (!loaded) return null; //doesn't load the private component until user is loaded
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <div>
              <div id="login">
                <Component {...props} {...rest} user={this.state.user} />
              </div>
            </div>
          );
        }}
      />
    );
  }
}
//export default withSnackbar(withStyles(styles)(HomeContainer));
PrivateRoute = withSnackbar(withRouter(PrivateRoute));

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/consoliplex/medicare" component={MedicareContainer}  />

      <Route path="/auth" render={(props) => <AuthContainer {...props} app={"init"} />}  />
      <Route path="/user/auth"  render={(props) => <AuthContainer {...props} app={"user"} />} />
      <Route path="/employer/auth"  render={(props) => <AuthContainer {...props} app={"employer"} />} />
      <Route path="/broker/auth"  render={(props) => <AuthContainer {...props} app={"broker"} />} />
      <Route path="/carrier/auth"  render={(props) => <AuthContainer {...props} app={"carrier"} />} />
      <Route path="/admin/auth"  render={(props) => <AuthContainer {...props} app={"admin"} />} />

      <Route path="/federated-login" component={FederatedAuth} />

      <Route path="/user/setup"  render={(props) => <AuthContainer {...props} app={"user"} flowBypass="signUp" />} />
      <PrivateRoute path="/user/setup-complete" component={EmployeeSetup} />
      <Route path="/user/link"  render={(props) => <AuthContainer {...props} app={"user"} flowBypass="signIn" />} />
      <PrivateRoute path="/user/link-complete" component={LinkEmployer} />


      <Route path="/employer/setup" render={(props) => <AuthContainer {...props} app={"employer"} flowBypass="signUp" />} />
      <PrivateRoute path="/employer/setup-complete" app={"employer"} message={"Please Login to Setup a New Employer Account"} component={EmployerSetup} />
      <Route path="/employer/link" render={(props) => <AuthContainer {...props} app={"employer"} flowBypass="signIn" />} />      
      <PrivateRoute path="/employer/link-complete" app={"employer"} message={"Please Login to Link Employer with Customer"} component={LinkCustomer} />

      <Route path="/broker/setup" render={(props) => <AuthContainer {...props} app={"broker"} flowBypass="signUp" newAgency={true} />} /> 
      <Route path="/broker-user/setup" render={(props) => <AuthContainer {...props} app={"broker"} flowBypass="signUp" newAgency={false} />} />
      <PrivateRoute path="/broker-user/setup-complete" app={"broker"} component={EmployeeSetup} />

      <Route path="/carrier/setup" render={(props) => <AuthContainer {...props} app={"carrier"} flowBypass="signUp" />} /> 
      <PrivateRoute path="/carrier/setup-complete" app={"carrier"} component={EmployeeSetup} />


      <PrivateRoute path="/admin" app="admin" component={AdminContainer} />
      <PrivateRoute path="/broker" message={"Please Login to View the Broker Portal"} app="broker" component={BrokerContainer} />
      <PrivateRoute path="/carrier" message={"Please Login to View the Carrier Portal"}  app="carrier" component={CarrierContainer}/>
      <PrivateRoute path="/employer" message={"Please Login to View the Employer Portal"} app="employer" component={EmployerContainer}/>
      <PrivateRoute path="/user" message={"Please Login to View the User Portal"} app="user" component={UserContainer} />
      <PrivateRoute path="/" component={AuthContainer} />

    </Switch>
  </Router>
);

export default Routes;