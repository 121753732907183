import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import { withSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import config from "../config";

import { connect } from "react-redux";

import Home from "./Home/Home";

import SecuritySettings from "../Shared/UserSettings/SecurityContainer";

import ProfilesContainer from "./Family/ProfilesContainer";

import EmployeeSetup from "../Auth/Employee/EmployeeSetup";

import HealthContainer from "./Health/HealthContainer";
//import SummaryContainer from './Summary/SummaryContainer'

import ReviewContainer from "./Review/ReviewContainer";
//import SignatureContainer from './Summary/SignatureContainer'

import HippaLog from "./HIPAA/HippaLog";

import NavList from "./Nav/NavList";
import SettingsMenu from "./Nav/SettingsMenu";

import BaseContainer from "../Shared/Layouts/BaseContainer";

import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./Redux/Store/index";

import api from "../Shared/Functions/APIHelpers";

import {
  loadUser,
  loadConditionCategories,
  loadStates
} from "../Shared/Redux/Actions/index";
import {
  loadAddresses,
  loadProfiles,
  loadMessageSummaries,
  setNewMessageCount
} from "./Redux/Actions/index";

//import { loadUser, userLoaded } from './Redux/Actions/index'
import {
  getUser,
  getConditionCategories,
  getStates
} from "../Shared/Functions/StaticFunctions";
import {
  getProfiles,
  getUserAddresses
} from "./Shared/Functions/StaticFunctions";

const mapDispatchToProps = dispatch => {
  return {
    loadUser: user => dispatch(loadUser(user)),
    loadConditionCategories: conditionCategories =>
      dispatch(loadConditionCategories(conditionCategories)),
    loadStates: states => dispatch(loadStates(states)),
    loadAddresses: addresses => dispatch(loadAddresses(addresses)),
    loadProfiles: profiles => dispatch(loadProfiles(profiles)),
    loadMessageSummaries: messageSummaries =>
      dispatch(loadMessageSummaries(messageSummaries)),
    setNewMessageCount: messageCount =>
      dispatch(setNewMessageCount(messageCount))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer,
    isUserLoaded: state.AuthReducer.isLoaded,
    isConditionCategoriesLoaded: state.ConditionCategoryReducer.isLoaded,
    isStatesLoaded: state.StateReducer.isLoaded,

    isUserAddressesLoaded: state.ProfilesReducer.addressesLoaded,
    isProfilesLoaded: state.ProfilesReducer.profilesLoaded,
    isMessageSummariesLoaded: state.MessageReducer.isMessageSummariesLoaded,
    messageSummaries: state.MessageReducer.messageSummaries,
    newMessageCount: state.MessageReducer.newMessageCount
  };
};

class PrivateRoute extends React.Component {
  state = {
    loaded: true,
    isAuthenticated: false,
    user: {}
  };

  componentDidMount() {
    if (!this.props.isUserLoaded) {
      getUser(Auth)
        .then(user => {
          this.props.loadUser(user);
          this.loadEverythingElse(user.user_id);
        })
        .catch(err => {
          //if 401 comes back from get user, they have not agreed to terms
          this.props.history.push("setup-complete");
          console.log("GOOD ERROR");
        });
    }

    if (!this.props.isConditionCategoriesLoaded) {
      getConditionCategories(Auth).then(categories => {
        this.props.loadConditionCategories(categories);
      });
    }

    if (!this.props.isStatesLoaded) {
      getStates(Auth).then(states => {
        this.props.loadStates(states);
      });
    }
  }

  loadEverythingElse = userID => {
    if (!this.props.isProfilesLoaded) {
      getProfiles(Auth, userID).then(profiles => {
        this.props.loadProfiles(profiles);
      });
    }

    if (!this.props.isUserAddressLoaded) {
      getUserAddresses(Auth, userID).then(addresses => {
        this.props.loadAddresses(addresses);
      });
    }

    if (!this.props.isMessageSummariesLoaded) {
      api
        .getMessageSummaries(userID)
        .then(response => {
          this.props.loadMessageSummaries(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Revision" });
          console.log(err);
        });
      api
        .getNewMessageCount(userID)
        .then(response => {
          this.props.setNewMessageCount(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting New Message Count" });
          console.log(err);
        });
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;

    return (
      <Route
        {...rest}
        render={props => {
          return (
            <BaseContainer
              {...props}
              user={this.props.user}
              reformUser={this.props.auth}
              component={Component}
              navList={NavList}
              home={"/user"}
              hasMessageBar={true}
              hasMessageMenu={true}
              messageSummaries={this.props.messageSummaries}
              newMessageCount={this.props.newMessageCount}
              setNewMessageCount={this.props.setNewMessageCount}
              settingsMenu={SettingsMenu}
              isLoaded={this.props.isProfilesLoaded}
            />
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute)
PrivateRoute = withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PrivateRoute)
  )
);

const Routes = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/user" user={props.user} component={Home} />
          <Route
            path="/user/setup-complete"
            render={props => (
              <EmployeeSetup
                {...props}
                user={props.user}
                flowBypass="agreement"
              />
            )}
          />
          <PrivateRoute
            exact
            path="/user/home"
            user={props.user}
            component={Home}
          />
          <PrivateRoute
            path="/user/security"
            user={props.user}
            component={SecuritySettings}
          />
          <PrivateRoute
            path="/user/hippalog"
            user={props.user}
            component={HippaLog}
          />
          <PrivateRoute
            path="/user/family"
            user={props.user}
            component={ProfilesContainer}
          />
          <PrivateRoute
            path="/user/health"
            user={props.user}
            component={HealthContainer}
          />
          <PrivateRoute
            path="/user/review"
            user={props.user}
            component={ReviewContainer}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
//export default connect(null, mapDispatchToProps)(Routes);