export const styles = theme => ({
  container: {
    float: "left",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    minWidth: 330,
    "@media only screen and (max-width: 600px)": {
      minWidth: 343,
      float: "none",
      marginRight: 0
    }
  },
  container_expanded: {
    float: "left",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      minWidth: 343,
      float: "none",
      marginRight: 0
    }
  },
  card: {
    minWidth: 330,
    "@media only screen and (max-width: 600px)": {
      minWidth: 343,
      width: "100%"
    },
    minHeight: 250
  },
  cardContent: {
    minHeight: 175
  },
  fieldGroup: {
    float: "left",
    marginRight: 100,
    marginBottom: 12,
    "@media only screen and (max-width: 600px)": {
      width: 250,
      marginRight: 0
    }
  },
  fieldName: {
    fontSize: 12,
    color: "grey"
  },
  fieldValue: {
    fontSize: 20
  },
  pos: {
    marginBottom: 12
  }
});
