import React from "react";
import PropTypes from "prop-types";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmAlert from "../../Shared/Components/ConfirmAlert";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import { styles } from "./Styles/WelcomeStyles";

import InvitationModal from "../../Shared/Components/InvitationModal";
import InviteTypeModal from "./InviteTypeModal";
import Paper from "@material-ui/core/Paper";

const mapStateToProps = state => {
  return {
    auth: state.AuthReducer
  };
};

const initialState = {
  expanded: "panel1",
  inviteTypeOpen: false,
  invitationModalOpen: false,
  confirmAlertOpen: false
};

class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  handleClickEmployees = () => {
    this.props.history.push("/employer/employees");
  };

  handleClickCreateAccount = () => {
    //this.props.history.push("/user/auth");
    window.location.href = "http://localhost:3000/user/auth";
  };

  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  enableMFA = () => {
    this.props.history.push("/employer/security");
  };  

  handleConfirmRedirect = () => {
    this.setState({ confirmAlertOpen: true});
  };

  DoNotRedirect = () => {
    this.setState({ confirmAlertOpen: false });
  };

  DoRedirect = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleClickCreateAccount()
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.DoNotRedirect}
          isYes={this.DoRedirect}
          AlertTitle={"Redirect Warning"}
          AlertText={"You will be logged out an redirect to create your own account. Is this ok?"}
        />
        <InvitationModal
          history={this.props.history}
          PrintableURL={'printable-invite'}
          PrintableOption={true}
          Type={1}
          Employer={this.props.reformUser.employer_id}
          employerCode={this.props.reformUser.employer_code}
          employerName={this.props.reformUser.employer_name}
          quotingEndDate={this.props.reformUser.quoting_end_date}
          Customer={""}
          Inviter={this.props.reformUser.profile_id}
          Open={this.state.invitationModalOpen}
          Close={() => {
            this.setState({ invitationModalOpen: false });
          }}
        />
        <InviteTypeModal
          enqueueSnackbar={this.props.enqueueSnackbar}
          isOpen={this.state.inviteTypeOpen}
          close={() => {
            this.setState({ inviteTypeOpen: false });
          }}
          employerCode={this.props.reformUser.employer_code}
          employerName={this.props.reformUser.employer_name}
          quotingEndDate={this.props.reformUser.quoting_end_date}
        />

        <div style={{ height: 20 }} />
        <Typography variant="h4" gutterBottom>
          Welcome to Reform Health!{" "}
        </Typography>
        <Typography variant="body1">
          Let’s walk you through a few simple steps to get your company up and running!
        </Typography>

        <div style={{ height: 20 }} />

        <ExpansionPanel
          expanded={this.state.expanded === "panel1"}
          onChange={this.handleChange("panel1")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              Invite your employees to create their accounts{" "}
            </Typography>
            <Typography className={classes.secondaryHeading} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: "80%" }}>
                <Typography variant="body1">
                  In order to get accurate quotes for your benefits, each full
                  time employee should create a Reform Health account. If they
                  happen to be waiving, that’s ok. They can indicate that right
                  on the home screen and sign their application.
                </Typography>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    this.setState({ invitationModalOpen: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Go
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

              <ExpansionPanel
                expanded={this.state.expanded === "panel2"}
                onChange={this.handleChange("panel2")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    Enable stronger security with 2-Step Verification
                  </Typography>
                  <Typography className={classes.secondaryHeading} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <Typography variant="body1" gutterBottom>
                        With 2-Step Verification, you'll protect your account with both a password and your phone.
                      </Typography>
                      <Typography variant='body2' gutterBottom>
                      How it works
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        When logging into your account, in addition to your password,
                        you will be prompted to enter a single use pin. This pin will be texted to your phone or you can use a mobile
                        app to generate one. This means that even if someone were able to guess your password, they could still not
                        log into your account!
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.enableMFA}
                        variant="contained"
                        color="primary"
                      >
                        Go
                      </Button>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.expanded === "panel3"}
          onChange={this.handleChange("panel3")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              Create your own employee account{" "}
            </Typography>
            <Typography className={classes.secondaryHeading} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: "80%" }}>
                <Typography variant="body1">
                  The account you’re currently using is only for managing your
                  company. Like your employees, you will need to create a
                  private account so you can enter your family information and
                  medical history.
                </Typography>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={this.handleConfirmRedirect}
                  variant="contained"
                  color="primary"
                >
                  Go
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>        
      </React.Fragment>
    );
  }
}

WelcomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WelcomePage);
/*
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(WelcomePage));



*/